<template>
  <div class="guideline-section p-4 bg-white rounded-lg shadow-md mb-4">
    <h3 class="text-lg font-semibold mb-3">{{ title }}</h3>
    <div v-for="(item, index) in parsedItems" :key="index" class="mb-3">
      <div v-if="title === 'Age of Roof'" class="mb-2">
        <p><strong>Material:</strong> {{ formatText(item.material) }}</p>
        <p>
          <strong>Age Comparison:</strong> {{ formatText(item.age_comparison) }}
        </p>
        <p v-if="item.age_range_min || item.age_range_max">
          <strong>Age Range:</strong> {{ item.age_range_min }} -
          {{ item.age_range_max }}
        </p>
        <p v-else><strong>Age:</strong> {{ item.age_single_value }} years</p>
        <p>
          <strong>Conditions:</strong>
          <span
            v-for="condition in item.conditions"
            :key="condition"
            class="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full mr-2"
            >{{ formatText(condition) }}</span
          >
        </p>
        <p v-if="item.custom_condition">
          <strong>Custom Condition:</strong> {{ item.custom_condition }}
        </p>
        <p>
          <strong>Last Updated By:</strong>
          {{ item.username_of_last_user_update }}
        </p>
        <p>
          <strong>Last Updated At:</strong> {{ formatDate(item.updated_at) }}
        </p>
      </div>
      <div v-else-if="title === 'Electrical'">
        <p>
          <strong>Conditions:</strong>
          <span
            v-for="condition in item.conditions"
            :key="condition"
            class="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full mr-2"
            >{{ formatText(condition) }}</span
          >
        </p>
        <p v-if="item.notes"><strong>Notes:</strong> {{ item.notes }}</p>
        <p>
          <strong>Last Updated By:</strong>
          {{ item.username_of_last_user_update }}
        </p>
        <p>
          <strong>Last Updated At:</strong> {{ formatDate(item.updated_at) }}
        </p>
      </div>
      <div v-else-if="title === 'Four Point Inspection'">
        <p><strong>Home Age:</strong> {{ item.homeAge }} years</p>
        <p><strong>Last Update:</strong> {{ item.lastUpdate }} years</p>
        <p>
          <strong>Coverage Amount:</strong> ${{
            item.coverageAmount ? item.coverageAmount.toLocaleString() : 'N/A'
          }}
        </p>
        <p><strong>Systems Updated:</strong> {{ item.systemsUpdated }} years</p>
        <p><strong>Photos Included:</strong> {{ item.photosIncluded }}</p>
        <p>
          <strong>Inspection Required:</strong> {{ item.inspectionRequired }}
        </p>
        <p>
          <strong>Last Updated By:</strong>
          {{ item.username_of_last_user_update }}
        </p>
        <p>
          <strong>Last Updated At:</strong> {{ formatDate(item.updated_at) }}
        </p>
      </div>
      <div v-else-if="title === 'Age of Home'">
        <p><strong>Condition:</strong> {{ formatText(item.condition) }}</p>
        <div v-if="item.additionalInfo" class="ml-4">
          <p v-if="item.additionalInfo.noLimit"><strong>No Limit</strong></p>
          <p v-if="item.additionalInfo.yearFrom || item.additionalInfo.yearTo">
            <strong>Year Range:</strong> {{ item.additionalInfo.yearFrom }} -
            {{ item.additionalInfo.yearTo }}
          </p>
          <p v-if="item.additionalInfo.ageFrom || item.additionalInfo.ageTo">
            <strong>Age Range:</strong> {{ item.additionalInfo.ageFrom }} -
            {{ item.additionalInfo.ageTo }}
          </p>
          <p>
            <strong>Requires Inspection:</strong>
            {{ item.additionalInfo.requiresInspection }}
          </p>
          <p>
            <strong>Requires Proof of Updates:</strong>
            {{ item.additionalInfo.requiresProofOfUpdates }}
          </p>
          <p v-if="item.additionalInfo.countyRestrictions">
            <strong>County Restrictions:</strong>
            {{ item.additionalInfo.countyRestrictions }}
          </p>
          <p v-if="item.additionalInfo.additionalDetails">
            <strong>Additional Details:</strong>
            {{ item.additionalInfo.additionalDetails }}
          </p>
        </div>
        <p>
          <strong>Last Updated By:</strong>
          {{ item.username_of_last_user_update }}
        </p>
        <p>
          <strong>Last Updated At:</strong> {{ formatDate(item.updated_at) }}
        </p>
      </div>
      <div v-else>
        <p v-for="(value, key) in item" :key="key">
          <strong>{{ formatText(key) }}:</strong> {{ value }}
        </p>
        <p>
          <strong>Last Updated By:</strong>
          {{ item.username_of_last_user_update }}
        </p>
        <p>
          <strong>Last Updated At:</strong> {{ formatDate(item.updated_at) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuidelineSection',
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    parsedItems() {
      return this.items.map((item) => {
        try {
          return typeof item === 'string' ? JSON.parse(item) : item;
        } catch (e) {
          console.error('Failed to parse item:', item, e);
          return {};
        }
      });
    },
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    formatText(text) {
      return text
        ? text.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
        : 'N/A';
    },
  },
};
</script>

<style scoped>
.guideline-section {
  max-width: 400px;
}
.guideline-section h3 {
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 0.5rem;
}
</style>
