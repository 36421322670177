<template>
  <AppHeaderDesktop />
  <div class="text-gray-800 antialiased">
    <main>
      <div
        class="relative pb-32 flex content-center items-center justify-center"
        style="min-height: 75vh"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1267&amp;q=80');
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="lg:pr-12">
                <h1 class="text-white font-semibold md:text-5xl text-3xl">
                  Growing and Pushing Boundaries
                </h1>
                <p class="mt-4 text-lg text-gray-300">
                  Insurance Express and Member Companies are a group of
                  companies who work together
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style="height: 70px; transform: translateZ(0px)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-[#F2F2F4] fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <section class="pb-20 bg-[#F2F2F4] -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-row flex-wrap mx-auto">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-2 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div class="">
                    <a href="https://www.protegovip.com">
                      <img
                        class="mx-auto mb-[20px] w-[1440px]"
                        src="@/assets/images/membercompanylogos/RocketFlood.png"
                        alt=""
                      />
                    </a>
                    <div></div>
                  </div>
                  <h6 class="text-xl font-semibold">
                    #1 Flood Insurance Agency in the US
                  </h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    We are a super powered insurance agency specializing in
                    personal and commercial flood insurance. Using a combination
                    of technology, insurance expertise and customer service, we
                    help clients secure flood insurance in 49 states across the
                    country.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 px-2 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div>
                    <a href="https://www.quoteslash.com">
                      <img
                        class="mx-auto mb-[20px] w-[400px]"
                        src="@/assets/images/membercompanylogos/Quoteslash.svg"
                        alt=""
                      />
                    </a>
                  </div>
                  <h6 class="text-xl font-semibold">
                    Home Insurance for Mortgage Professionals
                  </h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    Quote Slash is the only online platform dedicated to
                    providing fast, accurate home insurance quotes to mortgage
                    and real estate professionals. Our proprietary software
                    scans all of the markets, quoting every insurance carrier
                    within minutes, returning the lowest home insurance rates
                    available on any home, any age, anywhere instantly!
                  </p>
                </div>
              </div>
            </div>
            <div class="md:pt-6 w-full md:w-4/12 px-2 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-[5px] flex-auto">
                  <div>
                    <a href="https://www.str8linegroup.com">
                      <img
                        class="mx-auto mb-[0px] h-[110px] w-[300px]"
                        src="@/assets/images/membercompanylogos/Str8line.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <h6 class="text-xl font-semibold">
                    Access the Insurance Express Model
                  </h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    When a proven insurance alliance in the West met an equally
                    top-selling insurance firm in the East, fireworks went off
                    as we put our minds together to form Str8line Insurance
                    Group, an industry-changing, bold and innovative insurance
                    franchise model.
                  </p>
                </div>
              </div>
            </div>
            <div class="md:pt-6 w-full md:w-4/12 px-2 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-[5px] flex-auto">
                  <div>
                    <a href="https://nubuildinsurance.com/">
                      <img
                        class="mx-auto mb-4 h-[110px] w-[300px]"
                        src="@/assets/images/membercompanylogos/Nubuildlogo.svg"
                        alt=""
                      />
                    </a>
                  </div>
                  <h6 class="text-xl font-semibold">
                    New Construction Insurance
                  </h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    NuBuild Insurance Group is a subsidiary of Insurance
                    Express, the largest writer of homeowner insurance in the
                    USA and the #1 Insurance Agency in the country for new
                    construction homes. We specialize in working solely with
                    home builders, new construction developments and new
                    construction homeowners.
                  </p>
                </div>
              </div>
            </div>
            <div class="md:pt-6 w-full md:w-4/12 px-2 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-[5px] flex-auto">
                  <div>
                    <a href="https://www.protegovip.com">
                      <img
                        class="mx-auto mb-[0px] h-[120px] w-[150px]"
                        src="@/assets/images/membercompanylogos/Protego-VIP-1.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <h6 class="text-xl font-semibold">High Value Insurance</h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    Customized protection for the distinguised client. Protego
                    VIP offers a white glove experience and access to high value
                    insurance markets.
                  </p>
                </div>
              </div>
            </div>

            <div class="md:pt-6 w-full md:w-4/12 px-2 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-[5px] flex-auto">
                  <div>
                    <a href="https://www.theleadexchange.com">
                      <img
                        class="mx-auto mb-[0px] h-[110px] w-[300px]"
                        src="@/assets/images/membercompanylogos/Theleadexchangelogo.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <h6 class="text-xl font-semibold">
                    Get Access to our Vast Markets
                  </h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    By bridging access to every A-rated homeowner's insurance
                    company in Florida, we assist our partner agents to capture
                    and retain more business through our referral program The
                    Lead Exchange.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
  <AppFooter />
</template>
<script>
import AppHeaderDesktop from './AppHeaderDesktop.vue';
import AppFooter from './AppFooter.vue';
export default {
  name: 'AboutUs',
  components: {
    AppHeaderDesktop,
    AppFooter,
  },
};
</script>
