<template>
  <div>
    <AppHeaderDesktop />
    <ProductPageHeroBanner :productType="productType" />
    <homeInsurancePage v-if="productType === 'homeinsurance'" />
    <autoInsurancePage v-if="productType === 'autoinsurance'" />
    <businessInsurancePage v-if="productType === 'businessinsurance'" />
    <floodInsurancePage v-if="productType === 'floodinsurance'" />
    <lifeInsurancePage v-if="productType === 'lifeinsurance'" />
    <medicarePage v-if="productType === 'medicare'" />
    <petInsurancePage v-if="productType === 'petinsurance'" />
    <umbrellaInsurancePage v-if="productType === 'umbrellainsurance'" />
    <AppFooter />
  </div>
</template>

<script>
import AppHeaderDesktop from '../components/AppHeaderDesktop.vue';
import ProductPageHeroBanner from '../components/productpagecomponents/productPageHeroBanner.vue';
import ProductPage from '../components/productpagecomponents/productPageMainTemplate.vue';
import homeInsurancePage from '../components/productpagecomponents/homeInsurancePage.vue';
import autoInsurancePage from '../components/productpagecomponents/autoInsurancePage.vue';
import businessInsurancePage from '../components/productpagecomponents/businessInsurancePage.vue';
import floodInsurancePage from '../components/productpagecomponents/floodInsurancePage.vue';
import lifeInsurancePage from '../components/productpagecomponents/lifeInsurancePage.vue';
import medicarePage from '../components/productpagecomponents/medicarePage.vue';
import petInsurancePage from '../components/productpagecomponents/petInsurancePage.vue';
import umbrellaInsurancePage from '../components/productpagecomponents/umbrellaInsurancePage.vue';

import AppFooter from '../components/AppFooter.vue';
import { useRoute } from 'vue-router';
import { ref } from 'vue';

export default {
  name: 'InsuranceProductPage',

  components: {
    AppHeaderDesktop,
    ProductPageHeroBanner,
    AppFooter,
    ProductPage,
    homeInsurancePage,
    autoInsurancePage,
    businessInsurancePage,
    floodInsurancePage,
    lifeInsurancePage,
    medicarePage,
    petInsurancePage,
    umbrellaInsurancePage,
  },

  setup() {
    const route = useRoute();
    const productType = ref(route.params.productType);

    console.log('Route parameter - productType:', productType.value);

    return { productType };
  },
};
</script>

<!-- Remove the style tag if it's not being used -->
