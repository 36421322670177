<template>
  <div class="space-y-6 text-left md:text-center">
    <h2
      v-if="!changeRequestSelected && !confirmChange"
      class="text-3xl font-semibold mb-6"
    >
      Change Request
    </h2>
    <!-- <p class="text-lg font-medium text-gray-600 mb-4">Policy Number: <span class="text-blue-500">{{ policyNumber }}</span></p> -->
    <p
      v-if="!changeRequestSelected && !confirmChange"
      class="text-md text-gray-700 mb-8"
    >
      Please select an option below:
    </p>

    <div
      v-if="!changeRequestSelected && !confirmChange"
      class="p-2 overflow-y-auto flex justify-center items-center"
    >
      <div class="max-w-xs max-h-[40vh]">
        <!-- Group related options with headings -->
        <div class="space-y-4">
          <div>
            <h3 class="text-xl font-semibold mb-4 flex items-center">
              Coverage Limits<Icon
                icon="map:sheild"
                class="ml-2"
                color="#656563"
              ></Icon>
            </h3>
            <Button
              class="btn-fixed-width mb-2 bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Increase Dwelling Coverage')"
            >
              <span>Increase Dwelling Coverage</span>
            </Button>
            <Button
              class="btn-fixed-width mb-2 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Decrease Dwelling Coverage')"
            >
              <span>Decrease Dwelling Coverage</span>
            </Button>
            <Button
              class="btn-fixed-width mb-2 bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Increase Personal Property Coverage')"
            >
              <span>Increase Personal Property</span>
            </Button>
            <Button
              class="btn-fixed-width mb-2 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Decrease Personal Property Coverage')"
            >
              <span>Decrease Personal Property</span>
            </Button>

            <h3 class="text-xl font-semibold mb-4 flex items-center">
              Deductibles<Icon
                icon="map:sheild"
                class="ml-2"
                color="#656563"
              ></Icon>
            </h3>
            <Button
              class="btn-fixed-width mb-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Change AOP Deductible')"
            >
              <span>Change AOP Deductible</span>
            </Button>
            <Button
              class="btn-fixed-width mb-2 bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Change Hurricane Deductible')"
            >
              <span>Change Hurricane Deductible</span>
            </Button>

            <h3 class="text-xl font-semibold mb-4 flex items-center">
              Mortgagee Clause<Icon
                icon="icon-park-twotone:writing-fluently"
                class="ml-2"
                color="#656563"
              ></Icon>
            </h3>
            <Button
              class="btn-fixed-width mb-2 bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Add Mortgagee Clause')"
            >
              <span>Add Mortgagee Clause</span>
            </Button>
            <Button
              class="btn-fixed-width mb-2 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Change Mortgagee Clause')"
            >
              <span>Change Mortgagee Clause</span>
            </Button>
            <Button
              class="btn-fixed-width mb-2 bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Remove Mortgagee Clause')"
            >
              <span>Remove Mortgagee Clause</span>
            </Button>
          </div>

          <div>
            <h3 class="text-xl font-semibold mb-4 flex items-center">
              Insured<Icon
                icon="ph:users-fill"
                class="ml-2"
                color="#656563"
              ></Icon>
            </h3>
            <Button
              class="btn-fixed-width mb-2 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Add Insured')"
            >
              <span>Add Insured</span>
            </Button>
            <Button
              class="btn-fixed-width mb-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Remove Insured')"
            >
              <span>Remove Insured</span>
            </Button>
          </div>
          <div>
            <h3 class="text-xl font-semibold mb-4 flex items-center">
              Other<Icon
                icon="material-symbols:other-admission-outline-rounded"
                class="ml-2"
                color="#656563"
              ></Icon>
            </h3>
            <Button
              class="btn-fixed-width mb-2 bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              @click="selectOption('Make Custom a Service Request')"
            >
              <span>Other</span>
            </Button>
          </div>

          <!-- Add more groups as needed -->
        </div>
      </div>
    </div>
    <button
      v-if="!changeRequestSelected && !confirmChange"
      class="absolute top-0 right-0 md:top-3 md:right-3 mr-2 mt-0 md:mr-6 md:mt-6 text-white bg-gray-500 hover:bg-gray-600 rounded-full p-2 leading-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-300 ease-in-out"
      @click="closeManagePolicy"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>

    <div v-if="changeRequestSelected" class="px-2 md:px-6">
      <div class="mt-2 md:mt-4 space-y-4 text-center">
        <h2 class="text-lg md:text-xl font-semibold text-gray-800 mb-2">
          I would like to
          <span class="text-lg md:text-xl font-bold text-gray-800 mb-2">{{
            selectedOption
          }}</span>
        </h2>
        <h2 class="text-lg md:text-xl font-semibold text-gray-800">
          For my policy number:
          <span class="text-blue-600">{{ policyNumber }}</span>
        </h2>
        <h2
          v-if="isCurrencyInput"
          class="text-lg md:text-xl font-semibold text-gray-800"
        >
          To: <span class="text-blue-600">$ {{ inputValue }}</span>
        </h2>
        <h2
          v-if="isDropdownInput"
          class="text-lg md:text-xl font-semibold text-gray-800"
        >
          To: <span class="text-blue-600">{{ inputValue.label }}</span>
        </h2>
        <!-- <p class="text-sm text-gray-600 mt-3">Please provide detailed information for your request.</p> -->
        <!-- Currency Input -->
        <div v-if="isCurrencyInput" class="flex justify-center items-center">
          <span class="mr-2">$</span>
          <InputText
            v-model="inputValue"
            @input="formatCurrency"
            :placeholder="placeholderText"
            class="bg-white border border-gray-300 rounded-md my-2 py-2 px-4 leading-tight focus:outline-none focus:border-blue-500"
          />
        </div>
        <!-- Dropdown Input -->
        <Dropdown
          v-if="isDropdownInput"
          :options="dropdownOptions"
          optionLabel="label"
          v-model="inputValue"
          :placeholder="placeholderText"
          class="w-full bg-white border border-gray-300 rounded-md py-2 px-4 leading-tight focus:outline-none focus:border-blue-500"
        />

        <!-- Default Text Input -->
        <InputText
          v-if="!isCurrencyInput && !isDropdownInput && !isTextAreaInput"
          v-model="inputValue"
          :placeholder="placeholderText"
          class="w-full bg-white border border-gray-300 rounded-md py-2 px-4 leading-tight focus:outline-none focus:border-blue-500"
        />
        <!-- Text Area Input -->
        <TextArea
          v-if="isTextAreaInput"
          v-model="inputValue"
          :placeholder="placeholderText"
          class="w-full bg-white border border-gray-300 rounded-md py-2 px-4 leading-tight focus:outline-none focus:border-blue-500"
        />
      </div>
      <div class="mt-6 text-center">
        <p class="text-lg text-gray-700">
          Are you sure you want to submit this change?
        </p>
        <div class="flex flex-col sm:flex-row justify-center mt-2 md:mt-4">
          <button
            class="bg-red-500 hover:bg-red-600 text-white font-semibold py-3 px-6 sm:py-2 sm:px-4 rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 mt-4 sm:mt-0 sm:mr-4"
            @click="handleBackClick"
          >
            <div class="flex justify-center items-center">
              <!-- Center Icon Horizontally -->
              <Icon icon="octicon:x-12" />
            </div>
          </button>
          <button
            class="bg-green-500 hover:bg-green-600 text-white font-semibold py-3 px-6 sm:py-2 sm:px-4 rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 mt-4 sm:mt-0"
            @click="confirmChangeFunction"
          >
            <div class="flex justify-center items-center">
              <!-- Center Icon Horizontally -->
              <Icon icon="fluent:checkmark-12-filled" />
            </div>
          </button>
        </div>
      </div>
    </div>

    <SignatureSuccess
      v-if="confirmChange"
      @cancelSignature="handleCancelSignature"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputMask from 'primevue/inputmask';
import TextArea from 'primevue/textarea';
import Button from 'primevue/button';
import SignatureSuccess from './signatureAndSucess.vue';
import { Icon } from '@iconify/vue';

export default {
  name: 'ManagePolicy',
  components: {
    InputText,
    Button,
    Icon,
    TextArea,
    Dropdown,
    InputMask,
    SignatureSuccess,
  },
  props: {
    policyId: {
      type: String,
      required: true,
    },
    policyNumber: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const selectedOption = ref(null);
    const inputValue = ref('');
    const changeRequestSelected = ref(false);
    const selectedDropdownLabel = computed(() => {
      const selected = dropdownOptions.value.find(
        (option) => option.value === inputValue.value,
      );
      return selected ? selected.label : '';
    });
    const resetInput = () => {
      inputValue.value = '';
      changeRequestSelected.value = false;
    };
    const confirmChange = ref(false);
    const confirmChangeFunction = () => {
      confirmChange.value = true;
      changeRequestSelected.value = false;
    };
    const isCurrencyInput = computed(() => {
      return [
        'Increase Dwelling Coverage',
        'Decrease Dwelling Coverage',
        'Increase Personal Property Coverage',
        'Decrease Personal Property Coverage',
      ].includes(selectedOption.value);
    });
    const isDropdownInput = computed(() => {
      return ['Change AOP Deductible', 'Change Hurricane Deductible'].includes(
        selectedOption.value,
      );
    });
    const isTextAreaInput = computed(() => {
      return [
        'Add Mortgagee Clause',
        'Change Mortgagee Clause',
        'Remove Mortgagee Clause',
        'Other',
      ].includes(selectedOption.value);
    });

    const formatCurrency = () => {
      // Extract numeric value, remove non-numeric characters
      let rawValue = inputValue.value.replace(/[^\d.]/g, '');

      // Limit to 9 digits
      if (rawValue.length > 9) {
        rawValue = rawValue.slice(0, 9);
      }

      // Format the value as currency
      const formattedValue = applyCurrencyFormatting(rawValue);

      // Update the input value with the formatted value
      inputValue.value = formattedValue;
    };

    const applyCurrencyFormatting = (value) => {
      // Custom logic to format the value as currency
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    };

    const handleCancelSignature = () => {
      confirmChange.value = false;
      // Add any additional logic you need to handle here
    };

    const selectOption = (value) => {
      selectedOption.value = value;
      changeRequestSelected.value = true; // Set this to true when an option is selected
    };
    const closeManagePolicy = () => {
      // Emit an event named 'close-policy'
      context.emit('close-policy');
      resetInput();
    };
    const placeholderText = computed(() => {
      switch (selectedOption.value) {
        case 'Increase Dwelling Coverage':
        case 'Decrease Dwelling Coverage':
        case 'Increase Personal Property Coverage':
        case 'Decrease Personal Property Coverage':
          return 'Enter amount in dollars';
        case 'Change AOP Deductible':
          return 'Select AOP Deductible amount';
        case 'Change Hurricane Deductible':
          return 'Select Hurricane Deductible percentage or amount';
        case 'Add Mortgagee Clause':
        case 'Change Mortgagee Clause':
        case 'Remove Mortgagee Clause':
        case 'Other':
          return 'Enter details here';
        case 'Add Insured':
          return 'Write First and Last Name';
        case 'Remove Insured':
          return 'Write First and Last Name';
        default:
          return 'Enter details';
      }
    });

    const dropdownOptions = computed(() => {
      console.log('Selected Option:', selectedOption.value);
      if (selectedOption.value === 'Change AOP Deductible') {
        return [
          { label: '$500', value: 500 },
          { label: '$1,000', value: 1000 },
          { label: '$2,500', value: 2500 },
          { label: '$5,000', value: 5000 },
        ];
      }
      if (selectedOption.value === 'Change Hurricane Deductible') {
        return [
          { label: '$500', value: 500 },
          { label: '2%', value: '2%' },
          { label: '5%', value: '5%' },
        ];
      }
      return [];
    });
    const handleBackClick = () => {
      resetInput();
    };

    return {
      selectedOption,
      changeRequestSelected,
      inputValue,
      selectOption,
      closeManagePolicy,
      selectedDropdownLabel,
      dropdownOptions,
      handleBackClick,
      placeholderText,
      isCurrencyInput,
      isDropdownInput,
      isTextAreaInput,
      formatCurrency,
      confirmChangeFunction,
      confirmChange,
      handleCancelSignature,
    };
  },
};
</script>

<style scoped>
.button-interactive {
  transition: transform 0.3s ease-in-out;
}
.button-interactive:hover,
.button-interactive:focus {
  transform: scale(1.05);
}

.btn-fixed-width {
  width: 100%; /* Adjust the width as needed */
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  overflow: hidden; /* Hide overflowing text */
}

/* Webkit (Safari and Chrome) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background color */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar thumb color on hover */
}

/* Firefox */
.scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
}

.scrollbar-thumb:hover {
  background-color: #555; /* Scrollbar thumb color on hover */
}
</style>
