<template>
  <div class="fixed inset-0 overlay-bg flex justify-center items-center z-50">
    <div
      class="bg-white shadow-lg rounded-lg p-4 sm:p-8 max-h-[90vh] max-w-xl sm:max-w-2xl w-full relative"
    >
      <!-- Display ManagePolicy when showChangeRequest is true -->
      <ManagePolicy
        v-if="showChangeRequest"
        :policyId="policyId"
        :policyNumber="policyDetails.policy_number"
        @close-policy="handleClosePolicy"
      />

      <h2
        class="text-gray-800 text-xl sm:text-3xl font-semibold mb-6"
        v-if="!showChangeRequest"
      >
        Policy Details
      </h2>

      <div v-if="!showChangeRequest">
        <!-- Conditionally render either Policy Details or ManagePolicy -->
        <div
          v-if="policyDetails"
          class="bg-slate-100 rounded-lg shadow-md p-4 grid grid-cols-1 md:grid-cols-2 gap-x-4 md:gap-x-8 gap-y-4 mb-6"
        >
          <!-- Policy Details Here -->
          <div class=" ">
            <!-- <div><strong class="font-medium text-gray-800">Company: </strong>{{ policyDetails.writing_company_name || 'N/A' }}</div> -->
            <div>
              <strong class="font-medium text-gray-800">Policy Number: </strong
              >{{ policyDetails.policy_number }}
            </div>
            <div>
              <strong class="font-medium text-gray-800">Effective Date: </strong
              >{{ formatDate(policyDetails.start_date) }}
            </div>
            <div>
              <strong class="font-medium text-gray-800">Expiry Date: </strong
              >{{ formatDate(policyDetails.end_date) }}
            </div>
            <div>
              <strong class="font-medium text-gray-800">Type: </strong
              >{{ policyDetails.policy_type }}
            </div>
          </div>

          <div class="text-center my-auto">
            <img
              src="@/assets/images/carrierLogos/progressive.svg"
              alt="Company Logo"
              class="max-h-16 mx-auto"
            />
          </div>
        </div>
        <div v-else>
          <p>Loading policy details...</p>
        </div>

        <div class="flex flex-col gap-2 items-center sm:grid sm:grid-cols-3">
          <button
            class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 sm:py-3 sm:px-6 rounded transition duration-300 ease-in-out"
            @click="downloadPolicy"
          >
            Download Policy
          </button>
          <button
            class="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 sm:py-3 sm:px-6 rounded transition duration-300 ease-in-out"
            @click="handleButtonClick"
          >
            Change Request
          </button>
          <button
            class="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 sm:py-3 sm:px-6 rounded transition duration-300 ease-in-out"
          >
            Upload Documents
          </button>
        </div>
      </div>

      <div v-if="!showChangeRequest">
        <!-- Close Button -->
        <button
          class="absolute top-2 right-2 sm:top-3 sm:right-3 text-white bg-slate-500 hover:bg-slate-600 rounded-full p-2 leading-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-300 ease-in-out"
          @click="closePolicy"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 sm:h-6 sm:w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, inject, watch } from 'vue';
import axios from 'axios';
import Button from 'primevue/button';
import ManagePolicy from '../customerservice/managepolicy/ManagePolicyForm.vue';

export default {
  name: 'SelectedPolicy',
  components: {
    Button,
    ManagePolicy,
  },
  props: {
    policyId: {
      type: String,
      required: true,
    },
  },

  setup(props, context) {
    const policyDetails = ref(null);
    const store = inject('store'); // Inject the store
    const formatDate = (dateStr) => {
      if (!dateStr) return 'N/A';
      const [year, month, day] = dateStr.split('-');
      return `${day}-${month}-${year}`;
    };

    const showChangeRequest = ref(false); // Initialize the variable to control ManagePolicy visibility

    const showSelectedPolicy = ref(true); // Initialize the variable to control SelectedPolicy visibility

    // Watcher for showChangeRequest
    watch(showChangeRequest, (newValue, oldValue) => {
      console.log(`showChangeRequest changed from ${oldValue} to ${newValue}`);
    });

    // Watcher for showSelectedPolicy
    watch(showSelectedPolicy, (newValue, oldValue) => {
      console.log(`showSelectedPolicy changed from ${oldValue} to ${newValue}`);
    });

    const handleClosePolicy = () => {
      showChangeRequest.value = false;
    };

    const fetchPolicyDetails = async () => {
      try {
        const apiUrl = `/api/policy/details/${encodeURIComponent(props.policyId)}`;
        console.log('Making API call to:', apiUrl);

        const authToken = store.getters.authToken;
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        console.log('API response:', response);

        if (response.status === 200) {
          policyDetails.value = response.data;
        } else {
          console.error('API call failed with status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching policy details:', error);
      }
    };

    // Call fetchPolicyDetails directly without the setup function
    onMounted(fetchPolicyDetails);

    const closePolicy = () => {
      console.log('closePolicy method called');
      showChangeRequest.value = false;
      showSelectedPolicy.value = true;
      // Emit the 'close' event to the child component
      context.emit('close');
    };

    const handleButtonClick = () => {
      showChangeRequest.value = true;
    };

    return {
      policyDetails,
      formatDate,
      closePolicy,
      showChangeRequest,
      showSelectedPolicy,
      handleButtonClick,
      handleClosePolicy,
    };
  },
};
</script>

<style scoped>
.overlay-bg {
  background-color: rgba(
    71,
    85,
    105,
    0.2
  ); /* Equivalent to bg-slate-700 with 20% opacity */
}
</style>
