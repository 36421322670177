<template>
  <div class="min-h-fit relative">
    <!-- Success Message for form -->
    <div
      v-if="formSubmitted"
      class="w-full max-w-md mx-auto bg-white rounded-xl shadow-xl overflow-hidden"
    >
      <div class="bg-green-600 text-white text-center py-4">
        <h2 class="text-xl md:text-2xl font-bold">Submission Confirmed!</h2>
      </div>
      <div class="flex justify-center py-5">
        <Icon
          icon="line-md:clipboard-check-twotone"
          class="text-green-500"
          style="font-size: 3rem"
        />
      </div>
      <div class="p-6 fade-in space-y-3">
        <p
          class="text-lg md:text-xl font-semibold text-gray-800 text-center mb-4"
        >
          Your Mortgagee Change Request is in Good Hands
        </p>
        <p class="text-center text-sm md:text-md text-gray-600 mb-2">
          We're reviewing your request and will update your policy details
          within the next 24-48 hours.
        </p>
        <p class="text-center text-sm md:text-md text-gray-600 mb-2">
          A confirmation email has been sent to
          <span class="font-semibold">{{ requesteeEmail }}</span
          >. Please check your inbox for further instructions.
        </p>
        <p class="text-sm md:text-md text-gray-600 mb-4">
          <span class="font-bold text-center">Need immediate assistance?</span>
          <br />
          <Icon icon="carbon:phone" class="inline-block text-blue-600" />
          <a
            href="tel:+18002699137"
            class="text-center text-blue-600 hover:text-blue-800"
          >
            (800)-269-9137</a
          >
          <br />Our insurance experts are always here to help.
        </p>
        <div class="text-center">
          <a
            href="/"
            class="inline-block text-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          >
            Return to Homepage
          </a>
        </div>
      </div>
    </div>
    <!--Heading-->
    <div v-if="!formSubmitted" class="flex flex-col mb-20 z-0 md:z-10 relative">
      <div class="text-center mx-auto">
        <Icon
          icon="fluent:building-bank-20-filled"
          class="text-red-600 mx-auto text-4xl md:text-6xl"
        />
      </div>
      <h1 class="text-center text-xl md:text-2xl font-bold text-gray-800 mb-2">
        Mortgagee Change
      </h1>
      <!-- Progress Step Indicator -->
      <div class="flex justify-center space-x-4 mb-6">
        <div
          :class="{
            'current-step': currentStep === 1,
            'active-step': currentStep > 1,
          }"
          class="step-indicator"
        >
          1
        </div>
        <div
          :class="{
            'current-step': currentStep === 2,
            'active-step': currentStep > 2,
          }"
          class="step-indicator"
        >
          2
        </div>
        <div
          :class="{
            'current-step': currentStep === 3,
            'active-step': currentStep > 3,
          }"
          class="step-indicator"
        >
          3
        </div>
        <div
          :class="{ 'current-step': currentStep === 4 }"
          class="step-indicator"
        >
          4
        </div>
        <!-- New Step Indicator -->
      </div>
      <!-- Mortgagee Change Section-->
      <div
        v-if="showMortgageeChange"
        class="max-w-4xl mx-auto p-8 bg-white rounded-xl shadow-xl"
      >
        <transition name="fade" mode="out-in">
          <div key="stepContent" class="grid grid-cols-1 gap-6">
            <div
              v-if="currentStep === 1"
              key="step1"
              class="md:grid-cols-2 span-2"
            >
              <h1 class="text-lg font-bold text-center mb-6 text-gray-800">
                {{
                  customerType === 'lender'
                    ? 'Lender: Contact Information'
                    : 'Contact Information'
                }}
              </h1>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <!-- First Name -->
                <div class="mb-2">
                  <label class="block text-gray-700 text-sm font-semibold mb-2"
                    >First Name</label
                  >
                  <input
                    v-model="firstName"
                    type="text"
                    class="capitalize w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                  />
                  <p v-if="firstNameError" class="text-red-500 text-xs italic">
                    {{ firstNameError }}
                  </p>
                </div>
                <!-- Last Name -->
                <div class="mb-2">
                  <label class="block text-gray-700 text-sm font-semibold mb-2"
                    >Last Name</label
                  >
                  <input
                    v-model="lastName"
                    type="text"
                    class="capitalize w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                  />
                  <p v-if="lastNameError" class="text-red-500 text-xs italic">
                    {{ lastNameError }}
                  </p>
                </div>
                <!--Policy Number-->
                <div class="mb-2">
                  <label
                    for="policy-number"
                    class="block text-sm font-semibold text-gray-700 mb-2 flex items-center"
                  >
                    Policy Number
                    <div
                      @mouseover="showPopup = true"
                      @mouseleave="hidePopupWithDelay"
                      class="relative flex items-center"
                    >
                      <Icon
                        icon="mdi:information-outline"
                        class="text-blue-600 ml-2 cursor-pointer"
                        style="height: 1em; width: 1em"
                      />
                      <div
                        v-if="showPopup"
                        class="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-white rounded-lg shadow-lg text-sm text-gray-700 w-64"
                        style="min-width: 250px"
                      >
                        <!-- Adjusted width -->
                        <!-- Popup content goes here -->
                        If you do not know your policy number, use our
                        <router-link to="/policy-search" class="text-red-700"
                          >"Policy Search"</router-link
                        >
                        in the customer service section of the site.
                      </div>
                    </div>
                  </label>
                  <input
                    v-model="policyNumber"
                    type="text"
                    id="policy-number"
                    class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                  />
                  <p
                    v-if="policyNumberError"
                    class="text-red-500 text-xs italic"
                  >
                    {{ policyNumberError }}
                  </p>
                </div>
                <!-- Phone -->
                <div class="mb-2">
                  <label
                    for="phone-number"
                    class="block text-gray-700 text-sm font-semibold mb-2"
                    >Phone Number</label
                  >
                  <InputMask
                    id="phone-number"
                    mask="(999) 999-9999"
                    v-model="phoneNumber"
                    class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                  ></InputMask>
                  <p
                    v-if="phoneNumberError"
                    class="text-red-500 text-xs italic"
                  >
                    {{ phoneNumberError }}
                  </p>
                </div>
                <!-- Email -->
                <div class="mb-2 md:col-span-2">
                  <label
                    class="block text-gray-700 text-sm font-semibold mb-2"
                    >{{
                      customerType === 'lender' ? 'Work Email' : 'Email'
                    }}</label
                  >
                  <input
                    v-model="requesteeEmail"
                    type="email"
                    class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                  />
                  <p
                    v-if="requesteeEmailError"
                    class="text-red-500 text-xs italic"
                  >
                    {{ requesteeEmailError }}
                  </p>
                </div>
              </div>
              <!-- Navigation Buttons for Step 1 -->
              <div class="flex justify-end mt-4">
                <button
                  @click="nextStep"
                  class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Next
                </button>
              </div>
            </div>
            <div
              v-if="currentStep === 2"
              key="step2"
              class="max-w-4xl mx-auto p-4 rounded-lg shadow"
            >
              <div class="mb-12">
                <h1
                  class="text-center text-lg md:text-xl font-bold text-gray-600 mb-4"
                >
                  Select Action
                </h1>
                <div class="flex justify-center space-x-4">
                  <!-- Add button -->
                  <button
                    :class="{
                      'bg-green-500 text-white': mortgageeChangeType === 'add',
                      'border-gray-500 text-gray-600': !mortgageeChangeType,
                      'opacity-50 cursor-not-allowed':
                        mortgageeChangeType && mortgageeChangeType !== 'add',
                    }"
                    :disabled="
                      mortgageeChangeType && mortgageeChangeType !== 'add'
                    "
                    class="border font-bold py-2 px-4 rounded-full hover:bg-blue-600 hover:border-blue-500 hover:text-white flex-1 min-w-[100px]"
                    @click="mortgageeChangeType = 'add'"
                  >
                    Add
                  </button>

                  <!-- Change button -->
                  <button
                    :class="{
                      'bg-green-500 text-white':
                        mortgageeChangeType === 'change',
                      'border-gray-500 text-gray-600': !mortgageeChangeType,
                      'opacity-50 cursor-not-allowed':
                        mortgageeChangeType && mortgageeChangeType !== 'change',
                    }"
                    :disabled="
                      mortgageeChangeType && mortgageeChangeType !== 'change'
                    "
                    class="border font-bold py-2 px-4 rounded-full hover:bg-blue-600 hover:border-blue-500 hover:text-white flex-1 min-w-[100px]"
                    @click="mortgageeChangeType = 'change'"
                  >
                    Change
                  </button>

                  <!-- Remove button -->
                  <button
                    :class="{
                      'bg-green-500 text-white':
                        mortgageeChangeType === 'remove',
                      'border-gray-500 text-gray-600': !mortgageeChangeType,
                      'opacity-50 cursor-not-allowed':
                        mortgageeChangeType && mortgageeChangeType !== 'remove',
                    }"
                    :disabled="
                      mortgageeChangeType && mortgageeChangeType !== 'remove'
                    "
                    class="border font-bold py-2 px-4 rounded-full hover:bg-blue-600 hover:border-blue-500 hover:text-white flex-1 min-w-[100px]"
                    @click="mortgageeChangeType = 'remove'"
                  >
                    Remove
                  </button>
                </div>
                <p
                  v-if="actionSelectionError"
                  class="text-red-500 text-xs italic text-center"
                >
                  Please select an option
                </p>
              </div>
              <!-- Navigation Buttons -->
              <div class="flex justify-center space-x-4 mt-4">
                <button
                  @click="previousStep"
                  class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Back
                </button>
                <button
                  @click="nextStep"
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Next
                </button>
              </div>
            </div>
            <div v-if="currentStep === 3" key="step3" class="max-w-4xl mx-auto">
              <h1 class="text-lg font-bold text-center mb-6 text-gray-800">
                Change Request
              </h1>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <!-- Lienholder Name -->
                <div class="mb-2 md:col-span-2">
                  <label class="block text-gray-700 text-sm font-semibold mb-2"
                    >Lienholder Name</label
                  >
                  <input
                    v-model="lienholderName"
                    type="text"
                    class="form-input"
                    placeholder="Lienholder Name"
                  />
                  <p
                    v-if="lienholderNameError"
                    class="text-red-500 text-xs italic"
                  >
                    {{ lienholderNameError }}
                  </p>
                </div>
                <!-- Policy Number -->
                <div class="mb-2">
                  <label class="block text-gray-700 text-sm font-semibold mb-2"
                    >Policy Number</label
                  >
                  <input
                    v-model="policyNumber"
                    type="text"
                    class="form-input"
                    placeholder="Policy Number"
                  />
                  <p
                    v-if="policyNumberError"
                    class="text-red-500 text-xs italic"
                  >
                    {{ policyNumberError }}
                  </p>
                </div>
                <!-- Mortgagee Position -->
                <div class="mb-2">
                  <label class="block text-gray-700 text-sm font-semibold mb-2"
                    >Position</label
                  >
                  <select class="form-select" v-model="mortgageePosition">
                    <option
                      value=""
                      disabled
                      selected
                      class="placeholder-option"
                    >
                      Select Position
                    </option>
                    <option>1st Mortgagee</option>
                    <option>2nd Mortgagee</option>
                    <option>3rd Mortgagee</option>
                  </select>
                  <p
                    v-if="mortgageePositionError"
                    class="text-red-500 text-xs italic"
                  >
                    {{ mortgageePositionError }}
                  </p>
                </div>
                <!-- Mortgagee Clause -->
                <div class="mb-2 md:col-span-2">
                  <label class="block text-gray-700 text-sm font-semibold mb-2"
                    >Mortgagee Clause (e.g. ISAOA ATIMA)</label
                  >
                  <input
                    v-model="mortgageeClause"
                    type="text"
                    class="form-input"
                    placeholder="Mortgagee Clause"
                  />
                </div>
                <!-- Address -->
                <div class="mb-2 md:col-span-2">
                  <label class="block text-gray-700 text-sm font-semibold mb-2"
                    >Address</label
                  >
                  <input
                    v-model="address"
                    type="text"
                    class="form-input"
                    placeholder="Address"
                  />
                  <p v-if="addressError" class="text-red-500 text-xs italic">
                    {{ addressError }}
                  </p>
                </div>
                <!-- City -->
                <div class="mb-2">
                  <label class="block text-gray-700 text-sm font-semibold mb-2"
                    >City</label
                  >
                  <input
                    v-model="city"
                    type="text"
                    class="form-input"
                    placeholder="City"
                  />
                  <p v-if="cityError" class="text-red-500 text-xs italic">
                    {{ cityError }}
                  </p>
                </div>
                <!-- State -->
                <div class="mb-2">
                  <label
                    class="block text-gray-700 text-sm font-semibold mb-2"
                    for="state-select"
                    >State:</label
                  >
                  <select
                    class="form-select"
                    id="state-select"
                    placeholder="Alabama"
                    v-model="state"
                  >
                    <option value="" selected>Select a state</option>
                    <option
                      v-for="usState in usStates"
                      :key="usState.abbreviation"
                      :value="usState.abbreviation"
                    >
                      {{ usState.abbreviation }}
                    </option>
                  </select>
                  <p v-if="stateError" class="text-red-500 text-xs italic">
                    {{ stateError }}
                  </p>
                </div>
                <!-- Zip Code -->
                <div class="mb-2">
                  <label class="block text-gray-700 text-sm font-semibold mb-2"
                    >Zip Code</label
                  >
                  <input
                    v-model="zip"
                    type="text"
                    class="form-input"
                    placeholder="Zip"
                  />
                  <p v-if="zipError" class="text-red-500 text-xs italic">
                    {{ zipError }}
                  </p>
                </div>
                <!-- Loan Number -->
                <div class="mb-2">
                  <label class="block text-gray-700 text-sm font-semibold mb-2"
                    >Loan Number</label
                  >
                  <input
                    v-model="loanNumber"
                    type="text"
                    class="form-input"
                    placeholder="Loan Number"
                  />
                  <p v-if="loanNumberError" class="text-red-500 text-xs italic">
                    {{ loanNumberError }}
                  </p>
                </div>
              </div>
              <!-- Navigation Buttons -->
              <div class="flex justify-center space-x-4 mt-4">
                <button
                  @click="previousStep"
                  class="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Back
                </button>
                <button
                  @click="nextStep"
                  class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Next
                </button>
              </div>
            </div>
            <div
              v-if="currentStep === 4"
              key="step4"
              class="max-w-4xl mx-auto p-4 shadow-lg rounded-lg"
            >
              <h1 class="text-lg font-bold text-center mb-6 text-gray-800">
                Review Your Selections
              </h1>
              <!-- Display the selected values for review -->
              <div class="mb-6 p-4 bg-gray-100 rounded-lg">
                <p class="text-sm md:text-md">
                  <strong>{{
                    customerType === 'lender' ? 'Lender Name:' : 'Name:'
                  }}</strong>
                  {{ firstName }} {{ lastName }}
                </p>
                <p class="text-sm md:text-md">
                  <strong>{{
                    customerType === 'lender' ? 'Work Email:' : 'Email:'
                  }}</strong>
                  {{ requesteeEmail }}
                </p>
                <p class="text-sm md:text-md">
                  <strong>Phone:</strong> {{ phoneNumber }}
                </p>
              </div>
              <div class="mb-6 p-4 bg-gray-100 rounded-lg">
                <p class="text-sm md:text-md">
                  <strong>Lienholder Name:</strong> {{ lienholderName }}
                </p>
                <p class="text-sm md:text-md">
                  <strong>Policy Number:</strong> {{ policyNumber }}
                </p>
                <p class="text-sm md:text-md">
                  <strong>Mortgagee Position:</strong> {{ mortgageePosition }}
                </p>
                <p class="text-sm md:text-md">
                  <strong>Mortgagee Clause:</strong> {{ mortgageeClause }}
                </p>
                <p class="text-sm md:text-md">
                  <strong>Address:</strong> {{ address }}
                </p>
                <p class="text-sm md:text-md">
                  <strong>City:</strong> {{ city }}
                </p>
                <p class="text-sm md:text-md">
                  <strong>State:</strong> {{ state }}
                </p>
                <p class="text-sm md:text-md">
                  <strong>Zip Code:</strong> {{ zip }}
                </p>
                <p class="text-sm md:text-md">
                  <strong>Loan Number:</strong> {{ loanNumber }}
                </p>
              </div>
              <div v-if="(customerType = 'lender')" class="mt-6">
                <div
                  :class="{
                    'bg-green-500': documentUploaded,
                    'bg-white': !documentUploaded,
                  }"
                  class="py-6 px-4 rounded-lg shadow-sm"
                >
                  <div>
                    <!-- Completed Label -->
                    <div v-if="documentUploaded" class="mb-4">
                      <span
                        class="text-green-600 bg-white py-1 px-3 rounded-full custom-scale custom-fadeIn"
                        >Completed</span
                      >
                    </div>

                    <!-- Heading -->
                    <h2
                      :class="{
                        'text-white': documentUploaded,
                        'text-gray-900': !documentUploaded,
                      }"
                      class="text-lg font-bold mb-4"
                    >
                      Upload your borrower's authorization form
                    </h2>
                    <!-- Secondary heading -->
                    <h3
                      :class="{
                        'text-white': documentUploaded,
                        'text-gray-600': !documentUploaded,
                      }"
                      class="text-sm md:text-md text-gray-700 my-2"
                    >
                      PDF, DOC, DOCX, JPG, JPEG, PNG are accepted file types
                    </h3>

                    <!-- Upload Success Message -->
                    <p v-if="documentUploaded" class="text-white custom-fadeIn">
                      Uploaded: <br /><span class="font-medium">{{
                        uploadedFileName
                      }}</span
                      ><br />
                      (Upload Successful)
                    </p>

                    <div v-if="!documentUploaded">
                      <div class="mt-6">
                        <label
                          class="block text-md md:text-lg text-gray-700 font-medium mb-3 cursor-pointer"
                          for="document-upload"
                        >
                          Upload Document
                        </label>
                        <!-- File Upload Area -->
                        <div class="flex items-center justify-center w-full">
                          <input
                            type="file"
                            class="hidden"
                            id="document-upload"
                            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                            @change="handleFileChange"
                            ref="fileInput"
                          />
                          <label
                            for="document-upload"
                            class="flex flex-col items-center justify-center w-full h-24 md:h-32 p-4 md:p-6 rounded-lg border-4 border-dashed cursor-pointer hover:border-blue-500 transition-colors text-center"
                          >
                            <p class="text-gray-700 text-md md:text-lg">
                              {{ fileLabel }}
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Optional Email Input for Insured -->
              <div class="mb-6 p-4 bg-gray-100 rounded-lg">
                <div class="flex items-center mb-4">
                  <h2 class="text-md md:text-lg font-semibold text-gray-800">
                    {{
                      customerType === 'lender'
                        ? 'Keep your Customer Informed'
                        : 'Keep the Bank Informed'
                    }}
                  </h2>
                  <Icon
                    icon="fluent:alert-48-filled"
                    class="text-red-600 ml-2 text-md md:text-lg"
                  />
                  <!-- Icon aligned to the right of the text -->
                </div>
                <p class="text-md text-gray-700 mb-4">
                  {{
                    customerType === 'lender'
                      ? 'Would you like to send a copy of this request to your customer for status updates? Please enter their email address below:'
                      : 'Would you like to send a copy of this request to your loan officer for status updates? Please enter their email address below:'
                  }}
                </p>
                <input
                  v-model="ccEmail"
                  type="email"
                  placeholder="CC Email (optional)"
                  class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-300 focus:border-transparent"
                />
                <p v-if="ccEmailError" class="text-red-500 text-xs italic">
                  {{ ccEmailError }}
                </p>
              </div>

              <!-- Submit Button -->
              <div class="flex justify-center space-x-4 mt-4">
                <button
                  @click="previousStep"
                  class="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Back
                </button>
                <button
                  @click="submitForm"
                  class="bg-green-500 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <!-- Background Images -->
    <div v-if="!formSubmitted" class="mx-auto max-w-6xl">
      <div class="hidden md:flex justify-between mt-[-200px]">
        <img
          src="@/assets/images/productHeroBanners/businessinsurance/businessinsuranceHeroBannerLeft.svg"
          class="ml-4 w-1/3"
        />
        <div class="flex-grow"></div>
        <img
          src="@/assets/images/productHeroBanners/businessinsurance/businessinsuranceHeroBannerRight.svg"
          class="mr-4 w-1/3"
        />
      </div>
    </div>
  </div>
  <!-- <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Submit Request</button> -->
</template>

<script>
import { Icon } from '@iconify/vue';
import { ref, computed, watch } from 'vue';
import InputMask from 'primevue/inputmask';
import axios from 'axios';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import confetti from 'canvas-confetti';

export default {
  name: 'MortgageeChange',
  props: {
    customerType: {
      type: String,
      required: true,
    },
  },
  components: {
    Icon,
    InputMask,
  },
  setup(props) {
    const usStates = ref([
      { name: 'Alabama', abbreviation: 'AL' },
      { name: 'Alaska', abbreviation: 'AK' },
      { name: 'Arizona', abbreviation: 'AZ' },
      { name: 'Arkansas', abbreviation: 'AR' },
      { name: 'California', abbreviation: 'CA' },
      { name: 'Colorado', abbreviation: 'CO' },
      { name: 'Connecticut', abbreviation: 'CT' },
      { name: 'Delaware', abbreviation: 'DE' },
      { name: 'Florida', abbreviation: 'FL' },
      { name: 'Georgia', abbreviation: 'GA' },
      { name: 'Hawaii', abbreviation: 'HI' },
      { name: 'Idaho', abbreviation: 'ID' },
      { name: 'Illinois', abbreviation: 'IL' },
      { name: 'Indiana', abbreviation: 'IN' },
      { name: 'Iowa', abbreviation: 'IA' },
      { name: 'Kansas', abbreviation: 'KS' },
      { name: 'Kentucky', abbreviation: 'KY' },
      { name: 'Louisiana', abbreviation: 'LA' },
      { name: 'Maine', abbreviation: 'ME' },
      { name: 'Maryland', abbreviation: 'MD' },
      { name: 'Massachusetts', abbreviation: 'MA' },
      { name: 'Michigan', abbreviation: 'MI' },
      { name: 'Minnesota', abbreviation: 'MN' },
      { name: 'Mississippi', abbreviation: 'MS' },
      { name: 'Missouri', abbreviation: 'MO' },
      { name: 'Montana', abbreviation: 'MT' },
      { name: 'Nebraska', abbreviation: 'NE' },
      { name: 'Nevada', abbreviation: 'NV' },
      { name: 'New Hampshire', abbreviation: 'NH' },
      { name: 'New Jersey', abbreviation: 'NJ' },
      { name: 'New Mexico', abbreviation: 'NM' },
      { name: 'New York', abbreviation: 'NY' },
      { name: 'North Carolina', abbreviation: 'NC' },
      { name: 'North Dakota', abbreviation: 'ND' },
      { name: 'Ohio', abbreviation: 'OH' },
      { name: 'Oklahoma', abbreviation: 'OK' },
      { name: 'Oregon', abbreviation: 'OR' },
      { name: 'Pennsylvania', abbreviation: 'PA' },
      { name: 'Rhode Island', abbreviation: 'RI' },
      { name: 'South Carolina', abbreviation: 'SC' },
      { name: 'South Dakota', abbreviation: 'SD' },
      { name: 'Tennessee', abbreviation: 'TN' },
      { name: 'Texas', abbreviation: 'TX' },
      { name: 'Utah', abbreviation: 'UT' },
      { name: 'Vermont', abbreviation: 'VT' },
      { name: 'Virginia', abbreviation: 'VA' },
      { name: 'Washington', abbreviation: 'WA' },
      { name: 'West Virginia', abbreviation: 'WV' },
      { name: 'Wisconsin', abbreviation: 'WI' },
      { name: 'Wyoming', abbreviation: 'WY' },
    ]);
    const showPopup = ref(false);
    const popupHideDelay = ref(800); // Add this constant for delay duration
    const hidePopupWithDelay = () => {
      setTimeout(() => {
        showPopup.value = false;
      }, popupHideDelay.value); // Use the delay constant here
    };
    const showMortgageeChange = ref(true);
    const mortgageeChangeType = ref(null);
    const mortgageeClause = ref('');
    const selectedFile = ref(null);
    const uploadedFileName = ref('');
    const documentUploaded = ref(false);
    const formSubmitted = ref(false);
    const actionSelectionError = ref(false);

    // Local state to hold form data
    const formData = ref({
      customerType: props.customerType,
      firstName: '',
      lastName: '',
      policyNumber: '',
      phoneNumber: '',
      requesteeEmail: '',
      ccEmail: '',
      lienholderName: '',
      mortgageePosition: '',
      address: '',
      city: '',
      state: 'FL',
      zip: '',
      loanNumber: '',
    });

    // Watch for changes in the props and update the local state accordingly
    watch(
      () => props.customerType,
      (newVal) => {
        formData.value.customerType = newVal;
      },
    );

    // Starting Step
    const currentStep = ref(1);

    const { handleSubmit } = useForm();

    // Step 1 validation schema
    const step1Schema = yup.object({
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      policyNumber: yup.string().required('Policy number is required'),
      phoneNumber: yup
        .string()
        .required('Phone number is required')
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          'Phone number must be (XXX) XXX-XXXX',
        ),
      requesteeEmail: yup
        .string()
        .required('Email is required')
        .email('Invalid email format'),
      ccEmail: yup
        .string()
        .nullable()
        .email('Invalid email format')
        .notRequired(), // Add this line for cc_email validation
    });
    // Step 1 Form instance
    const step1Form = useForm({
      validationSchema: step1Schema,
    });

    // Step 1 Fields
    const { value: firstName, errorMessage: firstNameError } = useField(
      'firstName',
      yup.string().required('First name is required'),
    );
    const { value: lastName, errorMessage: lastNameError } = useField(
      'lastName',
      yup.string().required('Last name is required'),
    );
    const { value: policyNumber, errorMessage: policyNumberError } = useField(
      'policyNumber',
      yup.string().required('Policy number is required'),
    );
    const { value: phoneNumber, errorMessage: phoneNumberError } = useField(
      'phoneNumber',
      yup
        .string()
        .required('Phone number is required')
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          'Phone number must be (XXX) XXX-XXXX',
        ),
    );
    const { value: requesteeEmail, errorMessage: requesteeEmailError } =
      useField(
        'requesteeEmail',
        yup
          .string()
          .required('Email is required')
          .email('Invalid email format'),
      );

    // Step 2 validation schema
    const step2Schema = yup.object({
      lienholderName: yup.string().required('Lienholder name is required'),
      mortgageePosition: yup
        .string()
        .required('Mortgagee position is required'),
      address: yup.string().required('Address is required'),
      city: yup.string().required('City is required'),
      state: yup.string().required('State is required'),
      zip: yup
        .string()
        .required('Zip code is required')
        .matches(/^\d{5}$/, 'Zip code must be 5 digits'),
      loanNumber: yup.string().required('Loan number is required'),
    });
    // Step 2 Form instance
    const step2Form = useForm({
      validationSchema: step2Schema,
    });
    // Step 2 Fields
    const { value: lienholderName, errorMessage: lienholderNameError } =
      useField(
        'lienholderName',
        yup.string().required('Lienholder name is required'),
      );
    const { value: mortgageePosition, errorMessage: mortgageePositionError } =
      useField(
        'mortgageePosition',
        yup.string().required('Mortgagee position is required'),
      );
    const { value: address, errorMessage: addressError } = useField(
      'address',
      yup.string().required('Address is required'),
    );
    const { value: city, errorMessage: cityError } = useField(
      'city',
      yup.string().required('City is required'),
    );
    const { value: state, errorMessage: stateError } = useField(
      'state',
      yup.string().required('State is required'),
      { initialValue: 'FL' },
    );
    const { value: zip, errorMessage: zipError } = useField(
      'zip',
      yup
        .string()
        .required('Zip code is required')
        .matches(/^\d{5}$/, 'Zip code must be 5 digits'),
    );
    const { value: loanNumber, errorMessage: loanNumberError } = useField(
      'loanNumber',
      yup.string().required('Loan number is required'),
    );
    const { value: ccEmail, errorMessage: ccEmailError } = useField(
      'ccEmail',
      yup.string().nullable().email('Invalid email format').notRequired(),
    );

    const handleFileChange = (event) => {
      if (event.target.files.length > 0) {
        selectedFile.value = event.target.files[0];
        documentUploaded.value = true; // Set documentUploaded to true when a file is selected
        uploadedFileName.value = event.target.files[0].name; // Update the name of the uploaded file
      }
    };

    const fileLabel = computed(() => {
      if (selectedFile.value) {
        return selectedFile.value.name;
      }
      return 'Click or drag file to this area to upload';
    });
    const submitForm = async () => {
      try {
        const formData = new FormData();
        formData.append('customer_type', props.customerType);
        formData.append('first_name', firstName.value);
        formData.append('last_name', lastName.value);
        formData.append('policy_number', policyNumber.value);
        formData.append('phone', phoneNumber.value);
        formData.append('requestee_email', requesteeEmail.value);
        formData.append('lienholder_name', lienholderName.value);
        formData.append('mortgagee_position', mortgageePosition.value);
        formData.append('mortgagee_change_type', mortgageeChangeType.value);
        formData.append('address', address.value);
        formData.append('city', city.value);
        formData.append('state', state.value);
        formData.append('zip', zip.value);
        formData.append('loan_number', loanNumber.value);

        if (mortgageeClause.value) {
          formData.append('mortgagee_clause', mortgageeClause.value); // Add mortgagee_clause only if it exists
        }

        if (ccEmail.value) {
          formData.append('cc_email', ccEmail.value); // Add cc_email only if it exists
        }

        // Only append the file if it exists and is valid
        if (selectedFile.value && selectedFile.value !== '') {
          formData.append('document', selectedFile.value);
        }

        const response = await axios.post('/api/mortgagee-change', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('Form submitted:', response.data);
        formSubmitted.value = true;
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { x: 0.5, y: 0.5 },
        });
      } catch (error) {
        console.error('Error submitting form:', error);
        // Handle submission errors...
      }
    };


    const nextStep = async () => {
      let validationResult = null;

      if (currentStep.value === 1) {
        console.log('Validating Step 1');
        validationResult = await step1Form.validate();
        console.log('Step 1 Validation Result:', validationResult);
      } else if (currentStep.value === 2) {
        console.log('Validating Step 2');
        if (!['add', 'remove', 'change'].includes(mortgageeChangeType.value)) {
          actionSelectionError.value = true; // Update the ref to true
          console.log('Invalid mortgageeChangeType:', mortgageeChangeType);
          return; // Prevents moving to the next step if the type is not valid
        }
        validationResult = { valid: true }; // Assuming validation passed for step 2
      } else if (currentStep.value === 3) {
        console.log('Validating Step 3');
        validationResult = await step2Form.validate(); // Validate Step 3 with step2Form
        console.log('Step 3 Validation Result:', validationResult);
      }

      console.log('Is form valid?', validationResult?.valid);
      if (validationResult && validationResult.valid && currentStep.value < 4) {
        currentStep.value++;
        console.log('Moving to the next step:', currentStep.value);
      }
      // You can use actionSelectionError.value here to display an error message to the user.
    };

    const previousStep = () => {
      if (currentStep.value > 1) {
        currentStep.value--;
      }
    };

    return {
      usStates,
      currentStep,
      showPopup,
      hidePopupWithDelay,
      showMortgageeChange,
      mortgageeChangeType,
      nextStep,
      step1Form,
      step2Form,
      previousStep,
      submitForm,
      handleFileChange,
      documentUploaded,
      uploadedFileName,
      fileLabel,
      selectedFile,
      handleSubmit,
      formSubmitted,
      // Fields
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      policyNumber,
      policyNumberError,
      phoneNumber,
      phoneNumberError,
      requesteeEmail,
      requesteeEmailError,
      actionSelectionError,
      lienholderName,
      lienholderNameError,
      mortgageePosition,
      mortgageePositionError,
      mortgageeClause,
      address,
      addressError,
      city,
      cityError,
      state,
      stateError,
      zip,
      zipError,
      loanNumber,
      loanNumberError,
      ccEmail,
      ccEmailError,
    };
  },
};
</script>

<style>
.form-input {
  background-color: white;
  border: 1px solid #dddfe2;
  border-radius: 0.375rem; /* 6px */
  padding: 0.5rem 0.75rem; /* 8px 12px */
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}

.form-input:focus {
  border-color: #cbd5e0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.button {
  transition: background-color 0.3s ease-in-out;
}
.button:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  border: 1px solid #dddfe2;
  background-color: white;
  background-position: right 0.5rem center;
  background-size: 1.25em 1.25em;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.placeholder-option {
  color: #a0aec0; /* Tailwind's gray-400, adjust as needed */
}

.form-select:focus {
  border-color: #cbd5e0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.step-indicator {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ddd; /* Default background color */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.5s ease,
    transform 0.5s ease;
}

.active-step {
  background-color: #007bff; /* Active step color */
}

.current-step {
  background-color: #007bff; /* Current step color */
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.fade-in {
  animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
