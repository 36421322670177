<template>
  <div class="h-10 bg-gray-100"></div>
  <div class="max-w-[1440px] mx-auto font-Roboto text-[#646778] mt-[50px]">
    <div class="md:flex justify-between">
      <div class="left-side md:w-[30%] relative">
        <div
          class="sticky top-[30px] xl:w-[300px] md:h-[1050px] lg:w-[288px] md:w-[230px] px-[20px] mt-[50px]"
        >
          <div class="progress-bar hidden md:block">
            <ul>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#home1"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Commerical Insurance Introduction</a
                >
              </li>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#whatis"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >What is Commercial Insurance</a
                >
              </li>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#home3"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Main Price Impacting Factors</a
                >
              </li>
              <li class="after:hidden hover:text-[#D24948] duration-300">
                <a
                  href="#home4"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Bundle and Save</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right-side px-[20px] md:w-[70%]">
        <div>
          <div class="flex text-[11px] items-center text-[#000105] uppercase">
            <span class="pr-[20px] hover:text-[#D24948] duration-300"
              >Insurance</span
            >
            <span class="pr-[20px]">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25L15.75 11.25C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75L8.25 12.75C7.83579 12.75 7.5 12.4142 7.5 12Z"
                  fill="#000105"
                />
              </svg>
            </span>
            <span class="hover:text-[#D24948] duration-300"
              >Commercial Insurance</span
            >
          </div>
          <div id="home1">
            <h2
              class="xl:text-[35px] lg:text-[25px] text-[25px] pt-[10px] font-Merriweather font-black tracking-[-0.2px] pb-[20px] text-[#1F2937]"
            >
              Understanding Commercial Insurance
            </h2>
            <p class="text-[15px] my-[15px] leading-[27px]">
              Welcome to Insurance Express's commercial insurance guide! As a
              leading provider of commercial insurance in our region, we're
              dedicated to helping business owners protect their assets and
              their employees. With years of experience in the industry, we have
              the knowledge and expertise to help you understand the ins and
              outs of commercial insurance.<br />In this guide, we'll explain
              what commercial insurance is, the different types of policies
              available, and provide tips on how to get the best rate on your
              policy. Whether you're a small business owner or managing a large
              corporation, we hope that this guide will provide you with the
              information you need to make an informed decision.
            </p>
          </div>

          <div class="" id="whatis">
            <div class="bg-[#EDEDED] lg:p-6 p-4 rounded-md">
              <h2
                class="xl:text-[20px] lg:text-[15px] text-[15px] pt-[10px] font-roboto font-bold tracking-[-0.2px] pb-[20px] text-[#1F2937]"
              >
                Understanding Commercial Insurance: Policies, Coverage, and
                Limits.
              </h2>
              <ul class="list-disc ml-12 text-red-600 text-3xl leading-7">
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >What is commercial insurance and why is it important</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >Types of commercial insurance policies: general liability,
                    property, and workers' compensation</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >Components of a commercial insurance policy: coverage
                    limits, deductibles, and endorsements</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >What does commercial insurance typically cover?</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >What is not covered by commercial insurance?</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >How much commercial insurance coverage do you need?</span
                  >
                </li>
              </ul>
            </div>
            <hr class="w-full mt-6 h-[3px] bg-gray-300" />
          </div>

          <div class="hidden md:block">
            <h2
              class="xl:text-[35px] lg:text-[25px] text-[25px] pt-[10px] font-Merriweather font-black tracking-[-0.2px] pb-[20px] text-[#1F2937]"
            >
              What is commercial insurance and why is it important?
            </h2>
            <p class="text-[15px] my-[15px] leading-[27px]">
              Commercial insurance is a type of insurance that provides
              financial protection for businesses and their assets. It can help
              cover the costs associated with property damage, liability claims,
              and injuries to employees or customers. Without commercial
              insurance, a business could be left with a significant financial
              burden in the event of an unexpected event.
            </p>

            <div class="grid lg:grid-cols-2 sm:grid-cols-2 gap-2 mb-10">
              <div class="grid col">
                <p class="text-[15px] my-[15px] leading-[27px]">
                  In this article, we'll cover the key topics related to
                  commercial insurance, including:
                </p>
                <ul class="list-disc ml-12 text-red-600 text-3xl leading-7">
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Types of commercial insurance policies and what they
                      cover.</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Components of a commercial insurance policy, such as
                      coverage limits, deductibles, and endorsements.</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >What commercial insurance typically covers and what it
                      does not cover.</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Factors to consider when determining how much coverage
                      you need.</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Common mistakes to avoid when selecting a commercial
                      insurance policy.</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Tips for saving money on commercial insurance
                      premiums.</span
                    >
                  </li>
                </ul>
              </div>
              <div class="grid col my-auto mx-auto">
                <img src="@/assets/images/productPageImages/book.png" alt="" />
              </div>
            </div>
            <div>
              <p class="text-[15px] my-[15px] font-bold leading-[27px]">
                Types of commercial insurance policies:
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                General liability insurance: Covers claims of bodily injury,
                property damage, and advertising injury.<br />Property
                insurance: Covers damage to your business property, including
                buildings, equipment, and inventory.<br />Workers' compensation
                insurance: Provides financial assistance to employees who are
                injured or become ill while on the job.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                Components of a commercial insurance policy:
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Coverage limits: The maximum amount that your insurance policy
                will pay out for a covered claim.<br />
                Deductibles: The amount that you'll need to pay out of pocket
                before your insurance coverage kicks in.<br />Endorsements:
                Additional coverage options that can be added to your policy to
                provide extra protection for specific risks.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                What does commercial insurance typically cover?
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Property damage caused by natural disasters, theft, and other
                incidents.<br />
                Liability claims from third parties, including bodily injury and
                property damage.<br />
                Injuries to employees while on the job.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                What is not covered by commercial insurance?
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Damage caused by floods or earthquakes (requires separate
                insurance policies).<br />
                Intentional acts or criminal activity.<br />
                Normal wear and tear or lack of maintenance.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                How much commercial insurance coverage do you need?
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                The amount of coverage you need depends on factors such as the
                size of your business, the value of your assets, and the level
                of risk in your industry.
              </p>
            </div>
          </div>
        </div>

        <section>
          <div id="home2">
            <h2
              class="xl:text-[35px] lg:text-[25px] text-[25px] font-Merriweather font-black tracking-[-0.2px] py-[20px] text-[#1F2937]"
            >
              Main Price Impacting Factors for Life Insurance
            </h2>
            <p class="text-[15px] my-[15px] leading-[27px]">
              When comparing rates and coverages for life insurance, it is
              important to keep in mind data points that Insurance Companies
              find most important when evaluating your rate.
            </p>
          </div>
          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/multi.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Type of Business
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                The industry in which your business operates in can have a
                significant impact on the cost of your insurance. Industries
                with higher risks, such as construction or healthcare, may have
                higher insurance premiums.
              </p>
            </div>
          </div>
          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/multi.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Location
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                Where your business is located can also affect the cost of your
                insurance. Factors such as crime rates and natural disaster
                risks can impact your rate.
              </p>
            </div>
          </div>
          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/credit.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Size of the Business
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                The size of your business can affect the cost of your insurance,
                as well as the amount of coverage you need.
              </p>
            </div>
          </div>

          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/marital.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Claims History
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                A history of claims can increase your insurance premiums. It's
                important to take steps to prevent accidents and minimize risks
                in your business to keep your insurance costs as low as
                possible.
              </p>
            </div>
          </div>
        </section>

        <div
          class="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 pb-[30px] mt-[20px]"
          id="home4"
        >
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/homeInsurance.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-black md:py-[15px] py-2"
                >
                  Home Insurance
                </h3>
                <div>
                  <a
                    href="/products/homeinsurance"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/autoInsurance.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-black md:py-[15px] py-2"
                >
                  Auto Insurance
                </h3>
                <div>
                  <a
                    href="/products/autoinsurance"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/floodInsurance.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-[#1F2937] md:py-[15px] py-2"
                >
                  Flood Insurance
                </h3>
                <div>
                  <a
                    href="/products/floodinsurance"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="border-t pt-[50px] 2xl:pb-[436px] md:pb-[200px] pb-24 md:mx-[-20px]"
        >
          <div class="md:mx-[20px] bg-[#EDEDED] rounded-[5px]">
            <div
              class="bg-[url('@/assets/images/productPageImages/watch-bg.png')] lg:bg-contain bg-[length:395px] bg-no-repeat bg-right-bottom"
            >
              <div class="xl:p-[30px] lg:p-[20px] p-[20px]">
                <h2
                  class="xl:text-[25px] lg:text-[21px] text-[21px] font-extrabold text-[#1F2937] mb-[20px]"
                >
                  WATCH YOUR SAVINGS ADD UP.
                </h2>
                <p
                  class="text-[15px] py-[15px] leading-[27px] 2xl:max-w-[495px] xl:max-w-[415px] lg:max-w-[301px] md:max-w-[405px] sm:max-w-[500px]"
                >
                  Always check to see if you can save money by adding an
                  additional product to your home insurance policy! (Auto, Life,
                  Flood, etc.)
                </p>
                <div
                  class="mt-[20px] lg:pb-0 md:pb-[150px] sm:pb-[230px] pb-[200px] bg-padding"
                >
                  <a
                    href="/chooseproducts"
                    class="bg-[#DB0909] hover:bg-[#DC2626] duration-500 max-w-[220px] w-full flex items-center rounded-[5px] uppercase text-white text-center tracking-[2px] text-[14px] px-[40px] py-[15px]"
                    >Get a quote
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      class="ml-[5px]"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
                        fill="#ffffff"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
