<template>
  <div
    v-if="isVisible"
    class="modal fixed inset-0 bg-black bg-opacity-40 z-50 overflow-auto"
    @click="handleBackdropClick"
  >
    <div
      class="modal-content transform transition-all duration-300 scale-95 bg-white m-auto p-8 border border-gray-300 rounded-lg shadow-lg w-4/5 md:w-1/2"
      @click.stop
    >
      <span
        class="close cursor-pointer text-gray-500 hover:text-black float-right text-3xl font-bold"
        @click="close"
        >&times;</span
      >
      <h2 class="text-2xl font-semibold mb-4">Edit Carrier</h2>
      <form @submit.prevent="submit" class="animate-fade-in">
        <!-- Carrier Name Input -->
        <div class="form-group mb-4">
          <label
            for="carrier_name"
            class="block text-sm font-medium text-gray-700"
            >Name:</label
          >
          <input
            v-model="editFormData.carrier_name"
            type="text"
            id="carrier_name"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <!-- Customer Service Email Input -->
        <div class="form-group mb-4">
          <label
            for="customer_service_email"
            class="block text-sm font-medium text-gray-700"
            >Customer Service Email:</label
          >
          <input
            v-model="editFormData.customer_service_email"
            type="email"
            id="customer_service_email"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <!-- Customer Service Phone Input -->
        <div class="form-group mb-4">
          <label
            for="customer_service_phone"
            class="block text-sm font-medium text-gray-700"
            >Customer Service Phone:</label
          >
          <input
            v-model="formattedCustomerServicePhone"
            type="tel"
            id="customer_service_phone"
            v-mask="'(###) ###-####'"
            maxlength="14"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <!-- Claims Phone Number Input -->
        <div class="form-group mb-4">
          <label
            for="claims_phone_number"
            class="block text-sm font-medium text-gray-700"
            >Claims Phone Number:</label
          >
          <input
            v-model="formattedClaimsPhoneNumber"
            type="tel"
            id="claims_phone_number"
            v-mask="'(###) ###-####'"
            maxlength="14"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <!-- Billing Phone Number Input -->
        <div class="form-group mb-4">
          <label
            for="billing_phone_number"
            class="block text-sm font-medium text-gray-700"
            >Billing Phone Number:</label
          >
          <input
            v-model="formattedBillingPhoneNumber"
            type="tel"
            id="billing_phone_number"
            v-mask="'(###) ###-####'"
            maxlength="14"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <!-- Main Website Input -->
        <div class="form-group mb-4">
          <label
            for="main_website"
            class="block text-sm font-medium text-gray-700"
            >Main Website:</label
          >
          <input
            v-model="editFormData.main_website"
            type="url"
            id="main_website"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <!-- Carrier Billing Link Input -->
        <div class="form-group mb-4">
          <label
            for="carrier_billing_link"
            class="block text-sm font-medium text-gray-700"
            >Carrier Billing Link:</label
          >
          <input
            v-model="editFormData.carrier_billing_link"
            type="url"
            id="carrier_billing_link"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <!-- Carrier Customer Service Link Input -->
        <div class="form-group mb-4">
          <label
            for="carrier_customer_service_link"
            class="block text-sm font-medium text-gray-700"
            >Carrier Customer Service Link:</label
          >
          <input
            v-model="editFormData.carrier_customer_service_link"
            type="url"
            id="carrier_customer_service_link"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <!-- Overnight Mailing Address Input -->
        <div class="form-group mb-4">
          <label
            for="overnight_mailing_address"
            class="block text-sm font-medium text-gray-700"
            >Overnight Mailing Address:</label
          >
          <input
            v-model="editFormData.overnight_mailing_address"
            type="text"
            id="overnight_mailing_address"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <!-- Operating States Chips -->
        <div class="form-group mb-4">
          <label
            for="operating_states"
            class="block text-sm font-medium text-gray-700"
            >Operating States:</label
          >
          <MultiSelect
            v-model="operatingStates"
            display="chip"
            :options="stateOptions"
            optionLabel="name"
            placeholder="Select operating states"
            class="w-full md:w-20rem"
          />
        </div>
        <!-- Products Input -->
        <div class="form-group mb-4">
          <label for="products" class="block text-sm font-medium text-gray-700"
            >Products:</label
          >
          <MultiSelect
            v-model="products"
            display="chip"
            :options="productOptions"
            optionLabel="name"
            placeholder="Select products"
            class="w-full md:w-20rem"
          />
        </div>
        <!-- Logo Upload Input -->
        <div class="form-group mb-4">
          <label
            for="logo"
            class="block text-sm font-medium text-gray-700 cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block"
          >
            Upload Image
            <input
              type="file"
              id="logo"
              ref="fileInput"
              @change="previewImage"
              class="hidden"
              accept="image/png, image/jpeg, image/svg+xml"
            />
          </label>
          <div
            @dragover.prevent="dragOver"
            @dragleave.prevent="dragLeave"
            @drop.prevent="handleDrop"
            class="drop-zone"
          >
            Drag and drop your image here or click to select
          </div>
          <div class="flex mt-2">
            <div v-if="imagePreview" class="flex-none">
              <img
                :src="imagePreview"
                alt="Logo preview"
                class="h-20"
                @load="imageLoaded = true"
              />
            </div>
            <div v-if="imageInfo" class="ml-4 flex-grow">
              <p class="text-sm">File Name: {{ imageInfo.name }}</p>
              <p class="text-sm">Size: {{ formatBytes(imageInfo.size) }}</p>
              <p class="text-sm">Type: {{ imageInfo.type }}</p>
              <p class="text-sm" v-if="imageDimensions">
                Dimensions: {{ imageDimensions.width }} x
                {{ imageDimensions.height }} px
              </p>
            </div>
          </div>
          <p v-if="imageError" class="text-red-500 text-xs mt-2">
            {{ imageError }}
          </p>
        </div>
        <!-- Status Input -->
        <div class="form-group mb-4">
          <label for="status" class="block text-sm font-medium text-gray-700"
            >Status:</label
          >
          <select
            v-model="editFormData.status"
            id="status"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="Active">Active &#128994;</option>
            <option value="Disable">Disable &#128308;</option>
            <option value="Archive">Archive &#128452;</option>
          </select>
        </div>
        <button
          type="submit"
          class="btn-primary bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-full transition-colors duration-300"
        >
          Save Changes
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { Icon } from '@iconify/vue';
import MultiSelect from 'primevue/multiselect';
import axios from 'axios';

export default {
  name: 'EditCarrierModal',
  components: {
    Icon,
    MultiSelect,
  },
  props: ['editFormData', 'isVisible'],
  setup(props, { emit }) {
    const imagePreview = ref(null);
    const imageError = ref('');
    const imageLoaded = ref(false);
    const imageInfo = ref(null);
    const imageDimensions = ref(null);
    const operatingStates = ref([]);
    const products = ref([]);
    const stateOptions = ref([
      { name: 'Florida', value: 'FL' },
      { name: 'Texas', value: 'TX' },
      { name: 'New York', value: 'NY' },
      { name: 'New Jersey', value: 'NJ' },
      { name: 'New Hampshire', value: 'NH' },
      { name: 'Rhode Island', value: 'RI' },
      { name: 'Massachusetts', value: 'MA' },
      { name: 'Georgia', value: 'GA' },
      { name: 'South Carolina', value: 'SC' },
      { name: 'North Carolina', value: 'NC' },
      { name: 'Connecticut', value: 'CT' },
    ]);

    const productOptions = ref([
      { name: 'HO-3', value: 'HO-3' },
      { name: 'HO-6', value: 'HO-6' },
      { name: 'DP-3', value: 'DP-3' },
      { name: 'Personal Auto', value: 'Personal Auto' },
      { name: 'Commercial Auto', value: 'Commercial Auto' },
    ]);
    const formattedCustomerServicePhone = computed({
      get() {
        return formatPhoneNumber(props.editFormData.customer_service_phone);
      },
      set(newValue) {
        props.editFormData.customer_service_phone = newValue;
      },
    });

    const formattedClaimsPhoneNumber = computed({
      get() {
        return formatPhoneNumber(props.editFormData.claims_phone_number);
      },
      set(newValue) {
        props.editFormData.claims_phone_number = newValue;
      },
    });

    const formattedBillingPhoneNumber = computed({
      get() {
        return formatPhoneNumber(props.editFormData.billing_phone_number);
      },
      set(newValue) {
        props.editFormData.billing_phone_number = newValue;
      },
    });

    function formatPhoneNumber(phoneNumber) {
      const cleaned = ('' + phoneNumber).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return phoneNumber;
    }

    watch(
      () => props.editFormData,
      (newData) => {
        if (props.isVisible) {
          operatingStates.value = newData.operating_states || [];
          products.value = newData.products || [];
          imagePreview.value = newData.logo;
        }
      },
      { immediate: true, deep: true },
    );

    watch(
      () => props.isVisible,
      (newValue) => {
        if (!newValue) {
          resetForm();
        }
      },
    );

    function close() {
      resetForm();
      emit('close');
    }

    function resetForm() {
      emit('update:editFormData', {
        carrier_name: '',
        customer_service_email: '',
        customer_service_phone: '',
        claims_phone_number: '',
        billing_phone_number: '',
        main_website: '',
        logo: '',
        carrier_billing_link: '',
        carrier_customer_service_link: '',
        overnight_mailing_address: '',
        operating_states: [],
        products: [],
        status: '',
      });
      operatingStates.value = [];
      products.value = [];
      imagePreview.value = null;
    }

    function handleBackdropClick(event) {
      if (event.target.classList.contains('modal')) {
        close();
      }
    }

    function dragOver(event) {
      event.currentTarget.classList.add('drag-over');
    }

    function dragLeave(event) {
      event.currentTarget.classList.remove('drag-over');
    }

    function handleDrop(event) {
      const files = event.dataTransfer.files;
      if (files.length > 0 && this.$refs.fileInput) {
        this.$refs.fileInput.files = files;
        previewImage({ target: { files: files } });
      }
      event.currentTarget.classList.remove('drag-over');
    }

    function previewImage(event) {
      const file = event.target.files[0];

      if (!file) {
        imageError.value = 'Please select an image to upload.';
        return;
      }

      const validImageTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const isValidType =
        validImageTypes.includes(file.type) ||
        ['png', 'jpeg', 'jpg', 'svg'].includes(fileExtension);

      if (!isValidType) {
        imageError.value =
          'The selected file must be an image (PNG, JPEG, or SVG).';
        return;
      }

      if (file.size > 2000000) {
        imageError.value = 'The image size should not exceed 2MB.';
        return;
      }

      imageError.value = '';
      imageInfo.value = {
        name: file.name,
        size: file.size,
        type: file.type,
      };

      const reader = new FileReader();
      reader.onload = (e) => {
        imagePreview.value = e.target.result;
        props.editFormData.logoFile = file;
      };
      reader.onerror = (error) => {
        imageError.value = 'Error reading file: ' + error.message;
      };
      reader.readAsDataURL(file);
    }

    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    function submit() {
      // Update editFormData operating_states and products with the current values
      props.editFormData.operating_states = operatingStates.value;
      props.editFormData.products = products.value;

      let formData = new FormData();
      Object.keys(props.editFormData).forEach((key) => {
        if (key === 'operating_states') {
          // Append each item in the operating_states array correctly as JSON objects
          props.editFormData[key].forEach((state, index) => {
            formData.append(`${key}[${index}][name]`, state.name);
            formData.append(`${key}[${index}][value]`, state.value);
          });
        } else if (key === 'products') {
          // Append each item in the products array correctly as JSON objects
          props.editFormData[key].forEach((product, index) => {
            formData.append(`${key}[${index}][name]`, product.name);
            formData.append(`${key}[${index}][value]`, product.value);
          });
        } else if (key === 'logoFile' && props.editFormData[key]) {
          formData.append('logo', props.editFormData[key]);
        } else if (key === 'logo' && props.editFormData[key]) {
          // Only append the logo if it does not already contain the base URL
          if (!props.editFormData[key].startsWith(axios.defaults.baseURL)) {
            formData.append(key, props.editFormData[key]);
          }
        } else {
          formData.append(key, props.editFormData[key]);
        }
      });

      // Log FormData contents for debugging
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }

      emit('save', formData);
    }

    return {
      imagePreview,
      imageError,
      imageLoaded,
      imageInfo,
      imageDimensions,
      operatingStates,
      products,
      stateOptions,
      productOptions,
      formattedCustomerServicePhone,
      formattedClaimsPhoneNumber,
      formattedBillingPhoneNumber,
      formatPhoneNumber,
      close,
      handleBackdropClick,
      dragOver,
      dragLeave,
      handleDrop,
      previewImage,
      formatBytes,
      submit,
    };
  },
};
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.drop-zone {
  height: 100px;
  border: 2px dashed #007bff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #bdbdbd;
  transition: border-color 0.3s;
}

.drop-zone.drag-over {
  border-color: #4caf50;
}
</style>
