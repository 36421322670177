<template>
  <div class="p-4 bg-white shadow rounded-lg">
    <h2 class="text-2xl font-semibold mb-4">Contact Information</h2>
    <div class="grid grid-cols-1 gap-4">
      <!-- First Name -->
      <div>
        <label class="block text-gray-600 text-sm font-semibold mb-1">
          First Name
        </label>
        <InputText
          v-model="firstName"
          @blur="validateContactInfo"
          class="p-inputtext w-full border border-gray-300 rounded-lg py-2 px-3"
          :disabled="!isEditing"
        />
        <div class="text-red-500 text-sm">{{ firstNameError }}</div>
      </div>

      <!-- Last Name -->
      <div>
        <label class="block text-gray-600 text-sm font-semibold mb-1">
          Last Name
        </label>
        <InputText
          v-model="lastName"
          @blur="validateContactInfo"
          class="p-inputtext w-full border border-gray-300 rounded-lg py-2 px-3"
          :disabled="!isEditing"
        />
        <div class="text-red-500 text-sm">{{ lastNameError }}</div>
      </div>

      <!-- Email -->
      <div>
        <label class="block text-gray-600 text-sm font-semibold mb-1">
          Email
        </label>
        <InputText
          v-model="email"
          @blur="validateContactInfo"
          class="p-inputtext w-full border border-gray-300 rounded-lg py-2 px-3"
          :disabled="!isEditing"
        />
        <div class="text-red-500 text-sm">{{ emailError }}</div>
      </div>

      <!-- Phone Number -->
      <div>
        <label class="block text-gray-600 text-sm font-semibold mb-1">
          Phone Number
        </label>
        <div class="relative">
          <InputText
            v-model="formattedPhoneNumber"
            @blur="validateContactInfo"
            class="p-inputtext w-full border border-gray-300 rounded-lg py-2 px-3"
            :disabled="!isEditing"
            :mask="'(999) 999-9999'"
          />
          <div class="mt-2">
            <Button
              v-if="!isEditing"
              label="Edit"
              @click="toggleEditing"
              class="p-2 text-gray-600 hover:text-blue-500"
            />
            <Button
              v-else
              label="Update"
              :disabled="!formModified"
              @click="updateContactInfo"
              class="p-2 mx-4 bg-blue-500 text-white hover:bg-blue-600 transition duration-300 ease-in-out"
            />
          </div>
        </div>
        <div class="text-red-500 text-sm">{{ phoneNumberError }}</div>
      </div>
    </div>
  </div>
  <Toast />
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import * as yup from 'yup'; // Import yup for validation

export default {
  name: 'ContactInfo',
  components: {
    InputText,
    Button,
    Toast,
  },
  setup() {
    const store = useStore();
    const toast = useToast();

    // Define the validation schema using yup
    const validationSchema = computed(() =>
      yup.object({
        firstName: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        email: yup
          .string()
          .email('Must be a valid email')
          .required('Email is required'),
        phoneNumber: yup.string().required('Phone number is required'),
      }),
    );

    // Define error properties for each field
    const firstNameError = ref('');
    const lastNameError = ref('');
    const emailError = ref('');
    const phoneNumberError = ref('');

    // Use computed to create a reactive reference to customerInfo
    const customerInfo = computed(() => {
      console.log('Vuex getter: customerInfo', store.getters.customerInfo); // Log #1
      return store.getters.customerInfo;
    });

    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const phoneNumber = ref('');
    const formattedPhoneNumber = ref('');
    const isEditingPhoneNumber = ref(false);
    const isEditing = ref(false);
    const formModified = ref(false);

    const toggleEditing = () => {
      isEditing.value = !isEditing.value;
    };

    const validateContactInfo = () => {
      const valuesToValidate = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phoneNumber: formattedPhoneNumber.value,
      };

      // Validate the values against the validation schema
      try {
        validationSchema.value.validateSync(valuesToValidate, {
          abortEarly: false,
        });
        // Reset error messages
        firstNameError.value = '';
        lastNameError.value = '';
        emailError.value = '';
        phoneNumberError.value = '';
        return true; // Validation passed
      } catch (error) {
        // Validation failed, set error messages
        firstNameError.value =
          error.inner.find((e) => e.path === 'firstName')?.message || '';
        lastNameError.value =
          error.inner.find((e) => e.path === 'lastName')?.message || '';
        emailError.value =
          error.inner.find((e) => e.path === 'email')?.message || '';
        phoneNumberError.value =
          error.inner.find((e) => e.path === 'phoneNumber')?.message || '';
        return false; // Validation failed
      }
    };

    const updateContactInfo = () => {
      // Check if any changes have been made to the form
      if (!formModified.value) return;

      // Perform validation and update contact information if validation passes
      if (validateContactInfo()) {
        // Dispatch action to Vuex store for updating customer information
        store.dispatch('updateCustomerInfo', {
          id: customerInfo.value.id,
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          phoneNumber: phoneNumber.value.replace(/\D/g, ''), // Remove non-digit characters
          customerId: customerInfo.value.customerId,
        });

        // Reset editing state and form modification flag
        isEditing.value = false;
        formModified.value = false;

        // Use the defined toast instance
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Contact information updated successfully.',
          life: 3000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'Error updating contact information.',
          life: 3000,
        });
      }
    };

    watch([firstName, lastName, email, phoneNumber], () => {
      formModified.value = true;
    });

    onMounted(() => {
      // Initialize local refs with reactive customerInfo values
      firstName.value = customerInfo.value.firstName;
      lastName.value = customerInfo.value.lastName;
      email.value = customerInfo.value.email;
      formattedPhoneNumber.value = formatPhoneNumber(
        customerInfo.value.phoneNumber,
      );
    });

    const updatePhoneNumber = () => {
      // Format the phone number before updating it in Vuex
      formattedPhoneNumber.value = formatPhoneNumber(
        formattedPhoneNumber.value,
      );
      // Update the Vuex store with the new phone number
      store.dispatch(
        'updatePhoneNumber',
        formattedPhoneNumber.value.replace(/\D/g, ''),
      ); // Remove formatting
      isEditingPhoneNumber.value = false;
    };

    const toggleEditingPhoneNumber = () => {
      isEditingPhoneNumber.value = !isEditingPhoneNumber.value;
    };

    // Function to format the phone number
    function formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) return '';
      const digitsOnly = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters
      const formatted = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
      return formatted;
    }

    return {
      firstName,
      lastName,
      email,
      phoneNumber,
      formattedPhoneNumber,
      isEditingPhoneNumber,
      toggleEditingPhoneNumber,
      updatePhoneNumber,
      updateContactInfo,
      toggleEditing,
      validateContactInfo,
      validationSchema,
      isEditing,
      customerInfo,
      firstNameError,
      lastNameError,
      emailError,
      phoneNumberError,
      formModified,
    };
  },
};
</script>
