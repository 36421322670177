<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 bg-black bg-opacity-40 z-50 flex justify-center items-center overflow-auto"
  >
    <div
      class="bg-white m-4 p-4 md:p-8 border border-gray-300 rounded-lg shadow-lg w-full md:w-2/3 lg:w-1/2 animate-fade-in max-h-[90vh] overflow-y-auto"
    >
      <span
        class="cursor-pointer text-gray-500 hover:text-black float-right text-3xl font-bold"
        @click="close"
        >&times;</span
      >
      <h2 class="text-2xl font-semibold mb-4 text-center">Update Guideline</h2>
      <div
        class="flex overflow-x-auto whitespace-nowrap mb-4 pb-2 border-b border-gray-300 space-x-2"
      >
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          @click="selectTab(tab)"
          :class="[
            'flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md cursor-pointer transition-colors duration-300',
            {
              'bg-blue-500 text-white': selectedTab === tab,
              'bg-white text-gray-700 hover:bg-blue-500 hover:text-white':
                selectedTab !== tab,
            },
          ]"
        >
          <Icon :icon="tab.icon" class="mr-2" />
          {{ tab.label }}
        </button>
      </div>
      <form @submit.prevent="submit" class="space-y-4">
        <component
          :is="selectedTab.component"
          :modelValue="newGuideline[selectedTab.model]"
          @update:modelValue="updateModel"
          :isVisible="isVisible"
        />
        <button
          type="submit"
          class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mt-4 transition duration-300 transform hover:scale-105"
        >
          Update Guideline
        </button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, watchEffect, markRaw, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Icon } from '@iconify/vue';
import RoofAgeInput from './addGuidelineInputs/RoofAgeInput.vue';
import ElectricalInput from './addGuidelineInputs/ElectricalInput.vue';
import FourPointInspectionInput from './addGuidelineInputs/FourPointInspectionInput.vue';
import AgeOfHomeInput from './addGuidelineInputs/AgeOfHomeInput.vue';
import AcceptableNamedInsuredsInput from './addGuidelineInputs/AcceptableNamedInsuredsInput.vue';
import AssumedWindMitCreditsInput from './addGuidelineInputs/AssumedWindMitCreditsInput.vue';
import ClaimsHistoryInput from './addGuidelineInputs/ClaimsHistoryInput.vue';
import PoolsInput from './addGuidelineInputs/PoolsInput.vue';
import DwellingLimitsInput from './addGuidelineInputs/DwellingLimitsInput.vue';
import FlatRoofInput from './addGuidelineInputs/FlatRoofInput.vue';
import PEXPlumbingInput from './addGuidelineInputs/PEXPlumbingInput.vue';
import OpeningProtectionCreditsInput from './addGuidelineInputs/OpeningProtectionCreditsInput.vue';
import ProofOfRepairsInput from './addGuidelineInputs/ProofOfRepairsInput.vue';
import UnderwriterConfirmationInput from './addGuidelineInputs/UnderwriterConfirmationInput.vue';

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  carriers: {
    type: Array,
    required: true,
  },
  selectedCarrierId: {
    type: Number,
    required: false,
    default: null,
  },
  guideline: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const emit = defineEmits(['update', 'close']);
const store = useStore();

const tabs = ref([
  {
    label: 'Roof Age',
    component: markRaw(RoofAgeInput),
    model: 'age_of_roof',
    icon: 'mdi:home-roof',
  },
  {
    label: 'Electrical',
    component: markRaw(ElectricalInput),
    model: 'electrical',
    icon: 'mdi:lightning-bolt',
  },
  {
    label: 'Four Point Inspection',
    component: markRaw(FourPointInspectionInput),
    model: 'four_point',
    icon: 'mdi:check-circle-outline',
  },
  {
    label: 'Age of Home',
    component: markRaw(AgeOfHomeInput),
    model: 'age_of_home',
    icon: 'mdi:calendar-clock',
  },
  {
    label: 'Named Insureds',
    component: markRaw(AcceptableNamedInsuredsInput),
    model: 'acceptable_named_insureds',
    icon: 'mdi:account-group-outline',
  },
  {
    label: 'Wind Mit Credits',
    component: markRaw(AssumedWindMitCreditsInput),
    model: 'assumed_wind_mit_credits',
    icon: 'mdi:weather-windy',
  },
  {
    label: 'Claims History',
    component: markRaw(ClaimsHistoryInput),
    model: 'claims_history',
    icon: 'mdi:file-document-edit-outline',
  },
  {
    label: 'Pools',
    component: markRaw(PoolsInput),
    model: 'pools',
    icon: 'mdi:pool',
  },
  {
    label: 'Dwelling Limits',
    component: markRaw(DwellingLimitsInput),
    model: 'dwelling_limits',
    icon: 'mdi:home-currency-usd',
  },
  {
    label: 'Flat Roof',
    component: markRaw(FlatRoofInput),
    model: 'flat_roof',
    icon: 'mdi:home-outline',
  },
  {
    label: 'PEX Plumbing',
    component: markRaw(PEXPlumbingInput),
    model: 'pex_plumbing',
    icon: 'mdi:pipe',
  },
  {
    label: 'Opening Protection Credits',
    component: markRaw(OpeningProtectionCreditsInput),
    model: 'opening_protection_credits',
    icon: 'mdi:shield-home-outline',
  },
  {
    label: 'Proof of Repairs',
    component: markRaw(ProofOfRepairsInput),
    model: 'proof_of_repairs',
    icon: 'mdi:hammer-wrench',
  },
  {
    label: 'Underwriter Confirmation',
    component: markRaw(UnderwriterConfirmationInput),
    model: 'underwriter_confirmation',
    icon: 'mdi:account-check-outline',
  },
]);

const selectedTab = ref(tabs.value[0]); // Ensure this is reactive

const selectTab = (tab) => {
  selectedTab.value = tab;
};

// Helper function to parse JSON safely
const parseJson = (jsonString) => {
  try {
    console.log('Parsing JSON:', jsonString);
    return JSON.parse(jsonString);
  } catch (e) {
    return jsonString;
  }
};

// Watch for selectedCarrierId changes and set carrier_id
watch(
  () => props.selectedCarrierId,
  (newValue) => {
    newGuideline.value.carrier_id = newValue;
  },
);

// Initialize newGuideline with parsed JSON strings from props.guideline
const newGuideline = ref({});

watchEffect(() => {
  const updatedGuideline = Object.keys(props.guideline).reduce((acc, key) => {
    acc[key] =
      key === 'age_of_roof' && Array.isArray(props.guideline[key])
        ? props.guideline[key]
        : (typeof props.guideline[key] === 'string'
            ? parseJson(props.guideline[key])
            : props.guideline[key]) || getDefault(key);
    return acc;
  }, {});

  newGuideline.value = updatedGuideline;
});

function getDefault(key) {
  const defaults = {
    age_of_roof: [
      {
        material: '',
        age_comparison: 'less_than',
        age_range_min: '',
        age_range_max: '',
        age_single_value: '',
        conditions: [],
        custom_condition: '',
        details: '', 
        faqs: [],
        username_of_last_user_update: '',
        updated_at: ''
      }
    ],
    electrical: { conditions: [], notes: '' },
    four_point: {
      homeAge: '',
      lastUpdate: '',
      coverageAmount: '',
      systemsUpdated: '',
      photosIncluded: '',
      inspectionRequired: '',
      additionalNotes: '',
      username_of_last_user_update: '',
      updated_at: '',
    },
    age_of_home: {
      condition: '',
      additionalInfo: {
        noLimit: false,
        yearFrom: '',
        yearTo: '',
        ageFrom: '',
        ageTo: '',
        requiresInspection: false,
        requiresProofOfUpdates: false,
        countyRestrictions: '',
        additionalDetails: '',
      },
      username_of_last_user_update: '',
      updated_at: '',
    },
    acceptable_named_insureds: {
      condition: [],
      additionalDetails: '',
      username_of_last_user_update: '',
      updated_at: '',
    },
    assumed_wind_mit_credits: '',
    claims_history: '',
    pools: '',
    dwelling_limits: '',
    flat_roof: false,
    pex_plumbing: false,
    opening_protection_credits: '',
    proof_of_repairs: '',
    username_of_last_user_update: '',
    underwriter_name: '',
    underwriter_confirmation_date: '',
    fields_confirmed_by_underwriter: '',
  };
  return defaults[key] || '';
}


const close = () => {
  emit('close');
  resetForm();
};

const submit = async () => {
  const customerInfo = store.state.customerInfo;
  const username = `${customerInfo.firstName} ${customerInfo.lastName}`;
  const formatToISOString = (date) => {
    const parsedDate = new Date(date);
    return isNaN(parsedDate) ? '' : parsedDate.toISOString();
  };

  const guidelineData = {
    ...newGuideline.value,
    id: newGuideline.value.id,
    username_of_last_user_update: username,
    state_guideline_applies_to: newGuideline.value.state_guideline_applies_to
      ? JSON.stringify(newGuideline.value.state_guideline_applies_to)
      : null,
    product_guideline_applies_to: newGuideline.value
      .product_guideline_applies_to
      ? JSON.stringify(newGuideline.value.product_guideline_applies_to)
      : null,
    age_of_roof: newGuideline.value.age_of_roof
      ? JSON.stringify(newGuideline.value.age_of_roof)
      : null,
    electrical: newGuideline.value.electrical
      ? JSON.stringify(newGuideline.value.electrical)
      : null,
    four_point: newGuideline.value.four_point
      ? JSON.stringify(newGuideline.value.four_point)
      : null,
    age_of_home: newGuideline.value.age_of_home
      ? JSON.stringify(newGuideline.value.age_of_home)
      : null,
    acceptable_named_insureds: newGuideline.value.acceptable_named_insureds
      ? JSON.stringify(newGuideline.value.acceptable_named_insureds)
      : null,
    claims_history: newGuideline.value.claims_history
      ? JSON.stringify(newGuideline.value.claims_history)
      : null,
    pools: newGuideline.value.pools
      ? JSON.stringify(newGuideline.value.pools)
      : null,
    dwelling_limits: newGuideline.value.dwelling_limits
      ? JSON.stringify(newGuideline.value.dwelling_limits)
      : null,
    assumed_wind_mit_credits: newGuideline.value.assumed_wind_mit_credits
      ? JSON.stringify(newGuideline.value.assumed_wind_mit_credits)
      : null,
    flat_roof: newGuideline.value.flat_roof
      ? Boolean(newGuideline.value.flat_roof)
      : false,
    pex_plumbing: newGuideline.value.pex_plumbing
      ? Boolean(newGuideline.value.pex_plumbing)
      : false,
    opening_protection_credits: newGuideline.value.opening_protection_credits
      ? JSON.stringify(newGuideline.value.opening_protection_credits)
      : null,
    proof_of_repairs: newGuideline.value.proof_of_repairs
      ? JSON.stringify(newGuideline.value.proof_of_repairs)
      : null,
    underwriter_name: newGuideline.value.underwriter_name
      ? String(newGuideline.value.underwriter_name)
      : '',
    underwriter_confirmation_date: newGuideline.value
      .underwriter_confirmation_date
      ? formatToISOString(newGuideline.value.underwriter_confirmation_date)
      : null,
    fields_confirmed_by_underwriter: newGuideline.value
      .fields_confirmed_by_underwriter
      ? JSON.stringify(newGuideline.value.fields_confirmed_by_underwriter)
      : null,
  };

  console.log('Submitting guideline AppGuidelineModal:', guidelineData);

  try {
    emit('update', guidelineData);
    resetForm();
  } catch (error) {
    console.error('Error submitting guideline:', error);
  }
};

const resetForm = () => {
  newGuideline.value = {
    id: '',
    carrier_id: '',
    age_of_roof: [
      {
        material: '',
        age_comparison: 'less_than',
        age_range_min: '',
        age_range_max: '',
        age_single_value: '',
        conditions: [],
        custom_condition: '',
        details: '', 
        faqs: [],
        username_of_last_user_update: '',
        updated_at: ''
      }
    ],
    electrical: { conditions: [], notes: '' },
    four_point: {
      homeAge: '',
      lastUpdate: '',
      coverageAmount: '',
      systemsUpdated: '',
      photosIncluded: '',
      inspectionRequired: '',
      additionalNotes: '',
      username_of_last_user_update: '',
      updated_at: '',
    },
    age_of_home: {
      condition: '',
      additionalInfo: {
        noLimit: false,
        yearFrom: '',
        yearTo: '',
        ageFrom: '',
        ageTo: '',
        requiresInspection: false,
        requiresProofOfUpdates: false,
        countyRestrictions: '',
        additionalDetails: '',
      },
      username_of_last_user_update: '',
      updated_at: '',
    },
    acceptable_named_insureds: {
      condition: [],
      additionalDetails: '',
      username_of_last_user_update: '',
      updated_at: '',
    },
    assumed_wind_mit_credits: '',
    claims_history: '',
    pools: '',
    dwelling_limits: '',
    flat_roof: false,
    pex_plumbing: false,
    opening_protection_credits: '',
    proof_of_repairs: '',
    underwriter_name: '', // Ensure string default
    underwriter_confirmation_date: '', // Ensure string default
    fields_confirmed_by_underwriter: '',
    username_of_last_user_update: '',
  };
};

const updateModel = (value) => {
  newGuideline.value[selectedTab.value.model] = value;
};

onMounted(() => {
  console.log('AddGuidelineModal current guideline:', props.guideline);
});
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}
</style>
