<template>
  <div
    class="bg-slate-100 overflow-hidden shadow-sm px-4 py-2 cursor-pointer block md:hidden"
    href="/customerservice"
  >
    <div
      ref="scrollContainerMobile"
      class="flex items-center whitespace-nowrap animate-scroll-mobile"
    >
      <!-- Adjusted Logo Image Size -->
      <img
        src="@/assets/images/carrierLogos/citizens-prop-1.svg"
        alt="Citizens Logo"
        class="h-6 mr-4 align-middle"
      />
      <!-- Scrolling Text -->
      <span class="text-gray-800 font-medium align-middle">
        Received a letter from Citizens? Please visit our
        <span class="text-gray-900 font-bold">Citizens Q&A</span> for
        assistance.
      </span>
    </div>
  </div>
  <div
    class="bg-slate-100 overflow-hidden shadow-sm px-4 py-2 cursor-pointer hidden md:block"
    href="/customerservice"
  >
    <div
      ref="scrollContainerDesktop"
      class="flex items-center whitespace-nowrap animate-scroll-desktop"
    >
      <!-- Adjusted Logo Image Size -->
      <img
        src="@/assets/images/carrierLogos/citizens-prop-1.svg"
        alt="Citizens Logo"
        class="h-6 mr-4 align-middle"
      />
      <!-- Scrolling Text -->
      <span class="text-gray-800 font-medium align-middle">
        Received a letter from Citizens? Please visit our
        <span class="text-gray-900 font-bold">Citizens Q&A</span> for
        assistance.
      </span>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  components: {
    Icon,
  },
};
</script>

<style scoped>
/* Base animation keyframes */
@keyframes scroll-mobile {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll-desktop {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Mobile speed */
.animate-scroll-mobile {
  animation: scroll-mobile 8s linear infinite; /* Adjust the speed as needed for mobile */
}

/* Desktop speed */
.animate-scroll-desktop {
  animation: scroll-desktop 25s linear infinite; /* Adjust the speed as needed for desktop */
}
</style>
