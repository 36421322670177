<template>
  <div class="flex flex-col md:flex-row min-h-screen bg-gray-100">
    <Navigation />
    <div class="flex-grow p-4 md:p-10">
      <h1 class="text-2xl font-bold text-ietextlight mb-10">System Settings</h1>
      <div class="space-y-4">
        <!-- Dynamic Theme Switch -->
        <section class="bg-white shadow rounded-lg p-4">
          <h2 class="text-xl font-semibold mb-2">Theme Selection</h2>
          <div class="toggle-theme flex items-center justify-start">
            <button
              @click="toggleTheme('light')"
              class="theme-light mr-2 px-4 py-2 text-sm font-medium text-gray-800 bg-gray-200 rounded-lg focus:outline-none focus:shadow-outline"
            >
              Light
            </button>
            <button
              @click="toggleTheme('dark')"
              class="theme-dark px-4 py-2 text-sm font-medium text-white bg-gray-800 rounded-lg focus:outline-none focus:shadow-outline"
            >
              Dark
            </button>
          </div>
        </section>

        <!-- Notification Settings -->
        <section class="bg-white shadow rounded-lg p-4">
          <h2 class="text-xl font-semibold mb-2">Notifications</h2>
          <div class="notification-settings">
            <div class="flex items-center mb-2">
              <input
                id="email-notifications"
                type="checkbox"
                class="toggle-checkbox"
                checked
              />
              <label for="email-notifications" class="ml-2 text-sm"
                >Email Notifications</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="sms-notifications"
                type="checkbox"
                class="toggle-checkbox"
              />
              <label for="sms-notifications" class="ml-2 text-sm"
                >SMS Notifications</label
              >
            </div>
          </div>
        </section>

        <!-- System Maintenance -->
        <section class="bg-white shadow rounded-lg p-4">
          <h2 class="text-xl font-semibold mb-2">Maintenance</h2>
          <p class="mb-4 text-sm">
            Schedule system maintenance tasks to optimize performance.
          </p>
          <button
            class="restart-system bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg flex items-center"
          >
            <Icon icon="mdi:restart" class="mr-2" /> Restart System
          </button>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import Navigation from './components/Navigation.vue';

export default {
  components: {
    Icon,
    Navigation,
  },
  name: 'SystemSettings',
  methods: {
    toggleTheme(theme) {
      // Implement theme switching logic here
    },
  },
};
</script>

<style scoped>
/* Add responsive and theme-related styles here */
</style>
