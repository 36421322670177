<template>
  <div
    v-if="showModal"
    class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75"
  >
    <div class="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
      <h2 class="text-2xl font-semibold mb-4 text-gray-800">Edit Profile</h2>
      <form @submit.prevent="updateProfile">
        <div class="mb-4">
          <label for="firstName" class="block text-gray-700">First Name</label>
          <input
            v-model="form.firstName"
            type="text"
            id="firstName"
            class="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div class="mb-4">
          <label for="lastName" class="block text-gray-700">Last Name</label>
          <input
            v-model="form.lastName"
            type="text"
            id="lastName"
            class="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div class="mb-4">
          <label for="email" class="block text-gray-700">Email</label>
          <input
            v-model="form.email"
            type="email"
            id="email"
            class="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div class="mb-4">
          <label for="phoneNumber" class="block text-gray-700"
            >Phone Number</label
          >
          <input
            v-model="form.phoneNumber"
            type="tel"
            id="phoneNumber"
            class="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div class="mb-4">
          <label for="profileImage" class="block text-gray-700"
            >Profile Image URL</label
          >
          <input
            v-model="form.profileImage"
            type="text"
            id="profileImage"
            class="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div class="flex justify-end">
          <button
            type="button"
            @click="closeModal"
            class="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ProfileEditModal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        profileImage: '',
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.customerInfo,
    }),
  },
  methods: {
    ...mapActions(['updateCustomerInfo']),
    updateProfile() {
      this.updateCustomerInfo(this.form);
      this.closeModal();
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(newValue) {
        this.form = { ...newValue };
      },
    },
  },
};
</script>

<style scoped>
/* Styles for modal background and content */
.fixed {
  transition: opacity 0.3s ease-in-out;
}
.bg-opacity-75 {
  backdrop-filter: blur(5px);
}
</style>
