<template>
  <div class="relative inline-block">
    <div @click="toggle" class="cursor-pointer">
      <slot name="trigger"></slot>
    </div>
    <transition name="fade">
      <div v-if="visible" class="absolute z-10 p-2 mb-2 text-sm text-white bg-blue-600 rounded-lg shadow-lg w-max bottom-full left-1/2 transform -translate-x-1/2" role="tooltip" aria-hidden="true">
        <div class="flex items-start space-x-2">
          <div class="flex-grow">
            <slot></slot>
          </div>
          <button @click="close" class="text-gray-200 hover:text-gray-100">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="absolute top-full left-1/2 transform -translate-x-1/2 mt-1 w-3 h-3 bg-blue-600 rotate-45"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ToolTip',
  data() {
    return {
      visible: false,
      timer: null,
    };
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
      if (this.visible) {
        this.startTimer();
      } else {
        this.clearTimer();
      }
    },
    close() {
      this.visible = false;
      this.clearTimer();
    },
    startTimer() {
      this.clearTimer();
      this.timer = setTimeout(() => {
        this.visible = false;
      }, 3000);
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
  },
  beforeDestroy() {
    this.clearTimer();
  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
