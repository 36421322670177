<template>
  <div class="mobile-policy-list space-y-4">
    <div
      v-for="policy in policies"
      :key="policy.PolicyNumber"
      class="policy-card bg-white shadow-lg rounded-lg overflow-hidden"
    >
      <div class="px-4 py-3 bg-gray-100">
        <h3 class="text-lg font-semibold text-gray-800">
          Policy Number: {{ policy.PolicyNumber }}
        </h3>
      </div>
      <div class="p-4">
        <div class="mb-2">
          <span class="font-medium text-gray-600">Type:</span>
          <span class="ml-2 text-gray-700">{{ policy.PolicyTypeLOB }}</span>
        </div>
        <div class="mb-2">
          <span class="font-medium text-gray-600">Company:</span>
          <span class="ml-2 text-gray-700">{{
            policy.WritingCompanyName
          }}</span>
        </div>
        <div class="mb-2">
          <span class="font-medium text-gray-600">Effective Date:</span>
          <span class="ml-2 text-gray-700">{{ policy.EffectiveDate }}</span>
        </div>
        <div>
          <span class="font-medium text-gray-600">Expiry Date:</span>
          <span class="ml-2 text-gray-700">{{ policy.ExpiryDate }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobilePolicy',
  props: {
    policies: Array,
  },
};
</script>

<style scoped>
/* No additional styles needed as Tailwind CSS is used */
</style>
