// resources/js/store/index.js
import { createStore } from 'vuex';
import router from '../router.js';

export default createStore({
  state() {
    return {
      customerInfo: {
        id: localStorage.getItem('customerId') || null,
        phoneNumber: localStorage.getItem('phoneNumber') || null,
        firstName: localStorage.getItem('firstName') || null,
        lastName: localStorage.getItem('lastName') || null,
        email: localStorage.getItem('email') || null,
        customerId: localStorage.getItem('customerId') || null,
        profileImage:
          localStorage.getItem('profileImage') ||
          '/images/backend-graphics/default_avatar.png',
      },
      authToken: localStorage.getItem('authToken') || null,
      userId: localStorage.getItem('userId') || null,
      sessionId: null,
      role: localStorage.getItem('userRole') || null,
    };
  },
  mutations: {
    setCustomerInfo(
      state,
      { id, phoneNumber, firstName, lastName, email, customerId, profileImage },
    ) {
      console.log('Setting customer info:', firstName, lastName);
      state.customerInfo.id = id;
      state.customerInfo.phoneNumber = phoneNumber;
      state.customerInfo.firstName = firstName;
      state.customerInfo.lastName = lastName;
      state.customerInfo.email = email;
      state.customerInfo.customerId = customerId;
      state.customerInfo.profileImage =
        profileImage || '/images/backend-graphics/default_avatar.png'; // Provide a default if not provided

      // Update localStorage
      localStorage.setItem('id', id);
      localStorage.setItem('customerId', customerId);
      localStorage.setItem(
        'profileImage',
        profileImage || '/images/backend-graphics/default_avatar.png',
      );
      localStorage.setItem('phoneNumber', phoneNumber);
      localStorage.setItem('firstName', firstName);
      localStorage.setItem('lastName', lastName);
      localStorage.setItem('email', email);
    },
    clearAllData(state) {
      console.log('Clearing all user data from state and localStorage');
      Object.keys(state.customerInfo).forEach((key) => {
        state.customerInfo[key] = null;
      });
      state.authToken = null;
      state.userId = null;
      state.sessionId = null;
      state.role = null;

      // Clear all localStorage items
      localStorage.removeItem('id');
      localStorage.removeItem('customerId');
      localStorage.removeItem('profileImage');
      localStorage.removeItem('phoneNumber');
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('email');
      localStorage.removeItem('authToken');
      localStorage.removeItem('userId');
      localStorage.removeItem('userRole');
    },
    setAuthToken(state, token) {
      state.authToken = token;
      localStorage.setItem('authToken', token);
    },
    setUserId(state, userId) {
      state.userId = userId;
      localStorage.setItem('userId', userId);
    },
    setSessionId(state, sessionId) {
      console.log('Setting session ID:', sessionId);
      state.sessionId = sessionId;
    },
    setUserRole(state, role) {
      state.role = role;
      localStorage.setItem('userRole', role);
    },
    SET_UNAUTHORIZED_ACCESS(state, status) {
      state.unauthorizedAccess = status;
    },
  },
  actions: {
    logout({ commit }) {
      console.log('Clearing data and logging out');
      commit('clearAllData'); // Correct mutation name here
      console.log('Redirecting to login');
      router.push('/login').catch((err) => {
        console.error('Failed redirect to login:', err);
      });
    },
    login({ commit, dispatch }, response) {
      commit('setAuthToken', response.token);
      commit('setUserId', response.userId);
      commit('setUserRole', response.role);
      dispatch('updateCustomerInfo', {
        id: response.customerId,
        phoneNumber: response.phoneNumber,
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email,
        customerId: response.customerId,
        profileImage: response.profileImage,
      });

      if (response.role !== 'customer') {
        router.push('/backend/dashboard');
      } else {
        router.push('/dashboard');
      }
    },
    updateCustomerInfo({ commit }, customerInfo) {
      commit('setCustomerInfo', customerInfo);
    },
    updateAuthToken({ commit }, token) {
      commit('setAuthToken', token);
    },
    generateSessionId({ commit }) {
      const sessionId = `sess-${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
      commit('setSessionId', sessionId);
    },
  },
  getters: {
    customerInfo: (state) => state.customerInfo,
    authToken: (state) => state.authToken,
    userId: (state) => state.userId,
    sessionId: (state) => state.sessionId,
    userRole: (state) => state.role,
    formattedRole: (state) => {
      const roleMap = {
        superadmin: 'Super Admin',
        admin: 'Administrator',
        csr: 'Customer Service Representative',
        user: 'User',
        manager: 'Manager',
      };
      return roleMap[state.role] || state.role; // Default to raw role if no match found
    },
    unauthorizedAccess: state => state.unauthorizedAccess,
  },
});
