<template>
  <div class="form-group mb-4">
    <label for="pex_plumbing" class="block text-sm font-medium text-gray-700"
      >PEX Plumbing:</label
    >
    <input
      v-bind:checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
      type="checkbox"
      id="pex_plumbing"
      class="form-checkbox mt-1"
    />
  </div>
</template>

<script>
export default {
  name: 'PEXPlumbingInput',
  props: {
    modelValue: Boolean,
  },
};
</script>

<style scoped>
/* Add any specific styles for this component here */
</style>
