<template>
  <div class="max-w-4xl mx-auto p-6">
    <h2 class="text-2xl font-semibold text-center mb-6">
      Frequently Asked Questions
    </h2>

    <!-- Search Input -->
    <div class="mb-6">
      <InputText
        v-model="search"
        placeholder="Search FAQs..."
        class="w-full p-3 border border-gray-300 rounded-lg"
      />
    </div>

    <div class="space-y-4 overflow-y-auto max-h-96">
      <div
        v-for="(item, index) in filteredFaqItems"
        :key="index"
        class="p-4 bg-white shadow rounded-lg"
      >
        <button
          @click="toggle(index)"
          class="flex items-center justify-between w-full text-left"
        >
          <span class="font-medium text-gray-700">{{ item.question }}</span>
          <span
            class="iconify text-blue-500"
            :icon="item.open ? 'bi:dash-lg' : 'bi:plus-lg'"
          ></span>
        </button>
        <div v-if="item.open" class="mt-2 text-gray-600">
          {{ item.answer }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
  name: 'FAQcustomerService',
  components: {
    InputText,
    Button,
  },
  data() {
    return {
      search: '',
      faqItems: [
        {
          question: 'How do I update my personal information?',
          answer: `To update your personal information, please fill out a change request through our service site https://www.insexpress.com`,
          open: false,
        },
        {
          question: 'How can I make a payment?',
          answer: `To make a payment, please refer to the declarations page of your insurance policy to identify your insurance carrier. Once you have this information, you can make the payment directly with them. For your convenience, we have a directory of self-service carriers and their respective links on our service site at https://www.insexpress.com for assistance.`,
          open: false,
        },
        {
          question: 'How can I file a claim?',
          answer: `To file a claim, please check the declarations page of your insurance policy to determine your insurance carrier. You can then file a claim directly with them. For easy access to your carrier's claim filing services, visit our service site at https://www.insexpress.com, where we provide links to various carriers in our directory.`,
          open: false,
        },
        {
          question: 'Can I change my policy coverage online?',
          answer:
            'Yes, policy modifications can be made online. Go to the Policy section, select the policy you wish to modify, and choose the desired changes. Review and confirm your changes.',
          open: false,
        },
        {
          question: 'Is there a deadline for premium payments?',
          answer:
            'Yes, premium payments have specific due dates. Please refer to your policy details in the portal for payment deadlines.',
          open: false,
        },
        {
          question: 'How do I get in touch with customer support?',
          answer:
            'Our customer support can be reached via the Contact Us section in the portal, or you can call us directly at the provided hotline.',
          open: false,
        },
        {
          question: 'Are there any discounts available for policy renewals?',
          answer:
            'We offer various discounts for policy renewals based on your account history. Check the Offers section in the portal for more details.',
          open: false,
        },
        {
          question: 'What should I do if I forgot my portal password?',
          answer:
            'If you forget your password, use the "Forgot Password" feature on the login page to reset your password. Follow the instructions sent to your registered email.',
          open: false,
        },
        {
          question: 'Can I add additional properties to my existing policy?',
          answer:
            'Yes, you can add properties to your policy. Go to the Policy Modifications section in the portal and select the option to add properties.',
          open: false,
        },
        {
          question: 'How can I view my policy documents?',
          answer:
            'All your policy documents are available in the Documents section of the portal. You can view, download, or print them as needed.',
          open: false,
        },
        {
          question: 'Is online payment secure on this portal?',
          answer:
            'Yes, our portal uses advanced encryption and security measures to ensure that all transactions are secure and private.',
          open: false,
        },
      ],
    };
  },
  computed: {
    filteredFaqItems() {
      if (!this.search) {
        return this.faqItems;
      }
      const searchQuery = this.search.toLowerCase();
      return this.faqItems.filter((item) =>
        item.question.toLowerCase().includes(searchQuery),
      );
    },
  },
  methods: {
    toggle(index) {
      this.faqItems[index].open = !this.faqItems[index].open;
    },
  },
};
</script>

<style>
/* You can add more styles here if needed */
</style>
