<template>
  <div class="flex flex-col md:flex-row min-h-screen bg-gray-100 mb-10 md:mb-0">
    <Navigation />
    <div class="flex-auto p-2 md:p-10 w-full">
      <h1 class="text-2xl font-bold text-ietextlight mb-10">
        {{ dashboardTitle }}
      </h1>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <DashboardWidget
          v-if="canManageUsers"
          :isVisible="true"
          title="User Management"
          description="Manage user accounts, roles, and permissions."
          link="/backend/manage-users"
          buttonText="Manage Users"
          buttonColor="bg-blue-600 hover:bg-blue-700"
          icon="mdi:account-multiple-outline"
        />
        <DashboardWidget
          v-if="isSuperAdmin"
          :isVisible="true"
          title="System Settings"
          description="Configure system preferences and settings."
          link="/backend/system-settings"
          buttonText="System Settings"
          buttonColor="bg-green-600 hover:bg-green-700"
          icon="mdi:cog-outline"
        />
        <DashboardWidget
          v-if="isSuperAdmin"
          :isVisible="true"
          title="Carrier Management"
          description="Manage and configure insurance carriers."
          link="/backend/carrier-management"
          buttonText="Manage Carriers"
          buttonColor="bg-red-600 hover:bg-red-700"
          icon="map:insurance-agency"
        />
        <DashboardWidget
          v-if="canViewAnalytics"
          :isVisible="true"
          title="Analytics"
          description="View system and user analytics."
          link="/backend/analytics"
          buttonText="View Analytics"
          buttonColor="bg-purple-600 hover:bg-purple-700"
          icon="mdi:chart-bar"
        />
        <DashboardWidget
          v-if="canManageCommunications"
          :isVisible="true"
          title="Communications"
          description="Manage User Communications"
          link="/backend/communications"
          buttonText="Communications"
          buttonColor="bg-blue-600 hover:bg-blue-700"
          icon="maki:communications-tower"
        />
        <DashboardWidget
          v-if="customerServiceRepresentative"
          :isVisible="true"
          title="Accord Form Submission"
          description="Quickly fill out accord forms"
          link="/backend/accord-form-submission"
          buttonText="Get Started"
          buttonColor="bg-green-600 hover:bg-green-700"
          icon="ant-design:form-outlined"
        />
        <DashboardWidget
          v-if="customerServiceRepresentative"
          :isVisible="true"
          title="Accord Form Log"
          description="Review form status log"
          link="/backend/accord-form-log"
          buttonText="Get Started"
          buttonColor="bg-red-600 hover:bg-red-700"
          icon="ant-design:form-outlined"
        />
        <DashboardWidget
          v-if="underwriter"
          :isVisible="true"
          title="Underwriting"
          description="Updated Underwriting Bible"
          link="/backend/underwriting"
          buttonText="Get Started"
          buttonColor="bg-purple-600 hover:bg-purple-700"
          icon="ant-design:form-outlined"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue';
import DashboardWidget from './dashboardComponents/dashboardWidget.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MainDashboard',
  components: {
    Navigation,
    DashboardWidget,
  },
  computed: {
    ...mapGetters(['userRole']),
    dashboardTitle() {
      switch (this.userRole) {
        case 'superadmin':
          return 'Super Admin Dashboard';
        case 'admin':
          return 'Admin Dashboard';
        case 'marketing':
          return 'Marketing Dashboard';
        case 'csr':
          return 'Customer Service Dashboard';
        case 'underwriter':
          return 'Underwriter Dashboard';
        default:
          return 'User Dashboard';
      }
    },
    isSuperAdmin() {
      return this.userRole === 'superadmin';
    },
    canManageUsers() {
      return ['admin', 'superadmin'].includes(this.userRole);
    },
    canViewAnalytics() {
      return ['admin', 'superadmin'].includes(this.userRole);
    },
    canManageCommunications() {
      return ['superadmin', 'admin', 'csr'].includes(this.userRole);
    },
    customerServiceRepresentative() {
      return ['superadmin', 'admin', 'csr'].includes(this.userRole);
    },
    underwriter() {
      return ['superadmin', 'admin', 'csr', 'underwriter'].includes(this.userRole);
    },
  },
};
</script>

<style scoped></style>
