<template>
  <div class="h-10 bg-gray-100"></div>
  <div class="p-6 bg-white shadow-md rounded-lg">
    <!-- Introductory Section -->
    <div class="mb-6">
      <h1 class="text-3xl font-bold text-gray-800 mb-3">{{ policyTitle }}</h1>
      <p class="text-gray-600">{{ policyDescription }}</p>
    </div>

    <!-- Tips Section -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div
        v-for="(tip, index) in policyTips"
        :key="index"
        class="border p-4 rounded-lg bg-gray-100 shadow-sm"
      >
        <span
          class="iconify text-2xl text-blue-500"
          :data-icon="'mdi-lightbulb-on-outline'"
        ></span>
        <h2 class="font-semibold text-lg mt-2">{{ tip.title }}</h2>
        <p class="text-gray-600">{{ tip.description }}</p>
      </div>
    </div>

    <!-- FAQ Section -->
    <div class="mt-6">
      <h2 class="text-lg font-bold mb-4 text-gray-800">
        Frequently Asked Questions
      </h2>
      <Accordion>
        <AccordionTab
          v-for="(faq, index) in policyFAQs"
          :key="index"
          :header="faq.question"
        >
          <div class="p-2 text-gray-600">{{ faq.answer }}</div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ProductPageContent from '@/data/productPageContent.json';

export default {
  name: 'ProductPage',
  components: {
    Accordion,
    AccordionTab,
    Icon,
  },
  props: {
    productType: {
      type: String,
      required: true,
    },
  },
  created() {
    console.log('Received productType prop:', this.productType);
  },
  computed: {
    policyContent() {
      console.log('Policy Type:', this.productType); // Check the passed productType
      return ProductPageContent[this.productType] || {};
    },
    policyTitle() {
      console.log('Content:', ProductPageContent[this.productType]); // Check the fetched content
      return this.policyContent.title || 'Insurance Information';
    },
    policyDescription() {
      return this.policyContent.description || '';
    },
    policyTips() {
      return this.policyContent.tips || [];
    },
    policyFAQs() {
      return this.policyContent.faqs || [];
    },
  },
};
</script>

<style scoped>
/* Add your Tailwind CSS custom styles here */
</style>
