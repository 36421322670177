<template>
  <div :class="[
    'flex flex-col items-center justify-center p-4',
    isEmbedded ? 'min-h-full bg-transparent' : 'min-h-screen bg-white'
  ]">
    <Transition name="fade">
      <div v-if="isLoading || initialLoading" 
           class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <img src="@/assets/images/gifs/loading.gif" 
             alt="Loading..." 
             class="w-20 h-20 animate-pulse" />
      </div>
    </Transition>

    <!-- Form content only shows after initial load -->
    <template v-if="!initialLoading">
      <div v-if="!isSuccess" class="my-8 md:my-24 w-full md:w-1/3 h-auto bg-white shadow-lg mx-auto rounded-lg mb-10 p-6 flex flex-col items-center space-y-4"
      >
        <h2 class="text-2xl font-bold text-gray-800">Home Insurance Quote</h2>

        <div class="w-full max-w-lg">
          <label for="firstName" class="block text-sm font-medium text-gray-700"
            >First Name <span class="text-red-500">*</span></label
          >
          <InputText
            id="firstName"
            v-model="firstName"
            type="text"
            placeholder="Your First Name"
            class="mt-1 w-full border border-gray-300 px-3 py-2 rounded-md shadow-sm"
            :class="{ 'border-red-500': firstNameError }"
          />

          <label for="lastName" class="block mt-4 text-sm font-medium text-gray-700"
            >Last Name <span class="text-red-500">*</span></label
          >
          <InputText
            id="lastName"
            v-model="lastName"
            type="text"
            placeholder="Your Last Name"
            class="mt-1 w-full border border-gray-300 px-3 py-2 rounded-md shadow-sm"
            :class="{ 'border-red-500': lastNameError }"
          />

          <label for="email" class="block mt-4 text-sm font-medium text-gray-700"
            >Email <span class="text-red-500">*</span></label
          >
          <InputText
            id="email"
            v-model="email"
            type="email"
            placeholder="Your Email"
            class="mt-1 w-full border border-gray-300 px-3 py-2 rounded-md shadow-sm"
            :class="{ 'border-red-500': emailError }"
          />

          <label for="phone" class="block mt-4 text-sm font-medium text-gray-700"
            >Phone Number <span class="text-red-500">*</span></label
          >
          <InputMask
            id="phone"
            v-model="phone"
            mask="(999) 999-9999"
            class="mt-1 w-full border border-gray-300 px-3 py-2 rounded-md shadow-sm"
            :class="{ 'border-red-500': phoneError }"
          />

          <label for="message" class="block mt-4 text-sm font-medium text-gray-700">
            Additional Information
          </label>
          <Textarea
            id="message"
            v-model="message"
            rows="4"
            class="mt-1 w-full border border-gray-300 px-3 py-2 rounded-md shadow-sm"
          />

          <!-- File Upload Section -->
          <div class="w-full space-y-4 mt-4">
            <h3 class="text-lg font-semibold text-gray-700">Optional Documents</h3>
            
            <!-- 4 Point Inspection -->
            <div class="relative">
              <label class="block text-gray-700 text-sm font-medium mb-2" for="four-point">
                4 Point Inspection
              </label>
              <input
                type="file"
                class="hidden"
                id="four-point"
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                @change="handleFileChange('fourPoint', $event)"
                ref="fourPointInput"
              />
              <label
                class="flex flex-col rounded-lg border-2 border-dashed w-full p-4 group text-center cursor-pointer hover:border-blue-500"
                for="four-point"
              >
                <div class="h-full w-full text-center flex flex-col items-center justify-center">
                  <span class="text-blue-600 text-3xl mb-2">+</span>
                  <p class="text-sm text-gray-500">Click to upload or drag and drop</p>
                </div>
              </label>
              <p v-if="files.fourPoint" class="mt-1 text-sm text-gray-500">
                Selected: {{ files.fourPoint.name }}
              </p>
            </div>

            <!-- Wind Mitigation -->
            <div class="relative">
              <label class="block text-gray-700 text-sm font-medium mb-2" for="wind-mit">
                Wind Mitigation
              </label>
              <input
                type="file"
                class="hidden"
                id="wind-mit"
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                @change="handleFileChange('windMit', $event)"
                ref="windMitInput"
              />
              <label
                class="flex flex-col rounded-lg border-2 border-dashed w-full p-4 group text-center cursor-pointer hover:border-blue-500"
                for="wind-mit"
              >
                <div class="h-full w-full text-center flex flex-col items-center justify-center">
                  <span class="text-blue-600 text-3xl mb-2">+</span>
                  <p class="text-sm text-gray-500">Click to upload or drag and drop</p>
                </div>
              </label>
              <p v-if="files.windMit" class="mt-1 text-sm text-gray-500">
                Selected: {{ files.windMit.name }}
              </p>
            </div>

            <!-- Declarations Page -->
            <div class="relative">
              <label class="block text-gray-700 text-sm font-medium mb-2" for="dec-page">
                Declarations Page
              </label>
              <input
                type="file"
                class="hidden"
                id="dec-page"
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                @change="handleFileChange('decPage', $event)"
                ref="decPageInput"
              />
              <label
                class="flex flex-col rounded-lg border-2 border-dashed w-full p-4 group text-center cursor-pointer hover:border-blue-500"
                for="dec-page"
              >
                <div class="h-full w-full text-center flex flex-col items-center justify-center">
                  <span class="text-blue-600 text-3xl mb-2">+</span>
                  <p class="text-sm text-gray-500">Click to upload or drag and drop</p>
                </div>
              </label>
              <p v-if="files.decPage" class="mt-1 text-sm text-gray-500">
                Selected: {{ files.decPage.name }}
              </p>
            </div>
          </div>

          <Button
            :label="isLoading ? 'Submitting...' : 'Submit Quote Request'"
            class="mt-6 w-full max-w-lg bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            @click="debouncedSubmit"
            :disabled="isLoading"
            :loading="isLoading"
          />

          <p class="mt-4 text-sm text-gray-600">
            Fields marked with <span class="text-red-500">*</span> are required
          </p>
        </div>
      </div>

      <div v-else class="w-[100%] md:w-[30%] h-auto bg-[#FFFFFF] drop-shadow-md mx-auto rounded-lg p-6 flex flex-col items-center space-y-4">
        <h2 class="text-2xl font-bold">Success!</h2>
        <img src="@/assets/images/gifs/7efs.gif" alt="Success Animation" />
        <p class="text-green-600 font-bold">Your quote request has been submitted</p>
        <p class="mt-[4px] text-sm text-center text-textDarkGray">
          One of our specialists will contact you shortly.
        </p>
        <p class="mt-[4px] tracking-[0.2px] leading-[20px] text-center text-sm text-[#1E182E]">
          <span class="font-bold">Need immediate assistance? </span>
          <span>Call us at </span>
          <a href="tel:+15168417328" class="text-[#DB0908] font-bold">
            (516)-841-7328 
          </a>
        </p>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted } from 'vue';
import axios from 'axios';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';
import { useNotification } from '@kyvg/vue3-notification';
import { debounce } from 'lodash';

const props = defineProps({
  isEmbedded: {
    type: Boolean,
    default: false
  }
});

const isEmbedded = ref(props.isEmbedded);

const firstName = ref('');
const lastName = ref('');
const email = ref('');
const phone = ref('');
const message = ref('');
const isSuccess = ref(false);
const isLoading = ref(false);
const firstNameError = ref(false);
const lastNameError = ref(false);
const emailError = ref(false);
const phoneError = ref(false);

const notification = useNotification();

const files = ref({
  fourPoint: null,
  windMit: null,
  decPage: null
});

const initialLoading = ref(true);

const validateFirstName = () => {
  firstNameError.value = firstName.value.trim() === '';
};

const validateLastName = () => {
  lastNameError.value = lastName.value.trim() === '';
};

const validateEmail = () => {
  emailError.value = !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email.value);
};

const validatePhone = () => {
  const rawPhoneValue = phone.value.replace(/\D/g, '');
  phoneError.value = !/^\+?[1-9]\d{1,14}$/.test(rawPhoneValue);
};

const handleFileChange = (type, event) => {
  if (event.target.files.length > 0) {
    files.value[type] = event.target.files[0];
  }
};

const resetForm = () => {
  firstName.value = '';
  lastName.value = '';
  email.value = '';
  phone.value = '';
  message.value = '';
  files.value = {
    fourPoint: null,
    windMit: null,
    decPage: null
  };
  isSuccess.value = false;
  firstNameError.value = false;
  lastNameError.value = false;
  emailError.value = false;
  phoneError.value = false;
};

const sendMessage = (type, data = {}) => {
  if (props.isEmbedded) {
    window.parent.postMessage({
      type,
      ...data
    }, '*');
  }
};

const submitForm = async () => {
  validateFirstName();
  validateLastName();
  validateEmail();
  validatePhone();

  if (!firstNameError.value && !lastNameError.value && !emailError.value && !phoneError.value) {
    isLoading.value = true;
    const formData = new FormData();
    formData.append('firstName', firstName.value);
    formData.append('lastName', lastName.value);
    formData.append('email', email.value);
    formData.append('phone', phone.value);
    formData.append('message', message.value);

    // Append files if they exist
    if (files.value.fourPoint) {
      formData.append('fourPoint', files.value.fourPoint);
    }
    if (files.value.windMit) {
      formData.append('windMit', files.value.windMit);
    }
    if (files.value.decPage) {
      formData.append('decPage', files.value.decPage);
    }

    try {
      const response = await axios.post('/api/submit-guardian-angel-quote', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
        withCredentials: true,
        timeout: 30000,
        retries: 2,
        retryDelay: 1000
      });

      if (response.data) {
        isSuccess.value = true;
        sendMessage('formSubmitted');
        // Reset form after 5 seconds
        setTimeout(() => {
          resetForm();
        }, 8000);
      } else {
        throw new Error('No response data received');
      }
    } catch (error) {
      let errorMessage = 'Failed to submit quote request. Please try again.';
      
      if (error.response) {
        // Server responded with error
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.request) {
        // Request made but no response
        errorMessage = 'Server is not responding. Please try again later.';
      }

      notification.notify({
        title: 'Error',
        text: errorMessage,
        type: 'error',
        duration: 5000
      });
      
      // Log the error details for debugging
      console.error('Form submission error:', {
        status: error.response?.status,
        message: error.message,
        data: error.response?.data
      });
      sendMessage('formError', { error: error.message });
    } finally {
      isLoading.value = false;
    }
  }
};

const debouncedSubmit = debounce(() => {
  submitForm();
}, 500, { leading: true, trailing: false });

onMounted(async () => {
  if (window.hideChatwootWidget) {
    window.hideChatwootWidget();
  }
  
  // Simulate initial load time or add actual initialization logic here
  setTimeout(() => {
    initialLoading.value = false;
  }, 1000); // Shows loading for 1 second

  if (props.isEmbedded) {
    const resizeObserver = new ResizeObserver((entries) => {
      window.parent.postMessage({
        type: 'resize',
        height: entries[0].contentRect.height
      }, '*');
    });

    resizeObserver.observe(document.body);

    onUnmounted(() => {
      resizeObserver.disconnect();
    });
  }
});

watch(isSuccess, (newValue) => {
  if (props.isEmbedded) {
    window.parent.postMessage({
      type: newValue ? 'formSubmitted' : 'formReset'
    }, '*');
  }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
