<template>
  <!-- Container for the entire card component -->
  <div class="container mx-auto p-0 md:p-6">
    <!-- Check if carrier and guideline data is available -->
    <div v-if="carrier && guideline" class="shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row mb-6 transition-transform transform hover:scale-105 duration-500">
      
      <!-- Carrier logo section with dynamic background color -->
      <div :style="{ background: gradientBgColor }" class="flex-shrink-0 p-6 rounded-l-lg ">
        <div class="flex items-center justify-center h-24 w-24 overflow-hidden rounded-full shadow-md bg-opacity-90 bg-white md:mx-auto">
          <!-- Carrier logo image -->
          <img :src="carrier.logo" alt="Carrier Logo" class="max-h-full max-w-full object-contain" @load="extractColor" />
          
        </div>
        <div class="hidden md:flex flex-col ml-2 mt-4">
          <p class="text-sm text-gray-500 bg-white rounded-lg border border-gray-500 font-regular md:text-center">Guides:</p>
          <div class="mx-auto my-2">
            <div class="flex flex-row cursor-pointer" @click="openPdfModal">
              <p class="text-xs text-white underline">08-07-2024- Underwriting Guidelines</p>
              <Icon icon="vscode-icons:file-type-pdf2" class="w-6 justify-start"/>
            </div>
          </div>
        </div>
        <div class="hidden md:flex flex-col ml-2 mt-2">
          <p class="text-sm text-gray-500 bg-white rounded-lg border border-gray-500 font-regular md:text-center">Helpful Documents:</p>
          <div class="mx-auto my-2">
            <div class="flex flex-row cursor-pointer">
              <p class="text-xs text-white underline">08-07-2024- Roof Replacement Schedule</p>
              <Icon icon="vscode-icons:file-type-pdf2" class="w-6 justify-start"/>
            </div>
          </div>
        </div>
      </div>

      <!-- Main content section of the card -->
      <div class="flex-grow p-6 flex flex-col justify-between bg-white">
        <!-- Header with carrier name and product type -->
        <div class="">
            <div class="flex flex-cols-1"> 
            <h2 class="text-2xl font-semibold text-gray-800 mb-4">
              {{ carrier.carrier_name }}
              <span class="text-base font-normal opacity-95 align-baseline text-white bg-blue-500 rounded-full px-2 ml-2">
              {{ productType }}
              </span> 
            </h2>
          </div>
          
          
         
          <!-- Carrier contact information -->
          <div class="grid grid-cols-1 gap-2 mb-6 text-sm">
            <div class="flex items-center text-gray-600">
              <Icon icon="carbon:phone" class="h-4 w-4 mr-2" />
              <a :href="`tel:${carrier.customer_service_phone}`" class=" hover:underline">
                {{ carrier.customer_service_phone }}
              </a>
            </div>
            <div class="flex items-center text-gray-600">
              <Icon icon="mdi:email" class="h-4 w-4  mr-2" />
              <a :href="`mailto:${carrier.customer_service_email}`" class="hover:underline">
                {{ carrier.customer_service_email || 'N/A' }}
              </a>
            </div>
            <div class="flex items-center text-gray-600">
              <Icon icon="mdi:earth" class="h-4 w-4  mr-2" />
              <a :href="carrier.main_website" target="_blank" class=" hover:underline">
                {{ carrier.main_website }}
              </a>
            </div>
          </div>

        </div>
        
        <!-- Actions and toggle for guidelines -->
        <div class="flex flex-col md:flex-row md:items-end md:justify-between">
          <button @click="toggleGuidelines" class="text-blue-500 hover:text-blue-700 font-semibold mb-4 md:mb-0 transition-colors flex items-center">
              <Icon :icon="showGuidelines ? 'mdi:chevron-up' : 'mdi:chevron-down'" class="h-5 w-5 mr-1"/>
              {{ showGuidelines ? 'Hide' : 'Show' }} {{ guidelineCategoryName }} Guidelines
          </button>
          <button @click="openAddGuidelineModal(carrier.id, guideline.id)" class="btn-primary">
              Update Guideline
          </button>
        </div>


        <!-- Underwriting guidelines section, visible when toggled -->
        <transition name="expand" @before-enter="beforeEnter" @enter="enter" @leave="leave">
          <div v-if="showGuidelines" class="mt-4 overflow-hidden">
            <UnderwritingGuidelines
              :selectedUnderwritingGuidelineCategory="selectedUnderwritingGuidelineCategory"
              :guideline="guideline"
            />
          </div>
        </transition>        
      </div>

      <!-- User information and last update timestamp -->
      <div class="absolute top-0 right-0 m-4  flex flex-col sm:flex-row sm:items-center sm:space-x-2 space-y-2 sm:space-y-0">
        <span class="text-xs font-medium text-white sm:text-gray-500">User: <span class="text-xs font-normal text-white sm:text-gray-500">{{ guideline.username_of_last_user_update || 'System' }}</span></span>
        <span class="text-xs font-medium text-white sm:text-gray-500">Last updated: <span class="text-xs font-normal text-white sm:text-gray-500">{{ formatDate(guideline.updated_at) }}</span></span>
      </div>
    </div>

    <!-- Modal for adding/updating guidelines -->
    <AddGuidelineModal
      :isVisible="showAddGuidelineModal"
      :carriers="[carrier]"
      :selectedCarrierId="selectedCarrierId"
      :guideline="selectedGuideline"
      @close="showAddGuidelineModal = false"
      @update="emitUpdateGuideline"
    />
    
    <!-- PDF Modal -->
    <PdfModal
      :isVisible="showPdfModal"
      pdfPath="/images/pdfs/Geico/test.pdf"
      @close="showPdfModal = false"
    />
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed } from 'vue';
import { Icon } from '@iconify/vue';
import analyze from 'rgbaster';
import AddGuidelineModal from './AddGuidelineModal.vue';
import UnderwritingGuidelines from './UnderwritingGuidelines.vue';
import PdfModal from '/resources/js/superadminviews/components/PdfModal.vue';

const props = defineProps({
  carrier: {
    type: Object,
    required: true,
  },
  guideline: {
    type: Object,
    required: true,
  },
  selectedCarrierId: {
    type: Number,
    required: false,
    default: null,
  },
  selectedUnderwritingGuidelineCategory: {
    type: String,
    required: true,
    default: '',
  },
  productType: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  'updateGuideline',
  'deleteGuideline',
  'openAddGuidelineModal',
]);

const guidelineCategoryName = computed(() => {
  // Returns the value of selectedUnderwritingGuidelineCategory if it's truthy, otherwise defaults to "Show All Underwriting Guidelines"
  return (
    props.selectedUnderwritingGuidelineCategory ||
    'Show All Underwriting Guidelines'
  );
});

const loading = ref(true);
const showGuidelines = ref(false);
const gradientBgColor = ref('linear-gradient(to right, #f3f4f6, #d1d5db)');
const showAddGuidelineModal = ref(false);
const selectedCarrierId = ref(null);
const selectedGuideline = ref({});
const showPdfModal = ref(false);

const toggleGuidelines = () => {
  showGuidelines.value = !showGuidelines.value;
};
const beforeEnter = (el) => {
  el.style.maxHeight = "0";
};

const enter = (el, done) => {
  setTimeout(() => {
    el.style.transition = 'max-height 0.5s ease-in-out';
    el.style.maxHeight = el.scrollHeight + "px";
    el.addEventListener('transitionend', done);
  }, 100); // Delay might need adjustment based on actual content load time
};


const leave = (el, done) => {
  el.style.maxHeight = el.scrollHeight + "px";
  setTimeout(() => {
    el.style.transition = 'max-height 0.5s ease-in-out';
    el.style.maxHeight = "0";
  }, 10);
  el.addEventListener('transitionend', done);
};

const openAddGuidelineModal = (carrierId, guidelineId) => {
  if (carrierId && guidelineId) {
    selectedCarrierId.value = carrierId;
    selectedGuideline.value = { ...props.guideline };
    showAddGuidelineModal.value = true;
  } else {
    console.error('Invalid carrierId or guidelineId:', carrierId, guidelineId);
  }
};

const emitUpdateGuideline = (updatedGuideline) => {
  console.log('UnderwritingCarrierCard:', updatedGuideline);
  emit('updateGuideline', updatedGuideline);
};

const darkenColor = (color, percent) => {
  const [r, g, b] = color.match(/\d+/g).map(Number);
  // This reduces each RGB component by the specified percentage, with a `Math.max` check to ensure values do not go below 0.
  return `rgb(${Math.max(0, r - r * (percent / 100))}, ${Math.max(0, g - g * (percent / 100))}, ${Math.max(0, b - b * (percent / 100))})`;
};

const extractColor = async (event) => {
  const img = event.target;
  if (img && img.src) {
    try {
      // Remove the event listener if it exists
      if (img.removeEventListener) {
        img.removeEventListener('load', extractColor);
      }

      const result = await analyze(img.src, {
        ignore: ['rgb(255,255,255)', 'rgb(0,0,0)'],
      });

      const nonGrayColors = result.filter(
        (colorResult) => !isGray(colorResult.color)
      );

      if (nonGrayColors.length) {
        const dominantColor = nonGrayColors[0].color;
        const darkerColor = darkenColor(dominantColor, 30);
        gradientBgColor.value = `linear-gradient(to right, ${dominantColor}, ${darkerColor})`;
      } else {
        gradientBgColor.value = 'linear-gradient(to right, #f3f4f6, #d1d5db)';
      }
    } catch (error) {
      console.error('Error extracting color:', error);
    }
  }
};


function isGray(rgbString) {
  const [r, g, b] = rgbString.match(/\d+/g).map(Number);
  // Check if all components are nearly the same, considering a small tolerance to account for compression artifacts
  const tolerance = 10; // Adjust tolerance based on your needs
  return (
    Math.abs(r - g) < tolerance &&
    Math.abs(g - b) < tolerance &&
    Math.abs(b - r) < tolerance
  );
}
const observer = new ResizeObserver(entries => {
  for (let entry of entries) {
    if (showGuidelines.value) {
      entry.target.style.maxHeight = `${entry.contentRect.height}px`;
    }
  }
});
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};
const openPdfModal = () => {
  showPdfModal.value = true;
};
onMounted(() => {
  console.log('UnderwritingCarrierCard current guideline:', props.guideline);

  const img = document.querySelector('img');
  const guidelineContent = document.querySelector('.mt-4');
  
  if (guidelineContent) {
    observer.observe(guidelineContent);
  }

  if (img.complete) {
    extractColor(img.src).then(() => {
      loading.value = false; // Set loading to false once color extraction is complete
    });
  } else {
    img.addEventListener('load', () => {
      extractColor(img.src).then(() => {
        loading.value = false;
      });
    });
  }
});

</script>

<style>
.container {
  max-width: 1200px;
  margin: auto;
}

.btn-primary {
  background-color: #1a73e8;
  color: white;
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition:
    background-color 0.3s,
    transform 0.3s;
}

.btn-primary:hover {
  background-color: #135ab5;
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .btn-primary {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}


/* Initial state for entering element */
.expand-enter-active, .expand-leave-active {
  overflow: hidden; /* Keep this to avoid content spilling during transition */
}
</style>