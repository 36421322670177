<template>
  <div
    class="bg-[#F2F2F4] py-0 px-5 md:mt-[20px] relative drop-shadow-md hidden md:block"
  >
    <Carousel
      :settings="settings"
      :breakpoints="breakpoints"
      :wrap-around="true"
      :autoplay="2500"
    >
      <!-- Carousel Slides Here -->
      <Slide v-for="item in boardfields" :key="item.url">
        <div
          class="carousel__item opacity-60 w-26 md:w-full hover:opacity-100 transition-all"
        >
          <img
            class="w-full md:w-1/2 xs:w-3/4 pointer-events-none"
            :src="item.url"
            alt=""
          />
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default defineComponent({
  name: 'ExamplePagination',
  components: {
    Pagination,
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    boardfields: [
      { url: '/images/carrierLogos/Nationwide-Insurance-Logo.svg' },
      { url: '/images/carrierLogos/olympus.svg' },
      { url: '/images/carrierLogos/peoples-trust.svg' },
      { url: '/images/carrierLogos/edison.svg' },
      { url: '/images/carrierLogos/florida-pen.svg' },
      { url: '/images/carrierLogos/Plymouth_Rock_Assurance.svg' },
      { url: '/images/carrierLogos/progressive.svg' },
      { url: '/images/carrierLogos/Safeco-Insurance-Logo.svg' },
      { url: '/images/carrierLogos/sagesure.svg' },
      { url: '/images/carrierLogos/security-first.svg' },
      { url: '/images/carrierLogos/st-johns.svg' },
      { url: '/images/carrierLogos/swyfft.svg' },
      { url: '/images/carrierLogos/tower-hill.svg' },
      { url: '/images/carrierLogos/Travelers-logo.svg' },
      { url: '/images/carrierLogos/TypTap.svg' },
      { url: '/images/carrierLogos/universal.svg' },
      { url: '/images/carrierLogos/Universal-North-America-Logo.svg' },
      { url: '/images/carrierLogos/upc.svg' },
      { url: '/images/carrierLogos/velocity.svg' },
      { url: '/images/carrierLogos/wellington-logo-lg.svg' },
    ],
    settings: {
      itemsToShow: 2,
      snapAlign: 'left',
      touchDrag: true,
    },
    breakpoints: {
      576: {
        itemsToShow: 2,
        snapAlign: 'left',
      },
      768: {
        itemsToShow: 4,
        snapAlign: 'left',
      },
      1200: {
        itemsToShow: 5,
        snapAlign: 'left',
      },
      1600: {
        itemsToShow: 6,
        snapAlign: 'left',
      },
      1800: {
        itemsToShow: 8,
        snapAlign: 'left',
      },
    },
  }),
});
</script>
