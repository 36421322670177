<template>
  <div>
    <div
      v-for="(roof, index) in roofAgeData"
      :key="index"
      class="form-group mb-6"
    >
      <h3 class="text-lg font-semibold">
        {{
          roofMaterials.find((material) => material.value === roof.material)
            .label
        }}
      </h3>
      <div class="mt-2">
        <label
          :for="'age_range_' + index"
          class="block text-sm font-medium text-gray-700"
          >Age Range: <span class="text-red-500">*</span></label
        >
        <select
          v-model="roof.age_comparison"
          @change="emitUpdate(roofAgeData, index)"
          :id="'age_range_' + index"
          class="form-select mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          <option value="less_than">Less than</option>
          <option value="greater_than">Greater than</option>
          <option value="between">Between</option>
        </select>
      </div>
      <div class="mt-2" v-if="roof.age_comparison === 'between'">
        <div class="flex items-center space-x-2">
          <input
            type="number"
            v-model="roof.age_range_min"
            @input="emitUpdate(roofAgeData, index)"
            placeholder="Min Age"
            class="form-input mt-1 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
          <span>and</span>
          <input
            type="number"
            v-model="roof.age_range_max"
            @input="emitUpdate(roofAgeData, index)"
            placeholder="Max Age"
            class="form-input mt-1 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
      </div>
      <div class="mt-2" v-else>
        <input
          type="number"
          v-model="roof.age_single_value"
          @input="emitUpdate(roofAgeData, index)"
          placeholder="Age"
          class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          required
        />
      </div>
      <div class="mt-4">
        <label class="block text-sm font-medium text-gray-700"
          >Conditions:</label
        >
        <div class="mt-1">
          <div
            v-for="(condition, idx) in conditionOptions"
            :key="idx"
            class="flex items-center mb-2"
          >
            <input
              type="checkbox"
              :id="'condition_' + index + '_' + idx"
              v-model="roof.conditions"
              @change="emitUpdate(roofAgeData, index)"
              :value="condition.value"
              class="form-checkbox"
            />
            <label :for="'condition_' + index + '_' + idx" class="ml-2">{{
              condition.label
            }}</label>
          </div>
          <div class="flex items-center">
            <input
              type="text"
              v-model="roof.custom_condition"
              @input="emitUpdate(roofAgeData, index)"
              placeholder="Custom condition"
              class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <button
              @click="addCustomCondition(index)"
              class="ml-2 px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-700"
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <!-- Details Section -->
      <div class="mt-4">
        <label class="block text-sm font-medium text-gray-700"
          >Details:</label
        >
        <textarea
          v-model="roof.details"
          @input="emitUpdate(roofAgeData, index)"
          placeholder="Enter details here"
          class="form-textarea mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        ></textarea>
      </div>
      
      <!-- FAQ Builder Section -->
      <div class="mt-4">
        <label class="block text-sm font-medium text-gray-700"
          >FAQ:</label
        >
        <div v-for="(faq, faqIndex) in roof.faqs" :key="faqIndex" class="mt-2">
          <div class="flex space-x-2">
            <input
              v-model="faq.question"
              @input="emitUpdate(roofAgeData, index)"
              placeholder="Question"
              class="form-input block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <input
              v-model="faq.answer"
              @input="emitUpdate(roofAgeData, index)"
              placeholder="Answer"
              class="form-input block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <button
              @click="removeFAQ(index, faqIndex)"
              class="ml-2 px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-700"
            >
              Remove
            </button>
          </div>
        </div>
        <button
          @click="addFAQ(index)"
          class="mt-2 px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-700"
        >
          Add FAQ
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const store = useStore();
const userName = `${store.state.customerInfo.firstName} ${store.state.customerInfo.lastName}`;

const roofMaterials = ref([
  { label: 'Shingle', value: 'shingle' },
  { label: 'Tile', value: 'tile' },
  { label: 'Metal', value: 'metal' },
  { label: 'Flat', value: 'flat' },
]);

const conditionOptions = ref([
  { label: 'No existing damage', value: 'no_existing_damage' },
  { label: 'Good condition', value: 'good_condition' },
  { label: 'Proof of repairs required', value: 'proof_of_repairs' },
  { label: 'Subject to underwriting approval', value: 'underwriting_approval' },
]);

const roofAgeData = computed({
  get() {
    return props.modelValue && props.modelValue.length
      ? props.modelValue
      : roofMaterials.value.map((material) => ({
          material: material.value,
          age_comparison: 'less_than',
          age_range_min: '',
          age_range_max: '',
          age_single_value: '',
          conditions: [],
          custom_condition: '',
          details: '', // New field for details
          faqs: [], // New field for FAQs
        }));
  },
  set(newValue) {
    console.log('Roof Age Data:', newValue);
    emit('update:modelValue', newValue);
  },
});

const emitUpdate = (newValue, index = null) => {
  const currentDate = new Date().toISOString();

  const updatedData = newValue.map((item, idx) => {
    // Update only the specific record if index is provided
    if (index !== null && index === idx) {
      return {
        ...item,
        updated_at: currentDate,
        username_of_last_user_update: userName,
      };
    }
    // Return the item as is if no specific index is provided or no change
    return item;
  });

  console.log('Roof Age Input:', updatedData);
  emit('update:modelValue', updatedData);
};

const addFAQ = (index) => {
  roofAgeData.value[index].faqs.push({ question: '', answer: '' });
  emitUpdate(roofAgeData.value, index);
};

const removeFAQ = (roofIndex, faqIndex) => {
  roofAgeData.value[roofIndex].faqs.splice(faqIndex, 1);
  emitUpdate(roofAgeData.value, roofIndex);
};

const addCustomCondition = (index) => {
  const roof = roofAgeData.value[index];
  if (roof.custom_condition) {
    roof.conditions.push(roof.custom_condition);
    roof.custom_condition = '';
    emitUpdate(roofAgeData.value, index);
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
