<template>
  <div class="flex flex-col md:flex-row md:min-h-screen bg-gray-100">
    <Navigation />

    <!-- Main content -->
    <div class="flex-1 flex flex-col p-4 md:p-10">
      <h1 class="text-4xl font-bold text-gray-800 mb-4 md:mb-8 text-center">
        Carrier Management
      </h1>

      <!-- Action buttons and search input -->
      <div
        class="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-8 mb-6 w-full"
      >
        <button
          class="bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600 transition duration-200 focus:outline-none focus:ring focus:ring-green-700 focus:ring-opacity-50"
          @click="openAddCarrierModal"
        >
          Add Carrier
        </button>
        <div class="flex-grow sm:flex-grow-0 sm:max-w-md w-full">
          <input
            type="text"
            v-model="searchQuery"
            class="w-full rounded-md border-gray-300 px-4 py-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            placeholder="Search Carriers"
          />
        </div>
      </div>

      <div class="md:hidden w-full">
        <div
          v-for="carrier in filteredCarriers"
          :key="carrier.id"
          class="bg-white rounded-lg shadow mb-4 p-4 mx-auto"
        >
          <div class="flex items-center space-x-4">
            <div
              class="flex-shrink-0 h-20 w-20 overflow-hidden rounded-lg bg-gray-100 flex items-center justify-center"
            >
              <img
                :src="carrier.logo"
                alt="Carrier Logo"
                class="max-h-full max-w-full"
                style="object-fit: contain"
              />
            </div>
            <div>
              <div class="text-lg font-semibold">
                {{ carrier.carrier_name }}
              </div>
              <div class="text-sm text-gray-600">
                {{ carrier.customer_service_phone }}
              </div>
            </div>
          </div>

          <div class="mt-4">
            <div class="mt-2 flex flex-row">
              <p class="text-sm mr-2">Operating States:</p>
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
              >
                {{
                  carrier.operating_states
                    .map((state) => state.value)
                    .join(', ')
                }}
              </span>
            </div>
            <div class="mt-2 flex flex-row">
              <p class="text-sm mr-2">Products:</p>
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
              >
                {{
                  carrier.products && carrier.products.length > 0
                    ? carrier.products
                        .map((product) => product.value)
                        .join(', ')
                    : 'No products'
                }}
              </span>
            </div>
            <div class="mt-2 flex flex-row">
              <p class="text-sm mr-2">Missing Fields:</p>
              <span
                v-if="
                  getMissingFields(carrier).length &&
                  getMissingFields(carrier)[0] !== 'No missing fields'
                "
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
              >
                {{ getMissingFields(carrier).join(', ') }}
              </span>
              <span
                v-else
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
              >
                No missing fields
              </span>
            </div>
          </div>

          <div class="flex mt-4 space-x-4">
            <button
              class="text-blue-600 hover:text-blue-800"
              @click="startEdit(carrier)"
            >
              <Icon icon="mdi:pencil-outline" class="text-lg" />
            </button>
            <button
              class="text-red-600 hover:text-red-800"
              @click="deleteCarrier(carrier.id)"
            >
              <Icon icon="mdi:delete-outline" class="text-lg" />
            </button>
          </div>
        </div>
      </div>
      <!-- Desktop View -->
      <div class="hidden md:block drop-shadow-lg">
        <div class="flex max-h-[70vh] overflow-y-auto">
          <table class="min-w-full bg-white rounded-lg shadow overflow-hidden">
            <thead class="bg-gray-800 text-white">
              <tr>
                <th class="px-6 py-3 w-20">Logo</th>
                <th class="px-6 py-3">Name</th>
                <th class="px-6 py-3 flex-1">States</th>
                <th class="px-6 py-3 flex-1">Products</th>
                <th class="px-6 py-3">Missing Fields</th>
                <th class="px-6 py-3 w-32">Actions</th>
                <!-- Fixed width for action buttons -->
              </tr>
            </thead>
            <tbody class="text-gray-700">
              <tr
                v-for="carrier in filteredCarriers"
                :key="carrier.id"
                class="hover:bg-gray-100"
              >
                <td class="px-6 py-4">
                  <div
                    class="h-12 w-12 overflow-hidden rounded-lg bg-gray-100 flex items-center justify-center"
                  >
                    <!-- Larger container for logos -->
                    <img
                      :src="carrier.logo"
                      alt="Carrier Logo"
                      class="h-full w-full object-contain"
                    />
                    <!-- Fill the container without stretching -->
                  </div>
                </td>
                <td class="px-6 py-4">{{ carrier.carrier_name }}</td>
                <td class="px-6 py-4">
                  <span
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    {{
                      carrier.operating_states &&
                      carrier.operating_states.length > 0
                        ? carrier.operating_states
                            .map((state) => state.value)
                            .join(', ')
                        : 'No states'
                    }}
                  </span>
                </td>
                <td class="px-6 py-4">
                  <span
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                  >
                    {{
                      carrier.products && carrier.products.length > 0
                        ? carrier.products
                            .map((product) => product.value)
                            .join(', ')
                        : 'No products'
                    }}
                  </span>
                </td>
                <td class="px-6 py-4">
                  <span
                    v-if="
                      getMissingFields(carrier).length &&
                      getMissingFields(carrier)[0] !== 'No missing fields'
                    "
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
                  >
                    {{ getMissingFields(carrier).join(', ') }}
                  </span>
                  <span
                    v-else
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    No missing fields
                  </span>
                </td>
                <td
                  class="px-6 py-4 flex items-center justify-center space-x-2"
                >
                  <!-- Centered action buttons -->
                  <button
                    class="text-blue-600 hover:text-blue-800"
                    @click="startEdit(carrier)"
                  >
                    <Icon icon="mdi:pencil-outline" class="h-6 w-6" />
                    <!-- Larger icons -->
                  </button>
                  <button
                    class="text-red-600 hover:text-red-800"
                    @click="openDeleteConfirm(carrier.id)"
                  >
                    <Icon icon="mdi:delete-outline" class="h-6 w-6" />
                    <!-- Larger icons -->
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <EditCarrierModal
        :editFormData="editFormData"
        :isVisible="editModalVisible"
        @close="editModalVisible = false"
        @save="saveEdit"
      />
      <AddCarrierModal
        :isVisible="addModalVisible"
        @close="addModalVisible = false"
        @add="addCarrier"
      />
      <ConfirmDeletePopup
        v-if="showDeleteConfirm"
        :visible="showDeleteConfirm"
        @confirm="confirmDelete"
        @cancel="cancelDelete"
      />
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import axios from 'axios';
import EditCarrierModal from './components/EditCarrierModal.vue';
import AddCarrierModal from './components/AddCarrierModal.vue';
import Navigation from './components/Navigation.vue';
import Knob from 'primevue/knob';
import ConfirmDeletePopup from './components/ConfirmDeletePopup.vue';

export default {
  name: 'CarrierManagement',
  components: {
    Icon,
    EditCarrierModal,
    AddCarrierModal,
    Navigation,
    Knob,
    ConfirmDeletePopup,
  },
  data() {
    return {
      carriers: [],
      maxCarriersValue: 1000,
      searchQuery: '',
      editModalVisible: false,
      addModalVisible: false,
      showDeleteConfirm: false,
      carrierToDelete: null,
      editCarrierId: null,
      showDropdown: false,
      expandedCarrierId: null,
      editFormData: {
        carrier_name: '',
        customer_service_email: '',
        customer_service_phone: '',
        claims_phone_number: '',
        billing_phone_number: '',
        main_website: '',
        logo: '',
        carrier_billing_link: '',
        carrier_customer_service_link: '',
        overnight_mailing_address: '',
        operating_states: [],
        status: '',
      },
      fieldNames: {
        carrier_name: 'Carrier Name',
        customer_service_email: 'Customer Service Email',
        customer_service_phone: 'Customer Service Phone',
        claims_phone_number: 'Claims Phone Number',
        billing_phone_number: 'Billing Phone Number',
        main_website: 'Main Website',
        logo: 'Logo',
        carrier_billing_link: 'Carrier Billing Link',
        carrier_customer_service_link: 'Carrier Customer Service Link',
        overnight_mailing_address: 'Overnight Mailing Address',
        operating_states: 'States',
        products: 'Products',
        status: 'Status',
      },
    };
  },
  computed: {
    baseUrl() {
      return axios.defaults.baseURL;
    },
    filteredCarriers() {
      return this.carriers
        .filter((carrier) => {
          return carrier.carrier_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        })
        .sort((a, b) => {
          return a.carrier_name.localeCompare(b.carrier_name);
        });
    },
    totalCarriers() {
      return this.carriers.length;
    },
    carriersInFL() {
      return this.carriers.filter((carrier) =>
        carrier.operating_states.includes('FL'),
      ).length;
    },
    carriersInTX() {
      return this.carriers.filter((carrier) =>
        carrier.operating_states.includes('TX'),
      ).length;
    },
    carriersInSC() {
      return this.carriers.filter((carrier) =>
        carrier.operating_states.includes('SC'),
      ).length;
    },
    carriersInNC() {
      return this.carriers.filter((carrier) =>
        carrier.operating_states.includes('NC'),
      ).length;
    },
    carriersInCT() {
      return this.carriers.filter((carrier) =>
        carrier.operating_states.includes('CT'),
      ).length;
    },
    carriersInGA() {
      return this.carriers.filter((carrier) =>
        carrier.operating_states.includes('GA'),
      ).length;
    },
    carriersInNJ() {
      return this.carriers.filter((carrier) =>
        carrier.operating_states.includes('NJ'),
      ).length;
    },
    carriersInNY() {
      return this.carriers.filter((carrier) =>
        carrier.operating_states.includes('NY'),
      ).length;
    },
  },
  created() {
    this.fetchCarriers();
  },
  methods: {
    fetchCarriers() {
      const baseURL = axios.defaults.baseURL;
      axios
        .get(`${baseURL}/api/carriers`)
        .then((response) => {
          this.carriers = response.data.map((carrier) => ({
            ...carrier,
            logo: carrier.logo ? `${baseURL}${carrier.logo}` : null,
            operating_states: carrier.operating_states
              ? carrier.operating_states.map((state) => ({
                  name: state.name,
                  value: state.value,
                }))
              : [],
            products: carrier.products
              ? carrier.products.map((product) => ({
                  name: product.name,
                  value: product.value,
                }))
              : [],
          }));
        })
        .catch((error) => {
          console.error('There was an error fetching the carriers:', error);
        });
    },
    startEdit(carrier) {
      this.editCarrierId = carrier.id;
      this.editFormData = { ...carrier };
      this.editModalVisible = true;
    },
    saveEdit(formData) {
      const editURL = `/api/carriers/${this.editCarrierId}`;

      formData.append('_method', 'PUT');
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }

      axios
        .post(editURL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.fetchCarriers();
          this.editModalVisible = false;
        })
        .catch((error) => {
          console.error('There was an error updating the carrier:', error);
        });
    },
    openDeleteConfirm(id) {
      this.carrierToDelete = id;
      this.showDeleteConfirm = true;
    },
    confirmDelete() {
      const deleteURL = `/api/carriers/${this.carrierToDelete}`;
      axios
        .delete(deleteURL)
        .then(() => {
          this.fetchCarriers();
          this.showDeleteConfirm = false;
          this.carrierToDelete = null;
        })
        .catch((error) => {
          console.error('There was an error deleting the carrier:', error);
        });
    },
    cancelDelete() {
      this.showDeleteConfirm = false;
      this.carrierToDelete = null;
    },
    openAddCarrierModal() {
      this.addModalVisible = true;
    },
    addCarrier(formData) {
      const addURL = `/api/carriers`;

      axios
        .post(addURL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.fetchCarriers();
          this.addModalVisible = false;
        })
        .catch((error) => {
          console.error('There was an error adding the carrier:', error);
        });
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    getMissingFields(carrier) {
      const fields = [
        'carrier_name',
        'customer_service_email',
        'customer_service_phone',
        'claims_phone_number',
        'billing_phone_number',
        'main_website',
        'logo',
        'carrier_billing_link',
        'carrier_customer_service_link',
        'overnight_mailing_address',
        'operating_states',
        'products',
        'status',
      ];
      const missingFields = fields
        .filter(
          (field) =>
            !carrier[field] ||
            (Array.isArray(carrier[field]) && carrier[field].length === 0),
        )
        .map((field) => this.fieldNames[field]);

      return missingFields.length ? missingFields : ['No missing fields'];
    },
  },
};
</script>
