<template>
  <div class="guideline-section p-4 bg-white rounded-lg shadow-md mb-4">
    <h3 class="text-lg font-semibold mb-3">Assumed Wind Mitigation Credits</h3>
    <div v-for="(item, index) in parsedItems" :key="index" class="mb-3">
      <p>
        <strong>Wind Mitigation Features:</strong>
        {{ item.windMitigationFeatures }}
      </p>
      <p><strong>Credits Applied:</strong> {{ item.creditsApplied }}</p>
      <p>
        <strong>Last Updated By:</strong>
        {{ item.username_of_last_user_update }}
      </p>
      <p><strong>Last Updated At:</strong> {{ formatDate(item.updated_at) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssumedWindMitigationCreditsSection',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    parsedItems() {
      return this.items.map((item) => {
        try {
          return typeof item === 'string' ? JSON.parse(item) : item;
        } catch (e) {
          console.error('Failed to parse item:', item, e);
          return {};
        }
      });
    },
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    formatText(text) {
      return text
        ? text.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
        : 'N/A';
    },
  },
};
</script>

<style scoped>
.guideline-section {
  max-width: 600px;
}
.guideline-section h3 {
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 0.5rem;
}
</style>
