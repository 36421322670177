<template>
  <AppHeaderDesktop class="hidden md:block" />
  <transition name="fade">
    <div
      v-if="showLoginSuccess"
      class="fixed top-20 right-20 z-50 p-4 bg-green-500 text-white rounded-lg shadow-md"
      @click="showLoginSuccess.value = false"
    >
      Success! You are now logged in.
    </div>
  </transition>
  <div class="flex flex-col items-center justify-center min-h-screen bg-white">
    <!-- Adjust the size and positioning of background images for better layout control -->
    <img
      class="absolute left-0 bottom-0 md:w-1/4 hidden md:block"
      src="@/assets/images/IEXPleftherobanner.svg"
      alt="Left Background"
    />
    <img
      class="absolute right-0 bottom-0 md:w-1/4 hidden md:block"
      src="@/assets/images/IEXPrightherobanner.svg"
      alt="Right Background"
    />

    <!--Registration Success-->
    <div
      v-if="registrationSuccess"
      class="w-full h-full flex items-center justify-center"
    >
      <div
        class="w-full md:w-1/3 h-auto bg-white drop-shadow-2xl md:drop-shadow-md mx-auto rounded-lg p-6 flex flex-col items-center space-y-4"
      >
        <h2 class="text-2xl font-bold">Success!</h2>
        <img src="@/assets/images/gifs/7efs.gif" alt="Success Animation" />
        <p class="text-green-600 font-bold">Your registration is complete</p>
        <p class="mt-4 text-sm text-center text-textDarkGray">
          You can now log in with your credentials.
        </p>
        <button
          class="form-button w-full bg-red-600 text-white py-3 rounded focus:outline-none focus:ring focus:ring-red-600 disabled:opacity-50"
          @click="handleBackToLogin"
        >
          Back to Login
        </button>
      </div>
    </div>
    <!--Policy Verification Pop-up-->
    <div
      v-if="showPolicyPopup"
      class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-11"
    >
      <div class="bg-white rounded-lg shadow-xl text-center p-6">
        <h3 class="text-xl font-semibold text-gray-800">
          Policy Verification Required
        </h3>
        <p class="mt-2 text-gray-600">Select Your Relevant Policy to Proceed</p>
        <p class="mt-4 text-sm text-gray-600">
          To complete your registration, review the policies associated with
          your contact information. Select the policy that accurately reflects
          your current or most recent coverage.
        </p>
        <div class="mt-6 max-h-60 overflow-y-auto">
          <div v-if="policyData.length > 0">
            <div
              v-for="(policy, index) in policyData"
              :key="index"
              class="p-4 my-2 border border-gray-300 rounded hover:border-blue-500 cursor-pointer"
              @click="selectPolicy(policy)"
            >
              <div :class="{ 'bg-blue-100': policy.selected }">
                <strong>{{ policy.policy_type_lob }} Policy</strong><br />
                <strong>Policy Number:</strong> {{ policy.policy_number }}<br />
                <strong>Effective Date:</strong>
                {{ formatDate(policy.start_date) }}<br />
                <strong>Expiry Date:</strong> {{ formatDate(policy.end_date)
                }}<br />
                <strong>Insurer:</strong> {{ policy.writing_company_name }}
              </div>
            </div>
          </div>
          <div
            v-else
            class="text-white bg-red-600 rounded-2xl p-2 flex justify-center items-center"
          >
            <span
              >No policies were shown to be associated with the information
              provided.</span
            >
            <Icon icon="typcn:info" color="white" class="ml-2" />
          </div>
        </div>
        <div class="flex justify-end mt-4">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            @click="confirmPolicy"
          >
            Confirm
          </button>
          <button
            class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            @click="cancelPolicyVerification"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
    <!-- Registration/Login Form Container -->
    <div
      v-if="!registrationSuccess && !showPolicyPopup"
      class="w-full z-10 bg-white shadow-2xl rounded-lg overflow-hidden max-w-md md:max-w-lg mx-auto p-6"
    >
      <!-- Logo Container -->
      <div class="mb-4 w-full">
        <img
          src="@/assets/images/insurance_express-main-logo.svg"
          alt="Logo"
          class="mx-auto"
        />
      </div>

      <!-- Form Heading -->
      <div class="mb-2 text-center">
        <h2 class="text-xl text-ietextlight font-bold mb-2">
          {{ formType === 'login' ? 'Login' : 'Register' }}
        </h2>
      </div>

      <!-- Form Content -->
      <div class="space-y-6">
        <!-- Error Message Container -->
        <div
          v-if="formType === 'login' && loginError"
          class="text-red-600 text-sm mb-4"
        >
          {{ loginError }}
        </div>
        <div
          v-if="formType === 'register' && registrationError"
          class="text-red-600 text-sm mb-4"
        >
          {{ registrationError }}
        </div>

        <!-- Form -->
        <form
          @submit.prevent="
            formType === 'login' ? handleLogin() : handleRegister()
          "
        >
          <div v-if="formType === 'register'">
            <!-- First Name -->
            <div class="space-y-2 mt-2">
              <label
                for="firstName"
                class="block text-sm font-medium text-gray-700"
                >First Name</label
              >
              <input
                id="firstName"
                v-model="firstName"
                type="text"
                class="form-input w-full p-3 border rounded focus:border-red-600 focus:outline-none"
                placeholder="Enter your first name"
              />
              <div v-if="firstNameError" class="text-red-600 text-sm">
                {{ firstNameError }}
              </div>
              <div v-else class="my-2"></div>
            </div>

            <!-- Last Name -->
            <div class="space-y-2 mt-2">
              <label
                for="lastName"
                class="block text-sm font-medium text-gray-700"
                >Last Name</label
              >
              <input
                id="lastName"
                v-model="lastName"
                type="text"
                class="form-input w-full p-3 border rounded focus:border-red-600 focus:outline-none"
                placeholder="Enter your last name"
              />
              <div v-if="lastNameError" class="text-red-600 text-sm">
                {{ lastNameError }}
              </div>
              <div v-else class="my-2"></div>
            </div>

            <!-- Phone Number -->
            <div class="space-y-2 mt-2">
              <label
                for="phoneNumber"
                class="block text-sm font-medium text-gray-700"
                >Phone Number</label
              >
              <input
                id="phoneNumber"
                v-model="phoneNumber"
                type="text"
                class="form-input w-full p-3 border rounded focus:border-red-600 focus:outline-none"
                placeholder="(999) 999-9999"
              />
              <div v-if="phoneNumberError" class="text-red-600 text-sm">
                {{ phoneNumberError }}
              </div>
              <div v-else class="my-2"></div>
            </div>
          </div>

          <!-- Email -->
          <div class="space-y-2 mt-2">
            <label for="email" class="block text-sm font-medium text-gray-700"
              >Email</label
            >
            <input
              id="email"
              v-model="email"
              type="email"
              class="form-input w-full p-3 border rounded focus:border-red-600 focus:outline-none"
              placeholder="Enter your email"
            />
            <div v-if="emailError" class="text-red-600 text-sm">
              {{ emailError }}
            </div>
            <div v-else class="my-2"></div>
          </div>

          <!-- Password -->
          <div class="space-y-2 mt-2">
            <label
              for="password"
              class="block text-sm font-medium text-gray-700"
              >Password</label
            >
            <input
              id="password"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              class="form-input w-full p-3 border rounded focus:border-red-600 focus:outline-none"
              placeholder="Enter your password"
            />
            <div v-if="passwordError" class="text-red-600 text-sm">
              {{ passwordError }}
            </div>
            <div v-else class="my-2"></div>
            <button
              type="button"
              class="text-sm text-blue-600"
              @click.prevent="showPassword = !showPassword"
            >
              Show/Hide Password
            </button>
          </div>

          <!-- Remember Me Checkbox -->
          <div class="flex items-center my-4">
            <input
              id="remember"
              v-model="remember"
              type="checkbox"
              class="custom-checkbox mr-2"
            />
            <label for="remember" class="text-sm font-medium text-gray-700"
              >Remember Me</label
            >
          </div>

          <!-- Toggle Form Type -->
          <!-- <div class="text-center mt-4">
                <button @click.prevent="toggleFormType" type="button" class="text-blue-600 font-semibold">
                  {{ formType === 'login' ? 'Need an account? Register' : 'Already have an account? Login' }}
                </button>
              </div> -->

          <!-- Registration/Login Submit Button -->
          <button
            type="submit"
            :disabled="Object.keys(errors).length > 0"
            class="form-button w-full bg-red-600 text-white py-3 rounded focus:outline-none focus:ring focus:ring-red-600 disabled:opacity-50"
          >
            {{ formType === 'login' ? 'Login' : 'Register' }}
          </button>

          <!-- OAuth Login Buttons -->
          <!-- <div class="mt-4 flex flex-col space-y-3">
                <a href="/login/google" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-gray-600 bg-gray-200 hover:bg-gray-300">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/archive/c/c1/20190923152039%21Google_%22G%22_logo.svg" alt="Google" class="w-6 h-6 mr-3" />
                  Log in with Google
                </a>
                
                <a href="/login/apple" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-black hover:bg-gray-900">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/3/31/Apple_logo_white.svg" alt="Apple" class="w-6 h-6 mr-3" />
                  Log in with Apple
                </a>
              </div> -->
        </form>
      </div>
    </div>
  </div>
  <div v-if="isLoading" ref="loaderRef" class="loader-container">
    <div id="dbox">
      <div id="d1"></div>
      <div id="d2"></div>
      <div id="d3"></div>
      <div id="d4"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, computed, watch } from 'vue';
import { nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useField, useForm } from 'vee-validate';
import { Icon } from '@iconify/vue';
import { useStore } from 'vuex';
import * as yup from 'yup';
import AppHeaderDesktop from './AppHeaderDesktop.vue';
import Checkbox from 'primevue/checkbox';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import { gsap } from 'gsap'; // Import GSAP for animations
import cloud from '@/data/cloud.json';

export default {
  components: {
    Checkbox,
    InputMask,
    InputText,
    Dialog,
    Icon,
    AppHeaderDesktop,
  },
  setup() {
    const store = useStore();
    console.log(store); // Check if the store is correctly logged
    const isLoading = ref(false);
    const loaderRef = ref(null);
    const initLoaderAnimation = () => {
      console.log('Initializing Loader Animation');
      if (loaderRef.value) {
        // GSAP 3 syntax
        const tl = gsap.timeline({ repeat: -1 });

        tl.from('#d1', { duration: 1, y: '-=60px', autoAlpha: 0 })
          .from('#d2', { duration: 1, x: '+=60px', autoAlpha: 0 }, '-=0.5')
          .from('#d3', { duration: 1, y: '+=60px', autoAlpha: 0 }, '-=0.5')
          .from('#d4', { duration: 1, x: '-=60px', autoAlpha: 0 }, '-=0.5')
          .to('#dbox', { duration: 1, rotation: 360 })
          .to('#d4', { duration: 1, x: '-=60px', autoAlpha: 0 }, '>-0.5')
          .to('#d3', { duration: 1, y: '+=60px', autoAlpha: 0 }, '>-1')
          .to('#d2', { duration: 1, x: '+=60px', autoAlpha: 0 }, '>-1.5')
          .to('#d1', { duration: 1, y: '-=60px', autoAlpha: 0 }, '>-2');
      }
    };

    watch(isLoading, (newValue) => {
      if (newValue) {
        nextTick(() => {
          initLoaderAnimation();
        });
      } else {
        // Handle stopping the animation if necessary
      }
    });

    const customerId = computed(() => store.state.customerId);
    const router = useRouter();
    const formType = ref('login');
    const registrationSuccess = ref(false);
    const showLoginSuccess = ref(false);
    const remember = ref(false);
    const showPassword = ref(false);
    const showPolicyPopup = ref(false);
    const closePolicyPopup = () => {
      showPolicyPopup.value = false;
      // additional logic to return focus to where it was before opening the popup
    };
    const policyData = ref([]);

    const loginError = ref(null); // Define a ref for login error messages
    const registrationError = ref(null); // Define a ref for registration error messages
    const userRegistrationInfo = ref({});
    const formatPhoneNumber = (phoneNumber) => {
      return phoneNumber.replace(/[^\d]/g, '');
    };
    const phoneNumber = ref(''); // Declare phoneNumber here
    const formattedPhoneNumber = ref('');
    watch(phoneNumber, (newValue) => {
      formattedPhoneNumber.value = formatPhoneNumber(newValue);
    });
    // Define the validation schema
    const validationSchema = computed(() => {
      return formType.value === 'register'
        ? yup.object({
            firstName: yup.string().required('First name is required'),
            lastName: yup.string().required('Last name is required'),
            phoneNumber: yup.string().required('Phone number is required'),
            email: yup
              .string()
              .email('Must be a valid email')
              .required('Email is required'),
            password: yup
              .string()
              .min(8, 'Password must be at least 8 characters')
              .required('Password is required'),
          })
        : yup.object({
            email: yup
              .string()
              .email('Must be a valid email')
              .required('Email is required'),
            password: yup.string().required('Password is required'),
          });
    });

    // useForm setup with validation on blur
    const { handleSubmit, resetForm, errors } = useForm({
      validationSchema: validationSchema,
      mode: 'blur',
    });

    // Fields setup
    const { value: firstName, errorMessage: firstNameError } =
      useField('firstName');
    const { value: lastName, errorMessage: lastNameError } =
      useField('lastName');
    const { value: phoneNumberValue, errorMessage: phoneNumberError } =
      useField('phoneNumber'); // Rename to avoid conflicts
    const { value: email, errorMessage: emailError } = useField('email');
    const { value: password, errorMessage: passwordError } =
      useField('password');
    const handleLogin = handleSubmit(async () => {
      try {
        isLoading.value = true;
        const loginData = {
          email: email.value,
          password: password.value,
        };
        console.log('Attempting login', loginData);
        const response = await axios.post('/api/login', loginData);

        if (response.status === 200 && response.data) {
          console.log('Login response received', response.data);
          store.dispatch('login', response.data);
          axios.defaults.headers.common['Authorization'] =
            `Bearer ${response.data.token}`;

          showLoginSuccess.value = true; // Show success toast
          setTimeout(() => {
            showLoginSuccess.value = false;
          }, 3000);
        } else {
          console.error('Login failed with response', response);
          loginError.value =
            'Login was unsuccessful. Please check your credentials.';
        }
      } catch (error) {
        console.error('Login error:', error.response || error);
        loginError.value =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred during login. Please try again later.';
      } finally {
        isLoading.value = false;
        resetForm();
      }
    });

    const selectPolicy = (selectedPolicy) => {
      policyData.value.forEach((policy) => {
        policy.selected = policy === selectedPolicy;
      });
    };
    const fetchPolicyDataonRegister = async (formattedPhoneNumber) => {
      try {
        isLoading.value = true;
        const response = await axios.post('/api/verify-policy', {
          phoneNumber: formattedPhoneNumber,
        });
        console.log('Policy data:', response.data);
        if (response.data && response.data.policyInfo) {
          policyData.value = response.data.policyInfo.map((policy) => ({
            ...policy,
            selected: false,
          }));
          // Store customerId separately
          userRegistrationInfo.value.customerId = response.data.customerId;
          console.log('Fetched policy data:', policyData.value);
          console.log('Customer ID:', userRegistrationInfo.value.customerId);
          showPolicyPopup.value = true;
        } else {
          policyData.value = [];
          showPolicyPopup.value = true;
        }
        isLoading.value = false;
      } catch (error) {
        console.error('Error fetching policy data:', error);
        registrationError.value =
          error.response.data.error ||
          'An error occurred while fetching policy data.';
      }
    };
    const handlePolicyConfirmation = async () => {
      // try {
      //     const selectedPolicy = policyData.value.find(policy => policy.selected);
      //     console.log('Current policyData:', policyData.value);

      //     if (!selectedPolicy) {
      //     throw new Error('No policy selected');
      //     }

      //     // Since customerId is already in userRegistrationInfo, no need to add separately
      //     const userData = {
      //         ...userRegistrationInfo.value,
      //         policies: policyData.value // include all policy data
      //     };

      //     console.log('User data for registration:', userData);

      //     const response = await axios.post('/api/register', userData);

      //     if (response.status === 201) {
      registrationSuccess.value = true;
      showPolicyPopup.value = false;
      userRegistrationInfo.value = {}; // Clear registration info
      //     }
      // } catch (error) {
      //     console.error('Error during policy confirmation:', error);
      //     registrationError.value = error.message || "An error occurred during registration.";
      // }
    };

    const confirmPolicy = () => {
      // Ensure at least one policy is selected
      if (policyData.value.some((policy) => policy.selected)) {
        // Here you can extract the selected policies and proceed with confirmation
        handlePolicyConfirmation();
      } else {
        registrationError.value = 'Please select a policy to confirm.';
      }
    };
    const cancelPolicyVerification = () => {
      // Clear errors and user info, and switch to login view
      registrationError.value = null;
      userRegistrationInfo.value = {};
      showPolicyPopup.value = false;
      formType.value = 'login';
    };
    const handleRegister = handleSubmit(async (values) => {
      // Perform validation first
      if (!errors.phoneNumber) {
        // Format the phone number after validation
        values.phoneNumber = formatPhoneNumber(values.phoneNumber);
        // Log the newly formatted phone number
        console.log('Formatted Phone Number:', values.phoneNumber);
      }

      // Your existing logic for registration
      userRegistrationInfo.value = values;
      console.log('User registration info:', userRegistrationInfo.value);

      // Comment out or remove the next line to skip policy verification
      // await fetchPolicyDataonRegister(values.phoneNumber);

      // Optionally directly confirm registration if skipping policy check
      // simulateRegistrationConfirmation(); // Implement this function to simulate direct registration
    });
    const toggleFormType = () => {
      formType.value = formType.value === 'login' ? 'register' : 'login';
      registrationError.value = null;
      loginError.value = null;
      resetForm();
      showPolicyPopup.value = false;
    };

    const handleBackToLogin = () => {
      formType.value = 'login';
      registrationSuccess.value = false;
    };

    return {
      formType,
      firstName,
      lastName,
      phoneNumber: phoneNumberValue, // Use phoneNumberValue here
      email,
      password,
      remember,
      showPassword,
      registrationSuccess,
      showLoginSuccess,
      isLoading,
      loaderRef,
      handleLogin,
      handleRegister,
      policyData,
      selectPolicy,
      showPolicyPopup,
      closePolicyPopup,
      fetchPolicyDataonRegister,
      confirmPolicy,
      cancelPolicyVerification,
      toggleFormType,
      handleBackToLogin,
      cancelPolicyVerification,
      handlePolicyConfirmation,
      errors,
      firstNameError,
      lastNameError,
      phoneNumberError,
      emailError,
      passwordError,
      loginError,
      registrationError,
      customerId,
      formatPhoneNumber,
      cloud,
    };
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.loader-container {
  position: fixed;
  inset: 0;
  background-color: rgba(171, 170, 170, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

#dbox {
  width: 250px;
  height: 250px;
  margin: 0 auto;
  position: relative;
  z-index: 1010; /* Higher than the container to ensure it's on top */
}

#dbox div {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  opacity: 0.85; /* Slightly reduce opacity for a softer appearance */
}

#d1 {
  left: 50%;
  margin-left: -50px;
  background-color: #4479ee;
}

#d2 {
  top: 50%;
  margin-top: -50px;
  right: 0;
  background-color: #73cc6d;
}

#d3 {
  bottom: 0%;
  margin-left: -50px;
  left: 50%;
  background-color: #e06666;
}

#d4 {
  top: 50%;
  margin-top: -50px;
  left: 0;
  background-color: #b2b2b1;
}

/* Other styles remain unchanged */
</style>
