<template>
  <div>
    <div v-if="policies && policies.policyInfo" class="policies">
      <div class="overflow-x-auto overflow-y-scroll hidden md:block">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-100">
            <tr>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Policy Number
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider lg:table-cell hidden"
              >
                Policy Type
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider lg:table-cell hidden"
              >
                Company Name
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Effective Date
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Expiry Date
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <template
              v-for="policy in policies.policyInfo"
              :key="policy.policy_number"
            >
              <tr
                @click="showOptions(policy)"
                class="hover:bg-gray-50 cursor-pointer"
              >
                <!-- Desktop View Table Data -->
                <td class="flex items-center py-4">
                  <img
                    v-if="getIconPath(policy.policy_type_lob).endsWith('.svg')"
                    :src="getIconPath(policy.policy_type_lob)"
                    :alt="policy.policy_type_lob"
                    class="h-8 w-8 mr-3"
                  />
                  <Icon
                    v-else
                    :icon="getIconPath(policy.policy_type_lob)"
                    class="h-8 w-8 mr-3 iconify-icon"
                  ></Icon>
                  <div>
                    <div class="text-sm font-medium text-gray-900">
                      {{ policy.policy_number }}
                    </div>
                    <div class="text-xs text-gray-500">
                      {{ policy.policy_type_lob }}
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 lg:table-cell hidden"
                >
                  {{ policy.policy_type_lob }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 lg:table-cell hidden"
                >
                  {{ policy.writing_company_name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ formatDate(policy.start_date) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ formatDate(policy.end_date) }}
                </td>
              </tr>
              <!-- Action Buttons for each policy -->
              <tr
                v-if="
                  selectedPolicy &&
                  selectedPolicy.policy_number === policy.policy_number
                "
                :key="'actions-' + policy.policy_number"
              >
                <td colspan="5" class="py-4">
                  <div
                    class="flex flex-col sm:flex-row justify-around space-y-3 sm:space-y-0"
                  >
                    <Button
                      @click="makePayment(selectedPolicy)"
                      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full sm:w-auto"
                      >Make a Payment</Button
                    >
                    <Button
                      @click="managePolicy"
                      class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full sm:w-auto mb-4"
                      >Manage Policy</Button
                    >
                    <Button
                      @click="makeClaim(selectedPolicy)"
                      class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full sm:w-auto"
                      >Claims</Button
                    >
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <!-- Mobile view content -->
      <!-- Loop through policies -->
      <div
        v-for="policy in policies.policyInfo"
        :key="policy.policy_number"
        @click="showOptions(policy)"
        class="mb-4 block md:hidden overflow-y-scroll rounded-lg shadow hover:shadow-lg transition duration-300 ease-in-out cursor-pointer"
      >
        <div class="flex items-center p-4 bg-blue-50">
          <!-- Icon/Image -->
          <img
            v-if="getIconPath(policy.policy_type_lob).endsWith('.svg')"
            :src="getIconPath(policy.policy_type_lob)"
            :alt="policy.policy_type_lob"
            class="h-10 w-10 mr-4"
          />
          <Icon
            v-else
            :icon="getIconPath(policy.policy_type_lob)"
            class="iconify-icon h-10 w-10 mr-4"
          ></Icon>
          <!-- Policy Number and Tap to View Details -->
          <div class="flex-grow">
            <div class="text-xl font-semibold text-blue-600">
              {{ policy.policy_number }}
            </div>
            <div class="text-xs text-gray-500 italic">Tap to view details</div>
          </div>
        </div>
        <div class="p-4 bg-white">
          <!-- Policy Details -->
          <div class="text-sm text-gray-800">
            <span class="font-bold">Policy Type:</span>
            {{ policy.policy_type_lob }}
          </div>
          <div class="text-sm text-gray-800">
            <span class="font-bold">Company Name:</span>
            {{ policy.writing_company_name }}
          </div>
          <div class="text-sm text-gray-800">
            <span class="font-bold">Effective Date:</span>
            {{ formatDate(policy.start_date) }}
          </div>
          <div class="text-sm text-gray-800">
            <span class="font-bold">Expiry Date:</span>
            {{ formatDate(policy.end_date) }}
          </div>
        </div>

        <!-- Action Buttons -->
        <div
          v-if="
            selectedPolicy &&
            selectedPolicy.policy_number === policy.policy_number
          "
          class="mt-4"
        >
          <Button
            @click="makePayment(selectedPolicy)"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full sm:w-auto mb-4"
            >Make a Payment</Button
          >
          <Button
            @click="managePolicy"
            class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full sm:w-auto mb-4"
            >Manage Policy</Button
          >
          <Button
            @click="makeClaim(selectedPolicy)"
            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full sm:w-auto"
            >Claims</Button
          >
        </div>
      </div>
    </div>

    <div v-else class="text-center p-4 mt-5">
      <div class="text-lg font-semibold text-gray-700 mb-3">
        No Policy Data Available
      </div>
      <p class="text-gray-500">
        You currently do not have any policies to display.
      </p>
    </div>
  </div>

  <SelectedPolicy
    v-if="showSelectedPolicy && selectedPolicy?.policy_id"
    :policyId="selectedPolicy.policy_id"
    :showSelectedPolicy="showSelectedPolicy"
    :showChangeRequest="showChangeRequest"
    @close="updateShowSelectedPolicy"
  />
</template>

<script>
import axios from 'axios';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import MobilePolicy from './MobilePolicies.vue';
import Button from 'primevue/button';
import { Icon } from '@iconify/vue';
import SelectedPolicy from './SelectedPolicy.vue';

export default {
  name: 'Policies',
  components: {
    DataTable,
    Column,
    MobilePolicy,
    Icon,
    Button,
    SelectedPolicy,
  },
  setup() {
    const store = useStore();
    const windowWidth = ref(window.innerWidth);
    const policies = ref({ policyInfo: [] });
    const selectedPolicy = ref(null);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };
    const showSelectedPolicy = ref(false);
    let paymentInfo = [];
    let claimsInfo = [];
    const updateShowSelectedPolicy = (newValue) => {
      showSelectedPolicy.value = newValue;
    };

    const fetchJsonData = async () => {
      try {
        const paymentResponse = await axios.get(
          '/js/insuranceExpressPaymentInformation.json',
        );
        paymentInfo = paymentResponse.data;
        const claimsResponse = await axios.get(
          '/js/insuranceExpressClaimsInformation.json',
        );
        claimsInfo = claimsResponse.data;
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      }
    };
    const findMatchingCarrier = (writingCompanyName, carriers) => {
      let highestScore = 0;
      let bestMatch = null;
      const companyNameLower = writingCompanyName.toLowerCase();

      for (const carrier of carriers) {
        let score = 0;
        const carrierNameWords = carrier['Carrier Name']
          .toLowerCase()
          .split(/\s+/);

        for (const word of carrierNameWords) {
          if (companyNameLower.includes(word)) {
            score += word.length; // Longer words contribute more to the score
          }
        }

        // Update best match if this carrier has a higher score
        if (score > highestScore) {
          highestScore = score;
          bestMatch = carrier;
        }
      }

      return bestMatch;
    };

    const augmentPoliciesWithLinks = () => {
      policies.value.policyInfo = policies.value.policyInfo.map((policy) => {
        const paymentCarrier = findMatchingCarrier(
          policy.writing_company_name,
          paymentInfo,
        );
        const claimsCarrier = findMatchingCarrier(
          policy.writing_company_name,
          claimsInfo,
        );
        return {
          ...policy,
          paymentLink: paymentCarrier
            ? paymentCarrier['Carrier Make a Payment Website Link']
            : null,
          claimsLink: claimsCarrier ? claimsCarrier['Claim Link'] : null,
        };
      });
    };

    const fetchPolicies = async () => {
      try {
        const userId = store.state.userId;
        console.log('Fetching policies for user:', userId);
        const authToken = store.state.authToken;
        const response = await axios.get(`/api/policies/${userId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        policies.value.policyInfo = response.data; // Assuming response.data is the array of policies
        console.log('Policies:', policies.value);
      } catch (error) {
        console.error('Error fetching policies:', error);
      }
      augmentPoliciesWithLinks();
    };

    const getIconPath = (policy_type_lob) => {
      let iconName = '';
      const type = policy_type_lob.toLowerCase();
      if (type.includes('auto')) {
        iconName = 'autoInsurance';
      } else if (type.includes('business')) {
        iconName = 'businessInsurance';
      } else if (type.includes('flood')) {
        iconName = 'floodInsurance';
      } else if (type.includes('home')) {
        iconName = 'homeInsurance';
      } else if (type.includes('life')) {
        iconName = 'lifeInsurance';
      } else if (type.includes('medicare')) {
        iconName = 'medicare';
      } else if (type.includes('pet')) {
        iconName = 'petInsurance';
      } else if (type.includes('water')) {
        return 'emojione-monotone:motor-boat';
      } else {
        return 'mdi:file-document-outline'; // Default icon
      }
      return `/images/product-type-icons/${iconName}.svg`;
    };

    const showOptions = (policy) => {
      selectedPolicy.value = policy;
      console.log('Selected Policy:', selectedPolicy.value);
      showSelectedPolicy.value = false;
    };

    const managePolicy = (event) => {
      event.stopPropagation(); // Prevent event from bubbling up
      console.log(
        'Manage Policy Clicked, Selected Policy:',
        selectedPolicy.value,
      );
      if (selectedPolicy.value && selectedPolicy.value.policy_id) {
        showSelectedPolicy.value = true;
      } else {
        console.error('No selected policy or policy ID missing');
      }
    };

    const makePayment = (policy) => {
      if (policy && policy.paymentLink) {
        window.location.href = policy.paymentLink;
      } else {
        // Handle the case where there is no payment link
        console.error('Payment link not available for this policy');
      }
    };

    const makeClaim = (policy) => {
      if (policy && policy.claimsLink) {
        window.location.href = policy.claimsLink;
      } else {
        // Handle the case where there is no claims link
        console.error('Claims link not available for this policy');
      }
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      fetchJsonData().then(fetchPolicies);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    return {
      windowWidth,
      policies,
      selectedPolicy,
      getIconPath,
      showOptions,
      makePayment,
      makeClaim,
      managePolicy,
      augmentPoliciesWithLinks,
      showSelectedPolicy,
      updateShowSelectedPolicy,
      showChangeRequest: false,
      selectedPolicy,
    };
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}-${day}-${year}`;
    },
  },
};
</script>

<style scoped>
.break-words {
  word-break: break-word;
}
.iconify-icon {
  color: #656563; /* Your desired color */
}

/* Existing CSS */
</style>
