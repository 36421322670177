<template>
  <div class="form-group mb-4">
    <label for="claims_history" class="block text-sm font-medium text-gray-700"
      >Claims History:</label
    >
    <input
      v-bind:checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
      type="checkbox"
      id="claims_history"
      class="form-checkbox mt-1"
    />
  </div>
</template>
<script>
export default {
  name: 'ClaimsHistoryInput',
  props: {
    modelValue: Boolean,
  },
};
</script>
<style scoped></style>
