<template>
  <footer class="mt-20 custom-section">
    <div class="grid grid-cols-6 z-10 absolute">
      <div class="grid grid-cols-1 bg-[#192339]">
        <div class="hidden md:block">
          <img
            class="ml-10 xl:mt-5 mt-9 w-full"
            src="@/assets/images/insurance_express-main-logo-white.svg"
          />
        </div>
      </div>
    </div>
    <div class="bg-[#192339]">
      <div class="md:hidden">
        <img
          class="px-10 md:pt-5 pt-10 max-w-[380px] w-full mx-auto"
          src="@/assets/images/insurance_express-main-logo-white.svg"
        />
      </div>
    </div>
    <!--Facebook and More-->
    <div class="text-center bg-[#192339] text-white">
      <div class="p-6">
        <div
          class="flex justify-center gap-4 xs:mx-[5%] sm:mx-[10%] md:mx-[15%] lg:mx-[25%] xl:mx-[35%]"
        >
          <!-- Facebook Link -->
          <a href="https://www.facebook.com/Insuranceexpresscom" class="group">
            <div
              class="rounded-full border-2 border-white p-2 transition duration-150 ease-in-out hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
            >
              <!-- SVG icon -->
            </div>
          </a>
          <!-- Google Link -->
          <a href="https://maps.app.goo.gl/9Mgrx4RTiom6z29T7" class="group">
            <div
              class="rounded-full border-2 border-white p-2 transition duration-150 ease-in-out hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
            >
              <!-- SVG icon -->
            </div>
          </a>
          <!-- LinkedIn Link -->
          <a
            href="https://www.indeed.com/cmp/Insurance-Express-&-Member-Companies"
            class="group"
          >
            <div
              class="rounded-full border-2 border-white p-2 transition duration-150 ease-in-out hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
            >
              <!-- SVG icon -->
            </div>
          </a>
        </div>
      </div>
    </div>
    <!--Menu Items Section-->
    <div class="bg-[#192339]">
      <div
        class="flex flex-col space-y-6 md:grid md:grid-cols-4 md:space-y-0 mb-8"
      >
        <div v-for="(menu, index) in menus" :key="index" class="text-center">
          <!-- Button for mobile -->
          <button
            v-if="screenWidth <= 768"
            class="uppercase font-bold font-merriweather mb-2.5 text-white focus:outline-none"
            @click="menu.show = !menu.show"
          >
            {{ menu.title }}
            <i
              :class="menu.show ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
            ></i>
          </button>
          <!-- Static title for desktop -->
          <div
            v-else
            class="uppercase font-bold font-merriweather mb-2.5 text-center text-white"
          >
            {{ menu.title }}
          </div>
          <transition name="slide-fade">
            <!-- Show menus if on desktop or if menu.show is true -->
            <ul
              v-show="screenWidth > 768 || menu.show"
              class="list-none text-sm font-roboto md:bg-[#192339] bg-white text-center mb-0 grid md:grid-cols-2 md:hover:text-red-600"
              :class="
                ['About', 'Resources'].includes(menu.title)
                  ? 'grid-cols-1 md:grid-cols-1'
                  : 'grid-cols-2 md:grid-cols-2'
              "
            >
              <template v-if="menu.title === 'About'">
                <li class="mobile-view">
                  <a
                    :href="menu.items[0].link"
                    class="md:text-white text-[#192339] text-lg md:text-sm hover:text-red-500"
                    >{{ menu.items[0].name }}</a
                  >
                </li>
                <li
                  v-for="(item, i) in menu.items.slice(1)"
                  :key="i"
                  class="mobile-view my-2"
                >
                  <a
                    :href="item.link"
                    class="md:text-white text-[#192339] text-lg md:text-sm hover:text-red-500"
                    >{{ item.name }}</a
                  >
                </li>
              </template>
              <template v-else>
                <li
                  v-for="(item, i) in menu.items"
                  :key="i"
                  class="mobile-view my-2"
                >
                  <a
                    :href="item.link"
                    class="md:text-white text-[#192339] text-lg md:text-sm hover:text-red-500"
                    >{{ item.name }}</a
                  >
                </li>
              </template>
            </ul>
          </transition>
        </div>
      </div>

      <!-- Privacy Policy Link -->
      <div class="text-center bg-[#192339] text-white py-4">
        <a @click="showPrivacyPolicy = true" class="cursor-pointer hover:underline">
          Privacy Policy
        </a>
      </div>

      <div class="text-center p-4 text-xs font-merriweather bg-[#161F32]">
        <a class="text-white"
          >© 2024 Copyright: Insurance Express and Member Companies</a
        >
      </div>
    </div>

    <!-- Modal for Privacy Policy -->
    <transition name="modal">
      <div v-if="showPrivacyPolicy" class="modal-overlay">
        <div class="modal-content">
          <button class="modal-close" @click="showPrivacyPolicy = false">✕</button>
          <h2 class="text-2xl font-bold mb-4">Privacy Policy</h2>
          <div class="privacy-content space-y-4 text-sm text-gray-700">
            <p>Last updated: 10/17/2024</p>
            <hr class="my-4" />
            <h3 class="text-lg font-semibold">Introduction</h3>
            <p>
              At Insurance Express.com (GLV Insurance Agency Inc DBA Insurance Express.com), we are committed to protecting your personal information and respecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://www.insuranceexpress.com" class="text-blue-500 underline">https://www.insuranceexpress.com</a> or communicate with us, including through SMS/MMS text messaging.
            </p>
            <h3 class="text-lg font-semibold">Information We Collect</h3>
            <ul class="list-disc pl-5">
              <li><strong>Personal Information</strong>
                <p>We may collect the following personal information when you submit service requests, request insurance quotes, or opt-in to receive SMS/MMS text messages:</p>
                <ul class="list-disc pl-5">
                  <li><strong>Contact Information:</strong> Name, email address, phone number, and physical address.</li>
                  <li><strong>Non-Personal Data:</strong> IP addresses, browser types, device information, and browsing actions collected through cookies and similar technologies.</li>
                </ul>
                <p><em>Note: We do not collect sensitive personal information such as date of birth, Social Security numbers, driver's license numbers, or health information.</em></p>
              </li>
            </ul>
            <h3 class="text-lg font-semibold">Use of Your Information</h3>
            <p>We use the collected information to:</p>
            <ul class="list-disc pl-5">
              <li><strong>Provide Services:</strong> Process your service requests and provide insurance quotes.</li>
              <li><strong>Marketing and Analytics:</strong> Conduct marketing activities, send promotional materials, and perform analytics to improve our services.</li>
              <li><strong>Third-Party Services:</strong> Share information with third parties as needed for marketing, analytics, and service provision.</li>
              <li><strong>Communication:</strong> Respond to your inquiries and send administrative information.</li>
            </ul>
            <h3 class="text-lg font-semibold">SMS/MMS Text Messaging</h3>
            <p>By providing your mobile number and opting in, you consent to receive SMS/MMS text messages from us regarding our services, including but not limited to policy information, reminders, and promotional offers.</p>
            <ul class="list-disc pl-5">
              <li><strong>Opt-Out:</strong> You may opt-out of receiving text messages at any time by following the instructions provided in the message or by contacting us directly.</li>
              <li><strong>Message and Data Rates:</strong> Standard message and data rates may apply according to your mobile plan.</li>
            </ul>
            <h3 class="text-lg font-semibold">Data Sharing and Disclosure</h3>
            <ul class="list-disc pl-5">
              <li><strong>Third-Party Partners:</strong> We may share your information with insurance carriers, underwriters, marketing partners, and other third-party service providers. This is done to facilitate our services and may occur without obtaining additional consent at the time of sharing.</li>
              <li><strong>SMS/MMS Consent Data:</strong>
                <ul class="list-disc pl-5">
                  <li><em>Exclusion of SMS Consent Data:</em> All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties except as necessary to provide you with the messaging services.</li>
                  <li><em>Limited Sharing for Messaging Services:</em> We will not share your opt-in to an SMS campaign with any third party for purposes unrelated to providing you with the services of that campaign. We may share your personal data, including your SMS opt-in or consent status, with third parties that help us provide our messaging services, including but not limited to platform providers, phone companies, and any other vendors who assist us in the delivery of text messages.</li>
                </ul>
              </li>
              <li><strong>Legal Obligations:</strong> We may disclose your information to comply with legal obligations, regulations, or valid governmental requests.</li>
            </ul>
            <h3 class="text-lg font-semibold">Cookies and Tracking Technologies</h3>
            <ul class="list-disc pl-5">
              <li><strong>Future Use of Cookies:</strong> While we do not currently use cookies or similar tracking technologies, we reserve the right to implement them in the future to enhance user experience and analyze website performance.</li>
              <li><strong>Notification of Changes:</strong> If we begin using cookies or tracking technologies, we will update this Privacy Policy accordingly and provide appropriate notices.</li>
            </ul>
            <h3 class="text-lg font-semibold">Data Security</h3>
            <p>We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. Our security measures include:</p>
            <ul class="list-disc pl-5">
              <li><strong>SSL Encryption:</strong> All data transmitted between your browser and our website is encrypted using Secure Sockets Layer (SSL) technology.</li>
              <li><strong>Secure Servers:</strong> We host our website on secure AWS (Amazon Web Services) servers with standard security protocols.</li>
              <li><strong>PCI DSS Compliance:</strong> If we process payment transactions in the future, we will ensure compliance with the Payment Card Industry Data Security Standard (PCI DSS) to protect your payment information.</li>
            </ul>
            <h3 class="text-lg font-semibold">Data Retention</h3>
            <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy. We ensure data is stored securely throughout its lifecycle and will delete or anonymize data when it is no longer needed.</p>
            <h3 class="text-lg font-semibold">Your Rights and Choices</h3>
            <p>Under applicable data protection laws, including the General Data Protection Regulation (GDPR), you may have the following rights:</p>
            <ul class="list-disc pl-5">
              <li><strong>Access:</strong> You have the right to request access to the personal information we hold about you.</li>
              <li><strong>Rectification:</strong> You may request that we correct any inaccurate or incomplete personal information.</li>
              <li><strong>Erasure ("Right to be Forgotten"):</strong> You have the right to request the deletion of your personal information under certain circumstances.</li>
              <li><strong>Restriction of Processing:</strong> You may request that we restrict the processing of your personal information under certain conditions.</li>
              <li><strong>Data Portability:</strong> You have the right to receive your personal information in a structured, commonly used, and machine-readable format.</li>
              <li><strong>Objection:</strong> You may object to the processing of your personal information for direct marketing purposes or on grounds relating to your particular situation.</li>
            </ul>
            <p>To exercise any of these rights, please contact us using the contact information provided below.</p>
            <ul class="list-disc pl-5">
              <li><strong>Marketing Communications:</strong> You may opt-out of receiving marketing emails by clicking the "unsubscribe" link provided in the emails or by contacting us directly.</li>
              <li><strong>Access and Correction:</strong> While we do not offer an online portal for users to access or update their personal information, you can contact us to request changes or deletion of your information.</li>
            </ul>
            <h3 class="text-lg font-semibold">International Users</h3>
            <p>Our services are intended for users within the United States. However, if you are accessing our services from the European Union or other regions with laws governing data collection and use, please note that you are transferring your personal data to the United States, which may not have the same data protection laws as your jurisdiction.</p>
            <h3 class="text-lg font-semibold">Accessibility</h3>
            <p>We are committed to ensuring that our website is accessible to individuals with disabilities, in compliance with the Americans with Disabilities Act (ADA). If you experience any difficulty accessing any part of our website, please contact us, and we will work with you to provide the information or service you need.</p>
            <h3 class="text-lg font-semibold">Changes to This Privacy Policy</h3>
            <p>We may update this Privacy Policy from time to time. All changes will be effective immediately upon posting the updated policy on this page. We encourage you to review this Privacy Policy periodically for any changes.</p>
            <h3 class="text-lg font-semibold">Contact Us</h3>
            <p>If you have any questions or concerns about this Privacy Policy, or if you wish to exercise your data protection rights, please contact us:</p>
            <p>
              GLV Insurance Agency Inc DBA Insurance Express.com<br />
              2005 Vista Parkway Suite 200<br />
              West Palm Beach, FL 33411<br />
              Phone: <a href="tel:800-268-9137" class="text-blue-500 underline">800-268-9137</a><br />
              Email: <a href="mailto:customerservice@insuranceexpress.com" class="text-blue-500 underline">customerservice@insuranceexpress.com</a>
            </p>
            <hr class="my-4" />
            <p class="text-center text-xs text-gray-500">© 2024 Insurance Express and Member Companies</p>
          </div>
        </div>
      </div>
    </transition>
  </footer>
</template>

<style scoped>
.slide-fade-enter-active {
  transition:
    height 1.2s ease-in-out,
    opacity 0.1s ease-in-out;
}

.slide-fade-leave-active {
  transition:
    height 1.2s ease-in-out,
    opacity 0.1s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.slide-fade-enter-to,
.slide-fade-leave {
  opacity: 1;
  height: auto;
}
.custom-section {
  margin: 0; /* Remove the default margin */
}
.mobile-view {
  font-size: 1.25rem; /* Increase font size */
}

/* Color change on click */
a:active {
  color: red;
}

@media screen and (max-width: 768px) {
  ul {
    grid-template-columns: repeat(
      1,
      1fr
    ) !important; /* Single column layout on mobile */
  }
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 800px;
  position: relative;
  text-align: left;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>

<script>
export default {
  data() {
    return {
      showPrivacyPolicy: false, // Controls modal visibility
      screenWidth: window.innerWidth,
      menus: [
        {
          title: 'About',
          items: [
            { name: 'About Us', link: '/aboutus' },
            { name: 'Customer Service', link: '/customerservice' },
            {
              name: 'Claims',
              link: '/customerservice/carrier-directory/make-claim',
            },
            {
              name: 'Make a Payment',
              link: '/customerservice/carrier-directory/make-payment',
            },
            {
              name: 'Careers',
              link: 'https://www.indeed.com/cmp/Insurance-Express-&-Member-Companies',
            },
          ],
          show: false,
        },
        {
          title: 'Products',
          items: [
            { name: 'Home Insurance', link: '/products/homeinsurance' },
            { name: 'Auto Insurance', link: '/products/autoinsurance' },
            { name: 'Flood Insurance', link: '/products/floodinsurance' },
            { name: 'Medicare', link: '/products/medicare' },
            { name: 'Life Insurance', link: '/products/lifeinsurance' },
            { name: 'Umbrella Insurance', link: '/products/umbrellainsurance' },
            { name: 'Business Insurance', link: '/products/businessinsurance' },
          ],
          show: false,
        },
        {
          title: 'Resources',
          items: [
            {
              name: 'Join the Team',
              link: 'https://www.indeed.com/cmp/Insurance-Express-&-Member-Companies',
            },
          ],
          show: false,
        },
        {
          title: 'Member Companies',
          items: [
            { name: 'Rocket Flood', link: 'https://rocketflood.com/' },
            { name: 'Rocket MGA', link: 'https://rocketmga.com/' },
            { name: 'Str8line Group', link: 'http://www.str8linegroup.com/' },
            {
              name: 'Str8line Enterprises',
              link: 'http://www.str8linegroup.com/',
            },
            { name: 'QuoteSlash', link: 'https://quoteslash.com/' },
            { name: 'Protego VIP', link: 'https://protegovip.com/' },
            { name: 'TheLeadExchange', link: 'https://theleadexchange.com/' },
            { name: 'NuBuild', link: 'https://nubuildinsurance.com/' },
            { name: 'The IE Pipeline', link: 'https://iepipeline.com/' },
            {
              name: 'Graham Newton & McMahon Inc.',
              link: 'https://gnminsurance.com/',
            },
          ],
          show: false,
        },
      ],
    };
  },
  created() {
    window.addEventListener('resize', this.updateScreenWidth);
    this.updateScreenWidth();
  },
  methods: {
    updateScreenWidth() {
      if (window.visualViewport) {
        this.screenWidth =
          window.visualViewport.width * window.visualViewport.scale;
      } else {
        this.screenWidth = window.innerWidth;
      }
      console.log('Adjusted screenWidth to:', this.screenWidth);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
};
</script>
