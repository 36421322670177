<template>
  <div>
    <AcceptableNamedInsuredsSection
      v-if="title === 'Acceptable Named Insureds'"
      :title="title"
      :items="items"
    />
    <AgeOfHomeSection
      v-else-if="title === 'Age of Home'"
      :title="title"
      :items="items"
    />
    <AgeOfRoofSection
      v-else-if="title === 'Age of Roof'"
      :title="title"
      :items="items"
    />
    <AssumedWindMitigationCreditsSection
      v-else-if="title === 'Assumed Wind Mitigation Credits'"
      :title="title"
      :items="items"
    />
    <ElectricalSection
      v-else-if="title === 'Electrical'"
      :title="title"
      :items="items"
    />
    <FourPointInspectionSection
      v-else-if="title === 'Four Point Inspection'"
      :title="title"
      :items="items"
    />
  </div>
</template>

<script>
import AcceptableNamedInsuredsSection from '../guidelineComponents/guidelines/AcceptableNameInsuredsSection.vue';
import AgeOfHomeSection from '../guidelineComponents/guidelines/AgeOfHomeSection.vue';
import AgeOfRoofSection from '../guidelineComponents/guidelines/AgeOfRoofSection.vue';
import AssumedWindMitigationCreditsSection from '../guidelineComponents/guidelines/AssumedWindMitigationCreditsSection.vue';
import ElectricalSection from '../guidelineComponents/guidelines/ElectricalSection.vue';
import FourPointInspectionSection from '../guidelineComponents/guidelines/FourPointInspectionSection.vue';

export default {
  name: 'GuidelineSection',
  components: {
    AcceptableNamedInsuredsSection,
    AgeOfHomeSection,
    AgeOfRoofSection,
    AssumedWindMitigationCreditsSection,
    ElectricalSection,
    FourPointInspectionSection,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>
