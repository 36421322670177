<template>
  <div class="min-h-screen bg-gray-100">
    <!-- Navigation Component -->
    <Navigation />

    <!-- Main Content -->
    <div class="flex items-center justify-center pt-8">
      <div class="bg-white shadow rounded-lg p-8 max-w-lg w-full">
        <h2 class="text-2xl font-semibold text-gray-800 mb-6">Send an Email</h2>
        <div class="mb-4">
          <label
            for="template"
            class="block text-sm font-medium text-gray-700 mb-2"
            >Choose a Template:</label
          >
          <select
            v-model="selectedTemplate"
            id="template"
            class="block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          >
            <option disabled value="">Please select one</option>
            <option
              v-for="template in templates"
              :key="template.id"
              :value="template.id"
            >
              {{ template.name }}
            </option>
          </select>
        </div>
        <div class="mb-4">
          <label
            for="email"
            class="block text-sm font-medium text-gray-700 mb-2"
            >Recipient's Email:</label
          >
          <input
            type="email"
            v-model="email"
            id="email"
            class="block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div class="mb-4">
          <label
            for="subject"
            class="block text-sm font-medium text-gray-700 mb-2"
            >Subject:</label
          >
          <input
            type="text"
            v-model="subject"
            id="subject"
            class="block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div class="text-right">
          <Button
            label="Send Email"
            class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            @click="sendEmail"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Navigation from '../components/Navigation.vue';
import axios from 'axios';

const templates = ref([]);
const selectedTemplate = ref('');
const email = ref('');
const subject = ref('');

// Fetch templates on component mount
onMounted(async () => {
  try {
    const response = await axios.get('/api/templates');
    templates.value = response.data.templates;
  } catch (error) {
    console.error('Failed to fetch templates:', error);
  }
});

// Function to send the email
const sendEmail = async () => {
  if (!selectedTemplate.value || !email.value) {
    alert('Template and email are required.');
    return;
  }

  try {
    await axios.post('/api/send-email', {
      template_id: selectedTemplate.value,
      to: email.value,
      subject: subject.value,
    });
    alert('Email sent successfully!');
  } catch (error) {
    console.error('Failed to send email:', error);
    alert('Failed to send email. Please try again.');
  }
};
</script>
