<template>
  <div class="fixed inset-0 overlay-bg flex justify-center items-center z-50">
    <div class="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full relative">
      <h2 class="text-3xl font-semibold mb-6">Settings</h2>
      <div class="space-y-4">
        <!-- User Preferences Accordion -->
        <div
          @click="toggleAccordion('userPreferences')"
          class="accordion-header flex justify-between items-center"
        >
          <div class="flex items-center">
            <Icon icon="ph:user-light" color="#656563" class="mr-2" />
            <!-- Icon on the left -->
            <h3 class="text-xl font-semibold">User Preferences</h3>
          </div>
          <Icon icon="mingcute:down-line" color="#656563" />
          <!-- Icon on the right -->
        </div>
        <div v-if="isOpen.userPreferences" class="accordion-content">
          <!-- Communication Preferences -->
          <div>
            <label
              for="communication"
              class="block text-sm font-medium text-gray-700"
              >Preferred Communication Method</label
            >
            <select
              id="communication"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option>Email</option>
              <option>Phone Call</option>
              <option>Text Message</option>
            </select>
          </div>
          <!-- Policy Renewal Reminders -->
          <div>
            <label class="flex items-center">
              <input type="checkbox" class="form-checkbox" />
              <span class="ml-2">Receive Policy Renewal Reminders</span>
            </label>
          </div>
          <!-- Document Delivery Preference -->
          <div>
            <label
              for="documentDelivery"
              class="block text-sm font-medium text-gray-700"
              >Document Delivery Method</label
            >
            <select
              id="documentDelivery"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option>Email (Electronic)</option>
              <option>Postal Mail</option>
            </select>
          </div>
          <!-- Customized Policy Recommendations -->
          <div>
            <label class="flex items-center">
              <input type="checkbox" class="form-checkbox" />
              <span class="ml-2"
                >Receive Customized Policy Recommendations</span
              >
            </label>
          </div>
          <!-- Data Privacy Settings -->
          <div>
            <label class="flex items-center">
              <input type="checkbox" class="form-checkbox" />
              <span class="ml-2"
                >Allow Data Sharing for Better Service Offers</span
              >
            </label>
          </div>
        </div>

        <!-- Contact Information Accordion -->
        <div @click="toggleAccordion('contactInfo')" class="accordion-header">
          <h3 class="text-xl font-semibold">Contact Information</h3>
        </div>
        <div v-if="isOpen.contactInfo" class="accordion-content">
          <!-- Components for viewing and editing contact information -->
        </div>

        <!-- Policy Management Accordion -->
        <div
          @click="toggleAccordion('policyManagement')"
          class="accordion-header"
        >
          <h3 class="text-xl font-semibold">Policy Management</h3>
        </div>
        <div v-if="isOpen.policyManagement" class="accordion-content">
          <!-- Components to manage policies, view policy details, etc. -->
        </div>

        <!-- Notification Settings Accordion -->
        <div
          @click="toggleAccordion('notificationSettings')"
          class="accordion-header"
        >
          <h3 class="text-xl font-semibold">Notification Settings</h3>
        </div>
        <div v-if="isOpen.notificationSettings" class="accordion-content">
          <!-- Toggle switches or checkboxes for various notifications -->
          <div>
            <label class="flex items-center">
              <input type="checkbox" class="form-checkbox" />
              <span class="ml-2">Policy Renewal Reminders</span>
            </label>
            <label class="flex items-center">
              <input type="checkbox" class="form-checkbox" />
              <span class="ml-2">Claim Status Updates</span>
            </label>
            <label class="flex items-center">
              <input type="checkbox" class="form-checkbox" />
              <span class="ml-2">Promotional Offers</span>
            </label>
            <!-- Add more notification options as needed -->
          </div>
        </div>

        <button
          @click="closeSettings"
          class="absolute top-3 right-3 text-white bg-red-500 hover:bg-red-600 rounded-full p-2 leading-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-300 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';
import { Icon } from '@iconify/vue';

export default defineComponent({
  components: {
    Icon,
  },
  name: 'CustomerServiceSettings',
  setup(props, { emit }) {
    const isOpen = reactive({
      userPreferences: false,
      contactInfo: false,
      policyManagement: false,
      notificationSettings: false,
    });

    const toggleAccordion = (section) => {
      isOpen[section] = !isOpen[section];
    };

    const closeSettings = () => {
      emit('close');
    };

    return { closeSettings, toggleAccordion, isOpen };
  },
});
</script>
<style scoped>
.accordion-header {
  @apply cursor-pointer py-2 border-b border-gray-200;
}

.accordion-content {
  @apply py-2;
}
</style>
