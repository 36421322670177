<template>
  <AppHeaderDesktop />
  <div class="my-[5%]">
    <div class="mx-auto mt-4">
      <Icon
        icon="healthicons:magnifying-glass"
        class="text-red-600 mx-auto text-4xl"
      />
    </div>
    <div class="text-center">
      <h1 class="text-4xl font-bold text-gray-800 mb-4">Policy Search</h1>
      <p class="text-xl text-gray-600">Quickly find your policy number</p>
    </div>
    <policySearch />
  </div>
  <AppFooter />
</template>

<script>
import AppHeaderDesktop from '../components/AppHeaderDesktop.vue';
import AppFooter from '../components/AppFooter.vue';
import policySearch from '../components/customerservice/managepolicy/serviceRequestForms.vue/policySearch.vue';
import { Icon } from '@iconify/vue';

export default {
  components: {
    AppHeaderDesktop,
    AppFooter,
    policySearch,
    Icon,
  },
  data() {
    return {};
  },
  mounted() {
    // Scroll to the top of the window when the component is mounted
    window.scrollTo(0, 0);
  },
};
</script>
