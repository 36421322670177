<template>
  <div class="flex flex-col items-center justify-center bg-white min-h-screen">
    <!--Heading-->
    <div class="mb-20 z-0 md:z-10">
      <div class="flex flex-col">
        <div class="text-center mx-auto">
          <Icon
            icon="heroicons:home-solid"
            class="text-red-600 mx-auto text-4xl md:text-6xl"
          />
        </div>
        <h1
          class="text-center text-xl md:text-2xl font-bold text-gray-800 mb-2"
        >
          Request Evidence of Insurance
        </h1>
      </div>
      <!--Success Message for form-->
      <div
        v-if="formSubmitted"
        class="w-full h-auto mx-auto rounded-lg p-6 flex flex-col items-center space-y-4"
      >
        <h2 class="text-2xl font-bold">Success!</h2>
        <img src="@/assets/images/gifs/7efs.gif" alt="Success Animation" />
        <p class="text-green-600 font-bold">Your request has been submitted</p>
        <p class="mt-[4px] text-sm text-center text-textDarkGray">
          Please give us 24-48 hours to process your request.
        </p>
        <p
          class="mt-[4px] tracking-[0.2px] leading-[20px] text-center text-sm text-[#1E182E]"
        >
          <span class="font-bold">Can't wait? </span>
          <span>Give us a call at </span>
          <a href="tel:+18002699137" class="text-[#DB0908] font-bold"
            >(800)-269-9137</a
          >
          <br />One of our Insurance Experts will be ready to assist.
        </p>
        <a
          href="/"
          class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-[180px] mb-6 mt-40 text-center"
        >
          Back to homepage
        </a>
      </div>
      <!--Form Container-->
      <div class="max-w-2xl mx-auto bg-white p-8 rounded-xl shadow-xl">
        <!--Contact Information Step 1-->
        <form v-if="!formSubmitted" @submit.prevent="onSubmit">
          <h3 class="text-lg font-bold text-center mb-6 text-gray-800">
            Lender Contact Information
          </h3>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                for="first-name"
                class="block text-sm font-semibold text-gray-700 mb-2"
                >First Name</label
              >
              <input
                v-model="firstName"
                type="text"
                id="first-name"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
              <p v-if="errors.firstName" class="text-red-500 text-xs italic">
                {{ errors.firstName }}
              </p>
            </div>
            <div>
              <label
                for="last-name"
                class="block text-sm font-semibold text-gray-700 mb-2"
                >Last Name</label
              >
              <input
                v-model="lastName"
                type="text"
                id="last-name"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
              <p v-if="errors.lastName" class="text-red-500 text-xs italic">
                {{ errors.lastName }}
              </p>
            </div>
            <div class="col-span-1 md:col-span-2">
              <label
                for="email"
                class="block text-sm font-semibold text-gray-700 mb-2"
                >Work Email</label
              >
              <input
                id="email"
                v-model="email"
                type="email"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
              <p v-if="errors.email" class="text-red-500 text-xs italic">
                {{ errors.email }}
              </p>
            </div>
            <div>
              <label
                for="last-name"
                class="block text-sm font-semibold text-gray-700 mb-2"
                >Fax Number</label
              >
              <input
                v-model="faxNumber"
                type="text"
                id="last-name"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
              <p v-if="errors.faxNumber" class="text-red-500 text-xs italic">
                {{ errors.faxNumber }}
              </p>
            </div>
            <div>
              <label
                for="phone-number"
                class="block text-sm font-semibold text-gray-700 mb-2"
                >Phone Number</label
              >
              <InputMask
                id="phone-number"
                mask="(999) 999-9999"
                v-model="phoneNumber"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
              ></InputMask>
              <p v-if="errors.phoneNumber" class="text-red-500 text-xs italic">
                {{ errors.phoneNumber }}
              </p>
            </div>
            <div class="col-span-1 md:col-span-2">
              <label
                for="policy-number"
                class="text-sm font-semibold text-gray-700 mb-2 flex items-center"
              >
                Policy Number for Evidence of Insurance
                <div
                  @mouseover="showPopup = true"
                  @mouseleave="hidePopupWithDelay"
                  class="relative flex items-center"
                >
                  <Icon
                    icon="mdi:information-outline"
                    class="text-blue-600 ml-2 cursor-pointer"
                    style="height: 1em; width: 1em"
                  />
                  <div
                    v-if="showPopup"
                    class="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-white rounded-lg shadow-lg text-sm text-gray-700 w-64"
                    style="min-width: 250px"
                  >
                    If you do not know the policy number, use our
                    <router-link to="/policy-search" class="text-red-700"
                      >"Policy Search"</router-link
                    >
                    in the customer service section of the site.
                  </div>
                </div>
              </label>
              <input
                v-model="policyNumber"
                type="text"
                id="policy-number"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
              <p v-if="errors.policyNumber" class="text-red-500 text-xs italic">
                {{ errors.policyNumber }}
              </p>
            </div>
            <div class="mt-6 col-span-1 md:col-span-2">
              <div
                :class="{
                  'bg-green-500': documentUploaded,
                  'bg-white': !documentUploaded,
                }"
                class="py-6 px-4 rounded-lg shadow-sm"
              >
                <div>
                  <!-- Completed Label -->
                  <div v-if="documentUploaded" class="mb-4">
                    <span
                      class="text-green-600 bg-white py-1 px-3 rounded-full custom-scale custom-fadeIn"
                      >Completed</span
                    >
                  </div>

                  <!-- Heading -->
                  <h2
                    :class="{
                      'text-white': documentUploaded,
                      'text-gray-900': !documentUploaded,
                    }"
                    class="text-lg font-bold mb-4"
                  >
                    Upload your borrower's authorization form
                  </h2>
                  <!-- Secondary heading -->
                  <h3
                    :class="{
                      'text-white': documentUploaded,
                      'text-gray-600': !documentUploaded,
                    }"
                    class="text-sm md:text-md text-gray-700 my-2"
                  >
                    PDF, DOC, DOCX, JPG, JPEG, PNG are accepted file types
                  </h3>

                  <!-- Upload Success Message -->
                  <p v-if="documentUploaded" class="text-white custom-fadeIn">
                    Uploaded: <br /><span class="font-medium">{{
                      uploadedFileName
                    }}</span
                    ><br />
                    (Upload Successful)
                  </p>

                  <div v-if="!documentUploaded">
                    <div class="mt-6">
                      <label
                        class="block text-md md:text-lg text-gray-700 font-medium mb-3 cursor-pointer"
                        for="document-upload"
                      >
                        Upload Document
                      </label>
                      <!-- File Upload Area -->
                      <div class="flex items-center justify-center w-full">
                        <input
                          type="file"
                          class="hidden"
                          id="document-upload"
                          accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                          @change="handleFileChange"
                          ref="fileInput"
                        />
                        <label
                          for="document-upload"
                          class="flex flex-col items-center justify-center w-full h-32 md:h-40 px-6 py-4 md:py-6 rounded-lg border-4 border-dashed cursor-pointer hover:border-blue-500 transition-colors text-center"
                        >
                          <Icon
                            icon="mdi:cloud-upload-outline"
                            class="text-2xl text-gray-600 mb-2"
                          />
                          <p class="text-gray-700 text-md md:text-lg">
                            {{ fileLabel }}
                          </p>
                        </label>
                        <p v-if="documentUploadError" class="text-red-500">
                          {{ documentUploadError }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-1 md:col-span-2 flex justify-center">
              <button
                type="submit"
                class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--Background Images-->
    <div class="hidden md:flex justify-between w-full mt-[-300px]">
      <img
        src="@/assets/images/productHeroBanners/homeinsurance/homeinsuranceHeroBannerLeft.svg"
        class="w-1/3"
      />
      <div class="flex-grow"></div>
      <img
        src="@/assets/images/productHeroBanners/homeinsurance/homeinsuranceHeroBannerRight.svg"
        class="w-1/3"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Icon } from '@iconify/vue';
import InputMask from 'primevue/inputmask';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import confetti from 'canvas-confetti';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'RequestProofOfInsurance',
  components: {
    Icon,
    InputMask,
  },
  setup() {
    const { handleSubmit, errors } = useForm();
    const formSubmitted = ref(false); // State for form submissio
    // Form fields
    const { value: firstName } = useField(
      'firstName',
      yup.string().required('First name is required'),
    );
    const { value: lastName } = useField(
      'lastName',
      yup.string().required('Last name is required'),
    );
    const { value: email } = useField(
      'email',
      yup.string().email('Must be a valid email').required('Email is required'),
    );
    const { value: faxNumber } = useField('faxNumber', yup.string());
    const { value: phoneNumber } = useField(
      'phoneNumber',
      yup.string().required('Phone number is required'),
    );
    const { value: policyNumber } = useField(
      'policyNumber',
      yup.string().required('Policy number is required'),
    );

    // File upload state
    const uploadedFile = ref(null);
    const uploadedFileName = ref('');
    const documentUploaded = ref(false);
    const fileLabel = ref('Drag and drop a file here or click to upload');

    // Handle file selection
    const handleFileChange = (event) => {
      const files = event.target.files;
      if (files.length > 0) {
        uploadedFile.value = files[0];
        uploadedFileName.value = files[0].name;
        documentUploaded.value = true;
        fileLabel.value = 'File Selected';
      }
    };

    // Form submission
    const onSubmit = handleSubmit(async (values) => {
      console.log('Submitting form', documentUploaded.value); // Debugging line
      if (!documentUploaded.value) {
        console.log('No document uploaded'); // Debugging line
        documentUploadError.value = 'Uploading the document is required.';
        return;
      } else {
        documentUploadError.value = ''; // Reset error message if the check passes
      }

      const requestId = uuidv4();
      let formData = new FormData();
      formData.append('first_name', values.firstName);
      formData.append('last_name', values.lastName);
      formData.append('email', values.email);
      formData.append('fax_number', values.faxNumber);
      formData.append('phone_number', values.phoneNumber);
      formData.append('policy_number', values.policyNumber);
      formData.append('request_id', requestId);
      formData.append('document', uploadedFile.value);

      try {
        // API call
        const response = await axios.post(
          '/api/submit-insurance-request',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        console.log('API Response:', response.data);
        formSubmitted.value = true;
        triggerConfetti();
      } catch (error) {
        // Log the error for internal debugging
        console.error('API Call Error:', error);

        // Display a user-friendly error message
        if (error.response) {
          // Handle HTTP errors (e.g., validation errors from the server)
          if (error.response.status === 422 && error.response.data.errors) {
            // Handling validation errors specifically
            let userFriendlyErrors = 'Please check your input:';
            Object.keys(error.response.data.errors).forEach((key) => {
              userFriendlyErrors += `\n - ${error.response.data.errors[key].join(', ')}`;
            });
            alert(userFriendlyErrors);
          } else {
            // For other HTTP errors, provide a generic but helpful message
            alert(
              "We're unable to process your request at this time. Please try again later.",
            );
          }
        } else if (error.request) {
          // The request was made but no response was received
          alert(
            "We're experiencing technical difficulties. Please check your internet connection or try again later.",
          );
        } else {
          // An error occurred in setting up the request
          alert('An unexpected error occurred. Please try again.');
        }
      }
    });

    const documentUploadError = ref('');
    // Function to trigger confetti
    function triggerConfetti() {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    }
    const showPopup = ref(false);
    const popupHideDelay = ref(800);
    const hidePopupWithDelay = () => {
      setTimeout(() => {
        showPopup.value = false;
      }, popupHideDelay.value); // Use the delay constant here
    };

    return {
      errors,
      firstName,
      lastName,
      email,
      faxNumber,
      phoneNumber,
      policyNumber,
      hidePopupWithDelay,
      showPopup,
      popupHideDelay,
      // Include this
      onSubmit,
      handleFileChange,
      fileLabel,
      documentUploaded,
      documentUploadError,
      uploadedFileName,
      formSubmitted,
    };
  },
};
</script>

<style scoped></style>
