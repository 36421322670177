<template>
    <div
      v-if="isVisible"
      :class="widgetClass"
    >
      <div>
        <h2 class="text-2xl font-semibold text-gray-700">
          {{ title }}
        </h2>
        <p class="text-gray-600 mt-2">
          {{ description }}
        </p>
      </div>
      <router-link
        :to="link"
        :class="buttonClass"
      >
        {{ buttonText }}
        <Icon :icon="icon" class="ml-2" style="height: 1em; width: 1em" />
      </router-link>
    </div>
  </template>
  
  <script>
  import { Icon } from '@iconify/vue';
  
  export default {
    name: 'DashboardWidget',
    components: {
      Icon,
    },
    props: {
      isVisible: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      link: {
        type: String,
        required: true,
      },
      buttonText: {
        type: String,
        required: true,
      },
      buttonColor: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        required: true,
      },
    },
    computed: {
      widgetClass() {
        return 'bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between hover:shadow-xl transition-shadow duration-300 ease-in-out';
      },
      buttonClass() {
        return `mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${this.buttonColor} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${this.buttonColor}-500`;
      },
    },
  };
  </script>
  