<template>
  <div class="guideline-section p-4 bg-white rounded-lg shadow-md mb-4">
    <h3 class="text-lg font-semibold mb-3">{{ title }}</h3>
    <div
      v-for="(item, index) in parsedItems"
      :key="index"
      class="mb-3 bg-gray-50 p-4 rounded-lg shadow-sm"
    >
      <div v-if="title === 'Acceptable Named Insureds'">
        <div
          v-if="
            item.condition.includes('no_more_than_2_unrelated_named_insureds')
          "
          class="mb-2"
        >
          <strong>No more than 2 unrelated named insureds:</strong> Both
          insureds must reside in the dwelling.
        </div>
        <div
          v-if="item.condition.includes('home_must_be_owner_occupied')"
          class="mb-2"
        >
          <strong>Home must be owner-occupied:</strong> Residential occupancy by
          the named insured is required.
        </div>
        <div
          v-if="
            item.condition.includes(
              'corporations_and_partnerships_unacceptable',
            )
          "
          class="mb-2"
        >
          <strong>Corporations and partnerships are unacceptable.</strong>
        </div>
        <div
          v-if="item.condition.includes('estates_unacceptable')"
          class="mb-2"
        >
          <strong>Estates are unacceptable;</strong> however, Living Trusts may
          have an insurable interest as indicated below.
        </div>

        <div v-if="item.condition.includes('living_trusts')" class="mb-2">
          <strong
            >Living Trusts are acceptable, but only when endorsed as an
            Additional Insured when all of the following conditions are
            met:</strong
          >
          <ul class="list-disc list-inside ml-4">
            <li>The Additional Insured Endorsement – AP4501 is utilized.</li>
            <li>The Trust must have legal title to the residence.</li>
            <li>
              The Grantor(s) must regularly occupy the residence premises.
            </li>
            <li>
              A copy of the Trust documentation is retained in the agent’s
              office.
            </li>
            <li>
              The following information must be supplied when completing the
              AP4461 – Additional Insured Trust Endorsement.
            </li>
          </ul>
        </div>

        <div
          v-if="item.condition.includes('trusts_llcs_ir_as_corporations')"
          class="mb-2"
        >
          <strong
            >Trusts (Family, Revocable, and Irrevocable) are acceptable on all
            lines of business:</strong
          >
          <ul class="list-disc list-inside ml-4">
            <li>Estates are acceptable on DP1 & DP3 only.</li>
            <li>LLCs are acceptable on DP1 and DP3 only.</li>
            <li>
              IRAs must be sole ownership, occupied by named insured, and have
              no rental exposure.
            </li>
            <li>Corporations are acceptable only on DP1 and DP3.</li>
          </ul>
        </div>

        <div v-if="item.additionalDetails" class="mb-2">
          <strong>Additional Details:</strong> {{ item.additionalDetails }}
        </div>
        <div class="text-gray-600 text-sm">
          <p>
            <strong>Last Updated By:</strong>
            {{ item.username_of_last_user_update }}
          </p>
          <p>
            <strong>Last Updated At:</strong> {{ formatDate(item.updated_at) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AcceptableNamedInsuredsSection',
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    parsedItems() {
      return this.items.map((item) => {
        try {
          return typeof item === 'string' ? JSON.parse(item) : item;
        } catch (e) {
          console.error('Failed to parse item:', item, e);
          return {};
        }
      });
    },
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style scoped>
.guideline-section {
  max-width: 800px;
}
.guideline-section h3 {
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 0.5rem;
}
@media (max-width: 768px) {
  .guideline-section {
    padding: 1rem;
  }
  .guideline-section h3 {
    font-size: 1.25rem;
  }
}
</style>
