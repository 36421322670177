<template>
  <div class="form-group mb-4">
    <label
      for="acceptable_named_insureds"
      class="block text-sm font-medium text-gray-700"
    >
      Acceptable Named Insureds:
    </label>
    <MultiSelect
      v-model="selectedConditions"
      :options="namedInsuredConditions"
      optionLabel="label"
      optionValue="value"
      placeholder="Select Conditions"
      class="w-full"
      id="acceptable_named_insureds"
    ></MultiSelect>

    <div
      v-if="
        selectedConditions.includes('no_more_than_2_unrelated_named_insureds')
      "
      class="mt-4"
    >
      <p>
        &#9989; No more than 2 unrelated named insureds. Both insureds must
        reside in the dwelling.
      </p>
    </div>
    <div
      v-if="selectedConditions.includes('home_must_be_owner_occupied')"
      class="mt-4"
    >
      <p>&#9989; Home must be owner-occupied.</p>
    </div>
    <div
      v-if="
        selectedConditions.includes('residential_occupancy_by_named_insured')
      "
      class="mt-4"
    >
      <p>&#9989; Residential occupancy by the named insured is required.</p>
    </div>
    <div
      v-if="
        selectedConditions.includes(
          'corporations_and_partnerships_unacceptable',
        )
      "
      class="mt-4"
    >
      <p>&#9989; Corporations and partnerships are unacceptable.</p>
    </div>
    <div
      v-if="selectedConditions.includes('estates_unacceptable')"
      class="mt-4"
    >
      <p>&#9989; Estates are unacceptable.</p>
    </div>
    <div v-if="selectedConditions.includes('living_trusts')" class="mt-4">
      <p>
        &#9989; Living Trusts are acceptable, but only when endorsed as an
        Additional Insured when all of the following conditions are met:
      </p>
      <ul class="list-disc list-inside">
        <li>The Additional Insured Endorsement – AP4501 is utilized.</li>
        <li>The Trust must have legal title to the residence.</li>
        <li>The Grantor(s) must regularly occupy the residence premises.</li>
        <li>
          A copy of the Trust documentation is retained in the agent’s office.
        </li>
        <li>
          The following information must be supplied when completing the AP4461
          – Additional Insured Trust Endorsement.
        </li>
      </ul>
    </div>
    <div
      v-if="selectedConditions.includes('trusts_llcs_ir_as_corporations')"
      class="mt-4"
    >
      <p>
        &#9989; Trusts (Family, Revocable, and Irrevocable) are acceptable on
        all lines of business. Estates are acceptable on DP1 & DP3 only. LLCs
        are acceptable on DP1 and DP3 only. IRAs must be sole ownership,
        occupied by named insured, and have no rental exposure. Corporations are
        acceptable only on DP1 and DP3.
      </p>
    </div>

    <!-- Additional Details -->
    <div v-if="selectedConditions.includes('additional_details')" class="mt-4">
      <label
        for="additional_details"
        class="block text-sm font-medium text-gray-700"
        >Additional Details:</label
      >
      <textarea
        v-model="additionalDetails"
        id="additional_details"
        class="form-textarea mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        placeholder="Provide additional details"
      ></textarea>
    </div>

    <p v-if="modelValue.username_of_last_user_update" class="mt-4">
      <strong>Last Updated By:</strong>
      {{ modelValue.username_of_last_user_update }}
    </p>
    <p v-if="modelValue.updated_at">
      <strong>Last Updated At:</strong> {{ formatDate(modelValue.updated_at) }}
    </p>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import MultiSelect from 'primevue/multiselect';
import { useStore } from 'vuex';

export default {
  name: 'AcceptableNamedInsuredsInput',
  components: {
    MultiSelect,
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => ({
        condition: [],
        additionalDetails: '',
        username_of_last_user_update: '',
        updated_at: '',
      }),
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const userName = `${store.state.customerInfo.firstName} ${store.state.customerInfo.lastName}`;

    const namedInsuredConditions = [
      {
        label: 'No more than 2 unrelated named insureds',
        value: 'no_more_than_2_unrelated_named_insureds',
      },
      {
        label: 'Home must be owner-occupied',
        value: 'home_must_be_owner_occupied',
      },
      {
        label: 'Residential occupancy by the named insured is required',
        value: 'residential_occupancy_by_named_insured',
      },
      {
        label: 'Corporations and partnerships are unacceptable',
        value: 'corporations_and_partnerships_unacceptable',
      },
      { label: 'Estates are unacceptable', value: 'estates_unacceptable' },
      { label: 'Living Trusts', value: 'living_trusts' },
      {
        label: 'Trusts, LLCs, IRAs, Corporations',
        value: 'trusts_llcs_ir_as_corporations',
      },
      { label: 'Additional Details', value: 'additional_details' },
    ];

    const selectedConditions = ref(props.modelValue.condition || []);
    const additionalDetails = ref(props.modelValue.additionalDetails);

    watch(
      () => props.modelValue,
      (newVal) => {
        selectedConditions.value = newVal.condition || [];
        additionalDetails.value = newVal.additionalDetails;
      },
      { deep: true, immediate: true },
    );

    watch(
      [selectedConditions, additionalDetails],
      () => {
        emit('update:modelValue', {
          condition: selectedConditions.value,
          additionalDetails: additionalDetails.value,
          username_of_last_user_update: userName,
          updated_at: new Date().toISOString(),
        });
      },
      { deep: true },
    );

    const formatDate = (dateString) => {
      if (!dateString) return 'N/A';
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return {
      namedInsuredConditions,
      selectedConditions,
      additionalDetails,
      formatDate,
    };
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
