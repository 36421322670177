<template>
  <div class="form-group mb-4">
    <label for="age_of_home" class="block text-sm font-medium text-gray-700"
      >Age of Home:</label
    >
    <select
      v-model="selectedCondition"
      id="age_of_home"
      class="form-select mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    >
      <option value="" disabled>Select Age of Home Condition</option>
      <option
        v-for="condition in ageOfHomeConditions"
        :key="condition.value"
        :value="condition.value"
      >
        {{ condition.label }}
      </option>
    </select>

    <!-- No Limit -->
    <div v-if="selectedCondition === 'no_limit'" class="mt-4">
      <label class="block text-sm font-medium text-gray-700">No Limit:</label>
      <input
        type="checkbox"
        v-model="additionalInfo.noLimit"
        class="form-checkbox mt-1"
      />
    </div>

    <!-- Specific Year -->
    <div v-if="selectedCondition === 'specific_year'" class="mt-4">
      <label class="block text-sm font-medium text-gray-700"
        >Specific Year:</label
      >
      <div class="flex space-x-4">
        <input
          v-model="additionalInfo.yearFrom"
          type="number"
          class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Year From"
        />
        <input
          v-model="additionalInfo.yearTo"
          type="number"
          class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Year To"
        />
      </div>
    </div>

    <!-- Age Range -->
    <div v-if="selectedCondition === 'age_range'" class="mt-4">
      <label class="block text-sm font-medium text-gray-700">Age Range:</label>
      <div class="flex space-x-4">
        <input
          v-model="additionalInfo.ageFrom"
          type="number"
          min="1"
          max="100"
          class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Age From"
        />
        <input
          v-model="additionalInfo.ageTo"
          type="number"
          min="1"
          max="100"
          class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Age To"
        />
      </div>
    </div>

    <!-- Requires Inspection -->
    <div v-if="selectedCondition === 'requires_inspection'" class="mt-4">
      <label class="block text-sm font-medium text-gray-700"
        >Requires Inspection:</label
      >
      <input
        type="checkbox"
        v-model="additionalInfo.requiresInspection"
        class="form-checkbox mt-1"
      />
    </div>

    <!-- Requires Proof of Updates -->
    <div v-if="selectedCondition === 'requires_proof_of_updates'" class="mt-4">
      <label class="block text-sm font-medium text-gray-700"
        >Requires Proof of Updates:</label
      >
      <input
        type="checkbox"
        v-model="additionalInfo.requiresProofOfUpdates"
        class="form-checkbox mt-1"
      />
    </div>

    <!-- Specific County Restrictions -->
    <div
      v-if="selectedCondition === 'specific_county_restrictions'"
      class="mt-4"
    >
      <label
        for="county_restrictions"
        class="block text-sm font-medium text-gray-700"
        >Specific County Restrictions:</label
      >
      <input
        v-model="additionalInfo.countyRestrictions"
        type="text"
        id="county_restrictions"
        class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        placeholder="County Restrictions"
      />
    </div>

    <!-- Additional Details -->
    <div v-if="requiresAdditionalDetails" class="mt-4">
      <label
        for="additional_details"
        class="block text-sm font-medium text-gray-700"
        >Additional Details:</label
      >
      <textarea
        v-model="additionalInfo.additionalDetails"
        id="additional_details"
        class="form-textarea mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        placeholder="Provide additional details"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'AgeOfHomeInput',
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => ({
        condition: '',
        additionalInfo: {
          noLimit: false,
          yearFrom: '',
          yearTo: '',
          ageFrom: '',
          ageTo: '',
          requiresInspection: false,
          requiresProofOfUpdates: false,
          countyRestrictions: '',
          additionalDetails: '',
        },
      }),
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const userName = `${store.state.customerInfo.firstName} ${store.state.customerInfo.lastName}`;

    const ageOfHomeConditions = [
      { label: 'No Limit', value: 'no_limit' },
      { label: 'Specific Year', value: 'specific_year' },
      { label: 'Age Range', value: 'age_range' },
      { label: 'Requires Inspection', value: 'requires_inspection' },
      {
        label: 'Requires Proof of Updates',
        value: 'requires_proof_of_updates',
      },
      {
        label: 'Specific County Restrictions',
        value: 'specific_county_restrictions',
      },
      { label: 'Additional Details', value: 'additional_details' },
      // Add more structured conditions based on the provided data...
    ];

    const selectedCondition = ref(props.modelValue.condition);
    const additionalInfo = ref({ ...props.modelValue.additionalInfo });

    const requiresAdditionalDetails = computed(
      () => selectedCondition.value === 'additional_details',
    );

    // Watch for changes in props.modelValue to update local state
    watch(
      () => props.modelValue,
      (newVal) => {
        selectedCondition.value = newVal.condition;
        additionalInfo.value = {
          noLimit: false,
          yearFrom: '',
          yearTo: '',
          ageFrom: '',
          ageTo: '',
          requiresInspection: false,
          requiresProofOfUpdates: false,
          countyRestrictions: '',
          additionalDetails: '',
          ...newVal.additionalInfo,
        };
      },
      { deep: true, immediate: true },
    );

    // Emit updates only when there's a change
    watch(
      [selectedCondition, additionalInfo],
      () => {
        // Emit whenever any part of the model changes
        emit('update:modelValue', {
          condition: selectedCondition.value,
          additionalInfo: { ...additionalInfo.value }, // Ensures reactivity by spreading into a new object
          username_of_last_user_update: userName,
          updated_at: new Date().toISOString(),
        });
      },
      { deep: true },
    );

    return {
      ageOfHomeConditions,
      selectedCondition,
      additionalInfo,
      requiresAdditionalDetails,
    };
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
