<template>
  <div
    class="max-w-4xl mx-auto my-8 p-8 bg-white shadow-lg rounded-lg border border-gray-200"
  >
    <h2 class="text-2xl font-bold mb-6 text-center text-gray-800">
      Review Your Information
    </h2>

    <!--Contact Information-->
    <div
      v-if="formData.contactInformation.firstName"
      class="mb-8 p-2 bg-gray-50 rounded-lg shadow-sm border border-gray-200"
    >
      <h3 class="font-semibold text-xl text-gray-800 mb-4">
        Contact Information
      </h3>
      <div class="text-gray-600 space-y-2">
        <p>
          <span class="text-gray-700 font-medium capitalize">Name:</span>
          {{ formData.contactInformation.firstName }}
          {{ formData.contactInformation.lastName }}
        </p>
        <p>
          <span class="text-gray-700 font-medium">Email:</span>
          {{ formData.contactInformation.email }}
        </p>
        <p>
          <span class="text-gray-700 font-medium">Phone:</span>
          {{ formData.contactInformation.phone }}
        </p>
      </div>
    </div>

    <!--Change Options-->

    <!--Vehicle Changes-->
    <div
      v-if="
        formData.selectedChangeOption === 'vehicleChanges' ||
        formData.selectedChangeOption === 'coverageAdjustments' ||
        formData.selectedChangeOption === 'otherChanges'
      "
      class="mb-6"
    >
      <h3 class="font-semibold text-lg text-gray-700">Change Option</h3>
      <p class="mt-1 text-gray-600">
        {{ getChangeOptionLabel(formData.selectedChangeOption) }}
      </p>
    </div>

    <!--Other Change3s-->
    <div
      v-if="
        formData.otherChangesSelection === 'additionalInsured' ||
        formData.otherChangesSelection === 'lessor' ||
        formData.otherChangesSelection === 'lienholder'
      "
      class="mb-6"
    >
      <h3 class="font-semibold text-lg text-gray-700">Other Changes</h3>
      <p class="mt-1 text-gray-600">{{ formData.otherChangesSelection }}</p>
    </div>

    <!-- Selected Vehicles -->
    <div v-if="formData.vehicles.length > 0" class="mb-6">
      <h3 class="font-semibold text-lg text-gray-700">Selected Vehicles</h3>
      <div class="mt-2">
        <div
          v-for="vehicle in formData.vehicles"
          :key="vehicle.id"
          class="mb-4 p-4 rounded-lg border border-gray-300"
        >
          <p class="text-gray-600 mb-2">
            {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} - Change
            Type: <span class="text-blue-800">{{ vehicle.action }}</span>
          </p>
        </div>
      </div>
    </div>

    <!-- Associated Drivers -->
    <div v-if="formData.drivers.length > 0" class="mb-6">
      <h3 class="font-semibold text-lg text-gray-700">Assigned Drivers</h3>
      <div class="mt-2">
        <div
          v-for="driver in formData.drivers"
          :key="driver.id"
          class="mb-4 p-4 rounded-lg border border-gray-300"
        >
          <p class="text-gray-600 capitalize">
            Name: {{ driver.firstName }} {{ driver.lastName }} - Status:
            <span class="text-blue-800">{{ driver.action }}</span>
          </p>
          <p v-if="driver.associatedVehicleID" class="text-gray-600">
            Associated Vehicle ID: {{ driver.associatedVehicleID }}
          </p>
          <p v-else class="text-gray-600">No associated vehicle.</p>
        </div>
      </div>
    </div>
    <div v-if="formData.selectedCoverages.length > 0" class="mb-6">
      <h3 class="font-semibold text-lg text-gray-700">Selected Coverages</h3>
      <div class="mt-2">
        <div
          v-for="coverage in formData.selectedCoverages"
          :key="coverage.type"
          class="mb-4 p-4 rounded-lg border border-gray-300"
        >
          <p class="text-gray-600">
            {{ coverage.label }}: {{ coverage.selectedAmount }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="formData.additionalInsured.length > 0" class="mb-6">
      <h3 class="font-semibold text-lg text-gray-700">Additional Insured</h3>
      <div class="mt-2">
        <div
          v-for="insured in formData.additionalInsured"
          :key="insured.driversLicense"
          class="mb-4 p-4 rounded-lg border border-gray-300"
        >
          <p class="text-gray-600">
            {{ insured.firstName }} {{ insured.lastName }} - DOB:
            {{ insured.dob }} - {{ insured.action }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="formData.lienholdersLessors.length > 0" class="mb-6">
      <h3 class="font-semibold text-lg text-gray-700">Lienholders / Lessors</h3>
      <div class="mt-2">
        <div
          v-for="lienholder in formData.lienholdersLessors"
          :key="lienholder.policyNumber"
          class="mb-4 p-4 rounded-lg border border-gray-300"
        >
          <p class="text-gray-600">
            {{ lienholder.name }} - {{ lienholder.position }} -
            {{ lienholder.type }} - {{ lienholder.action }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-8">
      <button
        @click="emitSubmitForm"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
      >
        Submit Change Request
        <div
          v-if="loading"
          class="absolute right-0 inset-y-0 pr-3 flex items-center"
        >
          <Icon icon="svg-spinners:90-ring-with-bg" class="animate-spin" />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'AutoInsuranceFormStep3',
  components: {
    Icon,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    loading: {
      // Add this prop to handle spinner visibility
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitSubmitForm() {
      this.$emit('submitForm');
    },
    getChangeOptionLabel(option) {
      const labels = {
        vehicleChanges: 'Add Remove Replace Vehicle',
        coverageAdjustments: 'Coverage Adjustment',
        otherChanges: this.getOtherChangesLabel(
          this.formData.otherChangesSelection,
        ), // Call the function to get specific label
      };
      return labels[option];
    },
    getOtherChangesLabel(selection) {
      // Define the label structure based on the selection
      const labels = {
        additionalInsured: 'Add Remove Modify Additional Insured',
        lessor: 'Add Remove Modify Lessor',
        lienholder: 'Add Remove Modify Lienholder',
      };
      return labels[selection] || 'Other Changes'; // Default to "Other Changes" if selection is not recognized
    },
  },
};
</script>

<style scoped>
/* Additional styling can be added here as needed */
</style>

<style scoped>
/* Add any additional styles specific to this step here */
</style>
