<template>
  <div class="animate-fadeIn">
    <!-- Success Message for form -->
    <div
      v-if="formSubmitted"
      class="w-full max-w-md mx-auto bg-white rounded-xl shadow-xl overflow-hidden"
    >
      <div class="bg-green-600 text-white text-center py-4">
        <h2 class="text-xl md:text-2xl font-bold">Submission Confirmed!</h2>
      </div>
      <div class="flex justify-center py-5">
        <Icon
          icon="line-md:clipboard-check-twotone"
          class="text-green-500"
          style="font-size: 3rem"
        />
      </div>
      <div class="p-6 fade-in space-y-3">
        <p
          class="text-lg md:text-xl font-semibold text-gray-800 text-center mb-4"
        >
          Your Cancellation Request is in Good Hands
        </p>
        <p class="text-center text-sm md:text-md text-gray-600 mb-2">
          We're reviewing your request and will review your policy details
          within the next 24-48 hours.
        </p>
        <p class="text-center text-sm md:text-md text-gray-600 mb-2">
          A confirmation email has been sent to
          <span class="font-semibold">{{ form.email }}</span
          >. Please check your inbox for further instructions.
        </p>
        <p class="text-sm md:text-md text-gray-600 mb-4">
          <span class="font-bold text-center">Need immediate assistance?</span>
          <br />
          <Icon icon="carbon:phone" class="inline-block text-blue-600" />
          <a
            href="tel:+18002699137"
            class="text-center text-blue-600 hover:text-blue-800"
          >
            (800)-269-9137</a
          >
          <br />Our insurance experts are always here to help.
        </p>
        <div class="text-center">
          <a
            href="/"
            class="inline-block text-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          >
            Return to Homepage
          </a>
        </div>
      </div>
    </div>
    <!-- Main Form -->
    <div v-if="!formSubmitted" class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="bg-white shadow-lg rounded-lg p-6 mb-6">
        <div class="flex items-center justify-center mb-6">
          <Icon
            icon="material-symbols:cancel"
            class="text-blue-600"
            style="font-size: 4rem"
          />
        </div>
        <h2 class="text-xl text-center font-semibold text-gray-800 mb-6">
          Verify Cancellation Request Information
        </h2>
        <!-- Editable Policy Number Field with Highlight -->
        <div class="mb-4 relative">
          <label
            for="policy-number"
            class="block text-sm font-semibold text-gray-700 mb-2 flex items-center"
          >
            Policy Number:
            <div
              @mouseover="showPopup = true"
              @mouseleave="hidePopupWithDelay"
              class="relative flex items-center"
            >
              <Icon
                icon="mdi:information-outline"
                class="text-blue-600 ml-2 cursor-pointer"
                style="height: 1em; width: 1em"
              />
              <div
                v-if="showPopup"
                class="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-white rounded-lg shadow-lg text-sm text-gray-700 w-64"
                style="min-width: 250px"
              >
                <!-- Adjusted width -->
                <!-- Popup content goes here -->
                If you do not know your policy number, use our
                <router-link to="/policy-search" class="text-red-700"
                  >"Policy Search"</router-link
                >
                in the customer service section of the site.
              </div>
            </div>
          </label>
          <input
            type="text"
            id="policyNumber"
            v-model="form.policyNumber"
            class="shadow appearance-none border-2 border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter your policy number"
          />
          <span class="text-red-500 text-xs">{{
            validationErrors.policyNumber
          }}</span>
        </div>
        <!-- Display Fields as Text with Improved Layout -->
        <div class="grid grid-cols-1 gap-4 mb-6">
          <div>
            <h4 class="text-gray-600 font-bold">First Name</h4>
            <p class="mt-1 bg-gray-100 rounded p-2 text-gray-700">
              {{ form.firstName }}
            </p>
          </div>
          <div>
            <h4 class="text-gray-600 font-bold">Last Name</h4>
            <p class="mt-1 bg-gray-100 rounded p-2 text-gray-700">
              {{ form.lastName }}
            </p>
          </div>
          <div>
            <h4 class="text-gray-600 font-bold">Email</h4>
            <p class="mt-1 bg-gray-100 rounded p-2 text-gray-700">
              {{ form.email }}
            </p>
          </div>
          <div>
            <h4 class="text-gray-600 font-bold">Phone</h4>
            <p class="mt-1 bg-gray-100 rounded p-2 text-gray-700">
              {{ form.phone }}
            </p>
          </div>
        </div>
        <!-- Display Reason -->
        <div class="mb-4">
          <h4 class="text-gray-600 font-bold">Reason</h4>
          <p class="mt-1 bg-gray-100 rounded p-2 text-gray-700">
            {{ getReasonText(form.reason) }}
          </p>
        </div>
        <!-- Editable Remarks Field -->
        <div class="mb-6">
          <label
            for="remarks"
            class="block text-gray-700 text-sm font-bold mb-2"
            >Remarks:</label
          >
          <textarea
            id="remarks"
            v-model="form.remarks"
            aria-label="Remarks"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Add any remarks here"
          ></textarea>
          <span class="text-red-500 text-xs">{{
            validationErrors.remarks
          }}</span>
        </div>
        <!-- Realtor/Lender Contact Information -->
        <div
          v-if="form.realtorInfo"
          class="mb-6 p-6 bg-gradient-to-r from-blue-50 to-blue-100 rounded-lg shadow-lg"
        >
          <h4 class="text-gray-600 font-bold mb-4">
            Realtor/Lender Contact Information:
          </h4>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="flex items-start space-x-2">
              <span>💼</span>
              <div>
                <p class="text-gray-800 font-medium">First Name:</p>
                <p class="text-gray-600">{{ form.realtorInfo.firstName }}</p>
              </div>
            </div>
            <div class="flex items-start space-x-2">
              <span>👤</span>
              <div>
                <p class="text-gray-800 font-medium">Last Name:</p>
                <p class="text-gray-600">{{ form.realtorInfo.lastName }}</p>
              </div>
            </div>
            <div class="flex items-start space-x-2">
              <span>📧</span>
              <div>
                <p class="text-gray-800 font-medium">Email:</p>
                <p class="text-gray-600">{{ form.realtorInfo.email }}</p>
              </div>
            </div>
            <div class="flex items-start space-x-2">
              <span>📞</span>
              <div>
                <p class="text-gray-800 font-medium">Phone Number:</p>
                <p class="text-gray-600">{{ form.realtorInfo.phoneNumber }}</p>
              </div>
            </div>
          </div>
        </div>
        <!--Call Back Time Display-->
        <div v-if="callbackTime" class="mb-4 bg-white p-4 rounded-lg shadow">
          <h3 class="text-gray-700 text-sm font-bold mb-2">
            Scheduled Callback Time:
          </h3>
          <p class="bg-gray-100 rounded p-2 text-gray-700">
            {{ callbackTime }}
          </p>
        </div>
        <!-- Submit Button with Visual Feedback -->
        <button
          @click.prevent="submitForm"
          class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
        >
          Confirm and Submit
        </button>
      </div>
    </div>
    <!--Contact Information Modal-->
    <div
      v-if="showContactInformaton"
      class="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50"
    >
      <div
        class="p-5 border w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3 shadow-lg rounded-md bg-white"
      >
        <div class="mt-3 text-center">
          <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100"
          >
            <img
              src="@/assets/images/IEsubmark.svg"
              alt="Insurance Express Logo"
              class="h-6 w-6 text-blue-600"
            />
          </div>
          <h3 class="text-lg leading-6 font-medium text-gray-900 mt-2">
            Contact Information
          </h3>
          <p class="text-sm text-gray-600 mt-2">
            Please provide your contact information to proceed with the
            cancellation request.
          </p>
        </div>
        <div class="mb-4">
          <label
            for="firstName"
            class="block text-gray-700 text-sm font-bold mb-2"
            >First Name</label
          >
          <input
            type="text"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="firstName"
            v-model="form.firstName"
            @blur="validateField('firstName', form.firstName)"
          />
          <span class="text-red-500 text-xs">{{
            validationErrors.firstName
          }}</span>
        </div>
        <div class="mb-4">
          <label
            for="lastName"
            class="block text-gray-700 text-sm font-bold mb-2"
            >Last Name</label
          >
          <input
            type="text"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="lastName"
            v-model="form.lastName"
            @blur="validateField('lastName', form.lastName)"
          />
          <span class="text-red-500 text-xs">{{
            validationErrors.lastName
          }}</span>
        </div>
        <div class="mb-4">
          <label for="email" class="block text-gray-700 text-sm font-bold mb-2"
            >Email</label
          >
          <input
            type="email"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            v-model="form.email"
            @blur="validateField('email', form.email)"
          />
          <span class="text-red-500 text-xs">{{ validationErrors.email }}</span>
        </div>
        <div class="mb-4">
          <label for="phone" class="block text-gray-700 text-sm font-bold mb-2"
            >Phone</label
          >
          <input
            type="tel"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            v-mask="'(###) ###-####'"
            maxlength="14"
            v-model="form.phone"
            @blur="validateField('phone', form.phone)"
          />
          <span class="text-red-500 text-xs">{{ validationErrors.phone }}</span>
        </div>
        <div class="flex justify-center">
          <button
            @click="handleContactInfoNext"
            class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <!-- Reason for Cancellation Modal -->
    <div
      v-if="showReasonForCancellationModal"
      class="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50"
    >
      <div
        class="p-5 border w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3 shadow-lg rounded-md bg-white"
      >
        <div class="mt-3 mb-2 text-center">
          <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100"
          >
            <img
              src="@/assets/images/IEsubmark.svg"
              alt="Insurance Express Logo"
              class="h-6 w-6 text-blue-600"
            />
          </div>
          <h3 class="text-lg leading-6 font-medium text-gray-900 mt-2">
            Reason for Cancellation
          </h3>
          <p class="text-sm text-gray-600 mt-2">
            Please select the reason for your cancellation request. This
            information will help us process your request more efficiently.
          </p>
        </div>
        <div class="mb-4">
          <label for="reason" class="block text-gray-700 text-sm font-bold mb-2"
            >Reason</label
          >
          <select
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="reason"
            v-model="form.reason"
          >
            <option value="select_reason" disabled>Select Reason</option>
            <option value="selling_home">Selling the Home</option>
            <option value="switching_insurers">
              Switching to a New Insurer
            </option>
            <option value="change_in_ownership">Change in Ownership</option>
            <option value="risk_reduction">
              Significant Reduction in Risk
            </option>
            <option value="mortgage_paid_off">
              No Longer Required by Mortgage
            </option>
            <option value="moving_out">
              Moving and Not Renting Out Your Old Home
            </option>
            <option value="financial_hardship">Financial Hardship</option>
            <option value="policy_consolidation">
              Consolidation of Policies
            </option>
          </select>
          <span class="text-red-500 text-xs">{{
            validationErrors.reason
          }}</span>
        </div>
        <div class="flex justify-center">
          <button
            @click="closeModal()"
            class="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <!-- Win Back Modal-->
    <div v-if="showWinBackModal">
      <winBackModal
        v-if="showWinBackModal"
        :reason="form.reason"
        @selectedCallbackTime="handleSelectedTime"
        @closeCancellationRequest="closeModal"
        @closeWinBackModal="showWinBackModal = false"
        @openTermsModal="showTermsModal = true"
        @updateInterest="handleUpdateInterest"
        @submitContactInfo="handleRealtorInfo"
        :realtorInfo="realtorInfo"
      />
    </div>
    <!-- Terms and Conditions Modal -->
    <div
      v-if="showTermsModal"
      class="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50"
    >
      <div
        class="p-5 border w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3 shadow-lg rounded-md bg-white"
      >
        <div class="mt-3 text-center">
          <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100"
          >
            <img
              src="@/assets/images/IEsubmark.svg"
              alt="Insurance Express Logo"
              class="h-6 w-6 text-blue-600"
            />
          </div>
          <h3 class="text-lg leading-6 font-medium text-gray-900 mt-2">
            Cancellation Request Form - Insurance Express
          </h3>
          <div class="mt-2 px-7 py-3 text-left">
            <p class="text-sm text-red-500">
              Important Notice: Please Read Carefully and Scroll Over the Entire
              Terms before Submitting.
            </p>
            <div
              class="mt-4 text-sm text-gray-600 overflow-y-auto"
              style="max-height: 300px"
              ref="termsAndConditionsRef"
            >
              <p>
                By submitting this cancellation request form, you acknowledge
                that you are initiating a request to cancel your insurance
                policy with Insurance Express and its affiliated member
                companies. However, please note that submitting this form does
                not automatically cancel your policy. All cancellation requests
                are subject to review and approval by Insurance Express and its
                underwriting department.
              </p>
              <br />
              <p><strong>Terms and Conditions:</strong></p>
              <br />
              <ul class="list-disc pl-5 space-y-2">
                <li>
                  <strong>Request Submission:</strong> By submitting this form,
                  you are requesting cancellation of your insurance policy. Your
                  request will be processed in accordance with the terms and
                  conditions outlined in your insurance policy and applicable
                  state regulations.
                </li>
                <li>
                  <strong>Pending Approval:</strong> Please be aware that your
                  cancellation request is not considered final until it has been
                  reviewed and approved by Insurance Express. You will receive
                  confirmation of the cancellation once it has been processed.
                </li>
                <li>
                  <strong>Policy Obligations:</strong> Until your cancellation
                  request is approved and confirmed by Insurance Express, you
                  are still obligated to fulfill all terms, conditions, and
                  payments outlined in your insurance policy. Failure to do so
                  may result in penalties, fees, or legal actions.
                </li>
                <li>
                  <strong>Effective Date:</strong> The effective date of
                  cancellation, if approved, will be determined by Insurance
                  Express and may vary depending on policy terms and state
                  regulations. Please refer to your policy documents for
                  specific details.
                </li>
                <li>
                  <strong>No Coverage:</strong> Once your policy is canceled,
                  you will no longer have coverage under the insurance policy.
                  It is your responsibility to secure alternative coverage if
                  needed.
                </li>
                <li>
                  <strong>Contact Information:</strong> It is important to
                  provide accurate contact information to ensure timely
                  processing of your cancellation request. Insurance Express may
                  need to contact you for further information or clarification.
                </li>
              </ul>
              <p>
                By submitting this form, you acknowledge that you have read,
                understood, and agree to the terms and conditions outlined
                above. Insurance Express reserves the right to modify or update
                these terms at any time without prior notice.
              </p>
            </div>
          </div>
          <div class="mt-6">
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                id="agree"
                v-model="userAgrees"
                class="form-checkbox text-blue-600"
                :disabled="!userCanAgree"
              />
              <span
                class="ml-2 text-sm text-gray-700"
                :class="{ 'text-gray-500': !userCanAgree }"
                >I have read and agree to the terms and conditions.</span
              >
            </label>
          </div>
        </div>
        <div class="mt-4 flex justify-center space-x-4">
          <button
            @click="handleProceed"
            :disabled="!userAgrees"
            class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150 disabled:opacity-50"
          >
            Read Cancellation Info
          </button>
          <button
            @click="closeModal()"
            class="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <!-- Cancellation Info Modal -->
    <div
      v-if="showCancellationInfoModal"
      class="animate-fadeIn fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50"
    >
      <div
        class="bg-white rounded-lg max-w-lg p-8 mx-4 text-gray-800 shadow-lg"
      >
        <h2 class="text-xl font-semibold text-center mb-4">
          Cancellation Request Information
        </h2>
        <p class="text-sm text-gray-600 mb-2">
          Understanding why you can cancel your home insurance helps us serve
          you better. Here are some reasons commonly accepted:
        </p>
        <p class="text-sm text-red-600 mb-6">
          Prepare your required documents for your situation.
        </p>
        <ul class="list-disc pl-5 space-y-2 text-sm text-gray-600">
          <li>
            <strong>Selling the Home</strong>: Proof of sale such as the closing
            statement or a copy of the deed transfer.
          </li>
          <li>
            <strong>Switching Insurers</strong>: Declaration page or evidence of
            the new insurance policy.
          </li>
          <li>
            <strong>Change in Ownership</strong>:Legal documentation evidencing
            the change, such as a new deed.
          </li>
          <li>
            <strong>Mortgage Paid Off</strong>: A mortgage payoff statement from
            your lending institution.
          </li>
          <li>
            <strong>Moving Out</strong>: Proof of new residence, like a lease
            agreement or utility bill at the new address.
          </li>
          <li>
            <strong>Policy Consolidation</strong>: Evidence of the new policy,
            typically the declaration page showing coverage details.
          </li>
        </ul>
        <div class="mt-6 flex justify-center">
          <button
            @click="closeModals()"
            class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out text-sm"
          >
            Got It
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import { ref, toRefs, onMounted, onBeforeUnmount, watch, reactive } from 'vue';
import axios from 'axios';
import confetti from 'canvas-confetti';
import winBackModal from './winBackModal.vue';

export default {
  name: 'CancellationRequest',
  components: {
    Icon,
    winBackModal,
  },
  setup(props, { emit }) {
    const callbackTime = ref(''); // Reactive property to store the callback time
    const handleSelectedTime = (time) => {
      callbackTime.value = time;
    };
    const showPopup = ref(false);
    const popupHideDelay = ref(800);
    const hidePopupWithDelay = () => {
      setTimeout(() => {
        showPopup.value = false;
      }, popupHideDelay.value); // Use the delay constant here
    };

    const closeModal = () => {
      emit('closeCancellationModal'); // Emitting the event for the parent to handle
    };
    const handleProceedFromReasonsForCancellation = () => {
      showContactInformaton.value = false; // Close the Contact Information Modal
      showReasonForCancellationModal.value = true; // Open the Reason For Cancellation Modal
    };
    const handleProceed = () => {
      if (userAgrees.value) {
        showContactInformaton.value = false; // Close the Contact Information Modal
        showTermsModal.value = false; // Close the Terms and Conditions Modal
        showCancellationInfoModal.value = true; // Open the Cancellation Info Modal
      }
    };
    const getReasonText = (reasonValue) => {
      const reasonsMap = {
        selling_home: 'Selling the Home',
        switching_insurers: 'Switching to a New Insurer',
        change_in_ownership: 'Change in Ownership',
        risk_reduction: 'Significant Reduction in Risk',
        mortgage_paid_off: 'No Longer Required by Mortgage',
        moving_out: 'Moving and Not Renting Out Your Old Home',
        financial_hardship: 'Financial Hardship',
        policy_consolidation: 'Consolidation of Policies',
      };
      return reasonsMap[reasonValue] || 'Unknown Reason';
    };
    const closeModals = () => {
      showContactInformaton.value = false;
      showReasonForCancellationModal.value = false;
      showWinBackModal.value = false;
      showTermsModal.value = false;
      showCancellationInfoModal.value = false;
    };
    const handleContactInfoNext = () => {
      console.log('Initial form state before validation:', form);

      const fieldsToValidate = ['firstName', 'lastName', 'email', 'phone'];
      fieldsToValidate.forEach((field) => validateField(field, form[field]));

      let valid = !fieldsToValidate.some((field) => validationErrors[field]);

      if (valid) {
        console.log('Validation successful');
        showContactInformaton.value = false;
        showReasonForCancellationModal.value = true;
        console.log(
          'Modal state changed: Contact Information hidden, Reason For Cancellation shown',
        );
      } else {
        console.log('Validation failed for one or more fields.');
      }
    };
    const realtorInfo = reactive({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    });

    const formSubmitted = ref(false);

    const form = reactive({
      policyNumber: '',
      reason: 'select_reason',
      remarks: '',
      attachment: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      interest: '',
      realtorInfo: null,
    });
    const handleRealtorInfo = (realtorInfo) => {
      form.realtorInfo = realtorInfo; // Store the received realtor info
      console.log('Received realtor info:', realtorInfo);
    };
    const validationErrors = reactive({
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      policyNumber: null,
    });
    // Utility validation functions
    const isEmpty = (value) => !value.trim();
    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isValidPhone = (phone) => /^\(\d{3}\) \d{3}-\d{4}$/.test(phone);
    // Validation function
    const validateField = (field, value) => {
      // First, check if the value is empty and set a required message if so.
      if (isEmpty(value)) {
        switch (field) {
          case 'email':
            validationErrors.email = 'Email is required';
            break;
          case 'phone':
            validationErrors.phone = 'Phone number is required';
            break;
          case 'firstName':
            validationErrors.firstName = 'First name is required';
            break;
          case 'lastName':
            validationErrors.lastName = 'Last name is required';
            break;
          default:
            validationErrors[field] = 'This field is required';
        }
      } else {
        // If not empty, then proceed to check for specific format validations
        switch (field) {
          case 'email':
            validationErrors.email = isValidEmail(value)
              ? null
              : 'Invalid email format';
            break;
          case 'phone':
            validationErrors.phone = isValidPhone(value)
              ? null
              : 'Invalid phone format';
            break;
          // Assuming all other fields do not require format validation
          default:
            validationErrors[field] = null;
        }
      }
    };
    // Function to validate the contact information form

    // Add this inside your setup function
    const manualSetReason = (reason) => {
      form.reason = reason;
    };

    const showContactInformaton = ref(true);

    const showReasonForCancellationModal = ref(false);

    watch(
      () => form.reason,
      (newVal) => {
        if (newVal === 'select_reason') {
          // Handle the case when the default option is selected
          return;
        }
        // Cases that show the terms modal
        if (
          newVal === 'risk_reduction' ||
          newVal === 'moving_out' ||
          newVal === 'mortgage_paid_off' ||
          newVal === 'change_in_ownership'
        ) {
          showTermsModal.value = true;
          showReasonForCancellationModal.value = false;
        }
        // Explicitly handle the remaining options
        else if (
          newVal === 'selling_home' ||
          newVal === 'switching_insurers' ||
          newVal === 'financial_hardship' ||
          newVal === 'policy_consolidation'
        ) {
          showWinBackModal.value = true;
          showReasonForCancellationModal.value = false;
        } else {
          console.log(`Unexpected reason value: ${newVal}`);
        }
      },
    );

    const winBackMessage = ref('');
    const showWinBackModal = ref(false);
    const handleUpdateInterest = (interest) => {
      form.interest = interest;
      console.log('User interest:', interest);
    };

    const showTermsModal = ref(false);
    const showCancellationInfoModal = ref(false);
    const userAgrees = ref(false);
    const userCanAgree = ref(false);
    const showPolicyNumberPopup = ref(false);

    // Scroll and timeout refs
    const termsAndConditionsRef = ref(null);
    const hidePolicyNumberPopupTimeoutId = ref(null);

    // Form submission logic incorporating custom validation
    const submitForm = async () => {
      console.log('Starting form submission');
      if (!form.policyNumber.trim()) {
        validationErrors.policyNumber = 'Policy number is required';
        console.log('Policy number is required.');
        return;
      } else {
        validationErrors.policyNumber = null;
      }
      const submissionData = {
        first_name: form.firstName,
        last_name: form.lastName,
        email: form.email,
        phone: form.phone,
        policy_number: form.policyNumber,
        reason: form.reason,
        remarks: form.remarks,
        realtor_first_name: form.realtorInfo?.firstName || null,
        realtor_last_name: form.realtorInfo?.lastName || null,
        realtor_email: form.realtorInfo?.email || null,
        realtor_phone: form.realtorInfo?.phoneNumber || null,
        callback_time: callbackTime.value || null,
        interest: form.interest || null,
      };

      try {
        const response = await axios.post(
          '/api/cancellation-requests',
          submissionData,
        );
        console.log('Form submitted successfully:', response.data);

        formSubmitted.value = true;

        confetti({
          particleCount: 100,
          spread: 70,
          origin: { x: 0.5, y: 0.5 },
        });
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    };

    const handleScroll = () => {
      const container = termsAndConditionsRef.value;
      if (container) {
        const isScrolledToBottom =
          container.scrollHeight - container.scrollTop <=
          container.clientHeight;
        userCanAgree.value = isScrolledToBottom;
      }
    };

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const hidePolicyNumberPopupWithDelay = () => {
      clearTimeout(hidePolicyNumberPopupTimeoutId.value);
      hidePolicyNumberPopupTimeoutId.value = setTimeout(() => {
        showPolicyNumberPopup.value = false;
      }, 800); // Adjust delay as needed
    };

    // Lifecycle hooks
    onMounted(() => {
      scrollToTop();
      if (termsAndConditionsRef.value) {
        termsAndConditionsRef.value.addEventListener('scroll', handleScroll);
      }
    });
    watch(
      termsAndConditionsRef,
      (newValue, oldValue) => {
        if (newValue) {
          newValue.addEventListener('scroll', handleScroll);
        }
        if (oldValue) {
          oldValue.removeEventListener('scroll', handleScroll);
        }
      },
      { immediate: true, flush: 'post' },
    );

    onBeforeUnmount(() => {
      clearTimeout(hidePolicyNumberPopupTimeoutId.value);
      if (termsAndConditionsRef.value) {
        termsAndConditionsRef.value.removeEventListener('scroll', handleScroll);
      }
    });

    // Expose to template
    return {
      form,
      showPopup,
      hidePopupWithDelay,
      validateField,
      manualSetReason,
      validationErrors,
      showContactInformaton,
      showReasonForCancellationModal,
      showWinBackModal,
      winBackMessage,
      getReasonText,
      handleProceedFromReasonsForCancellation,
      handleUpdateInterest,
      handleContactInfoNext,
      handleRealtorInfo,
      handleSelectedTime,
      callbackTime,
      realtorInfo,
      handleScroll,
      scrollToTop,
      showTermsModal,
      handleProceed,
      showCancellationInfoModal,
      userCanAgree,
      userAgrees,
      showPolicyNumberPopup,
      termsAndConditionsRef,
      formSubmitted,
      showPolicyNumberPopup,
      hidePolicyNumberPopupWithDelay,
      closeModal,
      closeModals,
      submitForm,
      emit,
    };
  },
};
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-out;
}
</style>
