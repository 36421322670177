<template>
  <div class="max-w-4xl bg-white rounded-lg mb-8">
    <div class="max-w-4xl bg-white rounded-lg">
      <h3 class="text-xl font-semibold mb-4 flex justify-between items-center border-b-2 pb-3">
        Four Point Inspection
      </h3>
      <div v-if="!activeSection" class="flex flex-col md:flex-row gap-2">
        <button @click="setActiveSection('roof')" 
              :class="{'bg-blue-500 text-white': activeSection === 'roof'}"
              class="transition duration-300 ease-in-out hover:bg-blue-600 hover:text-white text-blue-500 rounded-full px-4 py-2 w-full">
          <Icon icon="mdi:home-roof" class="h-5 w-5 mx-auto" /> Roof
        </button>
        <button @click="setActiveSection('plumbing')" 
              :class="{'bg-blue-500 text-white': activeSection === 'plumbing'}"
              class="transition duration-300 ease-in-out hover:bg-blue-600 hover:text-white text-blue-500 rounded-full px-4 py-2 w-full">
          <Icon icon="mdi-pipe-leak" class="h-5 w-5 mx-auto" /> Plumbing
        </button>
        <button @click="setActiveSection('ac_heating')" 
              :class="{'bg-blue-500 text-white': activeSection === 'ac_heating'}"
              class="transition duration-300 ease-in-out hover:bg-blue-600 hover:text-white text-blue-500 rounded-full px-4 py-2 w-full">
          <Icon icon="mdi-fan" class="h-5 w-5 mx-auto" /> A/C & Heating
        </button>
        <button @click="setActiveSection('age_of_home')" 
              :class="{'bg-blue-500 text-white': activeSection === 'age_of_home'}"
              class="transition duration-300 ease-in-out hover:bg-blue-600 hover:text-white text-blue-500 rounded-full px-4 py-2 w-full">
          <Icon icon="mdi-home-city-outline" class="h-5 w-5 mx-auto" /> Age of Home
        </button>
    </div>
    <div v-else class="content-section">
        <button @click="resetActiveSection" class="text-blue-500 hover:text-blue-600 transition ease-in-out duration-300 mb-4">
          <Icon icon="mdi:arrow-left" class="h-5 w-5 inline" /> Back to Sections
        </button>
        
        <div v-if="activeSection === 'plumbing'" class="transition duration-500 ease-in-out">
          <h4 class="text-lg font-semibold my-2">Plumbing</h4>
          <ul class="list-disc pl-5">
            <li>Cast iron plumbing</li>
            <li>Galvanized</li>
            <li>Polybutylene</li>
            <li>Pex plumbing</li>
            <li>Water heater</li>
          </ul>
        </div>
        <div v-if="activeSection === 'ac_heating'" class="transition duration-500 ease-in-out">
          <h4 class="text-lg font-semibold my-2">A/C and Heating</h4>
          <ul class="list-disc pl-5">
            <li>Asbestos</li>
            <li>Inspections?</li>
          </ul>
        </div>
        <div v-if="activeSection === 'age_of_home'" class="transition duration-500 ease-in-out">
          <h4 class="text-lg font-semibold my-2">Age of Home</h4>
          <!-- Specific content for Age of Home -->
        </div>
        <div v-if="activeSection === 'roof'" class="transition duration-500 ease-in-out">
          <h4 class="text-lg font-semibold my-2">Roof</h4>
          <ul class="list-disc pl-5">
            <li>Age of Roof</li>
            <li>Flat roof</li>
            <li>Patch roof</li>
            <li>Solar panels (for personal use and/or selling power back)</li>
          </ul>
        </div>
    </div>

    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Icon } from '@iconify/vue';

const isActive = ref(true);
const activeSection = ref(null);

const setActiveSection = (section) => {
  activeSection.value = section;
};

const resetActiveSection = () => {
  activeSection.value = null;
};

const closeModal = () => {
  isActive.value = false;
  activeSection.value = null;
};
</script>



<style scoped>

</style>
