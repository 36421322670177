<template>
  <AppHeaderDesktop />
  <ImportantAnnouncements />
  <CitizensDocumentUploads :formType="formType" />
  <AppFooter />
</template>

<script>
import AppHeaderDesktop from '../components/AppHeaderDesktop.vue';
import AppFooter from '../components/AppFooter.vue';
import ImportantAnnouncements from '../components/ImportantAnnouncements.vue';
import CitizensDocumentUploads from '../components/customerservice/CitizensComponents/citizensDocumentUploads.vue';

export default {
  name: 'citizensFormUpload',
  components: {
    AppHeaderDesktop,
    AppFooter,
    ImportantAnnouncements,
    CitizensDocumentUploads,
  },
  data() {
    return {
      // Add a data property to hold the formType parameter
      formType: this.$route.params.formType,
    };
  },
  mounted() {
    // Scroll to the top of the window when the component is mounted
    window.scrollTo(0, 0);
  },
};
</script>
