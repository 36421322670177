<template>
  <div class="flex bg-gray-100">
    <Navigation />
    <div class="flex-grow pt-4">
      <h1 class="text-4xl font-bold text-gray-800 mb-10">
        Create New Email List
      </h1>
      <div class="bg-white rounded-lg p-10">
        <div class="selected-counter my-2">
          <span class="font-semibold">{{ selectedCustomers.length }}</span>
          record(s) selected.
        </div>
        <div class="bg-blue-100 text-blue-800 p-2 rounded-md my-4 shadow">
          <span class="font-bold">{{ selectedCustomers.length }}</span>
          record(s) selected.
        </div>
        <button
          @click="addToEmailList"
          class="bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-150 ease-in-out mb-4"
        >
          Add Selected to Email List
        </button>
        <div
          class="email-list-counter bg-purple-100 text-purple-800 p-2 rounded-md mb-4 shadow"
        >
          <span class="font-bold">{{ emailList.length }}</span> record(s) in
          Email List.
        </div>
        <!-- DataTable for displaying customer data -->
        <div class="table-container">
          <DataTable
            v-model:selection="selectedCustomers"
            scrollable
            scrollHeight="400px"
            :value="customers"
            :totalRecords="totalRecords"
            selectionMode="multiple"
            dataKey="id"
            :rows="rowsPerPage"
          >
            <Column selectionMode="multiple" style="width: 3em"></Column>
            <Column
              field="customer_name"
              header="Name"
              sortable
              filter
              filterPlaceholder="Search by name"
            ></Column>
            <Column
              field="email"
              header="Email"
              sortable
              filter
              filterPlaceholder="Search by email"
            ></Column>
            <Column
              field="policy_number"
              header="Policy Number"
              sortable
              filter
              filterPlaceholder="Search by policy number"
            ></Column>
            <Column
              field="policy_type"
              header="Policy Type"
              sortable
              filter
              filterPlaceholder="Search by policy type"
            ></Column>
            <!-- Add more columns as needed -->
          </DataTable>
        </div>
        <!-- Pagination controls -->
        <div class="pagination-container">
          <Paginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            :rowsPerPageOptions="rowsPerPageOptions"
            @update:rows="rowsPerPage = $event"
            @page="pageChange"
          ></Paginator>
        </div>
        <button
          @click="saveEmailList"
          class="mt-4 bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-150 ease-in-out"
        >
          Save Email List
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import Navigation from '../components/Navigation.vue';
import axios from 'axios';

const customers = ref([]);
const totalRecords = ref(0);
const selectedCustomers = ref([]);
const allSelectedCustomers = ref([]);
const emailList = ref([]);
const rowsPerPage = ref(10); // Set an initial value for rowsPerPage
const rowsPerPageOptions = ref([10, 25, 50, 100]);
watch(selectedCustomers, (currentSelections) => {
  // Add newly selected customers
  currentSelections.forEach((customer) => {
    if (!allSelectedCustomers.value.includes(customer.id)) {
      allSelectedCustomers.value.push(customer.id);
    }
  });

  // Remove deselected customers
  allSelectedCustomers.value = allSelectedCustomers.value.filter((id) =>
    currentSelections.some((customer) => customer.id === id),
  );
});
const loadData = async (page, rowsPerPage) => {
  try {
    const { data } = await axios.get(
      `/api/customers?page=${page}&per_page=${rowsPerPage}`,
    );
    customers.value = data.data;
    totalRecords.value = data.total;

    // Pre-select items after loading data
    selectedCustomers.value = data.data.filter((customer) =>
      allSelectedCustomers.value.includes(customer.id),
    );
  } catch (error) {
    console.error('Error fetching customers:', error);
  }
};

const onLazyLoad = async (event) => {
  try {
    console.log('Pagination Parameters:', event);
    const { data } = await axios.get(
      `/api/customers?page=${event.first / event.rows + 1}&per_page=${event.rows}`,
    );
    console.log('Data Response:', data);
    customers.value = data.data; // Update customers ref with new data
    totalRecords.value = data.total; // Update totalRecords ref with new total count
    console.log('Total customer records:', data.total);
  } catch (error) {
    console.error('Error fetching customers:', error);
  }
};

const pageChange = async (event) => {
  try {
    console.log('Pagination Parameters:', event);
    const { data } = await axios.get(
      `/api/customers?page=${event.page + 1}&per_page=${event.rows}`,
    );
    console.log('Data Response:', data);
    customers.value = data.data; // Update customers ref with new data
    totalRecords.value = data.total; // Update totalRecords ref with new total count
    console.log('Total customer records:', data.total);
  } catch (error) {
    console.error('Error fetching customers:', error);
  }
};

const addToEmailList = () => {
  // Add currently selected customers to the email list, avoiding duplicates
  allSelectedCustomers.value.forEach((customerId) => {
    if (!emailList.value.includes(customerId)) {
      emailList.value.push(customerId);
    }
  });

  // Optionally clear current selections or leave them as-is
  // selectedCustomers.value = [];
};

const saveEmailList = () => {
  // Logic to add selected customers to the email list...

  // Display a success notification
  this.$notify({
    title: 'Success',
    text: 'Email list has been successfully saved.',
    type: 'success',
  });

  // Optional: Clear all selections after saving
  allSelectedCustomers.value = [];
  selectedCustomers.value = [];
};

onMounted(() => {
  onLazyLoad({ first: 0, rows: rowsPerPage.value }); // Initial load
});
</script>

<style scoped>
/* Adjust the container to have a scrollbar */
.table-container {
  max-height: 500px; /* Adjust to the desired height */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Hides horizontal scrollbar */
}

/* Adjust the checkbox border */
.p-datatable .p-checkbox .p-checkbox-box {
  border: 1px solid #cccccc; /* Light gray border */
}

/* Adjust the checkbox background and border when checked/highlighted */
.p-datatable .p-checkbox .p-checkbox-box.p-highlight,
.p-datatable .p-checkbox .p-checkbox-box.p-highlight::before {
  background-color: #cccccc; /* Light gray background */
  border-color: #cccccc; /* Light gray border */
}

/* Adjust the checkbox's appearance (for supporting browsers) */
.p-datatable .p-checkbox .p-checkbox-input {
  accent-color: #cccccc; /* Light gray check color */
}

/* Ensure pagination margin */
.pagination-container {
  margin-top: 10px;
}
</style>
