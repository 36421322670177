<template>
  <div class="z-10 relative w-[492px] hidden md:block m-auto">
    <a href="/" class="inline-block"
      ><img src="@/assets/images/Insurance_express_logo.png" class="block"
    /></a>
  </div>
</template>

<script>
export default {
  name: 'AppHeaderLogo',
};
</script>

<style></style>
