<template>
  <div class="main-container m-6 md:mx-20">
    <h2
      class="text-[#525355] md:text-[35px] text-2xl font-merriweather font-[900] text-center mb-8"
    >
      Select products to bundle & save.
    </h2>
    <div class="flex flex-wrap justify-center">
      <div
        v-for="option in insuranceOptions"
        :key="option.title"
        class="w-[48%] md:w-[31%] xl:w-[24%] m-[1%] md:m-[0.5%] rounded-[5px] drop-shadow-xl hover:shadow flex flex-col items-center bg-white p-2 md:p-6"
      >
        <div class="flex justify-center items-center w-full">
          <!-- Fixed height container for image -->

          <img :src="option.imageSrc" :alt="option.title" class="w-24" />
          <!-- Fixed height for SVG -->
        </div>
        <p
          class="mb-2 font-merriweather md:text-[25px] text-base text-[#525355] text-center font-bold"
        >
          {{ option.title }}
        </p>
        <div class="text-center">
          <router-link
            :to="option.quoteLink"
            @click="trackQuoteClick(option.title)"
            class="btn-shadow inline-block font-merriweather sm:text-sm text-[12px] px-[22.5px] py-[7.5px] bg-[#E12F2F] rounded text-white drop-shadow-[-1px_7px_10px_0px_rgb(209,2,2,50)] hover:bg-[#C00202] transition-all duration-500 hover:scale-90"
          >
            GET A QUOTE
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      insuranceOptions: [
        {
          title: 'Home Insurance',
          // Uncomment to use Lottie animation
          // animationData: house,
          imageSrc: '/images/product-type-icons/homeInsurance.svg', // SVG image
          paddingTop: 'pt-[15%]',
          quoteLink: '/quotes/home?productType=Home Insurance',
        },
        {
          title: 'Auto Insurance',
          // Uncomment to use Lottie animation
          // animationData: Car,
          imageSrc: '/images/product-type-icons/autoInsurance.svg', // SVG image
          paddingTop: 'pt-[15%]',
          quoteLink: '/quotes/auto?productType=Auto Insurance',
        },
        {
          title: 'Flood Insurance',
          // Uncomment to use Lottie animation
          // animationData: floodAnimation, // Replace with the correct Lottie file
          imageSrc: '/images/product-type-icons/floodInsurance.svg', // SVG image
          paddingTop: 'pt-[15%]',
          quoteLink: '/quotes/flood?productType=Flood Insurance',
        },
        {
          title: 'Medicare',
          // Uncomment to use Lottie animation
          // animationData: medicareAnimation, // Replace with the correct Lottie file
          imageSrc: '/images/product-type-icons/medicare.svg', // SVG image
          paddingTop: 'pt-[15%]',
          quoteLink: '/quotes/medicare?productType=Medicare',
        },
        {
          title: 'Life Insurance',
          // Uncomment to use Lottie animation
          // animationData: lifeInsuranceAnimation, // Replace with the correct Lottie file
          imageSrc: '/images/product-type-icons/lifeInsurance.svg', // SVG image
          paddingTop: 'pt-[15%]',
          quoteLink: '/quotes/life?productType=Life Insurance',
        },
        {
          title: 'Pet Insurance',
          // Uncomment to use Lottie animation
          // animationData: petInsuranceAnimation, // Replace with the correct Lottie file
          imageSrc: '/images/product-type-icons/petInsurance.svg', // SVG image
          paddingTop: 'pt-[15%]',
          quoteLink: '/quotes/pet?productType=Pet Insurance',
        },
        {
          title: 'Business Insurance',
          // Uncomment to use Lottie animation
          // animationData: businessInsuranceAnimation, // Replace with the correct Lottie file
          imageSrc: '/images/product-type-icons/businessInsurance.svg', // SVG image
          paddingTop: 'pt-[15%]',
          quoteLink: '/quotes/commercial?productType=Business Insurance',
        },
      ],
    };
  },
  methods: {
    trackQuoteClick(insuranceType) {
      this.$gtag.event('click', {
        event_category: 'Get Quote',
        event_label: insuranceType,
        // Optionally add other relevant event parameters
      });
    },
  },
};
</script>
