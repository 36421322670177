<template>
  <div class="flex min-h-screen bg-gray-100">
    <!-- Sidebar Navigation -->
    <Navigation />
    <div class="flex-1 p-10">
      <h1 class="text-4xl font-bold text-gray-800 mb-10">Communications</h1>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <!-- Widgets Start -->
        <div
          class="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between hover:shadow-xl transition-shadow duration-300 ease-in-out"
        >
          <div class="text-center">
            <h2 class="text-2xl font-semibold text-gray-700">
              Create Template
            </h2>
            <img
              src="@/assets/images/backend-graphics/communicationsNewsLetterLogo.svg"
              alt="logo"
              class="mx-auto w-32 h-32 md:w-40 md:h-40"
            />
            <p class="text-gray-600 mt-2">Choose List</p>
          </div>
          <router-link
            to="/super-admin/create-template"
            class="mt-4 flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create Template
            <Icon
              icon="mdi:account-multiple-outline"
              class="ml-2"
              style="height: 1em; width: 1em"
            />
          </router-link>
        </div>

        <div
          class="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between hover:shadow-xl transition-shadow duration-300 ease-in-out"
        >
          <div class="text-center">
            <h2 class="text-2xl font-semibold text-gray-700">
              Create New List
            </h2>
            <img
              src="@/assets/images/backend-graphics/communicationsNewsListCreationLogo.svg"
              alt="logo"
              class="mx-auto w-32 h-32 md:w-40 md:h-40"
            />
            <p class="text-gray-600 mt-2">Choose Users</p>
          </div>
          <router-link
            to="/super-admin/create-new-list"
            class="mt-4 flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Create New List
            <Icon
              icon="mdi:cog-outline"
              class="ml-2"
              style="height: 1em; width: 1em"
            />
          </router-link>
        </div>

        <div
          class="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between hover:shadow-xl transition-shadow duration-300 ease-in-out"
        >
          <div class="text-center">
            <h2 class="text-2xl font-semibold text-gray-700">
              Campaign Settings
            </h2>
            <img
              src="@/assets/images/backend-graphics/communicationsNewsManageCommunicationSettings.svg"
              alt="logo"
              class="mx-auto w-32 h-32 md:w-40 md:h-40"
            />
            <p class="text-gray-600 mt-2">Campaign Settings</p>
          </div>
          <router-link
            to="/"
            class="mt-4 flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Manage Campaigns
            <Icon
              icon="map:insurance-agency"
              class="ml-2"
              style="height: 1em; width: 1em"
            />
          </router-link>
        </div>
        <div
          class="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between hover:shadow-xl transition-shadow duration-300 ease-in-out"
        >
          <div class="text-center">
            <h2 class="text-2xl font-semibold text-gray-700">Send Email</h2>
            <img
              src="@/assets/images/backend-graphics/sendEmail.svg"
              alt="Send Email Icon"
              class="mx-auto w-32 h-32 md:w-40 md:h-40"
            />
            <p class="text-gray-600 mt-2">Use a saved template</p>
          </div>
          <router-link
            to="/super-admin/send-email"
            class="mt-4 flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Send Email
            <Icon
              icon="mdi:email-send-outline"
              class="ml-2"
              style="height: 1em; width: 1em"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import Navigation from './components/Navigation.vue';

export default {
  name: 'Communications',
  components: {
    Icon,
    Navigation,
  },
};
</script>

<style scoped>
/* Tailwind's utility classes handle most styling, but you can add custom styles here */
</style>
