<template>
  <div class="flex flex-col md:flex-row md:min-h-screen bg-gray-100">
    <Navigation />
    <div class="flex-1 p-4 md:p-10">
      <h1 class="text-2xl font-bold text-ietextlight mb-10">
        Analytics Dashboard
      </h1>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <!-- Card 1: User Engagement -->
        <div class="bg-white rounded-lg shadow p-5">
          <div class="flex items-center justify-between">
            <h2 class="text-xl font-semibold">User Engagement</h2>
            <Icon
              icon="mdi:account-group-outline"
              class="text-blue-500"
              style="height: 2em; width: 2em"
            />
          </div>
          <p class="mt-2 text-gray-600">
            Metrics on user activity and interaction.
          </p>
          <div class="mt-4">
            <span class="text-2xl font-bold">89%</span>
            <p class="text-sm text-gray-500">Increase in user activity</p>
          </div>
        </div>

        <!-- Card 2: System Performance -->
        <div class="bg-white rounded-lg shadow p-5">
          <div class="flex items-center justify-between">
            <h2 class="text-xl font-semibold">System Performance</h2>
            <Icon
              icon="mdi:speedometer"
              class="text-green-500"
              style="height: 2em; width: 2em"
            />
          </div>
          <p class="mt-2 text-gray-600">
            Overview of system health and performance.
          </p>
          <div class="mt-4">
            <span class="text-2xl font-bold">97.5%</span>
            <p class="text-sm text-gray-500">Operational efficiency</p>
          </div>
        </div>

        <!-- Card 3: Financial Overview -->
        <div class="bg-white rounded-lg shadow p-5">
          <div class="flex items-center justify-between">
            <h2 class="text-xl font-semibold">Financial Overview</h2>
            <Icon
              icon="mdi:finance"
              class="text-red-500"
              style="height: 2em; width: 2em"
            />
          </div>
          <p class="mt-2 text-gray-600">
            Financial metrics and revenue streams.
          </p>
          <div class="mt-4">
            <span class="text-2xl font-bold">$24,300</span>
            <p class="text-sm text-gray-500">Monthly revenue</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import Navigation from './components/Navigation.vue';

export default {
  name: 'Analytics',
  components: {
    Icon,
    Navigation,
  },
};
</script>

<style scoped></style>
