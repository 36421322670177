<template>
  <div class="container mx-auto px-4 py-4">
    <CitizensDocumentUploads v-if="!getStarted" />
    <!-- Responsive Container with Shadow and Rounded Corners -->
    <div v-else class="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
      <!-- Logo -->
      <div class="text-center mb-6">
        <img
          src="@/assets/images/carrierLogos/citizens-prop-1.svg"
          alt="Citizens Logo"
          class="mx-auto h-20 w-auto"
        />
      </div>
      <!--Main Heading-->
      <div class="mb-6 mx-auto">
        <div class="flex justify-center mx-auto">
          <div class="text-red-600 mr-4">
            <Icon
              icon="solar:letter-broken"
              class="text-red-600 text-sm sm:text-md my-auto"
            />
          </div>
          <h1 class="text-md sm:text-lg font-bold text-gray-900">
            If you've received a letter
          </h1>
        </div>
        <p class="text-xs md:text-md text-center text-gray-700 mt-2">
          You're in the right place! Discover how to address your specific
          letter with our easy-to-follow guidance below.
        </p>
      </div>
      <!-- Tab buttons container -->
      <div class="flex justify-center mb-4 border-b">
        <div class="mr-1" v-for="(tab, index) in tabs" :key="index">
          <button
            class="py-2 px-1 md:px-4 font-semibold rounded-t-lg focus:outline-none transition-colors duration-200 text-sm md:text-base"
            :class="{
              'bg-blue-600 text-white': currentTab === index,
              'bg-gray-200 text-gray-600 hover:bg-gray-300':
                currentTab !== index,
            }"
            @click="currentTab = index"
            :aria-selected="currentTab === index"
          >
            {{ tab.title }}
          </button>
        </div>
      </div>
      <div class="border p-4 rounded-b-lg">
        <div v-if="currentTab === 0">
          <div class="space-y-4 p-tabview-panel">
            <!-- Navigating Policy Depopulation -->
            <h2
              class="text-2xl md:text-3xl text-center font-bold text-gray-800 mb-6"
            >
              Navigating Policy Depopulation
            </h2>

            <!-- Understanding Depopulation -->
            <p class="mb-4">
              <strong class="font-bold text-blue-700"
                >Understanding Depopulation:</strong
              ><br />
              The Citizens Property Insurance Depopulation Program is a Florida
              state-mandated initiative aimed at transitioning policies from
              Citizens to the private insurance market. This program is crucial
              for reducing the policy count of Citizens, ensuring it remains the
              insurer of last resort, and promoting a balanced and competitive
              insurance market in Florida. Key points include:
            </p>

            <ul class="list-disc ml-6 mb-4">
              <li>
                <strong class="text-gray-800"
                  >Criteria for Transitioning:</strong
                >
                Private insurers can offer policies with premiums not more than
                20% greater than Citizens' estimated renewal premium.
                Policyholders are generally expected to transition to these
                private insurers.
              </li>
              <li>
                <strong class="text-gray-800">Policyholder Choice:</strong> If
                the offered premium is over 20% higher than Citizens’ renewal
                premium, policyholders can opt to stay with Citizens.
              </li>
              <li>
                <strong class="text-gray-800">Potential Benefits:</strong>
                Transitioning to a private insurer can offer more comprehensive
                coverage, personalized service, and sometimes lower premiums.
              </li>
              <li>
                <strong class="text-gray-800">Consumer Protection:</strong> All
                private insurers in this program are vetted and approved by the
                Florida Office of Insurance Regulation.
              </li>
              <li>
                <strong>Informed Decisions:</strong> Policyholders should assess
                offers from private insurers carefully, considering coverage,
                premiums, and service quality.
              </li>
            </ul>

            <!-- Review notice and make a choice -->
            <p class="mb-4">
              <strong class="font-bold text-blue-700">Review the Notice:</strong
              ><br />
              When you get a depopulation notice, it's crucial to understand
              your options. The notice will detail alternatives to your current
              Citizens policy, offered by private insurers.
            </p>

            <p class="mb-4">
              <strong class="font-bold text-blue-700"
                >What If You Didn't Receive a Notice?:</strong
              ><br />
              This means no offer from a private insurer exceeds your current
              Citizens policy by more than 20%. In this scenario, your policy
              will not renew with Citizens and will automatically transition to
              the mentioned private carrier.
            </p>

            <!-- Time to make a choice -->
            <p class="mb-4">
              <strong class="font-bold text-blue-700"
                >Deciding Your Next Steps:</strong
              ><br />
              If the notice provides you with options and you have more than 48
              hours to register your choice, you can take the following steps:
            </p>

            <p class="mb-4">
              - Use the link provided in the letter to register your choice at
              <a
                class="text-red-700 font-semibold underline"
                href="www.citizensfla.com/online-choice"
                >www.citizensfla.com/online-choice</a
              >.
            </p>

            <p class="mb-4">
              - Make sure to use the policy number and code provided in the
              letter during the registration process.
            </p>

            <p class="mb-4">
              - For assistance, contact us with your policy number and your
              preferred option.
            </p>

            <!-- Confirmation of your choice -->
            <p class="mb-4">
              <strong class="font-bold text-blue-700"
                >Confirming Your Decision:</strong
              ><br />
              After registering your choice, Citizens will confirm your decision
              through your selected method of communication – email or regular
              mail.
            </p>
          </div>
        </div>
        <div v-if="currentTab === 1">
          <div class="space-y-4 p-tabview-panel">
            <!-- Navigating Policy Depopulation -->
            <h2
              class="text-2xl md:text-3xl text-center font-bold text-gray-800 mb-6"
            >
              Navigating Policy of Residency
            </h2>
            <p class="font-bold text-blue-700">
              Understanding Proof of Residency:
            </p>
            <p class="mb-4">
              If you've recently received a letter from Citizens about proving
              your residency, it's part of a new initiative to ensure proper
              categorization of homes. This helps in applying the correct
              insurance rates and minimizing financial risks. Here's what you
              need to do:
            </p>
            <!-- Step 2: Is the insured home your primary residence? -->
            <p class="mb-4">
              <strong class="font-bold text-blue-700"
                >For Primary Residences:</strong
              ><br />
              Click "Get Started" to fill out the required 'Policyholder
              Affirmation Regarding Primary Residence Form'. Before clicking
              "Get Started," include one of these documents in your submission:
            </p>

            <ul class="list-disc ml-5 space-y-2">
              <li class="font-bold text-gray-800">
                <i class="fas fa-check text-blue-500"></i> Homestead Exemption
              </li>
              <li class="font-bold text-gray-800">
                <i class="fas fa-check text-blue-500"></i> Voter Registration
                card
              </li>
              <li class="font-bold text-gray-800">
                <i class="fas fa-check text-blue-500"></i> Florida Driver’s
                License or Identification card
              </li>
              <li class="font-bold text-gray-800">
                <i class="fas fa-check text-blue-500"></i> Military orders
                <sup><span style="color: red">**if applicable</span></sup>
              </li>
              <li class="font-bold text-gray-800">
                <i class="fas fa-check text-blue-500"></i> Recent Utility Bill
              </li>
              <li class="font-bold text-gray-800">
                <i class="fas fa-check text-blue-500"></i> Vehicle registration
              </li>
              <li class="font-bold text-gray-800">
                <i class="fas fa-check text-blue-500"></i> Lease Agreement
                <sup
                  ><span style="color: red"
                    >**if executed in the past 12 months</span
                  ></sup
                >
              </li>
            </ul>
            <button
              @click="toggleGetStarted('proofofresidency')"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 flex items-center"
            >
              Get Started
              <Icon icon="material-symbols:not-started" class="ml-2" />
            </button>

            <!-- Step 3: Is the home tenant-occupied? -->
            <p class="mb-4">
              <strong class="font-bold text-blue-700"
                >For Tenant-Occupied Homes:</strong
              ><br />
              Submit the same form along with a copy of the current lease
              agreement or a tenant's utility bill.
            </p>

            <!-- Step 4: Is this a secondary or seasonal home? -->
            <p class="mb-4">
              <strong class="font-bold text-blue-700"
                >Secondary or Seasonal Homes:</strong
              ><br />
              These properties typically do not require additional action unless
              specified in your notice.
            </p>
          </div>
        </div>
        <div v-if="currentTab === 2">
          <div class="space-y-4 p-tabview-panel">
            <h2
              class="text-2xl md:text-3xl text-center font-bold text-gray-800 mb-6"
            >
              Navigating Proof of Flood Coverage
            </h2>
            <path class="font-bold text-blue-700"
              >Understanding Proof of Flood Coverage:</path
            >
            <p class="mb-4">
              The Florida Legislature's Senate Bill 2A requires most Citizens’
              policyholders to obtain flood insurance within three years. This
              initiative aims to encourage homeowners to return to the primary
              market and protect Citizens from legal disputes over
              wind-versus-water damage. This phased-in requirement begins on
              January 1, 2024, for properties with a replacement value of
              $600,000 or more, extending to all Citizens-covered structures by
              2027, regardless of their value.
            </p>
            <p class="mb-4">
              Flood insurance is crucial for Florida homeowners, especially
              given the state's vulnerability to severe weather. This regulatory
              change emphasizes the importance of flood risk management in the
              insurance landscape. For new and renewing policyholders, proof of
              flood insurance is mandatory, involving a completed Policyholder
              Affirmation Regarding Flood Insurance and proof of flood coverage.
            </p>
            <p class="mb-4">
              <strong class="font-bold text-blue-700"
                >Upon Receipt of Notice: </strong
              ><br />
              Verify that your flood insurance coverage aligns with Citizens’
              requirements. For most policies, this means ensuring the dwelling
              and personal property coverage either matches your Citizens policy
              or meets the minimum of $250,000 for dwelling and $100,000 for
              personal property.
            </p>
            <br />Upload a copy of your flood policy and the completed 'Flood
            Affirmation Form' to us, click "Get Started" to begin the process.
            <button
              @click="toggleGetStarted('floodaffirmation')"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 flex items-center"
            >
              Get Started
              <Icon icon="material-symbols:not-started" class="ml-2" />
            </button>
            <p class="mb-4">
              <strong class="font-bold text-blue-700"
                >No Notice Received?: </strong
              ><br />No action required, to check your properties flood zone
              coverage, please visit
              <a
                class="underline text-red-700"
                href="https://msc.fema.gov/portal/search"
                >FEEMA Flood Map Service Center</a
              >.
            </p>

            <!-- <p class="mb-4">
                    <strong class="font-bold text-blue-700">Document Submission:</strong><br>Email the necessary documents to <a class="underline text-red-700" href="mailto:policychanges@insuranceexpress.com">policychanges@insuranceexpress.com</a>.
                </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import CitizensDocumentUploads from './CitizensComponents/citizensDocumentUploads.vue';

export default {
  components: {
    Icon,
    CitizensDocumentUploads,
  },

  data() {
    return {
      getStarted: true,
      currentTab: 0,
      tabs: [
        { title: 'Policy Depopulation' },
        { title: 'Proof of Residency' },
        { title: 'Proof of Flood Coverage' },
      ],
    };
  },
  methods: {
    toggleGetStarted(formType) {
      this.$router.push(`/customerservice/${formType}`);
    },
  },
};
</script>

<style scoped></style>
