<template>
  <div class="flex flex-col md:flex-row max-h-screen bg-gray-100">
    <!-- Navigation Sidebar -->
    <Navigation />
    <div class="flex-grow flex flex-col p-5 overflow-y-scroll">
      <div class="flex flex-row mb-4 md:mb-6">
        <Icon
          icon="mingcute:mail-send-line"
          class="h-6 w-6 md:h-8 md:w-8 text-blue-500 my-auto mr-2"
        />
        <h1 class="text-2xl md:text-4xl font-bold text-gray-800">
          Forms Sent for Signature
        </h1>
      </div>
      <div class="bg-white shadow-lg rounded-lg p-4 md:p-6">
        <div
          class="flex flex-col md:flex-row justify-between items-center mb-4 md:mb-6"
        >
          <h2 class="text-lg md:text-xl font-semibold text-gray-700">
            Sent List
          </h2>
        </div>
        <div
          class="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4 mb-4"
        >
          <div class="relative w-full rounded-lg">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search..."
              class="custom-input px-3 py-2 rounded-lg focus:ring-2 focus:ring-blue-500 transition-all duration-300 ease-in-out w-full placeholder-[#6B7280]"
            />
            <Icon
              icon="mdi:magnify"
              class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-300"
            />
          </div>
          <select
            v-model="sortKey"
            class="form-select px-3 py-2 border rounded-lg shadow outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 ease-in-out w-full"
          >
            <option value="sort">Sort</option>
            <option value="name">Name</option>
            <option value="role">Sender</option>
            <option value="status">Sent At</option>
            <option value="email">Status</option>
          </select>
        </div>

        <table class="hidden md:block min-w-full w-full text-xs md:text-sm">
          <thead class="bg-gray-50">
            <tr>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
              >
                Signer Name
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
              >
                Sent To
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
              >
                Sent by User
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
              >
                Sent At
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
              >
                Status
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              v-for="document in filteredAndSortedDocuments"
              :key="document.id"
            >
              <td class="px-6 py-4 whitespace-nowrap capitalize">
                {{ document.customer_first_name }}
                {{ document.customer_last_name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <ul>
                  <li v-for="(email, index) in document.sent_to" :key="index">
                    {{ email }}
                  </li>
                </ul>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ document.sent_by }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">{{ document.sentAt }}</td>
              <td class="px-6 py-4 whitespace-nowrap">
                <span
                  :class="getStatusClass(document.status)"
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                >
                  {{ getStatusText(document.status) }}
                </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button
                  @click="viewDetails(document.id)"
                  class="text-indigo-600 hover:text-indigo-900 mr-4"
                >
                  <Icon icon="mdi-pencil" class="inline-block" /> Edit
                </button>
                <button
                  @click="removeDocument(document.id)"
                  class="text-red-600 hover:text-red-900"
                >
                  <Icon icon="mdi-delete" class="inline-block" /> Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Mobile Card View -->
      <div class="md:hidden">
        <div
          v-for="document in filteredAndSortedDocuments"
          :key="document.id"
          class="bg-white p-3 rounded-lg shadow mb-2"
        >
          <!-- Card Header (Clickable) -->
          <div
            @click="
              expandedDocumentId =
                expandedDocumentId === document.id ? null : document.id
            "
            class="cursor-pointer"
          >
            <div class="flex justify-between">
              <div>
                <div class="font-bold capitalize">
                  {{ document.customer_first_name }}
                  {{ document.customer_last_name }}
                </div>
                <div class="text-sm text-gray-600">{{ document.sender }}</div>
              </div>
              <div>
                <span
                  :class="getStatusClass(document.status)"
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                >
                  {{ getStatusText(document.status) }}
                </span>
              </div>
            </div>
            <!-- Additional Details (Conditionally Rendered) -->
            <div v-if="expandedDocumentId === document.id" class="mt-2 text-sm">
              <div>Form Type: {{ document.form_type }}</div>
              <div>Sent At: {{ document.sent_at }}</div>
              <!-- Add more fields as necessary -->
            </div>
            <!-- Action Buttons -->
            <!-- <div class="flex justify-end mt-4">
              <button @click.stop="openEditUserModal(user)" class="text-indigo-600 hover:text-indigo-900 mr-4">
                <Icon icon="mdi-pencil" class="h-5 w-5" />
              </button>
              <button @click.stop="deleteUser(user.id)" class="text-red-600 hover:text-red-900">
                <Icon icon="mdi-delete" class="h-5 w-5" />
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Add User Modal -->
    <!-- <div v-if="showAddUserForm" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
        <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <div class="mt-3 text-center">
            <div class="mt-2 px-7 py-3">
              <input v-model="newUser.firstName" type="text" placeholder="First Name" class="mb-3 px-4 py-2 border rounded-md w-full"/>
              <input v-model="newUser.lastName" type="text" placeholder="Last Name" class="mb-3 px-4 py-2 border rounded-md w-full"/>
              <input v-model="newUser.email" type="email" placeholder="Email" class="mb-3 px-4 py-2 border rounded-md w-full"/>
              <select v-model="newUser.role" class="mb-3 px-4 py-2 border rounded-md w-full">
                <option value="" disabled selected>Role</option>
                <option value="user">User</option>
                <option value="csr">CSR</option>
                <option value="superadmin">Super Admin</option>
                <option value="admin">Admin</option>
                <option value="customer">Customer</option>
                <option value="agent">Agent</option>
                <option value="developer">Developer</option>
              </select>
              
              <input v-model="newUser.password" type="password" placeholder="Password" class="mb-3 px-4 py-2 border rounded-md w-full"/>
            </div>
            <div class="items-center px-4 py-3">
              <button @click="addUser" class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300">
                Add User
              </button>
            </div>
            <div class="items-center px-4 py-3">
              <button @click="showAddUserForm = false" class="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div> -->

    <!-- Edit User Modal -->
    <!-- <div v-if="showEditUserForm" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
        <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <div class="mt-3 text-center">
            <div class="mt-2 px-7 py-3">
              <input v-model="editUser.firstName" type="text" placeholder="First Name" class="mb-3 px-4 py-2 border rounded-md w-full"/>
              <input v-model="editUser.lastName" type="text" placeholder="Last Name" class="mb-3 px-4 py-2 border rounded-md w-full"/>
              <input v-model="editUser.email" type="email" placeholder="Email" class="mb-3 px-4 py-2 border rounded-md w-full"/>
              <input v-model="editUser.phoneNumber" type="tel" placeholder="Phone Number" class="mb-3 px-4 py-2 border rounded-md w-full"/>
              <input v-model="editUser.company" type="text" placeholder="Company" class="mb-3 px-4 py-2 border rounded-md w-full"/>
              <select v-model="editUser.role" class="mb-3 px-4 py-2 border rounded-md w-full">
                <option value="" disabled selected>Role</option>
                <option value="user">User</option>
                <option value="superadmin">Super Admin</option>
                <option value="admin">Admin</option>
                <option value="customer">Customer</option>
                <option value="agent">Agent</option>
                <option value="developer">Developer</option>
              </select>
              <input v-model="editUser.profile_image" type="text" placeholder="Profile Image URL" class="mb-3 px-4 py-2 border rounded-md w-full"/>
              <div class="flex items-center mb-3">
                <input v-model="editUser.isActive" type="checkbox" id="activeCheckbox" class="mr-2"/>
                <label for="activeCheckbox">Is Active</label>
              </div>
              <button @click="saveEdit" class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300">
                Save Changes
              </button>
            </div>
            <div class="items-center px-4 py-3">
              <button @click="showEditUserForm = false" class="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
   -->
  </div>
</template>

<script>
import axios from 'axios';
import Navigation from '../components/Navigation.vue';
import { Icon } from '@iconify/vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SentAccordForms',
  components: {
    Navigation,
    Icon,
  },
  data() {
    return {
      documents: [],
      searchQuery: '',
      sortKey: 'sort',
      expandedDocumentId: null,
    };
  },
  computed: {
    ...mapGetters(['authToken']),

    filteredAndSortedDocuments() {
      // Temporarily return all documents to ensure they are loaded correctly
      return this.documents;
    },
  },
  mounted() {
    this.loadDocuments();
    setInterval(this.loadDocuments, 10000); // Reload documents every 10 seconds
  },
  methods: {
    getStatusText(status) {
      return (
        {
          sent: 'Sent',
          seen: 'Seen by Signer',
          signed: 'Completed',
        }[status] || 'Waiting for Signatures'
      );
    },
    getStatusClass(status) {
      return (
        {
          sent: 'bg-blue-100 text-blue-800',
          seen: 'bg-yellow-100 text-yellow-800',
          signed: 'bg-green-100 text-green-800',
        }[status] || 'bg-red-100 text-red-800'
      );
    },
    async loadDocuments() {
      try {
        axios.defaults.headers.common['Authorization'] =
          `Bearer ${this.authToken}`;
        axios.defaults.withCredentials = true;

        const response = await axios.get('/api/csr-documents');
        this.documents = response.data.map((doc) => ({
          ...doc,
          sent_to: this.parseSentTo(doc.sent_to),
          isCompleted: doc.status === 'Completed',
          sentAt: doc.sent_at
            ? new Date(doc.sent_at).toLocaleDateString()
            : 'Invalid Date',
        }));
        console.log('Documents loaded and processed:', this.documents);
      } catch (error) {
        console.error('Error loading documents:', error);
      }
    },
    async removeDocument(documentId) {
      try {
        axios.defaults.headers.common['Authorization'] =
          `Bearer ${this.authToken}`;
        axios.defaults.withCredentials = true;

        await axios.delete(`/api/csr-documents/${documentId}`);
        this.documents = this.documents.filter((doc) => doc.id !== documentId);
      } catch (error) {
        console.error('Error removing document:', error);
      }
    },
    parseSentTo(sentTo) {
      try {
        return JSON.parse(sentTo);
      } catch (e) {
        console.error('Failed to parse sent_to:', sentTo, e);
        return []; // Return empty array in case of error
      }
    },
  },
};
</script>

<style scoped>
.custom-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: #dddfe2;
  border-width: 1px;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
.p-select-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: #dddfe2;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
</style>
