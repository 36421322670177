<template>
  <div class="my-14 px-4">
    <h2
      class="text-[#525355] md:text-[35px] text-2xl font-merriweather font-[900] text-center mb-8"
    >
      States We Serve
    </h2>
    <p
      class="text-subTitle text-xs md:text-lg text-center my-0 md:my-2 font-roboto text-[#525355]"
    >
      Available Nationwide, Serving 49 out of 50 States!
    </p>
    <img
      class="mx-auto w-full md:max-w-2xl"
      src="@/assets/images/statesWeServe.svg"
      alt="States We Serve Image"
    />
    <!-- Add your content here -->
  </div>
</template>

<script>
export default {
  name: 'StatesWeServe',
  // Add your component options, data, methods, etc.
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
