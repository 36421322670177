<template>
  <div
    class="min-h-[65vh] bg-gray-100 p-4 flex flex-col items-center justify-center overflow-auto mb-8"
  >
    <div
      class="relative mx-auto p-5 border w-full max-w-4xl shadow-lg rounded-lg bg-white overflow-y-auto"
    >
      <div v-if="!signatureComplete" class="space-y-8">
        <div
          :class="{
            'bg-red-500': isError && !documentUploaded,
            'bg-green-500': documentUploaded,
            'bg-white': !documentUploaded && !isError,
          }"
          class="py-6 px-4 rounded-lg shadow-sm"
        >
          <div>
            <!-- Error Message -->
            <div v-if="isError" class="mb-4">
              <span
                class="text-red-600 bg-white py-1 px-3 rounded-full custom-scale custom-fadeIn"
                >{{ errorMessage }}</span
              >
            </div>
            <!-- Completed Label -->
            <div v-if="documentUploaded" class="mb-4">
              <span
                class="text-green-600 bg-white py-1 px-3 rounded-full custom-scale custom-fadeIn"
                >Completed</span
              >
            </div>

            <!-- Heading -->
            <h2
              :class="{
                'text-white': documentUploaded,
                'text-gray-900': !documentUploaded,
              }"
              class="text-xl md:text-3xl font-bold mb-4"
            >
              {{ tailoredHeading }}
            </h2>
            <!-- Secondary heading -->
            <h3
              :class="{
                'text-white': documentUploaded,
                'text-gray-600': !documentUploaded,
              }"
              class="text-sm md:text-md text-gray-700 my-2"
            >
              {{ secondaryHeading }}
            </h3>

            <!-- Upload Success Message -->
            <p v-if="documentUploaded" class="text-white custom-fadeIn">
              Uploaded: <br /><span class="font-medium">{{
                uploadedFileName
              }}</span
              ><br />
              (Upload Successful)
            </p>

            <div v-if="!documentUploaded">
              <div class="mt-6">
                <label
                  class="block text-md md:text-lg text-gray-700 font-medium mb-3 cursor-pointer"
                  for="document-upload"
                >
                  Upload Document or Photo
                </label>
                <!-- File Upload Area -->
                <div class="flex items-center justify-center w-full">
                  <input
                    type="file"
                    class="hidden"
                    id="document-upload"
                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                    @change="handleFileChange"
                    ref="fileInput"
                  />
                  <label
                    for="document-upload"
                    class="flex flex-col items-center justify-center w-full h-48 md:h-64 p-6 md:p-12 rounded-lg border-4 border-dashed cursor-pointer hover:border-blue-500 transition-colors text-center"
                  >
                    <Icon
                      icon="iconamoon:upload-bold"
                      class="text-blue-600"
                      height="48px"
                    />
                    <p class="text-gray-700 text-md md:text-lg">
                      {{ fileLabel }}
                    </p>
                  </label>
                </div>
              </div>
            </div>

            <!-- Responsive Progress Bar -->
            <div
              v-if="isLoadingFileInput"
              class="mt-6 w-full bg-gray-200 rounded-full overflow-hidden"
            >
              <div class="bg-blue-600 h-2 rounded-full" style="width: 50%">
                <span class="text-white text-xs md:text-sm px-2">50%</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Sign Document Section with Error Handling -->
        <div
          v-if="documentUploaded"
          ref="step2"
          :class="{
            'bg-red-500': isError && !signingLinkRequested,
            'bg-green-500': signingLinkRequested,
            'bg-white': !signingLinkRequested && !isError,
          }"
          class="py-6 px-5 rounded-lg shadow-sm"
        >
          <!-- Error Message -->
          <div v-if="isError" class="mb-4">
            <span
              class="text-red-600 bg-white py-1 px-3 rounded-full custom-scale custom-fadeIn"
              >{{ errorMessage }}</span
            >
          </div>
          <!-- Completed Label -->
          <div v-if="signingLinkRequested" class="mb-4">
            <span
              class="text-green-600 bg-white py-1 px-3 rounded-full custom-scale custom-fadeIn"
              >Completed</span
            >
          </div>
          <!-- Enlarged font for heading and increased margin for more space -->
          <h2
            :class="{
              'text-white': signingLinkRequested,
              'text-gray-900': !signingLinkRequested,
            }"
            class="text-xl md:text-3xl font-bold mb-4"
          >
            Step 2: Sign the Document
          </h2>
          <!-- Increased font size for readability -->
          <p
            :class="{
              'text-white': signingLinkRequested,
              'text-gray-600': !signingLinkRequested,
            }"
            class="text-md text-gray-700 mb-4"
          >
            Enter your information for quick signing and click the signing link.
          </p>
          <div v-if="!signingLinkRequested">
            <!-- Enhanced input field with larger padding for better focus and rounded-full for modern aesthetics -->
            <input
              v-model="firstName"
              type="text"
              placeholder="Enter your first name"
              class="capitalize mt-2 p-3 border border-gray-300 rounded-full w-full focus:outline-none focus:ring-2 focus:ring-blue-400 hover:border-blue-500 transition duration-300"
              required
            />
            <input
              v-model="lastName"
              type="text"
              placeholder="Enter your last name"
              class="capitalize mt-2 p-3 border border-gray-300 rounded-full w-full focus:outline-none focus:ring-2 focus:ring-blue-400 hover:border-blue-500 transition duration-300"
              required
            />
            <input
              v-model="recipientEmail"
              type="email"
              placeholder="Enter your email"
              class="mt-2 p-3 border border-gray-300 rounded-full w-full focus:outline-none focus:ring-2 focus:ring-blue-400 hover:border-blue-500 transition duration-300"
              required
            />
            <input
              v-model="policyNumber"
              type="text"
              placeholder="Enter your policy number"
              class="mt-2 p-3 border border-gray-300 rounded-full w-full focus:outline-none focus:ring-2 focus:ring-blue-400 hover:border-blue-500 transition duration-300"
              required
            />
            <!-- Adjusted button padding for a larger click area and rounded-full for consistency -->
            <button
              @click="signPolicyAffirmation"
              class="mt-5 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 shadow-lg transition duration-300"
              type="button"
            >
              Request Signing Link
            </button>
          </div>
          <!-- Loading animation with improved accessibility for the visually impaired -->
          <div
            v-if="isLoading"
            class="fixed inset-0 z-50 bg-gray-700 bg-opacity-60 flex justify-center items-center"
          >
            <div class="text-center p-6 bg-white rounded-lg shadow-xl">
              <h3 class="text-lg font-semibold text-gray-800 mb-4">
                Generating Your Signing Link
              </h3>
              <img
                src="@/assets/images/gifs/loading2.gif"
                alt="Loading animation"
                class="mx-auto w-10 h-10"
              />
              <p class="text-sm text-gray-600 mt-3">
                Please wait, this may take a moment...
              </p>
            </div>
          </div>
          <!-- Full-Screen Opacity Overlay -->
          <div
            v-if="signingUrl && !signatureComplete"
            class="fixed inset-0 z-50 bg-black bg-opacity-50"
          ></div>
          <!-- iFrame Popup with close button -->
          <div
            v-if="signingUrl && !signatureComplete"
            class="fixed inset-0 z-50 flex justify-center items-center p-5"
            style="bottom: 15vh; top: auto"
          >
            <div
              class="relative bg-white rounded-lg shadow-2xl"
              style="
                width: 100%;
                max-width: 640px;
                height: calc(100vh - 2rem - 15vh);
              "
            >
              <!-- Close Button Improved Styling and Position -->
              <button
                @click="closeIframe"
                class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-2 bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-full shadow-lg"
              >
                Close
              </button>
              <iframe
                :src="signingUrl"
                frameborder="0"
                class="w-full h-full"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <!-- Success Message -->
      <div
        v-if="signatureComplete"
        ref="successMessage"
        class="max-w-lg mx-auto p-4 mt-4 text-center bg-green-100 border border-green-200 rounded-lg shadow-lg"
      >
        <h2 class="text-xl font-semibold mb-4 text-green-800">
          Signature Completed
        </h2>
        <p class="text-lg font-medium text-gray-700">
          Thank you for submitting your document. We will process your request
          shortly.
        </p>

        <button
          @click="cancelAfterSuccessEmit"
          class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Back
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted, onMounted, nextTick, computed, watch } from 'vue';
import { Icon } from '@iconify/vue';
import confetti from 'canvas-confetti';
import axios from 'axios';
import ProgressSpinner from 'primevue/progressspinner';
import { useStore } from 'vuex';

export default {
  name: 'CitizensDocumentUploads',
  components: {
    Icon,
    ProgressSpinner,
  },
  props: ['formType'],
  setup(props) {
    const store = useStore();
    const isError = ref(false); // Tracks error state
    const errorMessage = ref(''); // Holds the error message
    const step2 = ref(null); // Reference for Step 2
    const signingLinkRequested = ref(false);
    const step3 = ref(null);
    const signingUrl = ref(null);
    const documentUploaded = ref(false);
    const signatureComplete = ref(false);
    const successMessage = ref(null);
    const documentId = ref(null); // Initialize as null
    const closeIframe = () => {
      signingUrl.value = null;
      signatureComplete.value = true;
      confetti({ particleCount: 100, spread: 70, origin: { y: 0.6 } });
    };
    const recipientEmail = ref('');
    const firstName = ref('');
    const lastName = ref('');
    const policyNumber = ref('');
    const uploadedFileName = ref('');
    const isLoading = ref(false);
    const isLoadingFileInput = ref(false);
    const fileLabel = ref(
      'Drag and drop your file here or click to select a file',
    );

    // Watcher for documentUploaded
    watch(documentUploaded, (newVal) => {
      if (newVal) {
        nextTick(() => {
          if (step2.value) {
            console.log('Scrolling to step 2');
            step2.value.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            console.log('step2 ref is not attached to an element.');
          }
        });
      }
    });
    watch(isLoading, (newVal) => {
      if (newVal) {
        nextTick(() => {
          if (step2.value) {
            console.log('Scrolling to step 2');
            step2.value.scrollIntoView({ behavior: 'smooth' });
          } else {
            console.log('step2 ref is not attached to an element.');
          }
        });
      }
    });
    watch(signatureComplete, (newValue) => {
      // Reacting when signatureComplete is false, which means "complete" in your logic
      if (!newValue) {
        // Notice the "!" making it react to false instead of true
        nextTick(() => {
          successMessage.value &&
            successMessage.value.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
        });
      }
    });

    // Computed property for tailored heading
    const tailoredHeading = computed(() => {
      switch (props.formType) {
        case 'proofofresidency':
          return 'Step 1: Upload Your Proof of Residency Document';
        case 'floodaffirmation':
          return 'Step 1: Upload Your Proof of Flood Insurance';
        default:
          return 'Step 1: Upload Your Document'; // Default text
      }
    });
    // Secondary tailored heading
    const secondaryHeading = computed(() => {
      switch (props.formType) {
        case 'proofofresidency':
          return 'Example of this can be your Homestead Exemption, Voter Registration card, or any other acceptable document listed.';
        case 'floodaffirmation':
          return 'This can be a copy of your flood insurance policy.';
        default:
          return 'Please upload your document to proceed.';
      }
    });

    const handleFileChange = async (event) => {
      isError.value = false;
      errorMessage.value = '';
      const file = event.target.files[0];

      if (!file) {
        isError.value = true;
        errorMessage.value =
          'No file selected. Please select a file to upload.';
        return;
      }

      // Generate a new session ID for this upload
      store.dispatch('generateSessionId');
      const sessionId = store.getters.sessionId;

      fileLabel.value = `File selected: ${file.name}`;
      uploadedFileName.value = file.name;
      isLoadingFileInput.value = true;

      const formData = new FormData();
      formData.append('document', file);
      formData.append('sessionId', sessionId); // You might want to remove this if you're not using it on the server side

      try {
        const response = await axios.post('/api/upload-document', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Session-ID': sessionId,
          },
        });
        documentUploaded.value = true;
        documentId.value = response.data.documentId;
      } catch (error) {
        console.error('Error uploading file:', error);
        isError.value = true;
        errorMessage.value =
          error.response?.data?.message ||
          'Failed to upload document. Please try again.';
      } finally {
        isLoadingFileInput.value = false;
      }
    };

    const signPolicyAffirmation = async () => {
      // Reset error state
      isError.value = false;
      errorMessage.value = '';

      if (!documentUploaded.value) {
        isError.value = true;
        errorMessage.value = 'Please upload the document first.';
        return;
      }

      if (
        !recipientEmail.value ||
        !firstName.value ||
        !lastName.value ||
        !policyNumber.value
      ) {
        isError.value = true;
        errorMessage.value =
          'Please complete all fields before requesting a signing link.';
        return;
      }
      const sessionId = store.getters.sessionId;
      isLoading.value = true;

      try {
        const response = await axios.post(
          '/api/generate-signing-link',
          {
            email: recipientEmail.value,
            firstName: firstName.value,
            lastName: lastName.value,
            policyNumber: policyNumber.value,
            formType: props.formType,
            documentId: documentId.value,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Session-ID': sessionId, // Ensure this line is correctly setting the session ID
            },
          },
        );
        signingLinkRequested.value = true;
        signingUrl.value = response.data.signingUrl;
      } catch (error) {
        console.error('Error fetching signing link:', error);
        isError.value = true;
        errorMessage.value =
          error.response?.data?.message ||
          'Failed to generate signing link. Please try again.';
      } finally {
        isLoading.value = false;
      }
    };

    const cancelAfterSuccessEmit = () => {
      // Redirecting to the /customerservice page
      window.location.href = '/customerservice';
    };

    function handleMessage(event) {
      // Replace with the actual origin of BoldSign iFrame
      if (event.origin !== 'https://app.boldsign.com') {
        return;
      }

      const type = event.data?.type;
      switch (type) {
        case 'document.loaded': // Update with the actual type sent by BoldSign
          console.log('Document view is loaded');
          break;
        case 'document.completed': // Update with the actual type sent by BoldSign
          console.log('Document is completed');
          // Add any additional actions you want to perform when the document is completed
          break;
        case 'document.exception': // Update with the actual type sent by BoldSign
          console.error('Exception during document finalization');
          break;
      }
    }

    // Add the event listener when the component is mounted
    onMounted(() => {
      window.addEventListener('message', handleMessage);
    });

    // Ensure to remove the event listener when the component is unmounted
    onUnmounted(() => {
      window.removeEventListener('message', handleMessage);
    });

    return {
      signingUrl,
      step2,
      step3,
      isError,
      errorMessage,
      closeIframe,
      tailoredHeading,
      secondaryHeading,
      successMessage,
      documentUploaded,
      uploadedFileName,
      documentId,
      fileLabel,
      recipientEmail,
      firstName,
      lastName,
      policyNumber,
      handleMessage,
      isLoading,
      isLoadingFileInput,
      signatureComplete,
      handleFileChange,
      signPolicyAffirmation,
      cancelAfterSuccessEmit,
      signingLinkRequested,
    };
  },
  mounted() {
    if (typeof window.Echo !== 'undefined') {
      Echo.private(`documents`).listen('.DocumentSigned', (e) => {
        console.log('DocumentSigned event received:', e);
        if (e.documentId === this.documentId) {
          this.signatureComplete = true;
          confetti({ particleCount: 100, spread: 70, origin: { y: 0.6 } });
        }
      });
    }
  },
};
</script>

<style scoped>
/* Existing Custom Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.custom-fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.overflow-auto {
  overflow: auto;
}
.custom-progressBar {
  animation: progressBar 2s ease-in-out;
  animation-fill-mode: forwards;

  /* New Scale Animation */
  @keyframes scaleUp {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(1);
    }
  }

  .custom-scale {
    animation: scaleUp 0.3s ease-out;
    display: inline-block;
  }
  @media (max-width: 768px) {
    /* Adjustments for smaller screens */
    /* Add any specific styles for mobile responsiveness here */
  }

  /* Hover Effect for Interactive Elements */
  .custom-hover-scale:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
}
</style>
