<template>
  <div class="flex justify-center items-center">
    <div
      class="card relative rounded-lg shadow-md p-6 border max-w-4xl border-gray-200 hover:shadow-lg transition-shadow flex flex-col justify-between"
    >
      <div>
        <!-- Custom Toggle Switch -->
        <div class="mb-4 flex justify-center">
          <label class="relative inline-flex cursor-pointer items-center">
            <input
              type="checkbox"
              class="sr-only peer"
              :checked="isMakingClaim"
              @change="toggleIsMakingClaim"
            />
            <div
              class="h-6 w-11 rounded-full border border-gray-300 bg-slate-200 after:absolute after:top-1 after:left-1 after:bg-white after:border after:border-gray-300 after:h-4 after:w-4 after:rounded-full after:transition-all peer-checked:bg-blue-300 peer-checked:after:translate-x-full"
            ></div>
          </label>
        </div>
        <h1 class="text-xl font-bold text-center sm:text-2xl">
          {{ mappedChoice }}
        </h1>
        <p class="text-center text-gray-600 mb-5 mt-4">
          Find your carrier below to make a payment or file a claim.
        </p>
        <div class="container px-0 md:px-[5%] mx-auto">
          <div class="w-full bg-white rounded shadow p-4">
            <input
              type="text"
              v-model="search"
              class="px-3 py-2 mb-4 w-full border border-gray-300 rounded"
              placeholder="Search for your carrier..."
            />
            <div class="carriers-list overflow-auto" style="max-height: 400px">
              <div
                v-for="(carrier, index) in filteredCarriers"
                :key="index"
                class="grid grid-cols-1 sm:grid-cols-4 gap-4 items-center border-b py-4"
              >
                <div class="sm:col-span-1 flex justify-center">
                  <img
                    :src="carrier.logo"
                    class="w-[150px] sm:w-[200px] mx-auto py-4"
                    alt="Company Logo"
                  />
                </div>
                <div
                  class="sm:col-span-2 text-xs sm:text-sm ml-4 sm:ml-6 py-4 text-center"
                >
                  <p class="text-black text-lg font-bold">
                    {{ carrier.carrier_name }}
                  </p>
                  <template v-if="isMakingClaim">
                    <p class="text-gray-700">
                      Claims Phone:
                      <a
                        :href="formattedPhoneLink(carrier, 'claims')"
                        :class="phoneClass(carrier, 'claims')"
                      >
                        {{ formattedPhoneDisplay(carrier, 'claims') }}
                      </a>
                    </p>
                  </template>
                  <template v-else>
                    <p class="text-gray-700">
                      Billing Phone:
                      <a
                        :href="formattedPhoneLink(carrier, 'billing')"
                        :class="phoneClass(carrier, 'billing')"
                      >
                        {{ formattedPhoneDisplay(carrier, 'billing') }}
                      </a>
                    </p>
                    <p class="text-gray-700">
                      Overnight Payment Address:
                      <span
                        :class="{
                          'text-red-600': carrier.overnight_mailing_address,
                          'text-gray-500': !carrier.overnight_mailing_address,
                        }"
                      >
                        {{
                          carrier.overnight_mailing_address || 'Not Available'
                        }}
                      </span>
                    </p>
                  </template>
                  <p class="text-gray-700">
                    Website:
                    <a
                      :href="carrier.main_website ? carrier.main_website : '#'"
                      :class="{
                        'text-red-600 underline': carrier.main_website,
                        'text-gray-500': !carrier.main_website,
                      }"
                      target="_blank"
                    >
                      {{ carrier.main_website || 'Not Available' }}
                    </a>
                  </p>
                </div>
                <div class="sm:col-span-1 flex justify-center">
                  <a
                    :href="
                      (isMakingClaim
                        ? carrier.claimlink
                        : carrier.carrier_billing_link) || '#'
                    "
                    target="_blank"
                    :class="[
                      (
                        isMakingClaim
                          ? carrier.claimlink
                          : carrier.carrier_billing_link
                      )
                        ? 'bg-red-600 text-white'
                        : 'bg-gray-400 text-gray-300 cursor-not-allowed',
                      'rounded-lg px-4 py-2 hover:bg-red-700 transition-colors w-full text-center block',
                    ]"
                    :disabled="
                      !(isMakingClaim
                        ? carrier.claimlink
                        : carrier.carrier_billing_link)
                    "
                  >
                    {{ isMakingClaim ? 'Make a Claim' : 'Make a Payment' }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="showDialog"
    :modal="true"
    :closable="false"
    class="copywriting-popup"
  >
    <div class="copywriting-content">
      <p><b>Are you familiar with Private Adjusters (PA)?</b></p>
      <p>
        A PA is a private adjuster that you are hiring for a fee, which is often
        times a percentage of the total settlement. Unfortunately, it’s become
        common practice for PA’s to inflate the claim amount in order to
        increase their fee, which can cause significant delays in the processing
        and closing of the claim. Your insurance company however will promptly
        assign an Adjuster to your claim, at no cost to you.
      </p>
      <p>
        <b>What about an Assignment of Benefits (AOB)?</b>
      </p>
      <p>
        An AOB is a contract that once signed, transfers full control of the
        claim from you the customer. To the contractor - allowing the contractor
        to collect payment directly from your insurance company. This gives the
        contractor the latitude to perform work before the insurance company has
        an opportunity to appraise the damage which could lead to disagreements
        about the settlement amount. If the insurance company determines that
        the claim settlement amount is less than the amount of the contractor’s
        bill, you as the customer could be on the hook to pay the difference out
        of pocket, ultimately forcing the decision whether to do a contractor
        lien.
      </p>
      <p>
        Unfortunately, there are contractors out there that will also exaggerate
        the amount of the damage for a bigger pay day. And this could leave you
        in a very compromised position.
      </p>
      <div class="bottomContent">
        <form @submit.prevent="closeDialog">
          <input
            type="checkbox"
            name="acknowledgement"
            id="acknowledgement"
            v-model="acknowledgementChecked"
          />
          <label for="acknowledgement">
            By checking this box you acknowledge you have read the above
            statements.
          </label>
          <button
            type="submit"
            :disabled="!acknowledgementChecked"
            class="submit-btn"
          >
            Close
          </button>
        </form>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import axios from 'axios';
import InputSwitch from 'primevue/inputswitch';
import Dialog from 'primevue/dialog';

export default {
  name: 'makePaymentMakeClaim',
  props: {
    carrierDirectoryType: String,
  },
  components: {
    InputSwitch,
    Dialog,
  },
  setup(props) {
    const search = ref('');
    const carriers = ref([]);
    const showDialog = ref(false);
    const acknowledgementChecked = ref(false);

    // Convert the prop to a reactive ref
    const isMakingClaim = ref(props.carrierDirectoryType === 'make-claim');
    const toggleIsMakingClaim = () => {
      isMakingClaim.value = !isMakingClaim.value;
      showDialog.value = isMakingClaim.value; // Optionally trigger dialogs or other UI elements
    };

    // Watch for external prop changes to adjust internal state
    watch(
      () => props.carrierDirectoryType,
      (newVal) => {
        isMakingClaim.value = newVal === 'make-claim';
      },
    );

    // React on isMakingClaim changes
    watch(
      isMakingClaim,
      (newVal) => {
        showDialog.value = newVal; // showDialog will be true if 'make-claim' and false otherwise
      },
      { immediate: true },
    );

    const closeDialog = () => {
      if (acknowledgementChecked.value) {
        showDialog.value = false;
        acknowledgementChecked.value = false; // Reset the checkbox
      }
    };

    const mappedChoice = computed(() =>
      isMakingClaim.value ? 'Make a Claim' : 'Make a Payment',
    );

    const fetchCarriers = async () => {
      try {
        const response = await axios.get('/api/public-carriers');
        carriers.value = response.data
          .map((carrier) => ({
            logo: carrier.logo,
            carrier_name: carrier.carrier_name,
            billing_phone_number: carrier.billing_phone_number,
            customer_service_phone: carrier.customer_service_phone,
            overnight_mailing_address: carrier.overnight_mailing_address,
            main_website: carrier.main_website,
            claims_phone_number: carrier.claims_phone_number,
            claimlink: carrier.carrier_customer_service_link,
            carrier_billing_link: carrier.carrier_billing_link,
          }))
          .sort((a, b) => a.carrier_name.localeCompare(b.carrier_name)); // Sort alphabetically by carrier_name
      } catch (error) {
        console.error('Failed to fetch carriers:', error);
      }
    };

    const formatPhoneNumber = (phoneNumber, display = true) => {
      if (!phoneNumber) {
        return display ? 'Not Available' : '';
      }
      const cleaned = phoneNumber.replace(/\D/g, ''); // Remove all non-numeric characters
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return display
          ? `(${match[1]}) ${match[2]}-${match[3]}`
          : `+1${match[1]}${match[2]}${match[3]}`;
      }
      return phoneNumber; // return the original if it doesn't match the pattern
    };

    const getEffectivePhoneNumber = (carrier, type) => {
      let phoneNumber = '';
      if (type === 'claims') {
        phoneNumber =
          carrier.claims_phone_number || carrier.customer_service_phone;
      } else {
        phoneNumber =
          carrier.billing_phone_number || carrier.customer_service_phone;
      }
      return phoneNumber;
    };

    const formattedPhoneLink = (carrier, type) => {
      const phoneNumber = getEffectivePhoneNumber(carrier, type);
      return phoneNumber ? `tel:${formatPhoneNumber(phoneNumber, false)}` : '#';
    };

    const formattedPhoneDisplay = (carrier, type) => {
      const phoneNumber = getEffectivePhoneNumber(carrier, type);
      return phoneNumber ? formatPhoneNumber(phoneNumber) : 'Not Available';
    };

    const phoneClass = (carrier, type) => {
      const phoneNumber = getEffectivePhoneNumber(carrier, type);
      return phoneNumber ? 'text-red-600 underline' : 'text-gray-500';
    };

    onMounted(fetchCarriers);

    const scoreMatch = (input, target) => {
      input = input.toLowerCase();
      target = target.toLowerCase();
      let score = 0;
      let index = 0;
      for (const char of input) {
        index = target.indexOf(char, index);
        if (index !== -1) {
          score++;
          index++;
        }
      }
      return score;
    };

    const filteredCarriers = computed(() => {
      if (!search.value) return carriers.value;
      const carriersWithScores = carriers.value.map((carrier) => ({
        carrier,
        score: scoreMatch(search.value, carrier.carrier_name),
      }));
      carriersWithScores.sort((a, b) => b.score - a.score);
      return carriersWithScores
        .filter((carrier) => carrier.score === carriersWithScores[0].score)
        .map((carrier) => carrier.carrier);
    });

    return {
      search,
      filteredCarriers,
      mappedChoice,
      isMakingClaim,
      toggleIsMakingClaim,
      showDialog,
      closeDialog,
      acknowledgementChecked,
      formatPhoneNumber,
      formattedPhoneLink,
      formattedPhoneDisplay,
      phoneClass,
    };
  },
};
</script>

<style scoped>
.copywriting-popup .copywriting-content {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.6;
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.copywriting-popup .copywriting-content h2 {
  color: #004085;
  margin-bottom: 16px;
}

.copywriting-popup .copywriting-content p {
  margin-bottom: 12px;
}

.copywriting-popup .copywriting-content p b {
  font-weight: bold;
}

.copywriting-popup .copywriting-content .bottomContent {
  text-align: center;
  margin-top: 24px;
}

.copywriting-popup .copywriting-content .bottomContent .submit-btn {
  background-color: #388e3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 12px;
  font-size: 16px;
}

.copywriting-popup .copywriting-content .bottomContent .submit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.copywriting-popup .copywriting-content label {
  cursor: pointer;
  display: block;
  margin-top: 8px;
}

.copywriting-popup .copywriting-content input[type='checkbox'] {
  margin-right: 8px;
}
</style>
