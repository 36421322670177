<template>
  <div
    v-if="selectedUnderwritingGuidelineCategory"
    class="grid grid-cols-1 gap-4"
  >
    <guidelineComponents
      :title="selectedUnderwritingGuidelineCategory"
      :items="selectedItems"
    />
  </div>
  <div
    v-if="selectedUnderwritingGuidelineCategory === 'All'"
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
  >
    <GuidelineSection
      v-for="(section, index) in sections"
      :key="index"
      :title="section.title"
      :items="section.items"
    />
  </div>
</template>

<script>
import GuidelineSection from './guidelineComponents/GuidelineSection.vue';
import guidelineComponents from './guidelineComponents/guidelineComponents.vue';

export default {
  name: 'UnderwritingGuidelines',
  components: {
    GuidelineSection,
    guidelineComponents,
  },
  props: {
    guideline: {
      type: Object,
      required: true,
    },
    selectedUnderwritingGuidelineCategory: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    sections() {
      return [
        {
          title: 'Age of Roof',
          items: this.parseItems(this.guideline.age_of_roof),
        },
        {
          title: 'Electrical',
          items: this.wrapInArray(this.parseItem(this.guideline.electrical)),
        },
        {
          title: 'Four Point Inspection',
          items: this.wrapInArray(this.parseItem(this.guideline.four_point)),
        },
        {
          title: 'Age of Home',
          items: this.wrapInArray(this.parseItem(this.guideline.age_of_home)),
        },
        {
          title: 'Acceptable Named Insureds',
          items: this.wrapInArray(
            this.parseItem(this.guideline.acceptable_named_insureds),
          ),
        },
        {
          title: 'Assumed Wind Mitigation Credits',
          items: this.wrapInArray(
            this.parseItem(this.guideline.assumed_wind_mit_credits),
          ),
        },
        {
          title: 'Claims History',
          items: this.wrapInArray(
            this.parseItem(this.guideline.claims_history),
          ),
        },
        {
          title: 'Pools',
          items: this.wrapInArray(this.parseItem(this.guideline.pools)),
        },
        {
          title: 'Dwelling Limits',
          items: this.wrapInArray(
            this.parseItem(this.guideline.dwelling_limits),
          ),
        },
        {
          title: 'Flat Roof',
          items: this.wrapInArray({ value: this.guideline.flat_roof }),
        },
        {
          title: 'PEX Plumbing',
          items: this.wrapInArray({ value: this.guideline.pex_plumbing }),
        },
        {
          title: 'Opening Protection Credits',
          items: this.wrapInArray(
            this.parseItem(this.guideline.opening_protection_credits),
          ),
        },
        {
          title: 'Proof of Repairs',
          items: this.wrapInArray(
            this.parseItem(this.guideline.proof_of_repairs),
          ),
        },
        {
          title: 'Last User Update',
          items: this.wrapInArray({
            value: this.guideline.username_of_last_user_update,
          }),
        },
        {
          title: 'Underwriter Name',
          items: this.wrapInArray({ value: this.guideline.underwriter_name }),
        },
        {
          title: 'Underwriter Confirmation Date',
          items: this.wrapInArray({
            value: this.guideline.underwriter_confirmation_date,
          }),
        },
        {
          title: 'Fields Confirmed by Underwriter',
          items: this.wrapInArray(
            this.parseItem(this.guideline.fields_confirmed_by_underwriter),
          ),
        },
        {
          title: 'Created At',
          items: this.wrapInArray({ value: this.guideline.created_at }),
        },
        {
          title: 'Updated At',
          items: this.wrapInArray({ value: this.guideline.updated_at }),
        },
        {
          title: 'Product Guideline Applies To',
          items: this.wrapInArray(
            this.parseItem(this.guideline.product_guideline_applies_to),
          ),
        },
      ];
    },
    selectedItems() {
      const selectedSection = this.sections.find(
        (section) =>
          section.title === this.selectedUnderwritingGuidelineCategory,
      );
      return selectedSection ? selectedSection.items : [];
    },
  },
  methods: {
    parseItems(items) {
      try {
        return Array.isArray(items) ? items : items ? JSON.parse(items) : [];
      } catch (e) {
        console.error('Failed to parse items:', e);
        return [];
      }
    },
    parseItem(item) {
      try {
        return item ? (typeof item === 'string' ? JSON.parse(item) : item) : {};
      } catch (e) {
        console.error('Failed to parse item:', e);
        return {};
      }
    },
    wrapInArray(item) {
      return Array.isArray(item) ? item : item ? [item] : [];
    },
  },
};
</script>

<style scoped>
.grid {
  gap: 1rem;
}
</style>
