<template>
  <section class="mt-10 md:mt-0 w-full px-4 lg:px-0">
    <div class="grid justify-center w-full">
      <div class="relative z-10 grid w-full text-center mx-auto"></div>
      <!-- Flex container for images and product name -->
      <div
        class="flex flex-row items-center justify-center md:justify-between gap-6"
      >
        <!-- Left Image -->
        <img
          :src="getImagePath('left')"
          class="w-24 md:w-1/4 lg:w-1/3 mx-auto hidden md:block"
          @error="onImageError($event, 'left')"
        />

        <!-- CTA -->
        <div class="flex flex-col items-center">
          <h2
            class="text-center mb-4 md:mb-8 text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800 whitespace-nowrap"
          >
            {{ formattedProductType }}
          </h2>

          <Button
            label="GET A QUOTE"
            class="custom-quote-button p-button-outlined"
            @click="navigateToChooseProducts"
          ></Button>
        </div>

        <!-- Right Image -->
        <img
          :src="getImagePath('right')"
          class="w-24 md:w-1/4 lg:w-1/3 mx-auto hidden md:block"
          @error="onImageError($event, 'right')"
        />
      </div>
    </div>
  </section>

  <div class="my-10 md:my-64 w-full justify-center block md:hidden">
    <img
      :src="getImagePath('mobile')"
      class="opacity-90 mx-auto"
      @error="onImageError($event, 'mobile')"
    />
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  components: {
    Button,
  },
  props: {
    productType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imageExtensions: {
        left: 'svg', // Default extension
        right: 'svg',
        mobile: 'svg',
      },
    };
  },
  computed: {
    formattedProductType() {
      const mapping = {
        homeinsurance: 'Home Insurance',
        autoinsurance: 'Auto Insurance',
        floodinsurance: 'Flood Insurance',
        petinsurance: 'Pet Insurance',
        lifeinsurance: 'Life Insurance',
        medicare: 'Medicare',
        commercialinsurance: 'Commercial Insurance',
        umbrellainsurance: 'Umbrella Insurance',
        businessinsurance: 'Business Insurance',
      };
      return mapping[this.productType] || this.productType;
    },
  },
  methods: {
    getImagePath(position) {
      return `/images/productHeroBanners/${this.productType}/${this.productType}HeroBanner${position.charAt(0).toUpperCase() + position.slice(1)}.${this.imageExtensions[position]}`;
    },
    onImageError(event, position) {
      if (this.imageExtensions[position] === 'png') {
        this.imageExtensions[position] = 'svg'; // Switch to SVG if PNG fails to load
        event.target.src = this.getImagePath(position);
      }
    },
    navigateToChooseProducts() {
      this.$router.push({
        path: `/quotes/${this.productType}`,
        query: { productType: this.formattedProductType },
      });
    },
  },
};
</script>

<style scoped>
.custom-quote-button {
  background-color: #ef4444;
  border-color: #ef4444; /* Tailwind red-500 */
  color: white; /* Tailwind red-500 */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 0.75rem 1.5rem; /* Increased padding */
  border-radius: 0.25rem; /* Less rounding */
  transition: all 0.3s ease;
}

.custom-quote-button:hover {
  background-color: transparent; /* Tailwind red-500 */
  border-color: #ef4444;
  color: #ef4444;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
