<template>
  <div class="flex flex-col items-center justify-center p-8">
    <h2 class="text-2xl font-semibold mb-4">Design Your Email Template</h2>
    <!-- Template Name Input -->
    <input
      v-model="templateName"
      placeholder="Template Name"
      class="mb-2 p-2 border border-gray-300 rounded"
    />
    <!-- Editor Component for Template Content -->
    <Editor v-model="templateContent" style="height: 300px" />
    <!-- Save Template Button -->
    <Button
      label="Save Template"
      icon="pi pi-check"
      class="mt-4"
      :disabled="isLoading || !isValid"
      @click="saveTemplate"
    />
    <!-- Success and Error Messages -->
    <div
      v-if="message.content"
      :class="{
        'text-green-500': message.type === 'success',
        'text-red-500': message.type === 'error',
      }"
      class="mt-2"
    >
      {{ message.content }}
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import axios from 'axios';

const templateName = ref('');
const templateContent = ref('<p>Start designing your email template...</p>');
const isLoading = ref(false);
const message = ref({ content: '', type: '' });

// Basic client-side validation
const isValid = computed(
  () =>
    templateName.value.trim().length > 0 &&
    templateContent.value.trim().length > 15,
);

const saveTemplate = async () => {
  if (!isValid.value) return; // Additional safety check

  isLoading.value = true;
  message.value = { content: '', type: '' }; // Reset message

  try {
    await axios.post('/save-template', {
      name: templateName.value,
      content: templateContent.value,
    });
    // Handle success
    message.value = { content: 'Template saved successfully', type: 'success' };
    templateName.value = ''; // Optionally reset the form
    templateContent.value = '<p></p>'; // Optionally reset the form
  } catch (error) {
    // Handle error
    message.value = {
      content: 'Error saving template: ' + error.message,
      type: 'error',
    };
  } finally {
    isLoading.value = false;
  }
};

// Placeholder for autosave feature
// Consider using a debounce function to limit API calls
const autosave = () => {
  // Implement autosave logic here
};
</script>

<style scoped>
/* Your existing styles */
</style>
