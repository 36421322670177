<template>
  <transition name="fade">
    <div
      v-if="visible"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div class="bg-white rounded-lg p-6 shadow-lg w-full max-w-md mx-4">
        <h2 class="text-xl font-semibold mb-4">Are you sure?</h2>
        <p class="mb-4">
          Are you sure you want to delete this carrier? Doing this will be
          permanent.
        </p>
        <div class="flex justify-end">
          <button
            @click="cancel"
            class="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            Cancel
          </button>
          <button
            @click="confirm"
            class="px-4 py-2 bg-red-600 text-white rounded-md"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, defineComponent } from 'vue';

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['confirm', 'cancel'],
  setup(props, { emit }) {
    const confirm = () => {
      emit('confirm');
    };

    const cancel = () => {
      emit('cancel');
    };

    return { confirm, cancel };
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
