<template>
  <div>
    <AppHeaderDesktop />
    <products />
    <AppFooter />
  </div>
</template>

<script>
import AppHeaderDesktop from '../components/AppHeaderDesktop.vue';
import products from '../components/products.vue';
import AppFooter from '../components/AppFooter.vue';

export default {
  name: 'chooseProducts',
  components: {
    AppHeaderDesktop,
    products,
    AppFooter,
  },
};
</script>

<style></style>
