<template>
  <section class="max-w-screen-xl mx-auto">
    <div class="container">
      <div
        class="relative md:pt-0 sm:mt-[20%] xs:mt-[20%] md:mt-20 xs:z-10 sm:z-10 md:z-10"
      >
        <div class="text-center relative z-20">
          <h1
            class="xs:mx-[10%] text-[#525355] px-4 text-2xl leading-[1.5] marker:font-extrabold font-merriweather font-[800] lg:text-[35px] md:text-[30px] md:leading-[46px]"
          >
            Insurance Simplified. <br />
            #1 Insurtech Agency In The USA
          </h1>
          <p
            class="xs:mx-[15%] text-[#525355] font-lato md:py-[39px] py-[20px]"
          >
            Simplifying the way you shop for insurance
          </p>
          <div class="button-container">
            <router-link to="/chooseproducts" class="quote-button"
              >GET A QUOTE</router-link
            >
          </div>
        </div>
      </div>
      <div class="flex items-baseline justify-between mt-[200px]">
        <!-- Lottie Animation as Background -->

        <div class="w-4/12 hidden md:block absolute left-0 z-10">
          <img
            class="w-full absolute bottom-0"
            src="@/assets/images/ieChristmasHeroBannerLeft.svg"
          />
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 absolute right-0 z-10">
          <img
            class="w-full absolute bottom-0"
            src="@/assets/images/ieChristmasHeroBannerRight.svg"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  methods: {
    emitQuoteClick() {
      this.$emit('quote-clicked');
    },
  },
};
</script>

<style scoped>
.lottie-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place it behind other content */
}
/* Flex container for overall layout */
.container {
  /* Add styling for your container here */
}

/* Text centering and positioning */
.text-center {
  position: relative;
  z-index: 20;
  text-align: center;
}

/* Container for the button to ensure center alignment */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Adjust spacing as needed */
}

/* Button styles - Adjust as per your original design */
.quote-button {
  display: inline-block;
  padding: 20px 45px; /* px-[45px] py-[20px] in Tailwind */
  background-color: #e12f2f; /* bg-[#E12F2F] in Tailwind */
  color: white; /* text-white in Tailwind */
  border-radius: 5px; /* rounded in Tailwind */
  text-decoration: none;
  font-size: 1rem; /* text-sm in Tailwind */
  font-family: 'Roboto', sans-serif; /* font-roboto in Tailwind */
  box-shadow: -1px 7px 10px 0px rgba(209, 2, 2, 0.5); /* Tailwind equivalent */
  transition:
    background-color 0.5s,
    transform 0.5s; /* transition-all duration-500 in Tailwind */
}

/* Button hover effect */
.quote-button:hover {
  background-color: #c00202; /* hover:bg-[#C00202] in Tailwind */
  transform: scale(0.9); /* hover:scale-90 in Tailwind */
}

/* Ensure images fill their containers */
img {
  width: 100%;
  height: auto;
}
</style>
