<template>
  <div class="form-group mb-4">
    <label for="dwelling_limits" class="block text-sm font-medium text-gray-700"
      >Dwelling Limits:</label
    >
    <input
      v-bind:value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      type="text"
      id="dwelling_limits"
      class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    />
  </div>
</template>

<script>
export default {
  name: 'DwellingLimitsInput',
  props: {
    modelValue: String,
  },
};
</script>

<style scoped>
/* Add any specific styles for this component here */
</style>
