<template>
  <div class="h-10 bg-gray-100"></div>
  <div
    class="max-w-[1440px] mx-auto font-Roboto text-[#646778] xl:mt-[50px] mt-[50px]"
  >
    <div class="md:flex justify-between">
      <div class="left-side md:w-[30%] relative">
        <div
          class="sticky top-[30px] xl:w-[300px] md:h-[1050px] lg:w-[288px] md:w-[230px] px-[20px] mt-[50px] hidden md:block"
        >
          <div class="progress-bar">
            <ul>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#home1"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Auto Insurance Overview</a
                >
              </li>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#home2"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Auto Insurance Average Costs</a
                >
              </li>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#home3"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Why Get Auto Insurance?</a
                >
              </li>
              <li class="after:hidden hover:text-[#D24948] duration-300">
                <a
                  href="#home4"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Popular Auto Insurance Companies</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right-side px-[20px] md:w-[70%]">
        <div>
          <div class="flex text-[11px] items-center text-[#000105] uppercase">
            <span class="pr-[20px] hover:text-[#D24948] duration-300"
              >Insurance</span
            >
            <span class="pr-[20px]">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25L15.75 11.25C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75L8.25 12.75C7.83579 12.75 7.5 12.4142 7.5 12Z"
                  fill="#000105"
                />
              </svg>
            </span>
            <span class="hover:text-[#D24948] duration-300"
              >Auto Insurance</span
            >
          </div>
          <div id="home1">
            <h2
              class="xl:text-[35px] lg:text-[25px] text-[20px] pt-[10px] font-Merriweather font-black tracking-[-0.2px] pb-[20px] text-[#1F2937]"
            >
              Estimating Auto Insurance Costs
            </h2>
            <p class="text-[15px] my-[15px] leading-[27px]">
              Car insurance is something most people are familiar with,
              especially considering the staggering number of licensed drivers
              in the United States, which reached 228 million in 2022. However,
              it can be confusing why insurance companies charge such wildly
              different prices for coverage. To help you get a handle on
              estimating your car insurance costs and finding the best coverage
              at an affordable rate, we've put together some useful information.
            </p>
          </div>

          <div class="">
            <div class="bg-[#EDEDED] lg:p-6 p-4 rounded-md">
              <h2
                class="xl:text-[20px] lg:text-[15px] text-[15px] pt-[10px] font-roboto font-bold tracking-[-0.2px] pb-[20px] text-[#1F2937]"
              >
                How to get the best rate
              </h2>
              <ul
                class="list-disc lg:ml-12 ml-8 text-red-600 text-3xl leading-7"
              >
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >Make sure to get quotes from many different auto insurance
                    companies- (There are 49 just in the state of Florida!) This
                    is the most important way to save money on your auto
                    insurance.</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >All of these companies will all have different appetites
                    for what kind of driver they are looking to insure- which
                    will effect your rate.</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >Make sure you get the right coverage, too little or too
                    much can cause you to severely over-pay -(See how much
                    coverage do you need?)</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >There are 4 major areas of evaluation that car insurance
                    companies look for when quoting for auto insurance- (check
                    your stats)</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >Bundle your policies and check for discounts- get multiple
                    policies and check for common discounts for Pay in Full,
                    Defensive Driving Class, Good Student GPA 3.0 or higher,
                    AARP, Homeowners Discount, Paperless Billing and more!</span
                  >
                </li>
              </ul>
            </div>
            <hr class="w-full mt-6 h-[3px] bg-gray-300" />
          </div>

          <section class="hidden md:block">
            <div>
              <h2
                class="text-[25px] pt-[10px] font-Merriweather font-black pb-[20px] text-[#1F2937]"
              >
                Determining the Right Car Insurance Coverage
              </h2>
              <p class="text-[15px] my-[15px] leading-[27px]">
                When it comes to car insurance, a common question is: "How much
                coverage do I need?" The answer varies depending on individual
                circumstances, but here are a few factors to consider.
              </p>
            </div>

            <div class="grid lg:grid-cols-2 sm:grid-cols-2 gap-2 mb-10">
              <div class="col">
                <p class="text-[15px] my-[15px] leading-[27px]">
                  A typical auto insurance policy usually includes six coverage
                  types:
                </p>
                <ul class="list-disc ml-12 text-red-600 text-3xl leading-7">
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Auto Liability Coverage</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Uninsured and Underinsured Motorist Coverage</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Comprehensive Coverage</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Collision Coverage</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Medical Payments Coverage</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Personal Injury Protection</span
                    >
                  </li>
                </ul>
              </div>
              <div class="col my-auto mx-auto">
                <img src="@/assets/images/productPageImages/book.png" alt="" />
              </div>
            </div>

            <div>
              <p class="text-[15px] my-[15px] font-bold leading-[27px]">
                Auto Liability Coverage
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                In an accident, your auto insurance protects you up to your
                policy limit for actions you are liable for. This includes
                Bodily Injury Coverage and Property Damage Liability.
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                Bodily Injury Coverage provides coverage up to your limit when
                someone gets injured in an accident where you are at fault.
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                For example, if you have $100,000 in Bodily Injury Coverage and
                cause an accident resulting in someone getting hurt, the
                insurance company will pay up to $100,000 to protect your
                personal liability. Anything beyond that limit leaves you
                unprotected.
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                On average, non-fatal accidents in the United States result in
                approximately $78,900 worth of medical expenses*, which could
                lead to a lawsuit if you are at fault for causing the accident.
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                *According to the National Safety Council.
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                Property Damage Liability provides coverage up to your limit for
                damaging someone else's property in an accident where you are at
                fault.
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                For instance, if you crash into another person's car and you are
                responsible for the accident, this coverage will protect you
                financially for the damage caused.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px]">
                Uninsured and Underinsured Motorist Coverage
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                This coverage is designed to financially protect you in case of
                an accident with an uninsured driver or a driver with
                insufficient coverage (underinsured).
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                Currently, approximately 13% of drivers in the United States
                operate vehicles without any insurance, and 1 out of 7 drivers
                are underinsured. This makes it important to have this type of
                coverage.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px]">
                Comprehensive Coverage
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                Comprehensive coverage protects your vehicle from damages caused
                by incidents other than collisions.
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                Common reasons for claims under this coverage include fire,
                theft, windstorm, hail, vandalism, flood, falling objects, or
                collisions with animals on the road.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px]">
                Collision Coverage
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                Collision coverage applies to your own vehicle if it is damaged
                in a collision-type accident, such as collisions with other
                vehicles, telephone poles, or trees.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px]">
                Medical Payments Coverage
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                Medical payments coverage is intended to cover medical expenses
                or funeral services resulting from bodily injuries in an
                accident, regardless of fault. Typically, this coverage offers a
                smaller amount of coverage.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px]">
                Personal Injury Protection
              </p>
              <p class="text-[15px] my-[15px] leading-[27px]">
                Personal injury protection coverage covers 80% of medical
                expenses, including work loss and death benefits. It provides
                additional support in case of injuries resulting from an
                accident.
              </p>
            </div>
          </section>
          <section>
            <div id="home2">
              <h2
                class="xl:text-[35px] lg:text-[25px] text-[25px] font-Merriweather font-black tracking-[-0.2px] py-[20px] text-[#1F2937]"
              >
                Main Price Impacting Factors
              </h2>
              <p class="text-[15px] my-[15px] leading-[27px]">
                When comparing rates and coverages for auto insurance, it is
                important to keep in mind data points that Insurance Companies
                find most important when evaluating your rate.
              </p>
            </div>
            <div class="grid grid-cols-4 p-4">
              <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
                <img src="@/assets/images/productPageImages/multi.png" alt="" />
              </div>
              <div class="col-span-3 p-4 md:p-0">
                <h3
                  class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
                >
                  Multi-policy discount
                </h3>
                <p
                  class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
                >
                  It goes without saying, Insurance Companies like when you
                  purchase more than one policy with them. Consider this when
                  you are getting one policy, that they may give a deeper
                  discount by adding another.
                </p>
              </div>
            </div>
            <div class="grid grid-cols-4 p-4">
              <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
                <img
                  src="@/assets/images/productPageImages/credit.png"
                  alt=""
                />
              </div>
              <div class="col-span-3 p-4 md:p-0">
                <h3
                  class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
                >
                  Credit Score
                </h3>
                <p
                  class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
                >
                  Your personal credit score is a impacting factor when
                  determining auto insurance rates. Insurance Companies
                  anticipate a person with a higher credit score to be more
                  attentive and preventative when evaluating risk. So you can
                  expect to receive lower rates for a high score, and higher
                  rates for a lower score.
                </p>
              </div>
            </div>

            <div class="grid grid-cols-4 p-4">
              <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
                <img
                  src="@/assets/images/productPageImages/marital.png"
                  alt=""
                />
              </div>
              <div class="col-span-3 p-4 md:p-0">
                <h3
                  class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
                >
                  Marital Status
                </h3>
                <p
                  class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
                >
                  Single vs Married, Your marital status can surprisingly effect
                  your insurance rates! There is a more broad category for
                  interpretation on how this effects your risk rating but it is
                  generally understood that if you are married this produces
                  better rates on your home insurance.
                </p>
              </div>
            </div>

            <div class="grid grid-cols-4 p-4">
              <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
                <img src="@/assets/images/productPageImages/age.png" alt="" />
              </div>
              <div class="col-span-3 p-4 md:p-0">
                <h3
                  class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
                >
                  Age
                </h3>
                <p
                  class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
                >
                  Ever heard of the phrase "Age considers; Youth Ventures"? Auto
                  Insurance companies know, just like us, that generally people
                  who are older are more cautionary than their youthful
                  counterparts. This results in discounts for older age
                  brackets, and higher rates for younger age brackets.
                </p>
              </div>
            </div>
          </section>
        </div>
        <div
          class="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 pb-[30px] mt-[20px]"
          id="home4"
        >
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/homeInsurance.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-black md:py-[15px] py-2"
                >
                  Home Insurance
                </h3>
                <div>
                  <router-link
                    to="/products/homeinsurance"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/floodInsurance.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-black md:py-[15px] py-2"
                >
                  Flood Insurance
                </h3>
                <div>
                  <router-link
                    to="/products/floodinsurance"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/medicare.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-black md:py-[15px] py-2"
                >
                  Medicare
                </h3>
                <div>
                  <router-link
                    to="/products/homeinsurance"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border-t pt-[50px] pb-24 md:mx-[-20px]">
          <div class="md:mx-[20px] bg-[#EDEDED] rounded-[5px]">
            <div
              class="bg-[url('@/assets/images/productPageImages/watch-bg.png')] lg:bg-contain bg-[length:395px] bg-no-repeat bg-right-bottom"
            >
              <div class="xl:p-[30px] lg:p-[20px] p-[20px]">
                <h2
                  class="xl:text-[25px] lg:text-[21px] text-[21px] font-extrabold text-[#1F2937] mb-[20px]"
                >
                  WATCH YOUR SAVINGS ADD UP.
                </h2>
                <p
                  class="text-[15px] py-[15px] leading-[27px] 2xl:max-w-[495px] xl:max-w-[415px] lg:max-w-[301px] md:max-w-[405px] sm:max-w-[500px]"
                >
                  Always check to see if you can save money by adding an
                  additional product to your home insurance policy! (Auto, Life,
                  Flood, etc.)
                </p>
                <div
                  class="mt-[20px] lg:pb-0 md:pb-[150px] sm:pb-[230px] pb-[200px] bg-padding"
                >
                  <a
                    href="/chooseproducts"
                    class="bg-[#DB0909] hover:bg-[#DC2626] duration-500 lg:max-w-[220px] max-w-[200px] w-full flex items-center rounded-[5px] uppercase text-white text-center tracking-[2px] text-[14px] px-[20px] py-[10px] lg:px-[40px] lg:py-[15px]"
                    >Get a quote
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      class="ml-[5px]"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
                        fill="#ffffff"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped></style>
