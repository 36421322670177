<template>
  <div class="flex flex-col md:flex-row max-h-screen bg-gray-100">
    <!-- Navigation Sidebar -->
    <Navigation />

    <!-- Primary content container to use the available space -->
    <div class="flex-grow flex flex-col p-5 overflow-y-scroll">
      <!-- Scrollable content section -->
      <div
        v-if="!showNoLossForms && !showCancellationForms"
        class="px-4 py-10 w-full overflow-y-auto"
        style="max-height: calc(100vh - 128px)"
      >
        <div class="flex justify-center items-center mb-10">
          <h1 class="text-2xl md:text-4xl font-bold text-ietextlight">
            Accord Forms
          </h1>
          <Icon
            icon="line-md:email-twotone"
            class="ml-4 text-6xl text-ietextlight"
          ></Icon>
        </div>
        <div class="space-y-6 mx-auto w-full md:w-[50%]">
          <!-- Buttons for Forms -->
          <button
            @click="showNoLossForms = true"
            class="flex items-center justify-center space-x-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg w-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <Icon icon="mdi:file-document-outline"></Icon>
            <span class="text-lg">No Loss Form</span>
          </button>
          <button
            @click="showCancellationForms = true"
            class="flex items-center justify-center space-x-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg w-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <Icon icon="mdi:cancel"></Icon>
            <span class="text-lg">Cancellation Form</span>
          </button>
        </div>
      </div>
      <!-- Dynamic content based on form selection -->
      <div v-if="showNoLossForms" class="mt-4">
        <NoLossForms @closeForm="resetView" />
      </div>
      <div v-if="showCancellationForms" class="mt-4">
        <CancellationForms @closeForm="resetView" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import Navigation from '../components/Navigation.vue';
import NoLossForms from '../accordForms/noLossForms.vue';
import CancellationForms from '../accordForms/cancellationForms.vue';

export default {
  name: 'options',
  components: {
    Icon,
    Navigation,
    NoLossForms,
    CancellationForms,
  },
  data() {
    return {
      showNoLossForms: false,
      showCancellationForms: false,
    };
  },
  methods: {
    resetView() {
      this.showNoLossForms = false;
      this.showCancellationForms = false;
    },
    toggleNoLossForms() {
      this.showNoLossForms = true;
      this.showCancellationForms = false; // Ensure that only one form is shown at a time
    },
    toggleCancellationForms() {
      this.showCancellationForms = true;
      this.showNoLossForms = false; // Ensure that only one form is shown at a time
    },
  },
};
</script>
