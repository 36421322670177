<template>
  <div class="max-w-lg mx-auto p-6 bg-white shadow rounded-lg">
    <!-- Display Policies for Selection -->
    <div
      v-if="policies && policies.length"
      class="space-y-4 overflow-auto"
      style="max-height: 320px"
    >
      <h2 class="text-xl font-semibold text-center text-blue-600">
        Update Your Auto Insurance Policy
      </h2>
      <p class="text-md text-gray-700 mb-4">
        Found
        <span class="font-semibold text-blue-500">{{ policies.length }}</span>
        policies associated with your profile. Select one to autofill your
        details and speed up the submission process.
      </p>
      <ul class="space-y-4">
        <li
          v-for="policy in policies"
          :key="policy.policy_id"
          @click="selectPolicy(policy)"
          :class="{
            'bg-blue-50':
              selectedPolicy && policy.policy_id === selectedPolicy.policy_id,
            'cursor-pointer hover:bg-gray-100': true,
          }"
          class="p-4 rounded-lg shadow"
        >
          <div class="flex items-center justify-between mb-4">
            <div>
              <p class="font-semibold">{{ policy.writing_company_name }}</p>
              <p class="text-sm text-gray-500">
                Policy Number: {{ policy.policy_number }}
              </p>
            </div>
            <p class="bg-blue-100 text-blue-800 text-sm px-3 py-1 rounded-full">
              {{ policy.policy_type }}
            </p>
          </div>
          <div class="text-sm">
            <p><strong>Start Date:</strong> {{ policy.start_date }}</p>
            <p><strong>End Date:</strong> {{ policy.end_date }}</p>
          </div>
        </li>
      </ul>
    </div>
    <!-- Buttons -->
    <div class="mt-4 flex justify-between">
      <button
        @click="submitSelectedPolicy"
        class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="button"
      >
        Submit Selected Policy
      </button>
      <button
        @click="noneOfThese"
        class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="button"
      >
        None of These
      </button>
    </div>
    <!-- Loading and No Results Messages -->
    <div v-if="isLoading" class="flex justify-center">
      <Icon
        icon="svg-spinners:3-dots-bounce"
        class="animate-bounce"
        style="font-size: 24px; color: gray"
      />
    </div>
    <div v-else-if="policies && !policies.length" class="mt-4 text-center">
      <p class="text-lg text-red-500">No associated auto policies found.</p>
      <p class="text-md text-gray-700">
        Please ensure your details are correct or contact support for
        assistance.
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from 'vue';
import { Icon } from '@iconify/vue';

export default defineComponent({
  name: 'PolicySelection',
  components: {
    Icon,
  },
  props: {
    searchResult: Object,
    isLoading: Boolean,
  },
  data() {
    console.log('Initial searchResult prop:', this.searchResult); // Log initial prop

    return {
      policies: this.searchResult ? this.searchResult.policyInfo : [],
      selectedPolicy: null,
    };
  },
  methods: {
    selectPolicy(policy) {
      this.selectedPolicy = policy; // Set the selected policy
    },
    submitSelectedPolicy() {
      if (this.selectedPolicy) {
        this.$emit('submit-policy', this.selectedPolicy); // Emit the selected policy
      } else {
        alert('Please select a policy first.'); // Alert if no policy is selected
      }
    },
    noneOfThese() {
      this.$emit('none-selected'); // Emit an event for 'none of these'
    },
    getCompanyLogo(companyName) {
      // Implement a method to map company names to logo URLs
    },
    getPolicyIcon(policyType) {
      // Implement a method to map policy types to icons
    },
  },
  watch: {
    searchResult(newVal, oldVal) {
      console.log('searchResult changed:', oldVal, '=>', newVal); // Log the change
      this.policies = newVal.policyInfo; // Update policies
      console.log('Updated policies:', this.policies); // Log the updated policies
    },
  },
  onMounted() {
    console.log('Component mounted with searchResult:', this.searchResult); // Log the prop value when the component mounts
    console.log('Component mounted with isLoading:', this.isLoading); // Log the prop value when the component mounts
  },
});
</script>

<style scoped>
/* Your CSS styles */
</style>
