<template>
  <div
    v-if="!isPolicyDataFetched"
    class="space-y-6 max-w-lg mx-auto bg-white p-6"
  >
    <h2 class="text-xl font-semibold text-center text-ietexth1">
      Contact Information
    </h2>
    <!-- First Name -->
    <div>
      <label for="firstName" class="block text-gray-700 font-medium"
        >First Name</label
      >
      <input
        type="text"
        id="firstName"
        v-model="formData.firstName"
        class="capitalize shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="John"
      />
      <span class="text-red-500 text-xs">{{ validationErrors.firstName }}</span>
    </div>
    <!-- Last Name -->
    <div>
      <label for="lastName" class="block text-gray-700 font-medium"
        >Last Name</label
      >
      <input
        type="text"
        id="lastName"
        v-model="formData.lastName"
        class="capitalize shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="Doe"
      />
      <span class="text-red-500 text-xs">{{ validationErrors.lastName }}</span>
    </div>
    <!-- Email -->
    <div>
      <label for="email" class="block text-gray-700 font-medium">Email</label>
      <input
        type="email"
        id="email"
        v-model="formData.email"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="john.doe@example.com"
      />
      <span class="text-red-500 text-xs">{{ validationErrors.email }}</span>
    </div>
    <!-- Phone -->
    <div>
      <label for="phone" class="block text-gray-700 font-medium">Phone</label>
      <input
        type="tel"
        id="phone"
        v-model="formData.phone"
        v-mask="'(###) ###-####'"
        maxlength="14"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="(555) 555-5555"
      />
      <span class="text-red-500 text-xs">{{ validationErrors.phone }}</span>
    </div>
    <div>
      <!-- Garaging Address with Autofill and badge -->
      <div class="flex justify-between items-center">
        <label for="autocomplete-input" class="block text-gray-700 font-medium"
          >Garaging Address</label
        >
        <span
          class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800"
          >Auto Complete</span
        >
      </div>
      <input
        v-if="!manualEntry"
        type="text"
        id="autocomplete-input"
        v-model="formData.address"
        @focus="showAutocomplete = true"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="Start typing address"
      />
    </div>
    <span class="text-red-500 text-xs">{{ validationErrors.address }}</span>
    <!-- Toggle for manual address entry -->
    <button @click="toggleManualEntry" class="text-blue-500 text-sm mt-2">
      {{ manualEntry ? 'Use Auto Complete' : 'Or enter address manually' }}
    </button>
    <!-- Manual Address Entry Fields, shown if manualEntry is true -->
    <div v-if="manualEntry" class="mt-2 shadow-sm rounded-lg">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <!-- Street -->
        <div class="flex items-center border-b border-gray-300 py-2">
          <icon
            icon="fa-solid:road"
            class="text-gray-500 mr-3"
            width="24"
            height="24"
          ></icon>
          <input
            type="text"
            v-model="manualAddress.street"
            placeholder="Street"
            class="w-full border-0 focus:ring-0"
          />
        </div>
        <!-- City -->
        <div class="flex items-center border-b border-gray-300 py-2">
          <icon
            icon="fa-solid:city"
            class="text-gray-500 mr-3"
            width="24"
            height="24"
          ></icon>
          <input
            type="text"
            v-model="manualAddress.city"
            placeholder="City"
            class="w-full border-0 focus:ring-0"
          />
        </div>
        <!-- State -->
        <div class="flex items-center border-b border-gray-300 py-2">
          <icon
            icon="fa-solid:map-marker-alt"
            class="text-gray-500 mr-3"
            width="24"
            height="24"
          ></icon>
          <input
            type="text"
            v-model="manualAddress.state"
            placeholder="State"
            class="w-full border-0 focus:ring-0"
          />
        </div>
        <!-- Zip Code -->
        <div class="flex items-center border-b border-gray-300 py-2">
          <icon
            icon="fa-solid:envelope"
            class="text-gray-500 mr-3"
            width="24"
            height="24"
          ></icon>
          <input
            type="text"
            v-model="manualAddress.zip"
            placeholder="Zip Code"
            class="w-full border-0 focus:ring-0"
          />
        </div>
      </div>
    </div>
    <!-- Navigation Buttons -->
    <div class="flex justify-between">
      <button
        @click="validateAndProceed"
        class="mx-auto mt-3 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition ease-in-out duration-150"
      >
        Next
      </button>
    </div>
  </div>
  <PolicySelection
    v-if="isPolicyDataFetched"
    :searchResult="searchResult"
    :isLoading="isLoading"
    @submit-policy="handlePolicySubmitted"
    @none-selected="handleNoneSelected"
  />
  <div v-if="isLoading" class="loader-container" ref="loaderRef">
    <div id="dbox">
      <div id="d1"></div>
      <div id="d2"></div>
      <div id="d3"></div>
      <div id="d4"></div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  toRefs,
  onMounted,
  ref,
  nextTick,
  reactive,
  watch,
} from 'vue';
import axios from 'axios';
import PolicySelection from './PolicySelection.vue';
import { gsap } from 'gsap';
import { Icon } from '@iconify/vue';

export default defineComponent({
  name: 'ContactInformationStep',
  components: {
    PolicySelection,
    Icon,
  },
  emits: ['updateMasterObject', 'updateStep', 'updateFormData', 'submitForm'],

  props: {
    formData: Object,
    currentStep: Number,
    loading: Boolean,
  },
  setup(props, { emit }) {
    const state = reactive({
      formData: { ...props.formData }, // Clone the formData for local state
      validationErrors: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
      },
      propertyCity: '',
      propertyState: '',
      propertyZipCode: '',
      selectedPolicy: null,
    });
    const d1 = ref(null);
    const d2 = ref(null);
    const d3 = ref(null);
    const d4 = ref(null);
    const dbox = ref(null);
    const isLoading = ref(false);
    const loaderRef = ref(null);
    const searchResult = ref(null);
    const isPolicyDataFetched = ref(false);
    const isPolicySelected = ref(false);
    const initLoaderAnimation = () => {
      console.log('Initializing Loader Animation');
      if (loaderRef.value) {
        // GSAP 3 syntax
        const tl = gsap.timeline({ repeat: -1 });

        tl.from('#d1', { duration: 1, y: '-=60px', autoAlpha: 0 })
          .from('#d2', { duration: 1, x: '+=60px', autoAlpha: 0 }, '-=0.5')
          .from('#d3', { duration: 1, y: '+=60px', autoAlpha: 0 }, '-=0.5')
          .from('#d4', { duration: 1, x: '-=60px', autoAlpha: 0 }, '-=0.5')
          .to('#dbox', { duration: 1, rotation: 360 })
          .to('#d4', { duration: 1, x: '-=60px', autoAlpha: 0 }, '>-0.5')
          .to('#d3', { duration: 1, y: '+=60px', autoAlpha: 0 }, '>-1')
          .to('#d2', { duration: 1, x: '+=60px', autoAlpha: 0 }, '>-1.5')
          .to('#d1', { duration: 1, y: '-=60px', autoAlpha: 0 }, '>-2');
      }
    };
    watch(isLoading, (newValue) => {
      if (newValue) {
        nextTick(() => {
          initLoaderAnimation();
        });
      } else {
        // Handle stopping the animation if necessary
      }
    });
    const handlePolicySubmitted = (policy) => {
      state.selectedPolicy = policy; // Store the selected policy
      console.log('Selected policy:', policy);
      // Assuming you have some steps or data to update in your parent component:
      emit('updateFormData', { ...state.formData, selectedPolicy: policy }); // Add selected policy to form data
      emit('updateStep', 'next'); // Move to the next step in your process
      console.log('Selected policy:', policy);
    };

    const handleNoneSelected = () => {
      console.log('None of the policies were selected.');
      proceedWithoutPolicy(); // Use this to move to the next step.
    };

    function validate() {
      let isValid = true;

      // First Name, Last Name, and Address Validation
      ['firstName', 'lastName', 'address'].forEach((field) => {
        if (!state.formData[field]) {
          state.validationErrors[field] = 'This field is required.';
          isValid = false;
        } else {
          state.validationErrors[field] = '';
        }
      });

      // Email Validation
      if (!state.formData.email || !/\S+@\S+\.\S+/.test(state.formData.email)) {
        state.validationErrors.email = 'Please enter a valid email address.';
        isValid = false;
      } else {
        state.validationErrors.email = '';
      }

      // Phone Validation
      if (
        !state.formData.phone ||
        !/\(\d{3}\) \d{3}-\d{4}/.test(state.formData.phone)
      ) {
        state.validationErrors.phone = 'Please enter a valid phone number.';
        isValid = false;
      } else {
        state.validationErrors.phone = '';
      }

      return isValid;
    }

    async function validateAndProceed() {
      const isFormValid = true; // Set to true for testing, replace with actual validation later
      // const isFormValid = validate(); // Uncomment for actual validation
      console.log('Is Loading Value START:', isLoading.value);
      if (isFormValid) {
        emit('updateStep');
        emit('updateFormData', state.formData);
        // This controls sending the garage address to the API, and fetching policy data, i am skipping for now because api did not return good results.
        isLoading.value = true;
        console.log('Is Loading Value MIDDLE:', isLoading.value);
        console.log('Form is valid. Fetching policy data...');
        await fetchPolicyData(); // Wait until policy data is fetched
        isLoading.value = false; // Reset loading state
        console.log('Is Loading Value END:', isLoading.value);

        if (!isPolicyDataFetched) {
          console.error(
            'Policy data could not be fetched. Cannot proceed to the next step.',
          );
        }
      } else {
        console.error('Form is not valid. Cannot proceed to the next step.');
      }
    }

    async function sendGarageAddressToAPI() {
      // Prepare the data to send
      const addressData = {
        address: state.formData.address,
        city: state.propertyCity,
        state: state.propertyState,
        zipCode: state.propertyZipCode,
      };

      // Check if all required fields have usable data
      const hasUsableData = Object.values(addressData).every(
        (value) => value && value.trim() !== '',
      );

      // Only proceed if there is usable data
      if (!hasUsableData) {
        console.error('Cannot send API request: Missing or invalid data');
        return; // Exit if data is missing or invalid
      }

      // Try to send a POST request to the API
      try {
        // Define your API endpoint URL here
        const apiURL = 'api/test-garage-address';
        const response = await axios.post(apiURL, addressData);
        console.log('API response:', response.data);
        // You might want to proceed with response processing here
      } catch (error) {
        console.error('API request failed:', error);
        // Handle errors here
      }
    }
    function handlePolicySelected(policy) {
      state.isPolicySelected = true;
      state.selectedPolicy = policy; // Assuming you want to save the selected policy
      // You may want to do more here, like updating form data or validation
    }

    async function fetchPolicyData() {
      try {
        // Define your policy data URL here
        const policyDataUrl = 'api/verify-auto-policy';
        const response = await axios.post(policyDataUrl, {
          email: state.formData.email,
          phoneNumber: state.formData.phone,
        });
        console.log('Policy data response:', response.data);
        // Check if response has data and policyInfo array has elements
        if (
          response.data &&
          response.data.policyInfo &&
          response.data.policyInfo.length > 0
        ) {
          // Update the state based on the response
          searchResult.value = response.data;
          isPolicyDataFetched.value = true; // Set true if data was received
        } else {
          // No relevant policy information received
          searchResult.value = null;
          isPolicyDataFetched.value = false;
          proceedWithoutPolicy();
        }
      } catch (error) {
        console.error('Failed to fetch policy data:', error);
        // Update the state to reflect that fetching has failed
        searchResult.value = null;
        isPolicyDataFetched.value = false;
        proceedWithoutPolicy();
      }
    }
    function proceedWithoutPolicy() {
      console.log(
        'No policies found or moving forward without selecting a policy.',
      );
      emit('update-step', 'next'); // Adjust 'next' as per your application's step logic.
    }

    const stateAbbreviations = {
      states: {
        Alabama: 'AL',
        Alaska: 'AK',
        Arizona: 'AZ',
        Arkansas: 'AR',
        California: 'CA',
        Colorado: 'CO',
        Connecticut: 'CT',
        Delaware: 'DE',
        Florida: 'FL',
        Georgia: 'GA',
        Hawaii: 'HI',
        Idaho: 'ID',
        Illinois: 'IL',
        Indiana: 'IN',
        Iowa: 'IA',
        Kansas: 'KS',
        Kentucky: 'KY',
        Louisiana: 'LA',
        Maine: 'ME',
        Maryland: 'MD',
        Massachusetts: 'MA',
        Michigan: 'MI',
        Minnesota: 'MN',
        Mississippi: 'MS',
        Missouri: 'MO',
        Montana: 'MT',
        Nebraska: 'NE',
        Nevada: 'NV',
        'New Hampshire': 'NH',
        'New Jersey': 'NJ',
        'New Mexico': 'NM',
        'New York': 'NY',
        'North Carolina': 'NC',
        'North Dakota': 'ND',
        Ohio: 'OH',
        Oklahoma: 'OK',
        Oregon: 'OR',
        Pennsylvania: 'PA',
        'Rhode Island': 'RI',
        'South Carolina': 'SC',
        'South Dakota': 'SD',
        Tennessee: 'TN',
        Texas: 'TX',
        Utah: 'UT',
        Vermont: 'VT',
        Virginia: 'VA',
        Washington: 'WA',
        'West Virginia': 'WV',
        Wisconsin: 'WI',
        Wyoming: 'WY',
      },
    };

    let autocomplete = ref(null);

    const loadGooglePlacesScript = () => {
      return new Promise((resolve, reject) => {
        // Check if the Google Places API is already loaded
        if (
          typeof google !== 'undefined' &&
          google.maps &&
          google.maps.places
        ) {
          resolve();
          return;
        }

        // Create a script element to load the Google Places API
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.src =
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyCGr836eojr4HL50J0ewPjY-a_pbd5ejp8&libraries=places&callback=initGooglePlaces';
        document.head.appendChild(script);

        // Define a callback function that will be called once the script is loaded
        window.initGooglePlaces = () => {
          if (
            typeof google !== 'undefined' &&
            google.maps &&
            google.maps.places
          ) {
            resolve();
          } else {
            reject('Google Places API failed to load');
          }
        };

        // Handle any errors that occur during script loading
        script.onerror = () =>
          reject('Google Places API script failed to load');

        // Append the script element to the document head to start loading the script
        document.head.appendChild(script);
      });
    };

    const initializeAutocomplete = () => {
      nextTick(() => {
        let input = document.getElementById('autocomplete-input');
        if (input instanceof HTMLInputElement) {
          autocomplete.value = new google.maps.places.Autocomplete(input, {
            types: ['geocode'],
          });

          autocomplete.value.addListener('place_changed', () => {
            const place = autocomplete.value.getPlace();
            state.formData.address = place.formatted_address;

            // Reset the manual address fields
            manualAddress.street = '';
            manualAddress.city = '';
            manualAddress.state = '';
            manualAddress.zip = '';

            // Decompose the selected place into components and fill the manual address fields
            const addressComponents = place.address_components;
            addressComponents.forEach((component) => {
              const types = component.types;
              if (types.includes('street_number') || types.includes('route')) {
                // Combine street number and route into the street address
                manualAddress.street +=
                  (manualAddress.street ? ' ' : '') + component.long_name;
              } else if (types.includes('locality')) {
                manualAddress.city = component.long_name;
              } else if (types.includes('administrative_area_level_1')) {
                // Directly use the short name as state abbreviation
                manualAddress.state = component.short_name;
              } else if (types.includes('postal_code')) {
                manualAddress.zip = component.long_name;
              }
            });

            // If you wish to automatically switch to manual entry mode after an address is selected
            manualEntry.value = true;
          });
        } else {
          console.error('Autocomplete input element not found or not valid');
        }
      });
    };

    const manualEntry = ref(false);
    const showAutocomplete = ref(false); // Controls visibility of autocomplete suggestions

    const toggleManualEntry = () => {
      manualEntry.value = !manualEntry.value;
      if (manualEntry.value) {
        // Clear the autocomplete field by accessing formData through state
        state.formData.address = '';
      }
    };
    const manualAddress = reactive({
      street: '',
      city: '',
      state: '',
      zip: '',
    });

    watch(
      () => isPolicyDataFetched.value,
      (newValue) => {
        if (newValue) {
          // Maybe log something, or check if additional UI updates are needed
          console.log('Policy data is now fetched and component should show.');
        }
      },
    );
    watch(
      () => isPolicySelected.value,
      async (isPolicySelected) => {
        if (isPolicySelected && isPolicyDataFetched) {
          await sendGarageAddressToAPI();
          emit('update-step');
          emit('update-form-data', state.formData);
        }
      },
    );
    onMounted(() => {
      loadGooglePlacesScript()
        .then(initializeAutocomplete)
        .catch((error) => console.error(error));
    });

    return {
      ...state,
      d1,
      d2,
      d3,
      d4,
      dbox,
      manualEntry,
      manualAddress,
      showAutocomplete,
      toggleManualEntry,
      validateAndProceed,
      sendGarageAddressToAPI,
      handlePolicySelected,
      fetchPolicyData,
      loadGooglePlacesScript,
      initializeAutocomplete,
      loaderRef,
      stateAbbreviations,
      initLoaderAnimation,
      isLoading,
      isPolicyDataFetched,
      isPolicySelected,
      searchResult,
      handlePolicySubmitted,
      handleNoneSelected,
      emit,
    };
  },
});
</script>

<style scoped>
.loader-container {
  position: fixed;
  inset: 0;
  background-color: rgba(
    171,
    170,
    170,
    0.5
  ); /* Lighter gray background with opacity for dimming effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
  backdrop-filter: blur(
    5px
  ); /* Optional: Adds a blur effect to the background content */
}

#dbox {
  width: 250px;
  height: 250px;
  margin: 0 auto;
  position: relative;
  z-index: 1010; /* Higher than the container to ensure it's on top */
}

#dbox div {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  opacity: 0.85; /* Slightly reduce opacity for a softer appearance */
}

#d1 {
  left: 50%;
  margin-left: -50px;
  background-color: #4479ee;
}

#d2 {
  top: 50%;
  margin-top: -50px;
  right: 0;
  background-color: #73cc6d;
}

#d3 {
  bottom: 0%;
  margin-left: -50px;
  left: 50%;
  background-color: #e06666;
}

#d4 {
  top: 50%;
  margin-top: -50px;
  left: 0;
  background-color: #b2b2b1;
}

/* Other styles remain unchanged */
</style>
