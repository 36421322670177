<template>
  <div class="h-10 bg-gray-100"></div>
  <div class="max-w-[1440px] mx-auto font-Roboto text-[#646778] mt-[50px]">
    <div class="md:flex justify-between">
      <div class="left-side md:w-[30%] relative">
        <div
          class="sticky top-[30px] xl:w-[300px] md:h-[1050px] lg:w-[288px] md:w-[230px] px-[20px] mt-[50px]"
        >
          <div class="progress-bar hidden md:block">
            <ul>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#home2"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Umbrella Insurance Overview</a
                >
              </li>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#understand"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Understanding Umbrella Insurance</a
                >
              </li>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#home3"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Main Price Impacting Factors</a
                >
              </li>
              <li class="after:hidden hover:text-[#D24948] duration-300">
                <a
                  href="#home4"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Bundle and Save</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right-side px-[20px] md:w-[70%]">
        <div>
          <div class="flex text-[11px] items-center text-[#000105] uppercase">
            <span class="pr-[20px] hover:text-[#D24948] duration-300"
              >Insurance</span
            >
            <span class="pr-[20px]">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25L15.75 11.25C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75L8.25 12.75C7.83579 12.75 7.5 12.4142 7.5 12Z"
                  fill="#000105"
                />
              </svg>
            </span>
            <span class="hover:text-[#D24948] duration-300"
              >Umbrella Insurance</span
            >
          </div>
          <div id="home2">
            <h2
              class="xl:text-[35px] lg:text-[25px] text-[25px] pt-[10px] font-Merriweather font-black tracking-[-0.2px] pb-[20px] text-[#1F2937]"
            >
              Understanding Umbrella Insurance
            </h2>
            <p class="text-[15px] my-[15px] leading-[27px]">
              While you may have various insurance policies in place to protect
              your home, car, and other assets, sometimes these policies may not
              be enough to cover the costs of a major incident or lawsuit.
              That's where umbrella insurance comes in. This type of insurance
              provides an extra layer of liability coverage that can help
              protect you and your assets from the unexpected. In this article,
              we'll cover the basics of umbrella insurance, what it covers, and
              how to determine if it's right for you.
            </p>
          </div>

          <div class="">
            <div class="bg-[#EDEDED] lg:p-6 p-4 rounded-md">
              <h2
                class="xl:text-[20px] lg:text-[15px] text-[15px] pt-[10px] font-roboto font-bold tracking-[-0.2px] pb-[20px] text-[#1F2937]"
              >
                What will we cover?
              </h2>
              <ul class="list-disc ml-12 text-red-600 text-3xl leading-7">
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >What is umbrella insurance and how does it work?</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >What does umbrella insurance typically cover?</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >How much umbrella insurance coverage do you need?</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >What is not covered by umbrella insurance?</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >How to obtain umbrella insurance and what to consider when
                    choosing a policy.</span
                  >
                </li>
              </ul>
            </div>
            <hr class="w-full mt-6 h-[3px] bg-gray-300" />
          </div>

          <div class="hidden md:block" id="understand">
            <h2
              class="xl:text-[35px] lg:text-[25px] text-[25px] pt-[10px] font-Merriweather font-black tracking-[-0.2px] pb-[20px] text-[#1F2937]"
            >
              Understanding Umbrella Insurance: Coverage, Limits, and Benefits
            </h2>
            <p class="text-[15px] my-[15px] leading-[27px]">
              Umbrella insurance is a type of insurance policy that provides
              additional liability coverage beyond your other insurance
              policies. It's important to have umbrella insurance coverage to
              protect your assets and financial future in the event of a costly
              lawsuit or other unexpected event. Here's what you need to know
              about umbrella insurance.
            </p>

            <div class="grid lg:grid-cols-2 sm:grid-cols-2 gap-2 mb-10">
              <div class="grid col">
                <ul class="list-disc ml-12 text-red-600 text-3xl leading-7">
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >What is umbrella insurance and how does it work?</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Types of claims covered by umbrella insurance.</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Benefits of having umbrella insurance coverage.</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >How much umbrella insurance coverage do you need?</span
                    >
                  </li>
                </ul>
              </div>
              <div class="grid col my-auto mx-auto">
                <img src="@/assets/images/productPageImages/book.png" alt="" />
              </div>
            </div>
            <div>
              <p class="text-[15px] my-[15px] font-bold leading-[27px]">
                Understanding Umbrella Insurance: Coverage, Limits, and Benefits
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Umbrella insurance is a type of insurance policy that provides
                additional liability coverage beyond your other insurance
                policies. It's important to have umbrella insurance coverage to
                protect your assets and financial future in the event of a
                costly lawsuit or other unexpected event. Here's what you need
                to know about umbrella insurance.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                What is umbrella insurance and how does it work?
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Umbrella insurance is an extra layer of liability coverage that
                kicks in after your other insurance policies have reached their
                coverage limits. It can provide coverage for legal fees, medical
                expenses, and other costs associated with a liability claim.
                Umbrella insurance typically covers claims related to bodily
                injury, property damage, and personal injury.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                Types of claims covered by umbrella insurance.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                Umbrella insurance can cover a wide range of claims, including:
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                • Bodily injury claims resulting from a car accident or other
                accident.<br />• Property damage claims resulting from damage to
                someone else's property.<br />• Personal injury claims resulting
                from libel, slander, or other types of defamation.<br />•
                Lawsuits resulting from incidents that occur on your property,
                such as a slip and fall.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                Benefits of having umbrella insurance coverage.
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                There are several benefits to having umbrella insurance
                coverage, including:
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                • Increased protection for your assets: Umbrella insurance can
                provide additional coverage beyond your other insurance
                policies, helping to protect your assets in the event of a
                costly lawsuit or other unexpected event.<br />
                • Peace of mind: Knowing that you have extra liability coverage
                can give you peace of mind and reduce your stress level.<br />
                • Cost savings: Having umbrella insurance coverage can
                potentially save you money on your other insurance policies by
                reducing the likelihood of a claim exceeding your coverage
                limits.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                How much umbrella insurance coverage do you need?
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                The amount of umbrella insurance coverage you need depends on
                several factors, including:
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                • Your net worth: The more assets you have, the more liability
                protection you may need.<br />• Potential liability risks: If
                you have a high-risk job or engage in activities that could
                result in liability claims, you may need more coverage.<br />
                • Existing insurance coverage: You'll want to make sure you have
                enough coverage to fill any gaps in your existing insurance
                policies.
              </p>
            </div>
          </div>
        </div>

        <section>
          <div id="home2">
            <h2
              class="xl:text-[35px] lg:text-[25px] text-[25px] font-Merriweather font-black tracking-[-0.2px] py-[20px] text-[#1F2937]"
            >
              Main Price Impacting Factors for Umbrella Insurance
            </h2>
            <p class="text-[15px] my-[15px] leading-[27px]">
              When comparing rates and coverages for Umbrella insurance, it is
              important to keep in mind data points that Insurance Companies
              find most important when evaluating your rate.
            </p>
          </div>
          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/multi.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Liability Coverage Limits
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                The amount of liability coverage you choose to purchase for your
                umbrella insurance policy can have a significant impact on the
                price of your policy. Higher liability coverage limits will
                typically result in higher premiums since it means that the
                insurance company is taking on more risk.
              </p>
            </div>
          </div>
          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/multi.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Prior Claims
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                If you have made previous claims on your insurance policies,
                including your auto, home, or other liability insurance, this
                can impact the price of your umbrella insurance. Insurance
                companies may view individuals who have made claims as being
                more likely to make future claims, which can result in higher
                premiums.
              </p>
            </div>
          </div>
          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/credit.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Personal Information
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                Your personal information, such as your age, marital status, and
                credit score, can also impact the price of your umbrella
                insurance. Insurance companies may use this information to
                determine your level of risk, and individuals who are considered
                higher risk may pay more for coverage.
              </p>
            </div>
          </div>

          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/marital.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Occupation
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                Your occupation can also impact the price of your umbrella
                insurance. Certain occupations, such as those that involve more
                physical labor or those that are considered high-risk, may
                result in higher premiums since the insurance company is taking
                on more risk by insuring you. On the other hand, individuals
                with lower-risk occupations may pay less for coverage.
              </p>
            </div>
          </div>
        </section>

        <div
          class="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 pb-[30px] mt-[20px]"
          id="home4"
        >
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/homeInsurance.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-black md:py-[15px] py-2"
                >
                  Home Insurance
                </h3>
                <div>
                  <a
                    href="/products/homeinsurance"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/autoInsurance.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-black md:py-[15px] py-2"
                >
                  Auto Insurance
                </h3>
                <div>
                  <a
                    href="/products/autoinsurance"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/floodInsurance.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-[#1F2937] md:py-[15px] py-2"
                >
                  Flood Insurance
                </h3>
                <div>
                  <a
                    href="/floodinsurance"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="border-t pt-[50px] 2xl:pb-[436px] md:pb-[200px] pb-24 md:mx-[-20px]"
        >
          <div class="md:mx-[20px] bg-[#EDEDED] rounded-[5px]">
            <div
              class="bg-[url('@/assets/images/productPageImages/watch-bg.png')] lg:bg-contain bg-[length:395px] bg-no-repeat bg-right-bottom"
            >
              <div class="xl:p-[30px] lg:p-[20px] p-[20px]">
                <h2
                  class="xl:text-[25px] lg:text-[21px] text-[21px] font-extrabold text-[#1F2937] mb-[20px]"
                >
                  WATCH YOUR SAVINGS ADD UP.
                </h2>
                <p
                  class="text-[15px] py-[15px] leading-[27px] 2xl:max-w-[495px] xl:max-w-[415px] lg:max-w-[301px] md:max-w-[405px] sm:max-w-[500px]"
                >
                  Always check to see if you can save money by adding an
                  additional product to your home insurance policy! (Auto, Life,
                  Flood, etc.)
                </p>
                <div
                  class="mt-[20px] lg:pb-0 md:pb-[150px] sm:pb-[230px] pb-[200px] bg-padding"
                >
                  <a
                    href="/chooseproducts"
                    class="bg-[#DB0909] hover:bg-[#DC2626] duration-500 max-w-[220px] w-full flex items-center rounded-[5px] uppercase text-white text-center tracking-[2px] text-[14px] px-[40px] py-[15px]"
                    >Get a quote
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      class="ml-[5px]"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
                        fill="#ffffff"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
