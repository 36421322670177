<template>
  <div class="flex flex-col md:flex-row min-h-screen bg-gray-100">
    <Navigation />

    <div
      class="flex-auto p-2 md:p-10 w-full"
      style="max-height: calc(100vh - 64px); overflow-y: auto"
    >
      <h1 class="text-2xl font-bold text-ietextlight mb-4 md:mb-8">
        Manage Users
      </h1>

      <div class="bg-white shadow-lg rounded-lg p-6 mb-10 md:mb-0">
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-semibold text-gray-700">User List</h2>
          <button
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            @click="showAddUserForm = true"
          >
            Add New User
            <Icon icon="mdi:plus" class="ml-2" />
          </button>
        </div>

        <div
          class="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4 mb-4"
        >
          <div class="relative w-full rounded-lg">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search users..."
              class="custom-input px-3 py-2 rounded-lg focus:ring-2 focus:ring-blue-500 transition-all duration-300 ease-in-out w-full placeholder-[#6B7280]"
            />
            <Icon
              icon="mdi:magnify"
              class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-300"
            />
          </div>
          <select
            v-model="sortKey"
            class="form-select px-3 py-2 border rounded-lg shadow outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 ease-in-out w-full"
          >
            <option value="sort">Sort</option>
            <option value="name">Name</option>
            <option value="role">Role</option>
            <option value="status">Status</option>
            <option value="email">Email</option>
            <option value="last_login_at">Last Login</option>
          </select>
        </div>

        <!--Desktop View-->
        <table class="hidden md:table min-w-full w-full text-xs md:text-sm">
          <thead class="bg-gray-50">
            <tr>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Role
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="user in filteredAndSortedUsers" :key="user.id">
              <td class="px-6 py-4 whitespace-nowrap">
                {{ user.firstName }} {{ user.lastName }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ normalizeRoleName(user.role) }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex items-center">
                  <span
                    :class="{
                      'bg-green-100 text-green-800': user.isActive,
                      'bg-red-100 text-red-800': !user.isActive,
                    }"
                    class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                  >
                    {{ user.isActive ? 'Active' : 'Inactive' }}
                  </span>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button
                  class="text-indigo-600 hover:text-indigo-900 mr-4"
                  @click="openEditUserModal(user)"
                >
                  <Icon icon="mdi-pencil" class="inline-block" /> Edit
                </button>
                <button
                  class="text-red-600 hover:text-red-900"
                  @click="deleteUser(user.id)"
                >
                  <Icon icon="mdi-delete" class="inline-block" /> Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Mobile Card View -->
      <div class="md:hidden">
        <div
          v-for="user in filteredAndSortedUsers"
          :key="user.id"
          class="bg-white p-4 rounded-lg shadow mb-3"
        >
          <!-- Card Header (Clickable) -->
          <div
            class="cursor-pointer"
            @click="
              expandedUserId = expandedUserId === user.id ? null : user.id
            "
          >
            <div class="flex justify-between">
              <div>
                <div class="font-bold">
                  {{ user.firstName }} {{ user.lastName }}
                </div>
                <div class="text-sm text-gray-600">
                  {{ normalizeRoleName(user.role) }}
                </div>
              </div>
              <div class="flex items-center">
                <span
                  :class="{
                    'bg-green-100 text-green-800': user.isActive,
                    'bg-red-100 text-red-800': !user.isActive,
                  }"
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                >
                  {{ user.isActive ? 'Active' : 'Inactive' }}
                </span>
              </div>
            </div>
          </div>
          <!-- Additional Details (Conditionally Rendered) -->
          <div v-if="expandedUserId === user.id" class="mt-4 text-sm">
            <div>Email: {{ user.email }}</div>
            <div>Phone: {{ user.phoneNumber }}</div>
            <div>Company: {{ user.company }}</div>
            <div>Last Login: {{ user.last_login_at }}</div>
            <!-- Add more fields as necessary -->
          </div>
          <!-- Action Buttons -->
          <div class="flex justify-end mt-4">
            <button
              class="text-indigo-600 hover:text-indigo-900 mr-4"
              @click.stop="openEditUserModal(user)"
            >
              <Icon icon="mdi-pencil" class="h-5 w-5" />
            </button>
            <button
              class="text-red-600 hover:text-red-900"
              @click.stop="deleteUser(user.id)"
            >
              <Icon icon="mdi-delete" class="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Add User Modal -->
    <div
      v-if="showAddUserForm"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
    >
      <div
        class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
      >
        <div class="mt-3 text-center">
          <div class="mt-2 px-7 py-3">
            <input
              v-model="newUser.firstName"
              type="text"
              placeholder="First Name"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            />
            <input
              v-model="newUser.lastName"
              type="text"
              placeholder="Last Name"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            />
            <input
              v-model="newUser.email"
              type="email"
              placeholder="Email"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            />
            <select
              v-model="newUser.role"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            >
              <option value="" disabled selected>Role</option>
              <option value="user">User</option>
              <option value="csr">CSR</option>
              <option value="superadmin">Super Admin</option>
              <option value="admin">Admin</option>
              <option value="customer">Customer</option>
              <option value="agent">Agent</option>
              <option value="developer">Developer</option>
              <option value="underwriter">Underwriter</option>
            </select>

            <input
              v-model="newUser.password"
              type="password"
              placeholder="Password"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            />
          </div>
          <div class="items-center px-4 py-3">
            <button
              class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              @click="addUser"
            >
              Add User
            </button>
          </div>
          <div class="items-center px-4 py-3">
            <button
              class="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
              @click="showAddUserForm = false"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit User Modal -->
    <div
      v-if="showEditUserForm"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
    >
      <div
        class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
      >
        <div class="mt-3 text-center">
          <div class="mt-2 px-7 py-3">
            <input
              v-model="editUser.firstName"
              type="text"
              placeholder="First Name"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            />
            <input
              v-model="editUser.lastName"
              type="text"
              placeholder="Last Name"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            />
            <input
              v-model="editUser.email"
              type="email"
              placeholder="Email"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            />
            <input
              v-model="editUser.phoneNumber"
              type="tel"
              placeholder="Phone Number"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            />
            <input
              v-model="editUser.company"
              type="text"
              placeholder="Company"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            />
            <select
              v-model="editUser.role"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            >
              <option value="" disabled selected>Role</option>
              <option value="user">User</option>
              <option value="superadmin">Super Admin</option>
              <option value="admin">Admin</option>
              <option value="customer">Customer</option>
              <option value="agent">Agent</option>
              <option value="developer">Developer</option>
            </select>
            <input
              v-model="editUser.profile_image"
              type="text"
              placeholder="Profile Image URL"
              class="mb-3 px-4 py-2 border rounded-md w-full"
            />
            <div class="flex items-center mb-3">
              <input
                id="activeCheckbox"
                v-model="editUser.isActive"
                type="checkbox"
                class="mr-2"
              />
              <label for="activeCheckbox">Is Active</label>
            </div>
            <!-- Omit sensitive fields like password, api_token, mfa_secret, etc. -->
            <button
              class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              @click="saveEdit"
            >
              Save Changes
            </button>
          </div>
          <div class="items-center px-4 py-3">
            <button
              class="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
              @click="showEditUserForm = false"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast />
</template>

<script>
import axios from 'axios';
import { Icon } from '@iconify/vue';
import Navigation from './components/Navigation.vue';
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';

export default {
  components: {
    Icon,
    Navigation,
    Toast,
  },
  data() {
    return {
      users: [], // Data for users
      searchQuery: '', // Search query string
      sortKey: 'sort', // Default sorting key
      showAddUserForm: false,
      showEditUserForm: false,
      expandedUserId: null,
      newUser: {
        role: '', // Initial empty role string
        isActive: true, // Set default to active
      },
      editUser: {},
    };
  },
  computed: {
    filteredAndSortedUsers() {
      const roleHierarchy = {
        superadmin: 1,
        admin: 2,
        developer: 3,
        csr: 4,
      };

      return this.users
        .filter((user) => {
          const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
          return (
            fullName.includes(this.searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        })
        .sort((a, b) => {
          if (this.sortKey === 'sort' || this.sortKey === 'role') {
            const roleA = roleHierarchy[a.role] || 99; // Assign a high value for roles not in the hierarchy
            const roleB = roleHierarchy[b.role] || 99;
            return roleA - roleB;
          }
          // Add other sort cases here if needed
          switch (this.sortKey) {
            case 'name':
              const nameA = `${a.firstName} ${a.lastName}`;
              const nameB = `${b.firstName} ${b.lastName}`;
              return nameA.localeCompare(nameB);
            case 'status':
              return a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1;
            case 'email':
              return a.email.localeCompare(b.email);
            case 'last_login_at':
              return new Date(a.last_login_at) - new Date(b.last_login_at);
            default:
              return 0;
          }
        });
    },
  },
  mounted() {
    this.fetchUsers();
  },

  methods: {
    fetchUsers() {
      const baseURL = `/api/users`;
      axios
        .get(baseURL)
        .then((response) => {
          this.users = response.data;
          console.log('Users:', this.users);
        })
        .catch((error) => {
          console.error('There was an error fetching the users:', error);
        });
    },
    openEditUserModal(user) {
      console.log('Opening modal for editing', user);
      this.editUser = { ...user };
      this.showEditUserForm = true;
    },
    saveEdit() {
      const editURL = `/api/users/${this.editUser.id}`;
      axios
        .put(editURL, this.editUser)
        .then(() => {
          this.fetchUsers();
          this.showEditUserForm = false;
          // Show success toast
          this.$toast.add({
            severity: 'success',
            summary: 'Updated User',
            detail: 'User details have been updated successfully.',
            life: 3000,
          });
        })
        .catch((error) => {
          console.error('There was an error updating the user:', error);
          // Show error toast
          this.$toast.add({
            severity: 'error',
            summary: 'Update Failed',
            detail: 'Failed to update user details.',
            life: 3000,
          });
        });
    },
    deleteUser(userId) {
      const deleteURL = `/api/users/${userId}`;
      axios
        .delete(deleteURL)
        .then(() => {
          this.fetchUsers();
          // Show success toast
          this.$toast.add({
            severity: 'success',
            summary: 'Deleted User',
            detail: 'User has been deleted successfully.',
            life: 3000,
          });
        })
        .catch((error) => {
          console.error('There was an error deleting the user:', error);
          // Show error toast
          this.$toast.add({
            severity: 'error',
            summary: 'Delete Failed',
            detail: 'Failed to delete user.',
            life: 3000,
          });
        });
    },
    resetForm() {
      this.newUser = {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        password: '',
      };
    },
    addUser() {
      const addURL = `/api/users`;
      axios
        .post(addURL, { ...this.newUser, isActive: true }) // Set default to true or based on a form input
        .then(() => {
          this.fetchUsers();
          this.showAddUserForm = false;
          this.resetForm();
          this.$toast.add({
            severity: 'success',
            summary: 'Added User',
            detail: 'User has been added successfully.',
            life: 3000,
          });
        })
        .catch((error) => {
          console.error('There was an error adding the user:', error);
          this.$toast.add({
            severity: 'error',
            summary: 'Add Failed',
            detail: 'Failed to add user.',
            life: 3000,
          });
        });
    },
    onSearchInput(event) {
      this.searchQuery = event.target.value;
    },
    onSortChange(newSortKey) {
      this.sortKey = newSortKey;
    },
    normalizeRoleName(role) {
      const roleNames = {
        superadmin: 'Super Administrator',
        admin: 'Administrator',
        agent: 'Agent',
        developer: 'Developer',
        customer: 'Customer',
        user: 'User',
        csr: 'Customer Service Representative',
        underwriter: 'Underwriter',
      };
      return roleNames[role] || role; // Return the normalized name or the original if not mapped
    },
  },
};
</script>

<style scoped>
.custom-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: #dddfe2;
  border-width: 1px;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
.p-select-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: #dddfe2;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
</style>
