<template>
  <div>
    <UnderwritingCarrierCard
      v-for="carrier in filteredCarriers"
      :key="carrier.id"
      :carrier="carrier"
      :guideline="filteredGuidelines.find((guideline) => guideline.carrier_id === carrier.id,) || {}"
      :selectedUnderwritingGuidelineCategory="selectedUnderwritingGuidelineCategory"
      :productType="productType"
      :selectedCarrierId="selectedCarrierId"
      @updateGuideline="handleUpdateGuideline"
      @deleteGuideline="handleDeleteGuideline"
      @openAddGuidelineModal="handleOpenAddGuidelineModal"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import UnderwritingCarrierCard from './UnderwritingCarrierCard.vue';

const props = defineProps({
  filteredCarriers: {
    type: Array,
    required: true,
  },
  filteredGuidelines: {
    type: Array,
    required: true,
  },
  selectedCarrierId: {
    type: Number,
    required: false,
    default: null,
  },
  selectedUnderwritingGuidelineCategory: {
    type: String,
    required: true,
    default: '',
  },
  productType: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  'updateGuideline',
  'deleteGuideline',
  'openAddGuidelineModal',
]);

const handleUpdateGuideline = (updatedGuideline) => {
  emit('updateGuideline', updatedGuideline);
};

const handleDeleteGuideline = (id) => {
  emit('deleteGuideline', id);
};

const handleOpenAddGuidelineModal = (carrierId) => {
  emit('openAddGuidelineModal', carrierId);
};
</script>
