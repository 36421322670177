<template>
  <div class="flex flex-col items-center w-full px-4 py-2">
    <div v-if="!signatureComplete">
      <h2 class="text-2xl sm:text-3xl font-semibold mb-6 text-gray-800">
        Please sign to complete your request.
      </h2>
      <div class="signature-area border-2 border-gray-300 rounded-lg">
        <Vue3Signature
          ref="signature1"
          :sigOption="state.option"
          :disabled="state.disabled"
          class="example w-full h-24"
        ></Vue3Signature>
      </div>
      <div
        class="button-group flex flex-wrap justify-center mt-4 space-y-2 sm:space-y-0 sm:space-x-2 sm:flex-row"
      >
        <button
          class="btn btn-save bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 sm:px-6 rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 mt-2 sm:mt-0"
          @click="confirmSignature"
        >
          Confirm
        </button>
        <button
          class="btn btn-clear bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 sm:px-6 rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 mx-4 mt-2 sm:mt-0"
          @click="clear"
        >
          Clear
        </button>
        <button
          class="btn btn-clear bg-gray-200 hover:bg-gray-300 text-black font-semibold py-2 px-4 sm:px-6 rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 mt-2 sm:mt-0"
          @click="cancelAfterSuccessEmit"
        >
          Cancel
        </button>
      </div>
    </div>

    <div
      v-if="signatureComplete"
      class="flex flex-col items-center w-full px-4 py-4 mt-4 text-center bg-white rounded-lg shadow-lg"
    >
      <h2 class="text-2xl sm:text-3xl font-semibold mb-4 text-green-600">
        Request Successfully Received
      </h2>
      <p class="text-lg sm:text-xl font-medium mb-4 text-gray-700">
        We're on it! Your policy change request has been successfully submitted.
        Please allow us 24-48 hours to process your request. Rest assured, we
        will send a confirmation email once your request has been reviewed and
        actioned.
      </p>
      <img
        src="@/assets/images/gifs/7efs.gif"
        alt="Success Animation"
        class="w-24 h-24 my-3"
      />
      <p class="text-sm sm:text-base text-gray-600 italic mb-4">
        Your request is important to us. Thank you for choosing Insurance!
      </p>
      <button
        @click="cancelAfterSuccessEmit"
        class="btn btn-clear bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 sm:px-6 rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 mt-2"
      >
        Cancel Request
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed } from 'vue';
import confetti from 'canvas-confetti';

const signatureComplete = ref(false);
const state = reactive({
  option: {
    penColor: 'rgb(0, 0, 0)',
    backgroundColor: 'rgb(255,255,255)',
  },
  disabled: false,
});

const signature1 = ref(null);

const saveSignatureAsJPEG = async () => {
  if (signature1.value) {
    try {
      const imageDataURL = signature1.value.save('image/jpeg');
      console.log('Signature Data URL:', imageDataURL);

      // Fallback for CSRF token
      const csrfToken =
        document
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute('content') || '';

      const response = await fetch('/api/save-signature', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // CSRF token is not needed for API routes
        },
        body: JSON.stringify({ dataURL: imageDataURL }),
      });

      if (response.ok) {
        console.log('Signature saved to server');
      } else {
        console.error('Failed to save signature');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  } else {
    console.error('Signature component is not initialized');
  }
};

const confirmSignature = async () => {
  await saveSignatureAsJPEG();
  signatureComplete.value = true;

  // Trigger confetti animation
  confetti({
    particleCount: 100,
    spread: 70,
    origin: { x: 0.5, y: 0.5 },
  });
};

const confirmClear = () => {
  if (confirm('Are you sure you want to clear the signature?')) {
    clear();
  }
};

const clear = () => {
  signature1.value.clear();
};

const cancelAfterSuccessEmit = () => {
  // Emit the 'cancelSignature' custom event
  $emit('cancelSignature');
};
</script>

<style scoped>
.signature-area {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}

.example {
  border: none;
  border-radius: 0.25rem;
}
</style>
