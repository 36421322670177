<template>
  <div class="flex bg-gray-100">
    <div class="max-w-4xl w-full overflow-auto">
      <div class="bg-white p-6 rounded-md shadow-md">
        <div class="flex flex-row mb-6">
          <Icon
            icon="mdi:file-document-outline"
            class="h-8 w-8 text-blue-500 my-auto mr-2"
          />
          <h1 class="text-4xl font-bold text-gray-800">
            No Loss Form Submission
          </h1>
        </div>
        <p class="text-sm md:text-md text-gray-700 mb-2">
          Please fill out the form below to send a statement of no loss.
        </p>
        <p class="text-sm md:text-md italic text-red-500">
          Required fields are marked with an asterisk *
        </p>

        <!-- Input Form -->
        <div v-if="!submitted">
          <div class="p-4 w-full text-left">
            <div class="grid grid-cols-1 md:grid-cols-2">
              <div
                v-if="
                  fieldVisibility.selectedCarrier && fieldLabels.selectedCarrier
                "
              >
                <label
                  for="selectedCarrier"
                  class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                >
                  {{ fieldLabels.selectedCarrier }}:
                  <span
                    v-if="fieldRequirements.selectedCarrier === 'required'"
                    class="text-red-500"
                    >*</span
                  >
                </label>
                <Dropdown
                  id="selectedCarrier"
                  v-model="formData.selectedCarrier"
                  :options="carrierOptions"
                  optionLabel="label"
                  optionValue="value"
                  filter
                  placeholder="Select a Carrier"
                />
              </div>
              <div v-if="fieldVisibility.policyEffectiveDate">
                <label
                  for="policyEffectiveDate"
                  class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                >
                  {{ fieldLabels.policyEffectiveDate }}:
                  <span
                    v-if="fieldRequirements.policyEffectiveDate === 'required'"
                    class="text-red-500"
                    >*</span
                  >
                </label>
                <Calendar
                  id="policyEffectiveDate"
                  v-model="formData.policyEffectiveDate"
                  showIcon
                  placeholder="mm/dd/yyyy"
                />
              </div>
              <div v-if="!fieldVisibility.policyEffectiveDate"></div>
              <div
                v-if="fieldVisibility.insuredName1 && fieldLabels.insuredName1"
              >
                <label
                  for="insuredName1"
                  class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                >
                  {{ fieldLabels.insuredName1 }}:
                  <span
                    v-if="fieldRequirements.insuredName1 === 'required'"
                    class="text-red-500"
                    >*</span
                  >
                </label>
                <InputText
                  id="insuredName1"
                  class="capitalize"
                  v-model="formData.insuredName1"
                  placeholder="Enter Full Name"
                />
              </div>
              <div
                v-if="
                  fieldVisibility.insuredEmail1 && fieldLabels.insuredEmail1
                "
              >
                <label
                  for="insuredEmail1"
                  class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                >
                  {{ fieldLabels.insuredEmail1 }}:
                  <span
                    v-if="fieldRequirements.insuredEmail1 === 'required'"
                    class="text-red-500"
                    >*</span
                  >
                </label>
                <InputText
                  id="insuredEmail1"
                  v-model="formData.insuredEmail1"
                  placeholder="Enter Insured Email"
                />
              </div>
              <div
                v-if="fieldVisibility.insuredName2 && fieldLabels.insuredName2"
              >
                <label
                  for="insuredName2"
                  class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                >
                  {{ fieldLabels.insuredName2 }}:
                  <span
                    v-if="fieldRequirements.insuredName2 === 'required'"
                    class="text-red-500"
                    >*</span
                  >
                </label>
                <InputText
                  id="insuredName2"
                  class="capitalize"
                  v-model="formData.insuredName2"
                  placeholder="Enter Full Name"
                />
              </div>
              <div
                v-if="
                  fieldVisibility.insuredEmail2 && fieldLabels.insuredEmail2
                "
              >
                <label
                  for="insuredEmail2"
                  class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                >
                  {{ fieldLabels.insuredEmail2 }}:
                  <span
                    v-if="fieldRequirements.insuredEmail2 === 'required'"
                    class="text-red-500"
                    >*</span
                  >
                </label>
                <InputText
                  id="insuredEmail2"
                  v-model="formData.insuredEmail2"
                  placeholder="Enter Insured Email"
                />
              </div>
              <div
                v-if="fieldVisibility.insuredName3 && fieldLabels.insuredName3"
              >
                <label
                  for="insuredName3"
                  class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                >
                  {{ fieldLabels.insuredName3 }}:
                  <span
                    v-if="fieldRequirements.insuredName3 === 'required'"
                    class="text-red-500"
                    >*</span
                  >
                </label>
                <InputText
                  id="insuredName3"
                  class="capitalize"
                  v-model="formData.insuredName3"
                  placeholder="Enter Full Name"
                />
              </div>
              <div
                v-if="
                  fieldVisibility.insuredEmail3 && fieldLabels.insuredEmail3
                "
              >
                <label
                  for="insuredEmail3"
                  class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                >
                  {{ fieldLabels.insuredEmail3 }}:
                  <span
                    v-if="fieldRequirements.insuredEmail3 === 'required'"
                    class="text-red-500"
                    >*</span
                  >
                </label>
                <InputText
                  id="insuredEmail3"
                  v-model="formData.insuredEmail3"
                  placeholder="Enter Insured Email"
                />
              </div>
              <div
                v-if="fieldVisibility.policyNumber && fieldLabels.policyNumber"
              >
                <label
                  for="policyNumber"
                  class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                >
                  {{ fieldLabels.policyNumber }}:
                  <span
                    v-if="fieldRequirements.policyNumber === 'required'"
                    class="text-red-500"
                    >*</span
                  >
                </label>
                <InputText
                  id="policyNumber"
                  v-model="formData.policyNumber"
                  placeholder="Enter policy number"
                />
              </div>
              <div v-if="fieldVisibility.selectedPolicyType">
                <label
                  for="selectedPolicyType"
                  class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                >
                  {{ fieldLabels.selectedPolicyType }}:
                  <span
                    v-if="fieldRequirements.selectedPolicyType === 'required'"
                    class="text-red-500"
                    >*</span
                  >
                </label>
                <Dropdown
                  id="selectedPolicyType"
                  v-model="formData.selectedPolicyType"
                  :options="policyTypeOptions"
                  optionLabel="label"
                  optionValue="value"
                  filter
                  placeholder="Select a Policy Type"
                />
              </div>
              <div
                v-if="
                  fieldVisibility.selectedAgency && fieldLabels.selectedAgency
                "
              >
                <label
                  for="selectedAgency"
                  class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                >
                  {{ fieldLabels.selectedAgency }}:
                  <span
                    v-if="fieldRequirements.selectedAgency === 'required'"
                    class="text-red-500"
                    >*</span
                  >
                </label>
                <Dropdown
                  id="selectedAgency"
                  v-model="formData.selectedAgency"
                  :options="agencyOptions"
                  optionLabel="label"
                  optionValue="value"
                  filter
                  placeholder="Select an Agency"
                />
              </div>
              <div v-if="fieldVisibility.propertyAddress" class="flex flex-col">
                <div>
                  <label
                    for="propertyAddress"
                    class="block text-sm font-medium text-gray-700 mt-4 mb-2"
                  >
                    {{ fieldLabels.propertyAddress }}:
                    <span
                      v-if="fieldRequirements.propertyAddress === 'required'"
                      class="text-red-500"
                      >*</span
                    >
                  </label>
                  <InputText
                    type="text"
                    id="propertyAddress"
                    v-model="formData.propertyAddress"
                    @focus="loadGooglePlacesAPI"
                    @input="loadGooglePlacesAPI"
                    v-if="!manualEntry"
                    placeholder="Start typing address"
                  />
                </div>
                <button
                  @click="toggleManualEntry"
                  class="text-blue-500 text-sm mt-2 text-left"
                >
                  {{
                    manualEntry
                      ? 'Use Auto Complete'
                      : 'Or enter address manually'
                  }}
                </button>
                <div v-if="manualEntry" class="mt-2">
                  <input
                    type="text"
                    v-model="manualAddress.street"
                    placeholder="Street"
                    class="w-full border p-2 rounded"
                  />
                  <input
                    type="text"
                    v-model="manualAddress.city"
                    placeholder="City"
                    class="w-full border p-2 rounded mt-2"
                  />
                  <input
                    type="text"
                    v-model="manualAddress.state"
                    placeholder="State"
                    class="w-full border p-2 rounded mt-2"
                  />
                  <input
                    type="text"
                    v-model="manualAddress.zip"
                    placeholder="Zip Code"
                    class="w-full border p-2 rounded mt-2"
                  />
                </div>
              </div>
              <div></div>
            </div>
            <div class="flex mt-6 md:mt-8">
              <button
                @click="goBack"
                class="bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300 text-white font-semibold py-2 px-4 rounded-lg shadow transition duration-150 ease-in-out"
              >
                Back
              </button>
              <!-- Submission Button -->
              <button
                @click="prepareSubmission"
                class="ml-4 bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 text-white font-semibold py-2 px-4 rounded-lg shadow transition duration-150 ease-in-out"
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        <!-- Loading State -->
        <div v-else-if="loading">
          <Icon
            icon="svg-spinners:3-dots-bounce"
            class="animate-bounce"
            style="font-size: 24px; color: gray"
          />
        </div>

        <!-- Review & Confirm Submission -->
        <div
          v-else-if="submitted && !loading && !successMessage"
          class="max-w-4xl mx-auto px-4 py-6 bg-white rounded-lg shadow-lg"
        >
          <div class="mb-4">
            <p class="text-lg text-gray-700 font-semibold">
              Please double-check all information before final submission:
            </p>

            <div class="space-y-2 mt-4">
              <p
                v-if="isNotEmpty(formData.insuredName1)"
                class="capitalize text-gray-600"
              >
                <span class="capitalize font-medium text-gray-900"
                  >{{ fieldLabels.insuredName1 }}:</span
                >
                {{ formData.insuredName1 }}
              </p>
              <p
                v-if="isNotEmpty(formData.insuredEmail1)"
                class="text-gray-600"
              >
                <span class="font-medium text-gray-900"
                  >{{ fieldLabels.insuredEmail1 }}:</span
                >
                {{ formData.insuredEmail1 }}
              </p>
              <p v-if="isNotEmpty(formData.insuredName2)" class="text-gray-600">
                <span class="capitalize font-medium text-gray-900"
                  >{{ fieldLabels.insuredName2 }}:</span
                >
                {{ formData.insuredName2 }}
              </p>
              <p
                v-if="isNotEmpty(formData.insuredEmail2)"
                class="text-gray-600"
              >
                <span class="font-medium text-gray-900"
                  >{{ fieldLabels.insuredEmail2 }}:</span
                >
                {{ formData.insuredEmail2 }}
              </p>
              <p v-if="isNotEmpty(formData.insuredName3)" class="text-gray-600">
                <span class="capitalize font-medium text-gray-900"
                  >{{ fieldLabels.insuredName3 }}:</span
                >
                {{ formData.insuredName3 }}
              </p>
              <p
                v-if="isNotEmpty(formData.insuredEmail3)"
                class="text-gray-600"
              >
                <span class="font-medium text-gray-900"
                  >{{ fieldLabels.insuredEmail3 }}:</span
                >
                {{ formData.insuredEmail3 }}
              </p>
              <p v-if="isNotEmpty(formData.policyNumber)" class="text-gray-600">
                <span class="capitalize font-medium text-gray-900"
                  >{{ fieldLabels.policyNumber }}:</span
                >
                {{ formData.policyNumber }}
              </p>
              <p
                v-if="isNotEmpty(formData.selectedPolicyType)"
                class="text-gray-600"
              >
                <span class="font-medium text-gray-900"
                  >{{ fieldLabels.selectedPolicyType }}:</span
                >
                {{ formData.selectedPolicyType }}
              </p>
              <p
                v-if="isNotEmpty(formData.propertyAddress)"
                class="text-gray-600"
              >
                <span class="font-medium text-gray-900"
                  >{{ fieldLabels.propertyAddress }}:</span
                >
                {{ formData.propertyAddress }}
              </p>
              <p
                v-if="isNotEmpty(formData.policyEffectiveDate)"
                class="text-gray-600"
              >
                <span class="font-medium text-gray-900"
                  >{{ fieldLabels.policyEffectiveDate }}:</span
                >
                {{ formatDate(formData.policyEffectiveDate) }}
              </p>
              <p
                v-if="isNotEmpty(formData.selectedAgency)"
                class="text-gray-600"
              >
                <span class="font-medium text-gray-900"
                  >{{ fieldLabels.selectedAgency }}:</span
                >
                {{ formData.selectedAgency }}
              </p>
              <p
                v-if="isNotEmpty(formData.selectedCarrier)"
                class="text-gray-600"
              >
                <span class="font-medium text-gray-900"
                  >{{ fieldLabels.selectedCarrier }}:</span
                >
                {{ formData.selectedCarrier }}
              </p>
            </div>

            <Button
              @click="confirmAndSubmit"
              :disabled="loading"
              class="mt-6 bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-150 ease-in-out"
            >
              Confirm and Submit
            </Button>
            <!-- Back Button -->
            <Button
              @click="submitted = false"
              class="mt-6 ml-4 bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-150 ease-in-out"
            >
              Go Back
            </Button>
          </div>
        </div>
        <!-- Success Message -->
        <div
          v-if="successMessage"
          class="mt-4 p-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <div class="flex flex-col">
            <strong class="font-bold">Success!</strong>
            <span class="block sm:inline">{{ successMessage }}</span>
          </div>
          <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg
              @click="successMessage = ''"
              class="fill-current h-6 w-6 text-green-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path
                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.846 7.349 14.849a1.2 1.2 0 1 1-1.697-1.697L8.303 10 5.652 7.349A1.2 1.2 0 1 1 7.349 5.652L10 8.303l2.651-2.651a1.2 1.2 0 1 1 1.697 1.697L11.697 10l2.651 2.651a1.2 1.2 0 0 1 0 1.697z"
              />
            </svg>
          </span>
        </div>
        <!-- Error Message -->
        <div
          v-if="errorMessage"
          class="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <div class="flex flex-col">
            <strong class="font-bold">Error!</strong>
            <span class="block sm:inline">{{ errorMessage }}</span>
          </div>
          <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg
              @click="errorMessage = ''"
              class="fill-current h-6 w-6 text-red-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path
                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.846 7.349 14.849a1.2 1.2 0 1 1-1.697-1.697L8.303 10 5.652 7.349A1.2 1.2 0 1 1 7.349 5.652L10 8.303l2.651-2.651a1.2 1.2 0 1 1 1.697 1.697L11.697 10l2.651 2.651a1.2 1.2 0 0 1 0 1.697z"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch, computed, nextTick, onMounted } from 'vue';
import axios from 'axios';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import { Icon } from '@iconify/vue';
import insuranceCarriersData from '../../../../public/js/CarrierList.json';

export default {
  name: 'NoLossForms',
  components: {
    InputText,
    Button,
    Calendar,
    Dropdown,
    Icon,
  },
  setup(props, { emit }) {
    const isNotEmpty = (value) => {
      return (
        value !== null && value !== undefined && value.toString().trim() !== ''
      );
    };
    const isValidEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };
    const validateField = (fieldValue, requirement) => {
      return requirement === 'required' ? isNotEmpty(fieldValue) : true;
    };
    const validateEmailField = (emailValue, requirement) => {
      if (requirement !== 'required') return true;
      return isNotEmpty(emailValue) && isValidEmail(emailValue);
    };
    const isFormValid = computed(() => {
      // Individual field validations for required fields
      const insuredName1Valid = validateField(
        formData.insuredName1,
        fieldRequirements.insuredName1,
      );
      const insuredEmail1Valid = validateEmailField(
        formData.insuredEmail1,
        fieldRequirements.insuredEmail1,
      );
      const insuredName2Valid = validateField(
        formData.insuredName2,
        fieldRequirements.insuredName2,
      );
      const insuredEmail2Valid = validateEmailField(
        formData.insuredEmail2,
        fieldRequirements.insuredEmail2,
      );
      const insuredName3Valid = validateField(
        formData.insuredName3,
        fieldRequirements.insuredName3,
      );
      const insuredEmail3Valid = validateEmailField(
        formData.insuredEmail3,
        fieldRequirements.insuredEmail3,
      );
      const policyNumberValid = validateField(
        formData.policyNumber,
        fieldRequirements.policyNumber,
      );
      const selectedPolicyTypeValid =
        fieldRequirements.selectedPolicyType === 'required'
          ? validateField(
              formData.selectedPolicyType,
              fieldRequirements.selectedPolicyType,
            )
          : true;
      const propertyAddressValid =
        fieldRequirements.propertyAddress === 'required'
          ? validateField(
              formData.propertyAddress,
              fieldRequirements.propertyAddress,
            )
          : true;
      const cityValid =
        fieldRequirements.city === 'required'
          ? validateField(formData.city, fieldRequirements.city)
          : true;
      const stateValid =
        fieldRequirements.state === 'required'
          ? validateField(formData.state, fieldRequirements.state)
          : true;
      const zipValid =
        fieldRequirements.zip === 'required'
          ? validateField(formData.zip, fieldRequirements.zip)
          : true;
      const policyEffectiveDateValid =
        fieldRequirements.policyEffectiveDate === 'required'
          ? validateField(
              formData.policyEffectiveDate,
              fieldRequirements.policyEffectiveDate,
            )
          : true;
      const selectedAgencyValid =
        fieldRequirements.selectedAgency === 'required'
          ? validateField(
              formData.selectedAgency,
              fieldRequirements.selectedAgency,
            )
          : true;
      const selectedCarrierValid =
        fieldRequirements.selectedCarrier === 'required'
          ? validateField(
              formData.selectedCarrier,
              fieldRequirements.selectedCarrier,
            )
          : true;

      // Combine all validations to determine overall form validity
      return (
        insuredName1Valid &&
        insuredEmail1Valid &&
        insuredName2Valid &&
        insuredEmail2Valid &&
        insuredName3Valid &&
        insuredEmail3Valid &&
        policyNumberValid &&
        selectedPolicyTypeValid &&
        propertyAddressValid &&
        cityValid &&
        stateValid &&
        zipValid &&
        policyEffectiveDateValid &&
        selectedAgencyValid &&
        selectedCarrierValid
      );
    });

    const successMessage = ref('');
    const errorMessage = ref('');
    const formData = reactive({
      insuredName1: '',
      insuredEmail1: '',
      insuredName2: '',
      insuredEmail2: '',
      insuredName3: '',
      insuredEmail3: '',
      policyNumber: '',
      selectedPolicyType: '',
      propertyAddress: '',
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      policyEffectiveDate: '',
      selectedAgency: '',
      selectedCarrier: '',
      formType: '',
      user: '',
    });
    const handleCarrierChange = () => {
      console.log('Carrier changed to:', formData.selectedCarrier);
      updateFieldVisibility(formData.selectedCarrier);
      formData.formType = `Statement of No Loss ${formData.selectedCarrier}`;
    };

    const manualEntry = ref(false);
    const manualAddress = reactive({
      street: '',
      city: '',
      state: '',
      zip: '',
    });

    const toggleManualEntry = () => {
      manualEntry.value = !manualEntry.value;
      if (manualEntry.value) {
        // Switching to manual entry
        formData.propertyAddress = '';
        formData.streetAddress = manualAddress.street;
        formData.city = manualAddress.city;
        formData.state = manualAddress.state;
        formData.zip = manualAddress.zip;
      } else {
        // Switching back to autocomplete
        manualAddress.street = '';
        manualAddress.city = '';
        manualAddress.state = '';
        manualAddress.zip = '';
        // Optionally clear the formData street address if you want it only populated by autocomplete
        // formData.streetAddress = '';
      }
    };

    function loadGooglePlacesAPI() {
      const input = document.getElementById('propertyAddress');
      if (
        input &&
        window.google &&
        google.maps.places &&
        !window.autocompleteInitialized
      ) {
        initializeAutocomplete(input);
      } else if (!window.google) {
        // Only append the script if it's not already loaded
        if (
          !document.querySelector('script[src*="googleapis.com/maps/api/js"]')
        ) {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCGr836eojr4HL50J0ewPjY-a_pbd5ejp8&libraries=places&callback=onGoogleScriptLoaded`;
          script.async = true;
          document.head.appendChild(script);
          window.onGoogleScriptLoaded = () => {
            initializeAutocomplete(input);
            window.autocompleteInitialized = true; // Mark as initialized
          };
        }
      }
    }

    function initializeAutocomplete(input) {
      if (!input) {
        input = document.getElementById('propertyAddress');
      }
      const autocomplete = new google.maps.places.Autocomplete(input, {
        types: ['geocode'],
      });
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        formData.propertyAddress = place.formatted_address; // Contains the full formatted address

        let streetAddress = ''; // Initialize a variable for the street address

        // Iterate over each component in the address
        place.address_components.forEach((component) => {
          const type = component.types[0];
          switch (type) {
            case 'street_number':
              streetAddress += component.long_name + ' '; // Add street number to the address
              break;
            case 'route':
              streetAddress += component.long_name; // Add the street name to the address
              break;
            case 'locality':
              formData.city = component.long_name;
              break;
            case 'administrative_area_level_1':
              formData.state = component.short_name;
              break;
            case 'postal_code':
              formData.zip = component.long_name;
              break;
          }
        });

        formData.streetAddress = streetAddress.trim(); // Set the street address in formData, trimming any extra space
      });
    }

    watch(
      manualAddress,
      (newVal) => {
        if (manualEntry.value) {
          formData.streetAddress = newVal.street;
          formData.city = newVal.city;
          formData.state = newVal.state;
          formData.zip = newVal.zip;
        }
      },
      { deep: true },
    );

    const formatDate = (date) => {
      if (!date) return '';
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [month, day, year].join('-');
    };
    const agencyOptions = ref([
      { label: 'GLV Insurance Agency', value: 'GLV INSURANCE AGENCY, INC.' },
      { label: 'Rocket MGA', value: 'ROCKET MGA LLC' },
      { label: 'Str8linegroup', value: 'STR8LINE GROUP, LLC' },
      { label: 'Insurance Express.com', value: 'INSURANCE EXPRESS.COM, INC.' },
    ]);
    const policyTypeOptions = ref([
      { label: 'Homeowners', value: 'Homeowners HO-3' },
      { label: 'Condo', value: 'Condo H0-6' },
      { label: 'Renters', value: 'Renters DP-3' },
      { label: 'Commercial', value: 'Commercial' },
    ]);

    const carrierOptions = ref(
      insuranceCarriersData.map((carrier) => ({
        label: carrier.name,
        value: carrier.name,
      })),
    );
    const submitted = ref(false);
    const loading = ref(false);
    const signingUrl = ref('');

    const fieldVisibility = reactive({
      insuredName1: true,
      insuredEmail1: true,
      insuredName2: false,
      insuredEmail2: false,
      insuredName3: false,
      insuredEmail3: false,
      policyNumber: true,
      selectedPolicyType: false,
      selectedAgency: true,
      propertyAddress: false,
      city: false,
      state: false,
      zip: false,
      policyEffectiveDate: false,
    });

    const fieldRequirements = reactive({
      selectedCarrier: 'required',
      insuredName1: 'required',
      insuredEmail1: 'required',
      insuredName2: 'optional',
      insuredEmail2: 'optional',
      insuredName3: 'optional',
      insuredEmail3: 'optional',
      policyNumber: 'required',
      selectedPolicyType: 'optional',
      propertyAddress: 'optional',
      city: 'optional',
      state: 'optional',
      zip: 'optional',
      policyEffectiveDate: 'optional',
      selectedAgency: 'required',
    });

    const fieldLabels = {
      selectedCarrier: 'Carrier',
      insuredName1: 'Insured 1 Name',
      insuredEmail1: 'Insured 1 Email',
      insuredName2: 'Insured 2 Name',
      insuredEmail2: 'Insured 2 Email',
      insuredName3: 'Insured 3 Name',
      insuredEmail3: 'Insured 3 Email',
      policyNumber: 'Policy Number',
      selectedPolicyType: 'Policy Type',
      propertyAddress: 'Property Address',
      city: 'City',
      state: 'State',
      zip: 'Zip Code',
      policyEffectiveDate: 'Policy Effective Date',
      selectedAgency: 'Selected Agency',
      selectedCarrier: 'Selected Carrier',
    };
    const namedCarriers = [
      'Safe Point',
      'Typ Tap',
      'Universal Property & Casualty',
      'Peoples Trust',
      'Pearl Holding Group',
      'Edison',
      'Olympus',
    ];

    const updateFieldVisibility = (carrier) => {
      // Clear all fields to false first to reset visibility and requirements
      Object.keys(fieldVisibility).forEach((key) => {
        fieldVisibility[key] = false;
        fieldRequirements[key] = 'optional';
      });

      console.log(`Carrier Selected: ${carrier}`);

      // Force update to propagate changes
      // Update fields based on the carrier
      if (namedCarriers.includes(carrier)) {
        // Handle specific settings for each named carrier
        switch (carrier) {
          case 'Safe Point':
            fieldVisibility.selectedCarrier = true;
            fieldVisibility.insuredName1 = true;
            fieldVisibility.insuredEmail1 = true;
            fieldVisibility.insuredName2 = true;
            fieldVisibility.insuredEmail2 = true;
            fieldVisibility.propertyAddress = true;
            fieldVisibility.policyNumber = true;
            fieldVisibility.selectedAgency = false;

            fieldRequirements.selectedCarrier = 'required';
            fieldRequirements.insuredName1 = 'required';
            fieldRequirements.insuredEmail1 = 'required';
            fieldRequirements.insuredName2 = 'optional';
            fieldRequirements.insuredEmail2 = 'optional';
            fieldRequirements.policyNumber = 'required';
            fieldRequirements.propertyAddress = 'required';
            break;
          case 'Typ Tap':
            fieldVisibility.selectedCarrier = true;
            fieldVisibility.insuredName1 = true;
            fieldVisibility.insuredEmail1 = true;
            fieldVisibility.policyEffectiveDate = true;

            fieldRequirements.selectedCarrier = 'required';
            fieldRequirements.insuredName1 = 'required';
            fieldRequirements.insuredEmail1 = 'required';
            fieldRequirements.policyEffectiveDate = 'required';
            break;
          case 'Universal Property & Casualty':
            fieldVisibility.selectedCarrier = true;
            fieldVisibility.insuredName1 = true;
            fieldVisibility.insuredEmail1 = true;
            fieldVisibility.insuredName2 = true;
            fieldVisibility.insuredEmail2 = true;

            fieldRequirements.selectedCarrier = 'required';
            fieldRequirements.insuredName1 = 'required';
            fieldRequirements.insuredEmail1 = 'required';
            fieldRequirements.insuredName2 = 'optional';
            fieldRequirements.insuredEmail2 = 'optional';
            break;
          case 'Peoples Trust':
            fieldVisibility.selectedCarrier = true;
            fieldVisibility.insuredName1 = true;
            fieldVisibility.insuredEmail1 = true;
            fieldVisibility.insuredName2 = true;
            fieldVisibility.insuredEmail2 = true;
            fieldVisibility.insuredName3 = true;
            fieldVisibility.insuredEmail3 = true;
            fieldVisibility.selectedPolicyType = true;
            fieldVisibility.policyEffectiveDate = true;
            fieldVisibility.policyNumber = true;
            fieldVisibility.propertyAddress = true;
            fieldVisibility.city = true;
            fieldVisibility.state = true;
            fieldVisibility.zip = true;

            fieldRequirements.selectedCarrier = 'required';
            fieldRequirements.insuredName1 = 'required';
            fieldRequirements.insuredEmail1 = 'required';
            fieldRequirements.insuredName2 = 'optional';
            fieldRequirements.insuredEmail2 = 'optional';
            fieldRequirements.insuredName3 = 'optional';
            fieldRequirements.insuredEmail3 = 'optional';
            fieldRequirements.policyNumber = 'required';
            fieldRequirements.selectedPolicyType = 'required';
            fieldRequirements.policyEffectiveDate = 'required';
            fieldRequirements.propertyAddress = 'required';
            fieldRequirements.city = 'required';
            fieldRequirements.state = 'required';
            fieldRequirements.zip = 'required';

            break;
          case 'Pearl Holding Group':
            fieldVisibility.selectedCarrier = true;
            fieldVisibility.insuredName1 = true;
            fieldVisibility.insuredEmail1 = true;
            fieldVisibility.policyEffectiveDate = true;
            fieldVisibility.agency = true;

            fieldRequirements.selectedCarrier = 'required';
            fieldRequirements.insuredName1 = 'required';
            fieldRequirements.insuredEmail1 = 'required';
            fieldRequirements.policyEffectiveDate = 'required';
            fieldRequirements.agency = 'required';
            break;
          case 'Edison':
            fieldVisibility.selectedCarrier = true;
            fieldVisibility.insuredName1 = true;
            fieldVisibility.insuredEmail1 = true;
            fieldVisibility.insuredName2 = true;
            fieldVisibility.insuredEmail2 = true;
            fieldVisibility.policyEffectiveDate = true;

            fieldRequirements.selectedCarrier = 'required';
            fieldRequirements.insuredName1 = 'required';
            fieldRequirements.insuredEmail1 = 'required';
            fieldRequirements.insuredName2 = 'optional';
            fieldRequirements.insuredEmail2 = 'optional';
            fieldRequirements.policyEffectiveDate = 'required';
            break;
          case 'Olympus':
            fieldVisibility.selectedCarrier = true;
            fieldVisibility.insuredName1 = true;
            fieldVisibility.insuredEmail1 = true;
            fieldVisibility.insuredName2 = true;
            fieldVisibility.insuredEmail2 = true;
            fieldVisibility.propertyAddress = true;
            fieldVisibility.policyEffectiveDate = true;

            fieldRequirements.selectedCarrier = 'required';
            fieldRequirements.insuredName1 = 'required';
            fieldRequirements.insuredEmail1 = 'required';
            fieldRequirements.insuredName2 = 'optional';
            fieldRequirements.insuredEmail2 = 'optional';
            fieldRequirements.propertyAddress = 'required';
            fieldRequirements.policyEffectiveDate = 'required';
            break;

          default:
            // You can remove the default case if you're handling it above
            break;
        }
      } else {
        // Fallback settings if the carrier is not named
        fieldVisibility.selectedCarrier = true;
        fieldVisibility.selectedAgency = true;
        fieldVisibility.insuredName1 = true;
        fieldVisibility.insuredEmail1 = true;
        fieldVisibility.propertyAddress = true;
        fieldVisibility.policyNumber = true;
        fieldVisibility.policyEffectiveDate = true;

        fieldRequirements.selectedCarrier = 'required';
        fieldRequirements.selectedAgency = 'required';
        fieldRequirements.insuredName1 = 'required';
        fieldRequirements.insuredEmail1 = 'required';
        fieldRequirements.propertyAddress = 'required';
        fieldRequirements.policyNumber = 'required';
        fieldRequirements.policyEffectiveDate = 'required';
      }
      // Log the updated visibility and requirement settings
      console.log('Updated fieldVisibility:', fieldVisibility);
      console.log('Updated fieldRequirements:', fieldRequirements);
    };

    // Use this function to initialize the field settings when the component is created
    // or when the selected carrier changes.
    updateFieldVisibility(formData.selectedCarrier);

    // Watch for changes in the selected carrier and update field visibility
    watch(
      () => formData.selectedCarrier,
      (newVal) => {
        updateFieldVisibility(newVal);
      },
    );
    watch(
      formData,
      (newVal) => {
        console.log('Form Data Updated:', newVal);
      },
      { deep: true },
    );

    watch(
      fieldVisibility,
      (newVal) => {
        console.log('Field Visibility Updated:', newVal);
      },
      { deep: true },
    );

    const goBack = () => {
      emit('closeForm'); // Emit an event to the parent component
    };
    const prepareSubmission = () => {
      if (isFormValid.value) {
        submitted.value = true; // Proceed to submission review if all fields are valid
        console.log('Proceeding to submission review:', formData);
      } else {
        console.error(
          'Validation failed, cannot proceed to submission review.',
        );
        alert('Please correct the errors in the form before proceeding.');
      }
    };

    const confirmAndSubmit = async () => {
      if (!isFormValid.value) {
        console.error('Validation failed, cannot submit form.');
        alert('Please correct the errors in the form before submitting.');
        return; // Stop execution if the form is not valid
      }

      loading.value = true;
      try {
        const response = await axios.post(
          'api/generate-signing-link-accord-form',
          formData,
        );
        if (response.status === 200) {
          successMessage.value = 'Document sent successfully!';
          errorMessage.value = ''; // Clear any previous errors
          console.log('Submission successful:', response.data);
        } else {
          throw new Error('Failed to send document due to server response.');
        }
      } catch (error) {
        errorMessage.value = 'Failed to send document. Please try again.';
        successMessage.value = ''; // Clear any previous success message
        console.error('Error sending document:', error);
      } finally {
        loading.value = false; // Ensure loading is false after operations complete
      }
    };

    return {
      formData,
      successMessage,
      errorMessage,
      carrierOptions,
      submitted,
      loading,
      signingUrl,
      fieldVisibility,
      formatDate,
      policyTypeOptions,
      isNotEmpty,
      fieldRequirements,
      fieldLabels,
      agencyOptions,
      prepareSubmission,
      confirmAndSubmit,
      handleCarrierChange,
      manualEntry,
      toggleManualEntry,
      manualAddress,
      loadGooglePlacesAPI,
      initializeAutocomplete,
      isFormValid,
      goBack,
    };
  },
};
</script>

<style scoped>
.p-dropdown {
  border: 1px solid #6b7280;
  width: 207px;
}
.p-calendar .p-inputtext {
  border-radius: 3px;
}
#policyNumber::placeholder {
  text-transform: none; /* Ensures placeholder text remains normal case */
}

#policyNumber {
  text-transform: uppercase; /* Transforms user input to uppercase */
}
</style>
