<template>
  <div class="flex flex-col md:flex-row max-h-screen bg-gray-100">
    <!-- Navigation Component -->
    <Navigation />

    <!-- Main Content Area -->
    <div class="flex-grow flex flex-col p-4 md:p-6 overflow-y-auto gradient-bg">

      <!-- Header Section -->
      <header class="flex flex-col md:flex-row items-center justify-between mb-6 p-6 bg-opacity-90 bg-white rounded-lg shadow-md">
        <div class="flex flex-col md:flex-row items-center mx-auto md:mx-0">
          <!-- Icon for the Header -->
          <Icon icon="mdi:shield-check" class="h-8 w-8 text-blue-500 mr-3 md:mr-6"/>
          <div>
            <h1 class="text-xl md:text-3xl font-bold text-gray-700">Underwriting Dashboard</h1>
            <p class="hidden md:block text-gray-500 text-sm">Manage and review underwriting guidelines efficiently</p>
          </div>
        </div>
      </header>


      <div :class="isCollapsed ? 'bg-white rounded-lg shadow-md opacity-90 p-4 mb-6' : 'bg-white rounded-lg shadow-md opacity-90 p-6 mb-6'">
        <!-- Toggle Button and Result Count for Mobile -->
        <div :class="isCollapsed ? 'flex justify-between items-center' : 'flex justify-between items-center mb-4'">
          <button @click="isCollapsed = !isCollapsed"
                  class="md:hidden bg-blue-500 text-white rounded-lg px-3 py-1">
            {{ isCollapsed ? 'Show Filters' : 'Hide Filters' }}
          </button>
          <span v-if="resultCount > 0" class="text-sm font-medium text-gray-700">Results: {{ resultCount }}</span>
        </div>
        <!-- Combined Selection Section -->
<div :class="{'hidden md:flex': isCollapsed, 'flex': !isCollapsed}" class="flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 transition-all duration-300 ease-in-out">
  <!-- State Selection Dropdown -->
  <div class="w-full md:w-1/4">
    <label for="state" class="block text-sm font-medium text-gray-700">Select State:</label>
    <select v-model="selectedState" id="state" class="mt-1 block w-full form-select">
      <option value="" disabled>Select a state</option>
      <option v-for="state in stateOptions" :key="state.value" :value="state.value">{{ state.name }}</option>
    </select>
    <!-- Notification for State Selection -->
    <div v-if="!selectedState" class="text-sm mt-4">
      <span class="bg-white bg-opacity-90 text-gray-700 font-semibold p-2 rounded-full animate-pulse">Please select a state.</span>
    </div>
    <!-- Invisible Placeholder -->
    <div v-if="!selectedState || !selectedProduct" class="text-sm mt-4 invisible">
      <span class="bg-white bg-opacity-90 text-gray-700 font-semibold p-2 rounded-full">Please select a state.</span>
    </div>
  </div>

  <!-- Product Selection Dropdown -->
  <div class="w-full md:w-1/4">
    <label for="product" class="block text-sm font-medium text-gray-700">Select Product:</label>
    <select v-model="selectedProduct" id="product" class="mt-1 block w-full form-select">
      <option value="" disabled>Select a product</option>
      <option v-for="product in productOptions" :key="product.value" :value="product.value">{{ product.name }}</option>
    </select>
    <!-- Notification for Product Selection -->
    <div v-if="!selectedProduct" class="text-sm mt-4">
      <span class="bg-white bg-opacity-90 text-gray-700 font-semibold p-2 rounded-full animate-pulse">Please select a product.</span>
    </div>
    <!-- Invisible Placeholder -->
    <div v-if="!selectedProduct || !selectedState" class="text-sm mt-4 invisible">
      <span class="bg-white bg-opacity-90 text-gray-700 font-semibold p-2 rounded-full">Please select a product.</span>
    </div>
  </div>


            <!-- Guideline and Carrier Dropdowns are only shown when a State and Product have been selected -->
            <template v-if="selectedState && selectedProduct">
              <div class="w-full md:w-1/4">
                <label for="selectedUnderwritingGuidelineCategory" class="block text-sm font-medium text-gray-700">Select Guideline:</label>
                <select v-model="selectedUnderwritingGuidelineCategory" id="selectedUnderwritingGuidelineCategory" class="mt-1 block w-full form-select">
                  <option value="All">All</option>
                  <option v-for="underwritingGuideline in underwritingGuidelineCategory" :key="underwritingGuideline.value" :value="underwritingGuideline.name">{{ underwritingGuideline.name }}</option>
                </select>
              </div>
              <div class="w-full md:w-1/4">
                <label for="pickSpecificCarrier" class="block text-sm font-medium text-gray-700">Pick Specific Carrier:</label>
                <select v-model="selectedCarrierId" id="pickSpecificCarrier" class="mt-1 block w-full form-select">
                  <option value="" disabled>Select a Carrier</option>
                  <option v-for="carrier in filteredCarriersByUserSelection" :key="carrier.id" :value="carrier.id">{{ carrier.carrier_name }}</option>
                </select>
              </div>
              <!-- Search Input -->
              <div class="w-full md:w-1/4">
                <label for="search" class="block text-sm font-medium text-gray-700">Search:</label>
                <input type="text" v-model="searchQuery" @input="performSearch" id="search" class="mt-1 block w-full form-input" placeholder="Search...">
              </div>
            </template>
        </div>
      </div>

      <!-- Loading Spinner -->
      <div v-if="isLoading" class="flex justify-center items-center h-full text-center">
        <SpinnerComponent />
      </div>

      <!-- Content Section (Based on Selections) -->
      <div v-else>
        <!-- Prompt for Selecting State and Product -->
        <div v-if="!selectedState || !selectedProduct" class="flex justify-center items-center h-full text-center">
          <div class="bg-white bg-opacity-90 shadow-md rounded-lg p-8 max-w-md mx-auto text-gray-700">
            <Icon icon="mdi:information-outline" class="h-10 w-10 text-blue-500 mb-4 mx-auto"/>
            <p class="text-xl font-semibold mb-2">Get Started</p>
            <p class="text-sm">Please select a <span class="font-semibold">STATE</span> and <span class="font-semibold">PRODUCT</span> to display the relevant carriers and their guidelines.</p>
          </div>
        </div>

        <!-- Display Guidelines and Carriers Section -->
        <div v-else>
        

          <!-- Carrier Cards Component -->
          <CarrierCards
            :filteredCarriers="filteredCarriersByUserSelection"
            :filteredGuidelines="filteredGuidelines"
            :carriers="carriers"
            :selectedUnderwritingGuidelineCategory="selectedUnderwritingGuidelineCategory"
            :productType="selectedProduct"
            :selectedCarrierId="selectedCarrierId"
            @updateGuideline="handleUpdateGuideline"
            @deleteGuideline="handleDeleteGuideline"
            @openAddGuidelineModal="handleOpenAddGuidelineModal"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Toast Notification Component -->
  <Toast />
</template>


<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { Icon } from '@iconify/vue';
import Navigation from '../components/Navigation.vue';
import CarrierCards from '../underwriting/CarrierCards.vue';
import SpinnerComponent from '../../components/SpinnerComponent.vue';
import axios from 'axios';
import { useToast } from 'primevue/usetoast';

const toast = useToast();

const isCollapsed = ref(false);
const resultCount = computed(() => {
  if (selectedState.value && selectedProduct.value) {
    return filteredCarriersByUserSelection.value.length;  // Adjust this according to your data structure and what you want to count
  }
  return 0;
});

const stateOptions = ref([
  { name: 'Florida', value: 'FL' },
  { name: 'Texas', value: 'TX' },
  { name: 'New York', value: 'NY' },
  { name: 'New Jersey', value: 'NJ' },
  { name: 'New Hampshire', value: 'NH' },
  { name: 'Rhode Island', value: 'RI' },
  { name: 'Massachusetts', value: 'MA' },
  { name: 'Georgia', value: 'GA' },
  { name: 'South Carolina', value: 'SC' },
  { name: 'North Carolina', value: 'NC' },
  { name: 'Connecticut', value: 'CT' },
]);

const productOptions = ref([
  { name: 'HO-3', value: 'HO-3' },
  { name: 'HO-6', value: 'HO-6' },
  { name: 'DP-3', value: 'DP-3' },
  { name: 'Personal Auto', value: 'Personal Auto' },
  { name: 'Commercial Auto', value: 'Commercial Auto' },
]);
const underwritingGuidelineCategory = ref([
  { name: 'Age of Roof', value: 'age_of_roof' },
  { name: 'Electrical', value: 'electrical' },
  { name: 'Four Point Inspection', value: 'four_point' },
  { name: 'Age of Home', value: 'age_of_home' },
  { name: 'Acceptable Named Insureds', value: 'acceptable_named_insureds' },
  {
    name: 'Assumed Wind Mitigation Credits',
    value: 'assumed_wind_mit_credits',
  },
  { name: 'Claims History', value: 'claims_history' },
]);
const selectedState = ref('');
const selectedProduct = ref('');
const selectedUnderwritingGuidelineCategory = ref('All');
watch(selectedUnderwritingGuidelineCategory, (newVal, oldVal) => {
  if (newVal !== null) {
    // Check if the newVal is not null
    console.log('Selected UW GUIDELINE STEP 1:', newVal.name);
    console.log('Selected UW GUIDELINE STEP 2:', newVal.value);
  }
});

const selectedFilters = ref([]);

const carriers = ref([]);
const guidelines = ref([]);
const selectedCarrierId = ref(null);
const showAddGuidelineModal = ref(false);
const isLoading = ref(true); // Loading state

const fetchCarriers = async () => {
  try {
    const response = await axios.get('/api/carriers');
    carriers.value = response.data.map((carrier) => ({
      ...carrier,
      logo: carrier.logo ? `${axios.defaults.baseURL}${carrier.logo}` : null,
      operating_states:
        carrier.operating_states?.map((state) => ({
          name: state.name,
          value: state.value,
        })) || [],
      products:
        carrier.products?.map((product) => ({
          name: product.name,
          value: product.value,
        })) || [],
    }));
    await fetchGuidelines();
    isLoading.value = false;
  } catch (error) {
    console.error('Error fetching carriers:', error);
    isLoading.value = false;
  }
};

const fetchGuidelines = async () => {
  try {
    const response = await axios.get('/api/underwriting-guidelines');
    if (response.headers['content-type'].includes('application/json')) {
      guidelines.value = response.data;
    } else {
      console.error('Unexpected response format:', response.data);
      throw new Error('Response is not JSON');
    }
  } catch (error) {
    console.error('Error fetching underwriting guidelines:', error);
  }
};

const filteredCarriers = computed(() => {
  return carriers.value.filter((carrier) => {
    const stateMatch = selectedState.value
      ? carrier.operating_states.some(
          (state) => state.value === selectedState.value,
        )
      : true;
    const productMatch = selectedProduct.value
      ? carrier.products.some(
          (product) => product.value === selectedProduct.value,
        )
      : true;
    return stateMatch && productMatch;
  });
});
const filteredCarriersByUserSelection = computed(() => {
  return filteredCarriers.value.filter((carrier) => {
    return selectedCarrierId.value
      ? carrier.id === selectedCarrierId.value
      : true;
  });
});
const filteredGuidelines = computed(() => {
  return guidelines.value.filter((guideline) => {
    const stateData = tryParseJSON(guideline.state_guideline_applies_to);
    const productData = tryParseJSON(guideline.product_guideline_applies_to);
    const stateMatch =
      selectedState.value && stateData
        ? stateData.value === selectedState.value
        : true;
    const productMatch =
      selectedProduct.value && productData
        ? productData.value === selectedProduct.value
        : true;
    return stateMatch && productMatch;
  });
});

function tryParseJSON(jsonString) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return null;
  }
}

const handleOpenAddGuidelineModal = (carrierId) => {
  console.log(
    'Open add guideline modal event received for Carrier ID:',
    carrierId,
  );
  selectedCarrierId.value = carrierId;
  showAddGuidelineModal.value = true;
};

const handleUpdateGuideline = async (updatedGuideline) => {
  try {
    // Send a PUT request to update the guideline on the server
    const response = await axios.put(
      `/api/underwriting-guidelines/${updatedGuideline.id}`,
      updatedGuideline,
    );

    // Find the index of the guideline in the local guidelines array
    const index = guidelines.value.findIndex(
      (g) => g.id === updatedGuideline.id,
    );

    // If the guideline exists, update it in the local array
    if (index !== -1) {
      guidelines.value[index] = response.data;
    } else {
      // If the guideline does not exist, add it to the local array
      guidelines.value.push(response.data);
    }

    // Display a success message
    toast.add({
      severity: 'success',
      summary: 'Updated',
      detail: 'Guideline successfully updated',
      life: 3000,
    });
  } catch (error) {
    // Log the error to the console
    console.error('Error updating guideline:', error);

    // Display an error message
    toast.add({
      severity: 'error',
      summary: 'Update Failed',
      detail:
        'Failed to update guideline: ' +
        (error.response.data.error || 'Server error'),
      life: 5000,
    });
  }
};

const handleDeleteGuideline = async (guidelineId) => {
  try {
    await axios.delete(`/api/underwriting-guidelines/${guidelineId}`);
    guidelines.value = guidelines.value.filter((g) => g.id !== guidelineId);
    toast.add({
      severity: 'warn',
      summary: 'Deleted',
      detail: 'Guideline has been deleted',
      life: 3000,
    });
  } catch (error) {
    console.error('Error deleting guideline:', error);
    toast.add({
      severity: 'error',
      summary: 'Deletion Failed',
      detail:
        'Failed to delete guideline: ' +
        (error.response.data.error || 'Server error'),
      life: 5000,
    });
  }
};

onMounted(() => {
  fetchCarriers();
  window.Echo.channel('guidelines').listen('GuidelineUpdated', (e) => {
    console.log('Guideline updated:', e.guideline);
    fetchGuidelines();
  });
});
</script>

<style scoped>
.gradient-bg {
  background: linear-gradient(
    -45deg,
    rgba(93, 133, 255, 0.30),
    rgba(252, 130, 150, 0.30),
    rgba(87, 166, 209, 0.30),
    rgba(159, 88, 277, 0.30)
  );
  background-size: 200% 200%;
  animation: gradient 30s ease infinite;
  height: 100vh;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.btn-primary {
  background-color: #1a73e8;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}
.btn-primary:hover {
  background-color: #135ab5;
}
</style>
