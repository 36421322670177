<template>
  <AppHeaderDesktop />
  <div class="py-8">
    <makePaymentMakeClaim :carrierDirectoryType="carrierDirectoryType" />
  </div>
  <AppFooter />
</template>

<script>
import AppHeaderDesktop from '../components/AppHeaderDesktop.vue';
import AppFooter from '../components/AppFooter.vue';
import makePaymentMakeClaim from '../components/customerservice/managepolicy/serviceRequestForms.vue/makePaymentmakeClaim.vue';

export default {
  name: 'makeAClaimMakeAPayment',
  props: ['carrierDirectoryType'],
  components: {
    AppHeaderDesktop,
    makePaymentMakeClaim,
    AppFooter,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
/* Your styles here */
</style>
