<template>
  <div
    class="win-back-modal fixed inset-0 bg-black bg-opacity-60 overflow-y-auto h-full w-full flex justify-center items-center z-50"
  >
    <div
      class="modal-content bg-white p-4 md:p-8 rounded-lg shadow-xl max-w-lg mx-2 md:mx-4 max-h-[90vh] overflow-y-auto"
    >
      <header class="modal-header text-center">
        <img
          src="@/assets/images/IEsubmark.svg"
          alt="Insurance Express Logo"
          class="modal-logo h-16 mx-auto p-2 bg-gray-100 rounded-full"
        />
        <h3 class="mt-4 text-xl md:text-2xl font-bold text-gray-800">
          {{ dynamicHeading }}
        </h3>
      </header>
      <div class="modal-body mt-4 md:mt-6">
        <!-- Offer sections based on the reason -->
        <template v-if="!offerAcceptance">
          <!--Switching Insurers Section-->
          <div
            v-if="reason === 'switching_insurers' && !formSubmitted"
            class="offer-section my-4 md:my-6 p-4 rounded-lg shadow-lg bg-gradient-to-r from-green-100 via-green-200 to-green-100"
          >
            <h2
              class="text-xl md:text-2xl font-semibold text-gray-800 mb-3 md:mb-4 flex items-center justify-start"
            >
              <span class="icon mr-2 text-3xl">🔄</span>Thinking of Switching
              Insurers?
            </h2>
            <p class="text-sm md:text-md text-gray-800 mb-3 md:mb-4">
              Explore <strong>unparalleled choices</strong> with us! We proudly
              represent <em>every major insurer in Florida</em>, ensuring you
              get the best possible options tailored to your needs.
            </p>
            <ul class="list-none p-0">
              <li class="mb-2 md:mb-3 text-sm md:text-md text-gray-800 flex">
                <span class="icon mr-2 text-xl">💼</span>
                <p>
                  Allow us the opportunity to
                  <strong>secure a better deal</strong> than your current
                  policy. We're committed to
                  <strong>outmatching your existing offer</strong> or finding
                  one that suits you better.
                </p>
              </li>
              <li class="mb-2 md:mb-3 text-sm md:text-md text-gray-800 flex">
                <span class="icon mr-2 text-xl">🌟</span>
                <p>
                  Gain <strong>exclusive access</strong> to special deals and
                  discounts
                  <strong>not available to the general public</strong>. Let us
                  unlock savings and benefits for you.
                </p>
              </li>
            </ul>
            <div class="interest-confirmation mt-3 md:mt-4">
              <label class="flex items-center mb-1 md:mb-2">
                <input
                  type="radio"
                  value="yes"
                  v-model="interest"
                  @change="emitInterest"
                  class="mr-2"
                />
                I am interested in this offer.
              </label>
              <label class="flex items-center">
                <input
                  type="radio"
                  value="no"
                  v-model="interest"
                  @change="emitInterest"
                  class="mr-2"
                />
                I am not interested at this time.
              </label>
            </div>
          </div>
          <!--Financial Hardship Section-->
          <div
            v-if="reason === 'financial_hardship' && !formSubmitted"
            class="offer-section my-4 sm:my-6 p-3 sm:p-4 rounded-lg shadow-lg bg-gradient-to-r from-blue-100 to-blue-200"
          >
            <h2
              class="text-xl sm:text-2xl font-semibold text-gray-800 mb-3 sm:mb-4 flex items-center justify-start"
            >
              <span class="icon mr-2 text-2xl sm:text-3xl">💡</span>Feeling
              Financial Pressure?
            </h2>
            <p class="text-sm sm:text-md text-gray-800 mb-3 sm:mb-4">
              We understand that times can be tough. That's why we're here to
              <strong>customize policies to fit within your budget</strong>,
              helping you keep the coverage you need without the financial
              strain.
            </p>
            <ul class="list-none p-0">
              <li class="mb-2 sm:mb-3 text-sm sm:text-md text-gray-800 flex">
                <span class="icon mr-2 text-xl">🔍</span>
                Discover options that could reduce your payments by up to 80%.
              </li>
              <li class="mb-2 sm:mb-3 text-sm sm:text-md text-gray-800 flex">
                <span class="icon mr-2 text-xl">🛡️</span>
                Keeping insurance is vital, and finding a plan that aligns with
                your financial situation is our priority.
              </li>
            </ul>
            <div class="interest-confirmation mt-3 sm:mt-4">
              <label class="flex items-center mb-1 sm:mb-2">
                <input
                  type="radio"
                  value="yes"
                  v-model="interest"
                  @change="emitInterest"
                  class="mr-2"
                />
                I am interested in exploring my options.
              </label>
              <label class="flex items-center">
                <input
                  type="radio"
                  value="no"
                  v-model="interest"
                  @change="emitInterest"
                  class="mr-2"
                />
                I am not interested at this time.
              </label>
            </div>
          </div>
          <!--Policy Consolidation Section-->
          <div
            v-if="reason === 'policy_consolidation' && !formSubmitted"
            class="offer-section my-4 sm:my-6 p-3 sm:p-4 rounded-lg shadow-lg bg-gradient-to-r from-purple-100 to-purple-200"
          >
            <h2
              class="text-xl sm:text-2xl font-semibold text-gray-800 mb-3 sm:mb-4 flex items-center justify-start"
            >
              <span class="icon mr-2 text-2xl sm:text-3xl">🔍</span>Considering
              Policy Consolidation?
            </h2>
            <p class="text-sm sm:text-md text-gray-800 mb-3 sm:mb-4">
              It's essential to weigh the long-term benefits. While bundling can
              be attractive,
              <strong>it might not always be the most beneficial</strong> in the
              long run.
            </p>
            <ul class="list-none p-0">
              <li class="mb-2 sm:mb-3 text-sm sm:text-md text-gray-800 flex">
                <span class="icon mr-2 text-xl">🚨</span>
                Beware that bundled discounts may not provide the best value
                over time. Let's analyze and compare.
              </li>
              <li class="mb-2 sm:mb-3 text-sm sm:text-md text-gray-800 flex">
                <span class="icon mr-2 text-xl">🔄</span>
                We're here to review your policies thoroughly, ensuring you
                receive the optimal coverage and pricing.
              </li>
            </ul>
            <div class="interest-confirmation mt-3 sm:mt-4">
              <label class="inline-flex items-center">
                <input
                  type="radio"
                  value="yes"
                  v-model="interest"
                  @change="emitInterest"
                  class="mr-2"
                />
                <span class="ml-2 text-gray-800"
                  >Count me in for policy review</span
                >
              </label>
              <label class="inline-flex items-center ml-4 sm:ml-6">
                <input
                  type="radio"
                  value="no"
                  v-model="interest"
                  @change="emitInterest"
                  class="mr-2"
                />
                <span class="ml-2 text-gray-800">I'll pass for now</span>
              </label>
            </div>
          </div>
          <!--Selling The Home Section -->
          <div
            v-if="reason === 'selling_home' && !formSubmitted"
            class="offer-section my-4 sm:my-6 p-3 sm:p-4 rounded-lg shadow-lg bg-gradient-to-r from-yellow-100 to-yellow-200"
          >
            <h2
              class="text-xl sm:text-2xl font-semibold text-gray-800 mb-3 sm:mb-4 flex items-center justify-start"
            >
              <span class="icon mr-2 text-2xl sm:text-3xl">🏡</span>Selling Your
              Home?
            </h2>
            <p class="text-sm sm:text-md text-gray-800 mb-3 sm:mb-4">
              We understand that every home has its unique charm and challenges.
              As you prepare for your next adventure, let us ensure that your
              transition to a new home is protected with the perfect coverage.
            </p>
            <ul class="list-none p-0">
              <li class="mb-2 sm:mb-3 text-sm sm:text-md text-gray-800 flex">
                <span class="icon mr-2 text-xl">🤝</span>
                Provide us with your realtor's or mortgage lender's contact
                information so we can collaborate to secure the ideal insurance
                solution for your new residence.
              </li>
              <li class="mb-2 sm:mb-3 text-sm sm:text-md text-gray-800 flex">
                <span class="icon mr-2 text-xl">🛡️</span>
                We're committed to assisting you through this change. Our goal
                is to compare and offer optimal coverage options tailored to
                your new home's unique needs.
              </li>
            </ul>
            <div
              class="contact-info-form mt-3 sm:mt-4"
              v-if="reason === 'selling_home' && !formSubmitted"
            >
              <label
                for="realtorLenderFirstName"
                class="block text-gray-700 text-sm sm:text-md font-bold mb-2"
                >Realtor's/Mortgage Lender's First Name:</label
              >
              <input
                type="text"
                v-model="realtorLenderFirstName"
                id="realtorLenderFirstName"
                placeholder="First Name"
                class="contact-info-input py-2 px-3 sm:px-4 rounded-lg w-full mb-3 border border-gray-300"
              />
              <p
                v-if="realtorLenderFirstNameError"
                class="text-red-500 text-xs italic"
              >
                {{ realtorLenderFirstNameError }}
              </p>

              <label
                for="realtorLenderLastName"
                class="block text-gray-700 text-sm sm:text-md font-bold mb-2"
                >Realtor's/Mortgage Lender's Last Name:</label
              >
              <input
                type="text"
                v-model="realtorLenderLastName"
                id="realtorLenderLastName"
                placeholder="Last Name"
                class="contact-info-input py-2 px-3 sm:px-4 rounded-lg w-full mb-3 border border-gray-300"
              />
              <p
                v-if="realtorLenderLastNameError"
                class="text-red-500 text-xs italic"
              >
                {{ realtorLenderLastNameError }}
              </p>

              <label
                for="realtorLenderEmail"
                class="block text-gray-700 text-sm sm:text-md font-bold mb-2"
                >Realtor's/Mortgage Lender's Email:</label
              >
              <input
                type="email"
                v-model="realtorLenderEmail"
                id="realtorLenderEmail"
                placeholder="Email"
                class="contact-info-input py-2 px-3 sm:px-4 rounded-lg w-full mb-3 border border-gray-300"
              />
              <p
                v-if="realtorLenderEmailError"
                class="text-red-500 text-xs italic"
              >
                {{ realtorLenderEmailError }}
              </p>

              <label
                for="realtorLenderPhoneNumber"
                class="block text-gray-700 text-sm sm:text-md font-bold mb-2"
                >Realtor's/Mortgage Lender's Phone Number:</label
              >
              <input
                type="tel"
                v-model="realtorLenderPhoneNumber"
                v-mask="'(###) ###-####'"
                maxlength="14"
                id="realtorLenderPhoneNumber"
                placeholder="Phone Number"
                class="contact-info-input py-2 px-3 sm:px-4 rounded-lg w-full mb-3 border border-gray-300"
              />
              <p
                v-if="realtorLenderPhoneNumberError"
                class="text-red-500 text-xs italic"
              >
                {{ realtorLenderPhoneNumberError }}
              </p>
            </div>
          </div>
        </template>
      </div>
      <footer
        v-if="!offerAcceptance"
        class="modal-footer flex justify-center mt-4 flex-wrap gap-2"
      >
        <!-- Adjusted classes for mobile responsiveness -->
        <button
          v-if="reason === 'selling_home'"
          @click="submitRealtorInfoContinueWithCancel"
          class="w-full sm:w-auto px-4 sm:px-6 py-2 rounded-md text-white transition-colors duration-200 shadow-md hover:bg-green-500 bg-green-400 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-opacity-50 text-sm sm:text-base"
        >
          Submit Contact Information and Continue with Cancel
        </button>

        <button
          v-if="reason === 'switching_insurers' && interest === 'yes'"
          @click="submitContactInfoContinueWithCancel"
          class="w-full sm:w-auto px-4 sm:px-6 py-2 rounded-md text-white transition-colors duration-200 shadow-md hover:bg-green-500 bg-green-400 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-opacity-50 text-sm sm:text-base"
        >
          Submit Contact Information and Obtain a New Offer
        </button>

        <button
          v-if="reason === 'policy_consolidation' && interest === 'yes'"
          @click="submitContactInfoContinueWithCancel"
          class="w-full sm:w-auto px-4 sm:px-6 py-2 rounded-md text-white transition-colors duration-200 shadow-md hover:bg-green-500 bg-green-400 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-opacity-50 text-sm sm:text-base"
        >
          Submit Contact Information and Receive a Policy Review
        </button>

        <button
          v-if="reason === 'financial_hardship' && interest === 'yes'"
          @click="submitContactInfoContinueWithCancel"
          class="w-full sm:w-auto px-4 sm:px-6 py-2 rounded-md text-white transition-colors duration-200 shadow-md hover:bg-green-500 bg-green-400 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-opacity-50 text-sm sm:text-base"
        >
          Submit Contact Information and Explore Options
        </button>

        <button
          v-else-if="interest !== 'yes' || reason === 'selling_home'"
          @click="continueWithCancel"
          :class="{
            'cursor-not-allowed opacity-50 bg-gray-400':
              interest === null && reason !== 'selling_home',
            'hover:bg-red-500 bg-red-400':
              interest === 'no' || reason === 'selling_home',
          }"
          class="w-full sm:w-auto px-4 sm:px-6 py-2 rounded-md text-white transition-colors duration-200 shadow-md focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50 text-sm sm:text-base"
          :disabled="interest === null && reason !== 'selling_home'"
        >
          Continue with Cancel
        </button>
      </footer>
      <!--Accept Offer Modal-->
      <transition name="fade">
        <div
          v-if="offerAcceptance"
          class="acceptance-section p-6 my-4 rounded-lg shadow-lg bg-blue-100 border-l-4 border-blue-500"
        >
          <h3 class="text-lg font-semibold text-blue-800 mb-2">Great News!</h3>
          <p class="text-blue-700">{{ acceptanceMessage }}</p>
          <div class="mt-4 bg-white p-4 rounded-lg shadow">
            <div v-if="callTimes.length > 0" class="mt-4">
              <p class="mb-4 font-medium text-sm md:text-md">
                Please select a convenient time for us to call you back:
              </p>
              <div
                class="time-slots-container overflow-y-auto"
                style="max-height: 150px"
              >
                <div class="flex flex-col gap-2">
                  <div
                    v-for="(time, index) in callTimes"
                    :key="index"
                    class="flex items-center"
                  >
                    <button
                      class="ml-2 px-4 py-2 text-center font-semibold text-sm md:text-md rounded-lg border shadow transition-colors duration-150 ease-in-out flex items-center justify-center gap-2"
                      :class="{
                        'bg-blue-500 text-white': selectedTime === time,
                        'bg-gray-200 text-gray-600': selectedTime !== time,
                      }"
                      @click="selectTime(time)"
                      :disabled="selectedTime !== null && selectedTime !== time"
                    >
                      <!-- Icon to the left -->
                      <Icon
                        icon="mdi:phone-schedule"
                        :class="{
                          'text-white': selectedTime === time,
                          'text-blue-500': selectedTime !== time,
                        }"
                      />
                      <!-- Time text -->
                      {{ time }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <button
        class="mt-4 w-full px-4 py-2 rounded-md transition-colors duration-200 shadow-md focus:outline-none focus:ring-2 bg-blue-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-opacity-50 text-white text-sm sm:text-base"
        :class="{ 'opacity-50 cursor-not-allowed': selectedTime == null }"
        :disabled="selectedTime === null"
        v-if="offerAcceptance"
        @click="submitCallbackTimeAndContinueWithCancel"
      >
        Submit callback time and continue with cancel
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { Icon } from '@iconify/vue';

export default {
  name: 'WinBackModal',
  components: {
    Icon,
  },
  props: {
    reason: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formSubmitted = ref(false);
    const acceptanceMessage = computed(() => {
      if (interest.value === 'yes' && offerAcceptance.value) {
        switch (props.reason) {
          case 'switching_insurers':
            return 'Fantastic! Your desire to explore better insurance options has been noted. A dedicated agent will contact you shortly with competitive offers that match your needs.';
          case 'financial_hardship':
            return 'We understand your situation and are here to support you. Our team is working to find you flexible policy options, and we will reach out with affordable solutions today.';
          case 'policy_consolidation':
            return 'Smart move! Consolidating policies can lead to savings. We’re reviewing your information and will present you with the most cost-effective options for your consideration.';
          case 'selling_home':
            return 'Exciting times ahead! We’re thrilled to assist you with your home transition. Expect a call from our agent today with the ideal insurance for your new home.';
          default:
            return 'Thank you for your interest! We are processing your request and will provide you with the details you need as soon as possible.';
        }
      }
      return '';
    });
    const dynamicHeading = computed(() => {
      switch (props.reason) {
        case 'switching_insurers':
          return 'More options than ever before!';
        case 'financial_hardship':
          return 'You have more options!';
        case 'policy_consolidation':
          return 'Considering Bundling?';
        case 'selling_home':
          return 'Helping Your Transition';
        default:
          return 'Welcome to Your Insurance Solutions';
      }
    });
    const offerAcceptance = ref(false);

    const selectedTime = ref(null);
    const selectedCallbackTime = ref(null);

    const submitCallbackTimeAndContinueWithCancel = () => {
      emit('closeWinBackModal');
      emit('openTermsModal');
    };

    const selectTime = (time) => {
      selectedTime.value = time;
      selectedCallbackTime.value = time;
      emit('selectedCallbackTime', time); // Directly emit here
    };

    const schema = yup.object({
      realtorLenderFirstName: yup.string().required('First Name is required'),
      realtorLenderLastName: yup.string().required('Last Name is required'),
      realtorLenderEmail: yup
        .string()
        .email('Please enter a valid Email address')
        .required('Email is required'),
      realtorLenderPhoneNumber: yup
        .string()
        .required('Phone Number is required'),
    });

    const { handleSubmit, errors } = useForm({ validationSchema: schema });

    const interest = ref('');
    const {
      value: realtorLenderFirstName,
      errorMessage: realtorLenderFirstNameError,
    } = useField(
      'realtorLenderFirstName',
      yup.string().required('First name is required'),
    );
    const {
      value: realtorLenderLastName,
      errorMessage: realtorLenderLastNameError,
    } = useField(
      'realtorLenderLastName',
      yup.string().required('Last name is required'),
    );
    const { value: realtorLenderEmail, errorMessage: realtorLenderEmailError } =
      useField(
        'realtorLenderEmail',
        yup.string().email('Invalid email').required('Email is required'),
      );
    const {
      value: realtorLenderPhoneNumber,
      errorMessage: realtorLenderPhoneNumberError,
    } = useField(
      'realtorLenderPhoneNumber',
      yup.string().required('Phone number is required'),
    );
    const realtorInfo = ref('');

    const emitInterest = () => {
      emit('updateInterest', interest.value);
    };

    const submitRealtorInfoContinueWithCancel = handleSubmit(async () => {
      if (props.reason === 'selling_home') {
        try {
          interest.value = 'yes';
          await schema.validate(
            {
              realtorLenderFirstName: realtorLenderFirstName.value,
              realtorLenderLastName: realtorLenderLastName.value,
              realtorLenderEmail: realtorLenderEmail.value,
              realtorLenderPhoneNumber: realtorLenderPhoneNumber.value,
            },
            { abortEarly: false },
          );

          realtorInfo.value = {
            firstName: realtorLenderFirstName.value,
            lastName: realtorLenderLastName.value,
            email: realtorLenderEmail.value,
            phoneNumber: realtorLenderPhoneNumber.value,
          };
          emit('updateInterest', interest.value);
          emit('submitContactInfo', realtorInfo.value);
        } catch (error) {
          console.error('Validation Error:', error);
          return;
        }
      }
      offerAcceptance.value = true;
    });
    const submitContactInfoContinueWithCancel = () => {
      offerAcceptance.value = true;
      if (selectedCallbackTime.value) {
        emit('submitCallbackTime', selectedCallbackTime.value);
        emit('updateInterest', interest.value);
        // continue with other actions if necessary
      }
    };
    const continueWithCancel = () => {
      if (props.reason === 'selling_home') {
        interest.value = 'no'; // Set interest to 'no' if the reason is 'selling_home'
      }
      emit('updateInterest', interest.value);
      emit('closeWinBackModal');
      emit('openTermsModal');
    };

    const callTimes = ref(generateCallTimes());
    function generateCallTimes() {
      const now = new Date();
      const estNow = new Date(
        now.toLocaleString('en-US', { timeZone: 'America/New_York' }),
      );

      let start = new Date(estNow.getTime() + 2 * 60 * 60 * 1000); // Starting 2 hours from now, in EST

      const remainder = 15 - (start.getMinutes() % 15);
      start = new Date(start.getTime() + remainder * 60 * 1000); // Round up to the nearest 15 minutes

      // Adjust start time if outside of business hours
      if (start.getHours() >= 17 || start.getHours() < 9) {
        start = new Date(estNow);
        start.setDate(start.getDate() + (start.getHours() >= 17 ? 1 : 0));
        start.setHours(9, 0, 0, 0); // Reset to 9 AM the next business day
      }

      // Skip weekends
      if (start.getDay() === 6) {
        // Saturday
        start.setDate(start.getDate() + 2); // Move to Monday
      } else if (start.getDay() === 0) {
        // Sunday
        start.setDate(start.getDate() + 1); // Move to Monday
      }

      const times = [];
      while (times.length < 4 || start.getHours() < 17) {
        const dateTimeString = start.toLocaleString('en-US', {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
          timeZone: 'America/New_York',
        });
        times.push(dateTimeString);
        start = new Date(start.getTime() + 30 * 60 * 1000); // Next 30-minute interval

        // If the current day's business hours end without fulfilling 4 time slots, continue the next day.
        if (start.getHours() >= 17 && times.length < 4) {
          start = new Date(start);
          start.setDate(start.getDate() + 1); // Move to the next day
          start.setHours(9, 0, 0, 0); // Start at 9 AM

          // Skip weekends again if needed
          if (start.getDay() === 6) {
            // Saturday
            start.setDate(start.getDate() + 2); // Move to Monday
          } else if (start.getDay() === 0) {
            // Sunday
            start.setDate(start.getDate() + 1); // Move to Monday
          }
        }
      }

      return times;
    }

    watch(selectedTime, (newValue, oldValue) => {
      console.log('Selected Time:', newValue);
      console.log('Selected Callback Time:', selectedCallbackTime.value);
    });
    watch(selectedCallbackTime, (newValue, oldValue) => {
      console.log('Selected Time:', selectedTime.value);
      console.log('Selected Callback Time:', newValue);
    });

    return {
      interest,
      dynamicHeading,
      offerAcceptance,
      submitCallbackTimeAndContinueWithCancel,
      submitRealtorInfoContinueWithCancel,
      selectTime,
      selectedTime,
      selectedCallbackTime,
      callTimes,
      formSubmitted,
      acceptanceMessage,
      realtorLenderFirstName,
      realtorLenderFirstNameError,
      realtorLenderLastName,
      realtorLenderLastNameError,
      realtorLenderEmail,
      realtorLenderEmailError,
      realtorLenderPhoneNumber,
      realtorLenderPhoneNumberError,
      realtorInfo,
      emitInterest,
      continueWithCancel,
      submitContactInfoContinueWithCancel,
      errors,
    };
  },
};
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.win-back-modal {
  animation: fadeIn 0.3s ease-in-out;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.time-slots-container {
  max-height: 200px; /* Adjust based on your needs */
  overflow-y: auto; /* Enables vertical scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #3b82f6 lightgray; /* For Firefox: thumb and track color */
}

/* For Webkit (Chrome, Safari, etc.) */
.time-slots-container::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.time-slots-container::-webkit-scrollbar-track {
  background: lightgray; /* Track color */
}

.time-slots-container::-webkit-scrollbar-thumb {
  background-color: #3b82f6; /* Thumb color */
  border-radius: 6px; /* Roundness of the scrollbar */
  border: 3px solid lightgray; /* Creates padding around the scrollbar */
}
</style>
