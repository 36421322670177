<template>
  <div
    class="flex flex-col items-center justify-center min-h-screen bg-white p-4"
  >
    <div
      v-if="formSubmitted"
      class="w-[100%] md:w-[30%] h-auto bg-[#FFFFFF] drop-shadow-md mx-auto rounded-lg p-6 flex flex-col items-center space-y-4"
    >
      <h2 class="text-2xl font-bold">Success!</h2>
      <img src="@/assets/images/gifs/7efs.gif" alt="Success Animation" />
      <p class="text-green-600 font-bold">Your quote has been submitted</p>
      <p class="mt-[4px] text-sm text-center text-textDarkGray">
        One of our licensed insurance agents will call you shortly.
      </p>
      <p
        class="mt-[4px] tracking-[0.2px] leading-[20px] text-center text-sm text-[#1E182E]"
      >
        <span class="font-bold">Can't wait? </span>
        <span>Give us a call at </span>
        <a href="tel:+18002699137" class="text-[#DB0908] font-bold"
          >(800)-269-9137</a
        >
        <br />One of our Insurance Experts will be ready to assist.
      </p>
      <a
        href="/"
        class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-[180px] mb-6 mt-40 text-center"
      >
        Back to homepage
      </a>
    </div>
    <!-- Mortgagee Changes Section -->
    <div v-if="!formSubmitted" class="space-y-4">
      <Icon
        icon="icon-park-solid:other"
        class="icon mb-4 text-red-600 mx-auto"
        style="font-size: 4rem"
      />
      <h3 class="text-lg font-semibold text-center mt-4 mb-2">
        Other Policy Change
      </h3>
      <div>
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="first-name"
        >
          First Name
        </label>
        <input
          v-model="firstName"
          type="text"
          id="first-name"
          class="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
        />
      </div>
      <div>
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="last-name"
        >
          Last Name
        </label>
        <input
          v-model="lastName"
          id="last-name"
          type="text"
          class="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
        />
      </div>
      <div>
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="policy-number"
        >
          Policy Number
        </label>
        <input
          v-model="policyNumber"
          id="policy-number"
          type="text"
          class="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
        />
      </div>
      <!-- Phone Number Input with PrimeVue InputMask -->
      <div>
        <label
          for="phone-number"
          class="block text-gray-700 text-sm font-bold mb-2"
        >
          Phone Number
        </label>
        <InputMask
          id="phone-number"
          mask="(999) 999-9999"
          v-model="phoneNumber"
          class="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
        ></InputMask>
        <ErrorMessage name="phoneNumber" />
      </div>

      <!-- Email Input -->
      <div>
        <label for="email" class="block text-gray-700 text-sm font-bold mb-2">
          Email
        </label>
        <input
          id="email"
          v-model="email"
          type="email"
          class="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
        />
        <ErrorMessage name="email" />
      </div>
      <div class="relative">
        <label
          class="block text-gray-700 text-sm font-bold mb-2 cursor-pointer"
          for="document-upload"
        >
          Upload Document or Photo
        </label>
        <div class="flex items-center justify-center w-full">
          <input
            type="file"
            class="hidden"
            id="document-upload"
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            @change="handleFileChange"
            ref="fileInput"
          />
          <label
            :class="[
              'flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group text-center cursor-pointer',
              fileBorderColor,
            ]"
            for="document-upload"
          >
            <div
              class="h-full w-full text-center flex flex-col items-center justify-center"
            >
              <!-- Utilizing Iconify Vue component for upload icon -->
              <Icon
                icon="iconamoon:upload-bold"
                class="icon my-2 text-blue-600 mx-auto"
                height="40px"
              />
              <p class="pointer-none text-gray-500">{{ instructionText }}</p>
            </div>
          </label>
        </div>
        <div v-if="selectedFileName" class="mt-2 text-sm text-gray-600">
          Selected file:
          <span class="file-name text-gray-900 font-semibold">{{
            selectedFileName
          }}</span>
        </div>
      </div>

      <div>
        <label class="block text-gray-700 text-sm font-bold mb-2" for="message">
          Message
        </label>
        <textarea
          v-model="message"
          id="message"
          rows="4"
          class="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
        ></textarea>
      </div>
      <div class="text-center">
        <div class="text-center">
          <button
            type="button"
            @click="submitForm"
            class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { Icon } from '@iconify/vue';
import emailjs from '@emailjs/browser';
import { useForm, useField, ErrorMessage } from 'vee-validate';
import * as yup from 'yup'; // for validation schema
import InputMask from 'primevue/inputmask';
import DropDown from 'primevue/dropdown';

export default {
  name: 'downloadUploadDocuments',
  components: {
    Icon,
    InputMask,
    ErrorMessage,
    DropDown,
  },
  setup() {
    const selectedFileName = ref('');
    const instructionText = ref(
      'Drag and drop files here or click to select a file',
    );
    // Define validation schema
    const schema = yup.object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      phoneNumber: yup
        .string()
        .required()
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          'Phone number must be in (XXX) XXX-XXXX format',
        ),
      email: yup.string().required().email(),
      policyNumber: yup.string().required(),
      message: yup.string().required(),
    });

    // Setup form with vee-validate
    const { handleSubmit, errors } = useForm({
      validationSchema: schema,
    });

    const changeType = ref('');
    // Define fields
    const { value: firstName } = useField('firstName');
    const { value: lastName } = useField('lastName');
    const { value: phoneNumber } = useField('phoneNumber');
    const { value: email } = useField('email');
    const { value: policyNumber } = useField('policyNumber');
    const { value: message } = useField('message');
    const fileInput = ref(null);
    const formSubmitted = ref(false);

    const submitForm = handleSubmit((values) => {
      console.log('Submit Form Initiated', values);
      if (fileInput.value && fileInput.value.files.length > 0) {
        const file = fileInput.value.files[0];
        console.log('File selected:', file.name);

        // Convert file to base64 string
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          console.log('FileReader onLoad initiated');
          const base64File = reader.result.split(',')[1]; // Remove the content type part
          console.log('Base64 File:', base64File);

          const templateParams = {
            from_firstName: firstName.value, // match to your EmailJS template parameters
            from_lastName: lastName.value, // match to your EmailJS template parameters
            from_policyNumber: policyNumber.value, // match to your EmailJS template parameters
            from_email: email.value, // match to your EmailJS template parameters
            from_phoneNumber: phoneNumber.value, // match to your EmailJS template parameters
            from_message: message.value, // match to your EmailJS template parameters
            // reply_to: ???,                 // assign if you're using this field
            attachment: base64File, // match to your EmailJS template parameters
          };

          console.log('Sending Email with params:', templateParams);
          emailjs
            .send(
              'service_8sokp7z',
              'template_z0qqwz8',
              templateParams,
              '1uqLc0qiNpveMBpl-',
            )
            .then((response) => {
              console.log('SUCCESS!', response.status, response.text);
              formSubmitted.value = true; // Update the UI or notify user
            })
            .catch((err) => {
              console.error('FAILED...', err);
            });
        };
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
        };
      } else {
        console.error('No file selected');
      }
    });

    function handleFileChange(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const file = files[0];
        selectedFileName.value = file.name; // Update the file name
        instructionText.value =
          'Click to replace the file or drag another file here'; // Update the instruction text

        // Add any additional file validations or operations here
      } else {
        // Reset if no file is selected
        selectedFileName.value = null;
        instructionText.value =
          'Drag and drop files here or click to select a file';
      }
    }

    // Dynamic border color based on file selection
    const fileBorderColor = computed(() => {
      return selectedFileName.value
        ? 'border-green-500'
        : 'hover:border-blue-300';
    });

    return {
      firstName,
      lastName,
      phoneNumber,
      email,
      policyNumber,
      message,
      changeType,
      fileInput,
      formSubmitted,
      submitForm,
      errors, // Make sure to return errors
      handleFileChange,
      selectedFileName,
      fileBorderColor,
      instructionText,
    };
  },
};
</script>
