<template>
  <div>
    <AppHeaderDesktop />
    <div
      v-if="!isSuccess"
      class="my-8 md:my-24 w-full md:w-1/3 h-auto bg-white shadow-lg mx-auto rounded-lg mb-10 p-6 flex flex-col items-center space-y-4"
    >
      <img :src="imagePath" class="w-24" alt="Insurance Type" />
      <h2 class="text-2xl font-bold text-gray-800">{{ productType }} Quote</h2>

      <div class="w-full max-w-lg">
        <label for="name" class="block text-sm font-medium text-gray-700"
          >First Name <span class="text-red-500">*</span></label
        >
        <InputText
          id="name"
          v-model="name"
          type="text"
          placeholder="Your Name"
          class="mt-1 w-full border border-gray-300 px-3 py-2 rounded-md shadow-sm"
          :class="{ 'border-red-500': nameError }"
        />

        <label
          for="lastname"
          class="block mt-4 text-sm font-medium text-gray-700"
          >Last Name <span class="text-red-500">*</span></label
        >
        <InputText
          id="lastname"
          v-model="lastName"
          type="text"
          placeholder="Your Last Name"
          class="mt-1 w-full border border-gray-300 px-3 py-2 rounded-md shadow-sm"
          :class="{ 'border-red-500': lastNameError }"
        />

        <label for="email" class="block mt-4 text-sm font-medium text-gray-700"
          >Your Email <span class="text-red-500">*</span></label
        >
        <InputText
          id="email"
          v-model="email"
          type="email"
          placeholder="Your Email"
          class="mt-1 w-full border border-gray-300 px-3 py-2 rounded-md shadow-sm"
          :class="{ 'border-red-500': emailError }"
        />

        <label for="phone" class="block mt-4 text-sm font-medium text-gray-700"
          >Your Phone Number <span class="text-red-500">*</span></label
        >
        <InputMask
          id="phone"
          v-model="phone"
          type="tel"
          placeholder="Your Phone Number"
          mask="(999) 999-9999"
          class="mt-1 w-full border border-gray-300 px-3 py-2 rounded-md shadow-sm"
          :class="{ 'border-red-500': phoneError }"
        />

        <label
          v-if="
            productType === 'Home Insurance' ||
            productType === 'Flood Insurance' ||
            productType === 'Auto Insurance'
          "
          for="autocomplete-input"
          class="block mt-4 text-sm font-medium text-gray-700"
          >Property Address <span class="text-red-500">*</span></label
        >
        <InputText
          v-if="
            productType === 'Home Insurance' ||
            productType === 'Flood Insurance' ||
            productType === 'Auto Insurance'
          "
          id="autocomplete-input"
          ref="autocomplete"
          v-model="propertyAddress"
          placeholder="Property Address"
          class="mt-1 w-full border border-gray-300 px-3 py-2 rounded-md shadow-sm"
          :class="{ 'border-red-500': propertyAddressError }"
          @blur="validatePropertyAddress"
        />

        <label
          for="message"
          class="block mt-4 text-sm font-medium text-gray-700"
        >
          Your Message
        </label>
        <Textarea
          id="message"
          v-model="message"
          rows="5"
          placeholder="Your Message"
          class="mt-1 w-full border border-gray-300 px-3 py-2 rounded-md shadow-sm"
        />
      </div>
      <label for="insured" class="w-full max-w-lg">
        Are you currently insured with Insurance Express and Member Companies?
        <span class="required">* </span>
        <span class="help-icon">
          <span class="question-mark">?</span>
          <span class="help-content">
            <span
              class="text-xs text-blue-500 font-bold"
              style="color: #0b2779"
            >
              Member Companies<br />
              <span class="text-xs text-gray-500">
                GLV Insurance<br />
                K&G Insurance<br />
                QuoteSlash<br />
                RocketFlood<br />
                RocketMGA<br />
                Nubuild<br />
                TheLeadExchange<br />
                Protego VIP<br />
                Str8line Group<br />
                Str8line Enterprises
              </span>
            </span>
          </span>
        </span>
      </label>

      <div
        class="flex justify-start pt-3"
        :class="{ 'text-red-500': insuredError }"
      >
        <div class="flex items-center mr-4">
          <input
            id="insuredYes"
            v-model="insured"
            type="radio"
            value="Yes"
            class="form-radio h-5 w-5 text-blue-600"
          />
          <label for="insuredYes" class="ml-2">Yes</label>
        </div>
        <div class="flex items-center">
          <input
            id="insuredNo"
            v-model="insured"
            type="radio"
            value="No"
            class="form-radio h-5 w-5 text-blue-600"
          />
          <label for="insuredNo" class="ml-2">No</label>
        </div>
      </div>

      <Button
        label="Submit"
        class="mt-6 w-full max-w-lg bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        @click="submitForm"
      />

      <p class="note">
        Fields marked with <span class="required">*</span> are required.
      </p>

      <Message
        v-if="phoneError"
        class="fixed top-20 right-2.5 z-50 bg-red-500 text-white p-4 rounded-md shadow-lg"
      >
        Phone is Required
      </Message>
      <Message
        v-if="emailError"
        class="fixed top-20 right-2.5 z-50 bg-red-500 text-white p-4 rounded-md shadow-lg"
      >
        Email is Required
      </Message>
      <Message
        v-if="nameError"
        class="fixed top-20 right-2.5 z-50 bg-red-500 text-white p-4 rounded-md shadow-lg"
      >
        Name is Required
      </Message>
      <Message
        v-if="lastNameError"
        class="fixed top-20 right-2.5 z-50 bg-red-500 text-white p-4 rounded-md shadow-lg"
      >
        Last Name is Required
      </Message>
      <Message
        v-if="insuredError"
        class="fixed top-20 right-2.5 z-50 bg-red-500 text-white p-4 rounded-md shadow-lg"
      >
        Insured status is Required
      </Message>
      <Message
        v-if="propertyAddressError"
        class="fixed top-20 right-2.5 z-50 bg-red-500 text-white p-4 rounded-md shadow-lg"
      >
        Property Address is Required
      </Message>
    </div>

    <div
      v-else
      class="w-[100%] md:w-[30%] h-auto bg-[#FFFFFF] drop-shadow-md mx-auto rounded-lg p-6 flex flex-col items-center space-y-4"
    >
      <h2 class="text-2xl font-bold">Success!</h2>
      <img src="../assets/images/gifs/7efs.gif" alt="Success Animation" />
      <p class="text-green-600 font-bold">Your quote has been submitted</p>
      <p class="mt-[4px] text-sm text-center text-textDarkGray">
        One of our licensed insurance agents will call you shortly.
      </p>
      <p
        class="mt-[4px] tracking-[0.2px] leading-[20px] text-center text-sm text-[#1E182E]"
      >
        <span class="font-bold">Can't wait? </span>
        <span>Give us a call at </span>
        <a href="tel:+18002699137" class="text-[#DB0908] font-bold">
          (800)-268-9137</a
        >
        <br />One of our Insurance Experts will be ready to discuss your quote.
      </p>
      <a
        href="/"
        class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-[180px] mb-6 mt-40 text-center"
      >
        Back to homepage
      </a>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import { ref, onMounted, nextTick, watch } from 'vue';
import { useRouter } from 'vue-router';
import emailjs from '@emailjs/browser';
import axios from 'axios';
import AppHeaderDesktop from './AppHeaderDesktop.vue';
import AppFooter from './AppFooter.vue';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';
import { useNotification } from '@kyvg/vue3-notification';
import Message from 'primevue/message';
import stateAbbreviations from '@/data/stateAbbreviations.js';
import confetti from 'canvas-confetti';

const productInfo = {
  'Home Insurance': {
    url: 'https://api.jangl.com/v2/home_insurance/direct_post',
    token: 'Token f367c133a96f34e7cf8c28e2093df685da09eced',
  },
  'Auto Insurance': {
    url: 'https://api.jangl.com/v2/auto_insurance/direct_post',
    token: 'Token 0c98684cd8db87f2fd6af0029d51e1f4671c9acc',
  },
  'Flood Insurance': {
    url: 'https://api.jangl.com/v2/home_insurance/direct_post',
    token: 'Token cddf567c4e6116c830b41d0dbd41e844ab5965ff',
  },
};

const getEndpointUrl = (productType) => {
  return productInfo[productType]?.url || '';
};

const getAuthorizationToken = (productType, insured) => {
  if (insured === 'Yes') {
    if (productType === 'Home Insurance') {
      return 'Token 21b4852f8d41db7fcf6143898bb5ebeb1707c9db';
    } else if (productType === 'Auto Insurance') {
      return 'Token 6490ae790867f8dffe797ed9072c140b53dcbaa2';
    } else if (productType === 'Flood Insurance') {
      return 'Token 4b32d8a2c7f8e25908acdb6f8eeb942ce1606802';
    }
  }

  return productInfo[productType]?.token || '';
};

export default {
  components: {
    AppHeaderDesktop,
    AppFooter,
    InputText,
    Textarea,
    Button,
    InputMask,
    Message,
  },
  props: ['product'],
  setup() {
    const name = ref('');
    const lastName = ref('');
    const email = ref('');
    const phone = ref('');
    const message = ref('');
    const productType = ref('');
    const propertyAddress = ref('');
    const propertyCity = ref('');
    const propertyState = ref('');
    const propertyZipCode = ref('');
    const autocomplete = ref(null);
    const imagePath = ref(''); // Initialize with an empty string or default image path
    watch(productType, (newValue) => {
      switch (newValue) {
        case 'Home Insurance':
          imagePath.value = '/images/product-type-icons/homeInsurance.svg';
          break;
        case 'Auto Insurance':
          imagePath.value = '/images/product-type-icons/autoInsurance.svg';
          break;
        case 'Flood Insurance':
          imagePath.value = '/images/product-type-icons/floodInsurance.svg';
          break;
        case 'Medicare':
          imagePath.value = '/images/product-type-icons/medicare.svg';
          break;
        case 'Life Insurance':
          imagePath.value = '/images/product-type-icons/lifeInsurance.svg';
          break;
        case 'Pet Insurance':
          imagePath.value = '/images/product-type-icons/petInsurance.svg';
          break;
        case 'Business Insurance':
          imagePath.value = '/images/product-type-icons/businessInsurance.svg';
          break;
        default:
          imagePath.value = ''; // Set to a default image or keep it empty
          break;
      }
    });
    const nameError = ref(false);
    const lastNameError = ref(false);
    const emailError = ref(false);
    const phoneError = ref(false);
    const propertyAddressError = ref(false);
    const insured = ref('');

    const insuredOptions = ref(['Yes', 'No']);

    const insuredError = ref(false);

    const validateInsured = () => {
      insuredError.value = !insured.value || insured.value.trim() === '';
    };
    const notification = useNotification();
    const isSuccess = ref(false);
    const validateName = () => {
      nameError.value = name.value.trim() === '';
    };
    const validateLastName = () => {
      lastNameError.value = name.value.trim() === '';
    };

    const validateEmail = () => {
      emailError.value = !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
        email.value,
      );
    };

    const validatePhone = () => {
      // Retrieve the raw phone number value without the mask
      const rawPhoneValue = phone.value.replace(/\D/g, '');

      // Perform validation on the raw phone number value
      phoneError.value = !/^\+?[1-9]\d{1,14}$/.test(rawPhoneValue);
    };
    const validatePropertyAddress = () => {
      if (['home', 'flood', 'auto'].includes(productType.value)) {
        propertyAddressError.value = propertyAddress.value.trim() === '';
      } else {
        propertyAddressError.value = false;
      }
    };

    const submitForm = () => {
      validateName();
      validateLastName();
      validateEmail();
      validatePhone();
      validateInsured();
      validatePropertyAddress();
      if (
        !nameError.value &&
        !lastNameError.value &&
        !emailError.value &&
        !phoneError.value &&
        !insuredError.value &&
        !propertyAddressError.value
      ) {
        const templateParams = {
          from_name: name.value,
          from_lastName: lastName.value,
          from_email: email.value,
          from_phone: phone.value,
          property_address: propertyAddress.value,
          message: message.value,
          product_type: productType.value,
          insured: insured.value,
        };

        emailjs
          .send(
            'service_8sokp7z',
            'template_ixzt38v',
            templateParams,
            '1uqLc0qiNpveMBpl-',
          )
          .then(function (response) {
            console.log(
              'Email successfully sent!',
              response.status,
              response.text,
            );
            isSuccess.value = true;
            notification.notify({
              id: Date.now(),
              title: 'Success',
              text: 'Email successfully sent!',
              type: 'success',
            });

            const janglPayload = {
              meta: Payload[productType.value]?.meta || {},
              contact: {
                first_name: name.value,
                last_name: lastName.value,
                email: email.value,
                phone: phone.value,
                address: propertyAddress.value,
                city: propertyCity.value,
                state: propertyState.value,
                zip_code: propertyZipCode.value,
                ip_address: '192.156.78.100',
              },
              data: {
                birth_date: '1970-01-01',
                gender: 'M',
                marital_status: 'Single',
                occupation: 'Teacher',
                properties: [
                  {
                    zip_code: propertyZipCode.value,
                    property_type: 'Single Family',
                    ownership: 'Rent',
                    occupancy: 'Primary Residence',
                    months_at_residence: 4,
                    garage: 'No Garage',
                    foundation: 'Basement',
                    home_security: 'No Alarm',
                    year_built: 1991,
                    year_upgraded: 1999,
                    stories: 1,
                    bedrooms: 3,
                    bathrooms: 2,
                    square_footage: 1400,
                    dwelling_value: 145000,
                    construction_class: 'Standard',
                    construction_type: 'Wood Frame',
                    roof_type: 'Asphalt Shingle',
                    panel_type: 'Circuit Breaker',
                    proximity_water: 'Not Applicable',
                    exterior_walls: 'Mostly Brick',
                    heating_type: 'Gas Forced Air',
                    wiring_type: 'Copper',
                    newly_purchased: true,
                    dog: 'Pit Bull',
                    fireplace: true,
                    deadbolt_locks: true,
                    smoke_alarm: true,
                    fire_alarm: true,
                    fire_extinguisher: true,
                    manned_fire_station_within_5_miles: true,
                    fire_hydrant_within_1000_feet: true,
                    indoor_sprinklers: false,
                    copper_water_pipes: true,
                    brush_hazard_within_500_feet: false,
                    flood_area: false,
                    central_air_conditioning: true,
                    sauna: false,
                    hot_tub: true,
                    woodburning_stove: false,
                    sump_pump: true,
                    in_ground_swimming_pool: true,
                    swimming_pool_is_fenced: false,
                    smoker_in_household: false,
                    trampoline: false,
                    covered_deck_patio: true,
                    claims: [
                      {
                        claim_date: '2000-01-01',
                        type: 'weather',
                        paid_amount: '1000.00',
                      },
                    ],
                  },
                ],
              },
            };

            if (
              ['Auto Insurance', 'Home Insurance', 'Flood Insurance'].includes(
                productType.value,
              )
            ) {
              const endpointUrl = getEndpointUrl(productType.value);
              const authorizationToken = getAuthorizationToken(
                productType.value,
                insured.value,
              );

              axios
                .post(endpointUrl, janglPayload, {
                  headers: {
                    Authorization: authorizationToken,
                  },
                })
                .then(function (response) {
                  console.log('Jangl API request successful:', response.data);
                  // Handle the Jangl response as needed
                })
                .catch(function (error) {
                  console.log('Failed to send Jangl API request:', error);
                  // Handle the Jangl API request error as needed
                });
            }
          })
          .catch(function (error) {
            console.log('Failed to send email:', error);
            notification.notify({
              id: Date.now(),
              title: 'Error',
              text: 'Failed to send email.',
              type: 'error',
            });
          });
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { x: 0.5, y: 0.5 },
        });
      }
    };

    // Accessing the router instance
    const router = useRouter();

    const loadGooglePlacesScript = () => {
      return new Promise((resolve, reject) => {
        // Check if the Google Places API is already loaded
        if (
          typeof google !== 'undefined' &&
          google.maps &&
          google.maps.places
        ) {
          resolve();
          return;
        }

        // Create a script element to load the Google Places API
        const script = document.createElement('script');
        script.src =
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyCGr836eojr4HL50J0ewPjY-a_pbd5ejp8&libraries=places&callback=initGooglePlaces';
        script.async = true;
        script.defer = true;

        // Define a callback function that will be called once the script is loaded
        window.initGooglePlaces = () => {
          if (
            typeof google !== 'undefined' &&
            google.maps &&
            google.maps.places
          ) {
            resolve();
          } else {
            reject('Google Places API failed to load');
          }
        };

        // Handle any errors that occur during script loading
        script.onerror = () =>
          reject('Google Places API script failed to load');

        // Append the script element to the document head to start loading the script
        document.head.appendChild(script);
      });
    };

    // This function will be called when the Google Places API script has loaded
    const initializeAutocomplete = () => {
      // Wait until the next DOM update cycle to ensure the input element is present
      nextTick(() => {
        var input = document.getElementById('autocomplete-input');
        // Only initialize Autocomplete if the input element is valid
        if (input instanceof HTMLInputElement) {
          autocomplete.value = new google.maps.places.Autocomplete(input);

          // Add event listener
          autocomplete.value.addListener('place_changed', () => {
            const place = autocomplete.value.getPlace();

            // Update propertyAddress with the selected place's formatted address
            propertyAddress.value = place.formatted_address;

            // Fill propertyCity, propertyState, and propertyZipCode
            const addressComponents = place.address_components;
            for (let i = 0; i < addressComponents.length; i++) {
              const component = addressComponents[i];
              const types = component.types;

              if (types.includes('locality')) {
                propertyCity.value = component.long_name;
              } else if (types.includes('administrative_area_level_1')) {
                const stateName = component.long_name;
                propertyState.value =
                  stateAbbreviations.states[stateName] || stateName;
              } else if (types.includes('postal_code')) {
                propertyZipCode.value = component.long_name;
              }
            }
            console.log('City:', propertyCity.value);
            console.log('State:', propertyState.value);
            console.log('Zip Code:', propertyZipCode.value);
          });
        } else {
          // Log an error or handle this case as needed
          console.error('Autocomplete input element not found or not valid');
        }
      });
    };

    onMounted(() => {
      loadGooglePlacesScript()
        .then(initializeAutocomplete)
        .catch((error) => console.error(error));

      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });

      // Retrieving the product type from the query parameter
      const query = router.currentRoute.value.query;
      if (query.productType) {
        productType.value = query.productType;
      }
    });

    return {
      name,
      lastName,
      email,
      phone,
      propertyAddress,
      propertyCity,
      propertyState,
      propertyCity,
      propertyZipCode,
      message,
      productType,
      insured,
      insuredOptions,
      nameError,
      lastNameError,
      emailError,
      phoneError,
      insuredError,
      propertyAddressError,
      validatePropertyAddress,
      validateName,
      validateLastName,
      validateEmail,
      validatePhone,
      validateInsured,
      submitForm,
      isSuccess,
      imagePath,
    };
  },
};
</script>

<style scoped>
:deep(.p-button) {
  background: #0b2779;
}
:deep(.p-button:hover) {
  background: #6c7dae;
}
.active-insured {
  background-color: #0b2779; /* this is a dark blue color */
  color: #ffffff; /* white text */
}

.error {
  border: 2px solid #ff0000; /* red border */
  color: #ff0000; /* red text */
}
.error-border input[type='radio'] {
  border-color: #ff0000; /* red border */
}
.error-container {
  border: 2px solid red;
  border-radius: 4px;
  padding: 4px;
}
.help-icon {
  position: relative;
  display: inline-flex;
  align-items: center; /* Adjust the vertical alignment */
}

.question-mark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: #333333;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  line-height: 1;
  transition: transform 0.2s ease-in-out;
}

.question-mark:hover {
  transform: scale(1.1); /* Apply the desired animation effect */
}

.help-content {
  position: absolute;
  z-index: 1;
  top: calc(100% + 4px); /* Adjust the top positioning */
  left: 0;
  display: none;
  width: max-content;
  max-width: 250px;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  white-space: normal;
  word-wrap: break-word;
  text-align: left;
}

.help-text {
  display: block;
  color: #0b2779;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 8px;
}

.help-icon:hover .help-content {
  display: block;
}
</style>
