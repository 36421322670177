<template>
  <div class="form-group mb-4">
    <label for="electrical" class="block text-sm font-medium text-gray-700"
      >Electrical:</label
    >

    <div class="mt-2">
      <label
        for="common_conditions"
        class="block text-sm font-medium text-gray-700"
        >Common Conditions:</label
      >
      <select
        v-model="selectedCondition"
        id="common_conditions"
        class="form-select mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      >
        <option value="" disabled>Select a condition</option>
        <option
          v-for="condition in commonConditions"
          :key="condition.value"
          :value="condition.value"
        >
          {{ condition.label }}
        </option>
      </select>
      <button
        @click="addCondition"
        class="mt-2 px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-700"
      >
        Add Condition
      </button>
    </div>

    <div class="mt-4">
      <label
        for="custom_condition"
        class="block text-sm font-medium text-gray-700"
        >Custom Condition:</label
      >
      <input
        v-model="customCondition"
        type="text"
        id="custom_condition"
        class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
      <button
        @click="addCustomCondition"
        class="mt-2 px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-700"
      >
        Add Custom Condition
      </button>
    </div>

    <div class="mt-4">
      <label class="block text-sm font-medium text-gray-700">Conditions:</label>
      <ul>
        <li
          v-for="(condition, index) in localValue.conditions"
          :key="index"
          class="flex items-center justify-between bg-gray-50 p-2 rounded-md mb-2"
        >
          <span>{{ getConditionLabel(condition) }}</span>
          <button
            @click="removeCondition(index)"
            class="ml-2 px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-700"
          >
            Remove
          </button>
        </li>
      </ul>
    </div>

    <div class="mt-4">
      <label
        for="additional_notes"
        class="block text-sm font-medium text-gray-700"
        >Additional Notes:</label
      >
      <textarea
        v-model="localValue.notes"
        id="additional_notes"
        class="form-textarea mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        rows="4"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'ElectricalInput',
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => ({ conditions: [], notes: '' }),
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const userName = `${store.state.customerInfo.firstName} ${store.state.customerInfo.lastName}`;

    const localValue = ref(initializeLocalValue(props.modelValue));
    const selectedCondition = ref('');
    const customCondition = ref('');
    const commonConditions = ref([
      {
        label: 'No Federal Pacific, Zinsco, Challenger, or Sylvania',
        value: 'no_federal_pacific_zinsco_challenger_sylvania',
      },
      {
        label: 'Minimum 100 AMP service required',
        value: 'min_100_amp_service',
      },
      { label: 'No knob and tube wiring', value: 'no_knob_and_tube' },
      {
        label: 'No aluminum branch circuit wiring',
        value: 'no_aluminum_branch',
      },
      {
        label: 'Must be inspected by qualified electrician',
        value: 'qualified_electrician_inspection',
      },
    ]);

    function initializeLocalValue(value) {
      return {
        conditions: value?.conditions || [],
        notes: value?.notes || '',
      };
    }

    function addCondition() {
      if (
        selectedCondition.value &&
        !localValue.value.conditions.includes(selectedCondition.value)
      ) {
        localValue.value.conditions.push(selectedCondition.value);
        selectedCondition.value = '';
        updateModelValue();
      }
    }

    function addCustomCondition() {
      const customValue = `custom:${customCondition.value}`;
      if (
        customCondition.value &&
        !localValue.value.conditions.includes(customValue)
      ) {
        localValue.value.conditions.push(customValue);
        customCondition.value = '';
        updateModelValue();
      }
    }

    function removeCondition(index) {
      localValue.value.conditions.splice(index, 1);
      updateModelValue();
    }

    function updateModelValue() {
      const currentDate = new Date().toISOString();
      const updatedData = {
        ...localValue.value,
        username_of_last_user_update: userName,
        updated_at: currentDate,
      };
      emit('update:modelValue', updatedData);
    }

    function getConditionLabel(value) {
      if (value.startsWith('custom:')) {
        return value.slice(7);
      }
      const condition = commonConditions.value.find(
        (cond) => cond.value === value,
      );
      return condition ? condition.label : value;
    }

    watch(
      () => props.modelValue,
      (newVal) => {
        localValue.value = initializeLocalValue(newVal);
      },
      { deep: true, immediate: true },
    );

    return {
      localValue,
      selectedCondition,
      customCondition,
      commonConditions,
      addCondition,
      addCustomCondition,
      removeCondition,
      updateModelValue,
      getConditionLabel,
    };
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
