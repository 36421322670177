<template>
  <div v-if="isVisible" class="p-4 bg-white rounded-lg shadow-md">
    <form @submit.prevent="submitForm">
      <h2 class="text-lg font-semibold mb-4">Home Information</h2>

      <div class="form-group">
        <label for="homeAge">Home Age</label>
        <input
          type="number"
          v-model="formData.homeAge"
          id="homeAge"
          class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Enter the age of the home in years"
        />
        <p v-if="errors.homeAge" class="text-red-600">{{ errors.homeAge }}</p>
      </div>

      <div class="form-group">
        <label for="lastUpdate">Last Update (years)</label>
        <input
          type="number"
          v-model="formData.lastUpdate"
          id="lastUpdate"
          class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Enter the number of years since the last update"
        />
        <p v-if="errors.lastUpdate" class="text-red-600">
          {{ errors.lastUpdate }}
        </p>
      </div>

      <div class="form-group">
        <label for="coverageAmount">Coverage Amount ($)</label>
        <input
          type="number"
          v-model="formData.coverageAmount"
          id="coverageAmount"
          class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Enter the coverage amount in dollars"
        />
        <p v-if="errors.coverageAmount" class="text-red-600">
          {{ errors.coverageAmount }}
        </p>
      </div>

      <div class="form-group">
        <label for="systemsUpdated">Systems Updated (years)</label>
        <input
          type="number"
          v-model="formData.systemsUpdated"
          id="systemsUpdated"
          class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Enter the number of years since systems were updated"
        />
        <p v-if="errors.systemsUpdated" class="text-red-600">
          {{ errors.systemsUpdated }}
        </p>
      </div>

      <div class="form-group">
        <label for="photosIncluded">Include Photos</label>
        <select
          v-model="formData.photosIncluded"
          id="photosIncluded"
          class="form-select mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          <option value="" disabled>Select an option</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
        <p v-if="errors.photosIncluded" class="text-red-600">
          {{ errors.photosIncluded }}
        </p>
      </div>

      <div class="form-group">
        <label for="inspectionRequired">Inspection Required</label>
        <select
          v-model="formData.inspectionRequired"
          id="inspectionRequired"
          class="form-select mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          <option value="" disabled>Select an option</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
        <p v-if="errors.inspectionRequired" class="text-red-600">
          {{ errors.inspectionRequired }}
        </p>
      </div>

      <div class="form-group">
        <label for="additionalNotes">Additional Notes</label>
        <textarea
          v-model="formData.additionalNotes"
          id="additionalNotes"
          class="form-textarea mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Enter any additional notes or information"
        ></textarea>
      </div>

      <button
        type="submit"
        class="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
      >
        Submit
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
    default: () => ({
      homeAge: '',
      lastUpdate: '',
      coverageAmount: '',
      systemsUpdated: '',
      photosIncluded: '',
      inspectionRequired: '',
      additionalNotes: '',
    }),
  },
  isVisible: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const store = useStore();
const userName = `${store.state.customerInfo.firstName} ${store.state.customerInfo.lastName}`;

const formData = ref({
  homeAge: '',
  lastUpdate: '',
  coverageAmount: '',
  systemsUpdated: '',
  photosIncluded: '',
  inspectionRequired: '',
  additionalNotes: '',
  username_of_last_user_update: userName,
  updated_at: new Date().toISOString(),
});

const errors = ref({
  homeAge: '',
  lastUpdate: '',
  coverageAmount: '',
  systemsUpdated: '',
  photosIncluded: '',
  inspectionRequired: '',
});

const clearErrors = () => {
  for (const key in errors.value) {
    errors.value[key] = '';
  }
};

const initializeFormData = () => {
  formData.value = { ...props.modelValue };
  clearErrors();
};

const updateModelValue = () => {
  emit('update:modelValue', { ...formData.value });
};

watch(() => props.modelValue, initializeFormData, { immediate: true });

const submitForm = () => {
  formData.value.username_of_last_user_update = userName;
  formData.value.updated_at = new Date().toISOString();
  updateModelValue();
  // Additional submit actions can be added here
};

onMounted(() => {
  initializeFormData();
});
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
