<template>
  <div>
    <!-- Button to trigger the request -->
    <button @click="exchangeToken">Exchange Token</button>

    <!-- Display response message -->
    <p>{{ responseMessage }}</p>

    <!-- Button to fetch modules -->
    <button @click="fetchModules">Fetch Modules</button>

    <!-- Display module list -->
    <div v-if="modules.length > 0">
      <h2>Modules</h2>
      <ul>
        <li v-for="module in modules" :key="module.api_name">
          Module Name: {{ module.module_name }} | API Name:
          {{ module.api_name }}
        </li>
      </ul>
    </div>
    <div v-else>
      <p>No modules fetched yet.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'OAuthCallback',
  data() {
    return {
      responseMessage: '', // Initialize response message
      modules: [], // Initialize modules array
    };
  },
  methods: {
    exchangeToken() {
      // Extract authorization code from URL
      const code = this.$route.query.code;

      // Make a POST request to exchange token
      axios
        .post('/api/oauth/zoho', { code })
        .then((response) => {
          // Handle successful response
          this.responseMessage = response.data.message;
        })
        .catch((error) => {
          // Handle error
          console.error('OAuth token exchange error:', error);
          this.responseMessage = 'Failed to exchange token';
        });
    },
    fetchModules() {
      // Make a GET request to fetch modules
      axios
        .get('/api/zoho/modules')
        .then((response) => {
          // Handle successful response
          this.modules = response.data.modules;
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching modules:', error);
        });
    },
  },
};
</script>
