<template>
  <header
    class="bg-white shadow-lg py-4 flex justify-between items-center px-6"
  >
    <!-- Company Logo -->
    <div>
      <img
        src="@/assets/images/insurance_express-main-logo.svg"
        alt="Insurance Express and Member Companies"
        class="h-10"
      />
    </div>
    <!-- <a class="text-gray-600 font-semibold cursor-pointer ml-2 pr-auto">Dashboard</a> -->

    <!-- Welcome Message and User Dropdown -->
    <div class="relative" @click="toggleDropdown">
      <span
        class="text-gray-600 text-sm md:text-md font-semibold cursor-pointer"
        >Welcome, {{ customerInfo.firstName }} {{ customerInfo.lastName }}
      </span>

      <!-- Dropdown Menu -->
      <div
        v-if="showDropdown"
        class="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20"
      >
        <a
          @click.stop="openSettings"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
          >Settings</a
        >
        <a
          href="/profile"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >Profile</a
        >
        <a
          href="/logout"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >Logout</a
        >
      </div>
    </div>
  </header>

  <!-- Main Content Container -->
  <div class="container mx-auto p-4">
    <!-- Loading screen -->
    <div v-if="isLoading" class="loading-screen">
      <div class="loader"></div>
      <span class="loading-text">Loading, please wait...</span>
    </div>

    <!-- Main Content with Fade-in Effect -->
    <div
      v-else
      :class="{
        'opacity-0': !isRendered,
        'opacity-100': isRendered,
        'transition-opacity': true,
        'duration-1000': true,
        'ease-in-out': true,
      }"
      class="bg-white shadow-lg rounded-lg p-8"
    >
      <!-- Customer Dashboard -->
      <div v-if="customerFirstName && customerLastName">
        <!-- Dashboard Overview -->
        <section class="mb-6">
          <!-- Display customer information, policies overview, and actions -->
        </section>

        <!-- Tabs for Different Sections -->
        <div>
          <ul class="flex border-b">
            <li class="-mb-px mr-1">
              <a
                :class="{
                  'border-blue-500 text-blue-600': activeTab === 'policies',
                  'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                    activeTab !== 'policies',
                }"
                @click="activeTab = 'policies'"
                class="bg-white inline-block py-2 px-4 font-semibold"
                >My Policies</a
              >
            </li>
            <li class="mr-1">
              <a
                :class="{
                  'border-blue-500 text-blue-600': activeTab === 'contact',
                  'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                    activeTab !== 'contact',
                }"
                @click="activeTab = 'contact'"
                class="bg-white inline-block py-2 px-4 font-semibold"
                >Contact</a
              >
            </li>
            <li class="mr-1">
              <a
                :class="{
                  'border-blue-500 text-blue-600': activeTab === 'faq',
                  'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                    activeTab !== 'faq',
                }"
                @click="activeTab = 'faq'"
                class="bg-white inline-block py-2 px-4 font-semibold"
                >FAQ</a
              >
            </li>
            <!-- Add tabs for other sections (e.g., Claims, Documents) -->
          </ul>
        </div>

        <!-- Tab Content -->
        <div class="py-4">
          <!-- Display content based on the active tab -->
          <div v-if="activeTab === 'policies'">
            <Policies />
          </div>
          <div v-else-if="activeTab === 'contact'">
            <ContactInfo />
          </div>
          <div v-else-if="activeTab === 'faq'">
            <FAQcustomerService />
          </div>
          <!-- Add sections for other tabs -->
        </div>
      </div>

      <!-- Error Message -->
      <div v-else>
        <!-- Your Apple card message content here -->
        <div class="bg-slate-900 p-4 rounded-lg shadow-md">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/3/31/Apple_logo_white.svg"
            alt="Apple Logo"
            class="h-12 w-12 mx-auto mb-4"
          />

          <h2 class="text-xl font-semibold text-white">Welcome, Apple User!</h2>
          <p class="text-slate-100 mt-2">
            Thank you for signing in with your Apple account. Enjoy your
            experience with our platform!
          </p>
        </div>
      </div>
      <!-- <div class="bg-gray-100 p-4 rounded-lg shadow-md">
          <img src="https://upload.wikimedia.org/wikipedia/commons/archive/c/c1/20190923152039%21Google_%22G%22_logo.svg" alt="Google Logo" class="h-12 w-12 mx-auto mb-4">
          
          <h2 class="text-xl font-semibold text-gray-800">Welcome, Google User!</h2>
          <p class="text-gray-600 mt-2">
            Thank you for signing in with your Google account. Enjoy your experience with our platform!
          </p>
        </div>
      </div> -->
    </div>
  </div>
  <customerServiceSettings v-if="showSettings" @close="showSettings = false" />
</template>

<script>
import axios from 'axios';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

// Import your custom components here
import Policies from '../components/customerservice/Policies.vue';
import ContactInfo from '../components/customerservice/ContactInfo.vue';
import PolicyChangeRequests from '../components/customerservice/PolicyChangeRequests.vue';
import UploadDocuments from '../components/customerservice/UploadDocuments.vue';
import FAQcustomerService from '../components/customerservice/FAQ.vue';
import customerServiceSettings from '../components/customerservice/Settings.vue';

export default {
  name: 'customerPortalMainPage',
  components: {
    Policies, // Add your custom components here
    ContactInfo,
    PolicyChangeRequests,
    UploadDocuments,
    FAQcustomerService,
    customerServiceSettings,
  },
  setup(props, { emit }) {
    const closeSettings = () => {
      emit('close');
    };
    const store = useStore();
    const userId = ref(store.state.userId);
    const customerInfo = computed(() => store.getters.customerInfo);
    const customerFirstName = ref(null);
    const customerLastName = ref(null);
    const customerEmail = ref(null);
    const customerId = ref(null);
    const customerPhoneNumber = ref(null);
    const isLoading = ref(false);
    const isRendered = ref(false);
    const activeTab = ref('policies');
    const showDropdown = ref(false);
    const showSettings = ref(false);
    // this.$emit('close');
    const openSettings = () => {
      showSettings.value = true;
      showDropdown.value = false; // Optionally, close the dropdown menu
    };

    const initializeData = async () => {
      if (!store.state.isUserDataFetched) {
        // Assuming you have a flag in your Vuex store
        isLoading.value = true;
        try {
          const response = await axios.get(`/api/user/${userId.value}`, {
            headers: {
              Authorization: `Bearer ${store.state.authToken}`,
            },
          });
          // Update the individual user properties
          customerId.value = response.data.customerId;
          customerFirstName.value = response.data.firstName;
          customerLastName.value = response.data.lastName;
          customerEmail.value = response.data.email;
          customerPhoneNumber.value = response.data.phoneNumber;
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          isLoading.value = false;
        }
      }
    };

    onMounted(() => {
      isRendered.value = true;
      initializeData();
    });

    return {
      isLoading,
      initializeData,
      isRendered,
      activeTab,
      customerId,
      customerFirstName,
      customerLastName,
      customerEmail,
      customerPhoneNumber,
      showDropdown,
      toggleDropdown: () => {
        showDropdown.value = !showDropdown.value;
      },
      openSettings,
      userId,
      customerInfo,
      showSettings,
      closeSettings,
    };
  },
};
</script>

<style scoped>
body {
  /* Existing styles */
}

.loading-screen {
  /* Centering the loader */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader {
  /* Loader design */
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #03a9f4;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  margin-top: 10px;
  font-size: 1rem;
  color: #555;
}

/* Tailwind CSS for overall layout */
/* PrimeVue specific styling */
.p-tabview-nav-link.active {
  background-color: #0056b3; /* Brand Blue */
  color: white;
}

.p-tabview-nav-link:hover {
  background-color: #d32f2f; /* Brand Red */
  color: white;
}
</style>
