<template>
  <div class="mx-auto px-4 mt-8 mb-8">
    <div v-if="showCards" class="text-center mb-12">
      <div class="mx-auto my-4" style="width: 7rem; height: 7rem">
        <img
          src="@/assets/images/customerServiceIcons/policyChanges.svg"
          alt="Policy Changes"
          class="w-full h-full mb-4 mx-auto"
          loading="lazy"
        />
      </div>
      <h1 class="text-4xl font-bold text-ietextlight mb-4">Policy Changes</h1>
      <p class="text-xl text-gray-600">
        We're here to assist you with all your service needs.
      </p>
    </div>
    <!--Service Options Cards-->
    <div v-if="showCards" class="flex flex-wrap justify-center gap-6">
      <div
        class="card flex-grow max-w-md rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow"
      >
        <img
          src="@/assets/images/customerServiceIcons/mortgageeChanges.svg"
          alt="Mortgagee Changes"
          class="h-20 mb-4 mx-auto"
          loading="lazy"
        />
        <h2 class="text-lg font-semibold text-gray-700 mb-3">
          Mortgagee Changes
        </h2>
        <p class="text-gray-600 mb-5">
          Add, update, or remove mortgagee clauses.
        </p>
        <button
          id="mortgageeChange"
          class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition-colors mt-4"
          @click="handleShowMortgageeChanges"
        >
          Make Changes
        </button>
      </div>
      <div
        class="card flex-grow max-w-md rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow"
      >
        <img
          src="@/assets/images/customerServiceIcons/homeInsuranceChanges.svg"
          alt="Home Insurance Changes"
          class="h-20 mb-4 mx-auto"
          loading="lazy"
        />
        <h2 class="text-lg font-semibold text-gray-700 mb-3">
          Home Insurance Changes
        </h2>
        <p class="text-gray-600 mb-5">
          Request changes for your home insurance policy.
        </p>
        <button
          class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition-colors mt-4"
          @click="handleShowHomeInsurancePolicyChange"
        >
          Make Changes
        </button>
      </div>
      <div
        class="card flex-grow max-w-md rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow"
      >
        <img
          src="@/assets/images/customerServiceIcons/policySearch.svg"
          alt="Policy Search"
          class="h-20 mb-4 mx-auto"
          loading="lazy"
        />
        <h2 class="text-lg font-semibold text-gray-700 mb-3">Policy Search</h2>
        <p class="text-gray-600 mb-5">
          Don't have your policy number? No worries! Use our helpful policy
          search tool.
        </p>
        <button
          class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition-colors mt-4"
          @click="$router.push('/policy-search')"
        >
          Find Policy
        </button>
      </div>
      <div
        class="card flex-grow max-w-md rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow"
      >
        <img
          src="@/assets/images/customerServiceIcons/cancellationRequest.svg"
          alt="Cancellation Request"
          class="h-20 mb-4 mx-auto"
          loading="lazy"
        />
        <h2 class="text-lg font-semibold text-gray-700 mb-3">
          Cancellation Request
        </h2>
        <p class="text-gray-600 mb-5">
          Use our helpful online form to streamline policy cancellation
          requests.
        </p>
        <button
          class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 transition-colors"
          @click="handleCancellation"
        >
          Make Request
        </button>
      </div>
    </div>
    <!-- Service Request Forms -->
    <PolicySearch v-if="showPolicySearch" />
    <HomeInsurancePolicyChange v-if="showHomeInsurancePolicyChange" />
    <auto_insurance_form v-if="showAutoInsurancePolicyChange" />
    <OtherPolicyChanges v-if="showOtherPolicyChanges" />
    <mortgageeChangesForm v-if="showMortgageeChanges" customerType="customer" />
    <cancellationRequest
      v-if="showCancellationRequest"
      @closeCancellationModal="handleCloseCancellationModal"
    />
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import { ref, onMounted } from 'vue';
import mortgageeChangesForm from './managepolicy/serviceRequestForms.vue/mortgageeChangesForm.vue';
import HomeInsurancePolicyChange from './managepolicy/serviceRequestForms.vue/homeInsurancePolicyChange.vue';
import auto_insurance_form from './managepolicy/serviceRequestForms.vue/auto_insurance_support_components/auto_insurance_form.vue';
import OtherPolicyChanges from './managepolicy/serviceRequestForms.vue/otherPolicyChanges.vue';
import PolicySearch from './managepolicy/serviceRequestForms.vue/policySearch.vue';
import cancellationRequest from './managepolicy/serviceRequestForms.vue/cancellationRequest.vue';

export default {
  name: 'serviceRequests',
  components: {
    Icon,
    mortgageeChangesForm,
    HomeInsurancePolicyChange,
    auto_insurance_form,
    OtherPolicyChanges,
    PolicySearch,
    cancellationRequest,
  },
  data() {
    return {
      showCards: true,
      showMortgageeChanges: false,
      showHomeInsurancePolicyChange: false,
      showAutoInsurancePolicyChange: false,
      showOtherPolicyChanges: false,
      showPolicySearch: false,
      showCancellationRequest: false,
    };
  },
  methods: {
    handleShowMortgageeChanges() {
      this.showCards = false;
      this.showMortgageeChanges = true;
    },
    handleShowHomeInsurancePolicyChange() {
      this.showCards = false;
      this.showHomeInsurancePolicyChange = true;
    },
    handleShowAutoInsurancePolicyChange() {
      this.showCards = false;
      this.showAutoInsurancePolicyChange = true;
    },
    handleShowOtherPolicyChanges() {
      this.showCards = false;
      this.showOtherPolicyChanges = true;
    },
    handleShowPolicySearch() {
      this.showCards = false;
      this.showPolicySearch = true;
    },
    handleCancellation() {
      this.showCards = false;
      this.showCancellationRequest = true;
    },
    handleCloseCancellationModal() {
      this.showCancellationRequest = false;
      this.showCards = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped></style>
