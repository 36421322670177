<template>
  <div class="max-w-lg mx-auto p-6 bg-white shadow rounded-lg">
    <!-- Input field for Phone Number -->
    <div class="mb-4">
      <label
        for="phoneNumber"
        class="block text-sm font-semibold text-gray-600 mb-2"
        >Phone Number:</label
      >
      <InputMask
        id="phone-number"
        placeholder="(999) 999-9999"
        mask="(999) 999-9999"
        v-model="phoneNumber"
        class="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
      ></InputMask>
    </div>
    <p class="text-center text-sm font-semibold text-gray-600">-Or-</p>
    <!-- Input field for Email -->
    <div class="mb-6">
      <label for="email" class="block text-sm font-semibold text-gray-600 mb-2"
        >Email Address:</label
      >
      <input
        v-model="email"
        type="email"
        id="email"
        class="w-full px-4 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
        placeholder="Enter email address"
      />
    </div>

    <!-- Search Button -->
    <button
      @click="searchPolicy"
      :disabled="isLoading"
      class="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 disabled:opacity-50 transition duration-300 flex justify-center items-center"
    >
      <div v-if="isLoading" class="flex items-center">
        <Icon
          icon="svg-spinners:90-ring"
          class="animate-spin"
          style="font-size: 20px; color: white"
        />
        <span class="ml-2">Searching...</span>
      </div>
      <div v-else>Search</div>
    </button>

    <!-- Error Message -->
    <p v-if="error" class="text-red-500 text-sm mt-4">{{ error }}</p>

    <!-- Display Search Results with Scrollable Container -->
    <div
      v-if="searchResult && searchResult.length"
      class="mt-6 bg-gray-100 p-4 rounded overflow-auto"
      style="max-height: 300px"
    >
      <h3 class="text-lg font-semibold text-gray-700 mb-4">
        Search Results:
        <span class="text-blue-500">{{ searchResult.length }}</span> found
      </h3>
      <ul class="space-y-4">
        <li
          v-for="policy in searchResult"
          :key="policy.policy_id"
          class="bg-white p-4 rounded shadow"
        >
          <div class="flex items-center mb-4">
            <!-- <img :src="getCompanyLogo(policy.writing_company_name)" class="h-10 w-10 rounded-full mr-4" alt="Company Logo"> -->
            <div>
              <p class="font-semibold">{{ policy.writing_company_name }}</p>
              <p class="text-xs text-gray-500">{{ policy.policy_number }}</p>
            </div>
          </div>
          <div class="flex items-center mb-2">
            <!-- <Icon :icon="getPolicyIcon(policy.policy_type)" class="h-6 w-6 text-blue-500 mr-2" /> -->
            <p class="font-semibold">{{ policy.policy_type }}</p>
          </div>
          <p>
            <strong>Start Date:</strong>
            {{ formatDateString(policy.start_date) }}
          </p>
          <p>
            <strong>End Date:</strong> {{ formatDateString(policy.end_date) }}
          </p>
        </li>
      </ul>
    </div>

    <!-- Loading Indicator -->
    <div v-if="isLoading" class="mt-4 flex justify-center">
      <Icon
        icon="svg-spinners:3-dots-bounce"
        class="animate-bounce"
        style="font-size: 24px; color: gray"
      />
    </div>
    <!-- Display a message when no results are found -->
    <div v-else-if="searchResult && !searchResult.length" class="mt-4">
      <p class="text-red-500">No results found.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { watch } from 'vue';
import { Icon } from '@iconify/vue';
import InputMask from 'primevue/inputmask';

export default {
  name: 'policySearch',
  components: {
    Icon,
    InputMask,
  },
  data() {
    return {
      phoneNumber: '',
      email: '',
      searchResult: null,
      isLoading: false,
      error: null,
    };
  },
  methods: {
    formatDateString(dateStr) {
      const dateObj = new Date(dateStr);
      const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS, add 1 to normalize
      const day = String(dateObj.getDate()).padStart(2, '0');
      const year = dateObj.getFullYear();
      return `${month}-${day}-${year}`;
    },
    async searchPolicy() {
      // Reset previous search data
      this.searchResult = null;
      this.error = null;

      try {
        this.isLoading = true;

        // Clean the phone number by removing parentheses and hyphens
        const cleanedPhoneNumber = this.phoneNumber.replace(/[()\s-]+/g, '');

        // Define the payload for the request
        const payload = this.email
          ? { email: this.email }
          : { phoneNumber: cleanedPhoneNumber };

        // Send a POST request to the server
        const response = await axios.post('api/verify-policy', payload);

        // Handle the API response
        this.searchResult = response.data.policyInfo; // Assuming response.data.value contains the array of policies
      } catch (error) {
        this.error = error.response
          ? error.response.data.error
          : 'An error occurred';
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-bounce {
  animation: bounce 1s infinite;
}
</style>
