<template>
  <div class="flex flex-col md:flex-row md:h-screen">
    <aside class="sidebar w-64 bg-gray-800 drop-shadow-lg text-white hidden md:block shiny-effect">
      <div class="py-4 px-3">
        <ul class="space-y-8">
          <li>
            <router-link to="/backend/dashboard">
              <img
                src="@/assets/images/insurance_express-main-logo.svg"
                alt="logo"
                class="w-full mx-auto hover:cursor-pointer"
              />
            </router-link>
          </li>
          <li>
            <div @click="toggleUserProfile" class="flex items-center space-x-3 my-4 p-2 rounded-md hover:cursor-pointer hover:bg-gray-100 hover:text-gray-800">
              <img :src="userAvatar" alt="User avatar" class="h-10 w-10 rounded-full" />
              <div>
                <p class="text-gray-800 text-sm font-semibold">{{ userName }}</p>
                <p class="text-gray-800 text-xs">{{ formattedRole }}</p>
              </div>
            </div>
          </li>
          <li>
            <router-link to="/backend/dashboard" class="flex items-center p-2 hover:bg-gray-100 rounded-md hover:cursor-pointer">
              <Icon icon="mdi:view-dashboard-outline" class="shrink-0 h-6 w-6 text-gray-800" />
              <span class="ml-3 text-gray-800">Dashboard</span>
            </router-link>
          </li>
          <li v-if="isSuperAdmin">
            <router-link to="/backend/manage-users" class="flex items-center p-2 hover:bg-gray-100 rounded-md hover:cursor-pointer">
              <Icon icon="mdi:account-multiple-outline" class="shrink-0 h-6 w-6 text-gray-800" />
              <span class="ml-3 text-gray-800">User Management</span>
            </router-link>
          </li>
          <li v-if="isSuperAdmin">
            <router-link to="/backend/system-settings" class="flex items-center p-2 hover:bg-gray-100 rounded-md hover:cursor-pointer">
              <Icon icon="mdi:cog-outline" class="shrink-0 h-6 w-6 text-gray-800" />
              <span class="ml-3 text-gray-800">System Settings</span>
            </router-link>
          </li>
          <li v-if="canViewAnalytics">
            <router-link to="/backend/analytics" class="flex items-center p-2 hover:bg-gray-100 rounded-md hover:cursor-pointer">
              <Icon icon="mdi:chart-bar" class="shrink-0 h-6 w-6 text-gray-800" />
              <span class="ml-3 text-gray-800">Analytics</span>
            </router-link>
          </li>
          <li v-if="canManageCommunications && !isSuperAdmin">
            <router-link to="/backend/communications" class="flex items-center p-2 hover:bg-gray-100 rounded-md hover:cursor-pointer">
              <Icon icon="maki:communications-tower" class="shrink-0 h-6 w-6 text-gray-800" />
              <span class="ml-3 text-gray-800">Communications</span>
            </router-link>
          </li>
          <li v-if="customerServiceRepresentative && !isSuperAdmin">
            <router-link to="/backend/accord-form-submission" class="flex items-center p-2 hover:bg-gray-100 rounded-md hover:cursor-pointer">
              <Icon icon="ant-design:form-outlined" class="shrink-0 h-6 w-6 text-gray-800" />
              <span class="ml-3 text-gray-800">Accord Form Submission</span>
            </router-link>
          </li>
          <li v-if="customerServiceRepresentative && !isSuperAdmin">
            <router-link to="/backend/accord-form-log" class="flex items-center p-2 hover:bg-gray-100 rounded-md hover:cursor-pointer">
              <Icon icon="ant-design:form-outlined" class="shrink-0 h-6 w-6 text-gray-800" />
              <span class="ml-3 text-gray-800">Accord Form Log</span>
            </router-link>
          </li>
          <li v-if="underwriter && !isSuperAdmin">
            <router-link to="/backend/underwriting" class="flex items-center p-2 hover:bg-gray-100 rounded-md hover:cursor-pointer">
              <Icon icon="ant-design:form-outlined" class="shrink-0 h-6 w-6 text-gray-800" />
              <span class="ml-3 text-gray-800">Underwriting</span>
            </router-link>
          </li>
          <li>
            <button @click="logout" class="flex items-center p-2 hover:bg-gray-100 rounded-md hover:cursor-pointer w-full">
              <Icon icon="mdi:logout" class="shrink-0 h-6 w-6 text-gray-800" />
              <span class="ml-3 text-gray-800">Logout</span>
            </button>
          </li>
        </ul>
      </div>
    </aside>
    <!-- Bottom Navigation Bar for Mobile -->
    <div class="bg-gray-800 text-white fixed bottom-0 inset-x-0 flex justify-between text-sm block md:hidden shadow-md pb-safe z-10 shiny-effect">
      <router-link to="/backend/dashboard" class="flex flex-col items-center p-2 transition hover:bg-gray-100" :class="{ 'bg-gray-100': isActive('/backend/dashboard') }">
        <Icon icon="mdi:view-dashboard-outline" class="h-6 w-6 text-gray-800 nav-icon" />
      </router-link>
      <router-link v-if="isSuperAdmin" to="/backend/manage-users" class="flex flex-col items-center p-2 transition hover:bg-gray-100" :class="{ 'bg-gray-100': isActive('/backend/manage-users') }">
        <Icon icon="mdi:account-multiple-outline" class="h-6 w-6 text-gray-800 nav-icon" />
      </router-link>
      <router-link v-if="isSuperAdmin" to="/backend/system-settings" class="flex flex-col items-center p-2 transition hover:bg-gray-100" :class="{ 'bg-gray-100': isActive('/backend/system-settings') }">
        <Icon icon="mdi:cog-outline" class="h-6 w-6 text-gray-800 nav-icon" />
      </router-link>
      <router-link v-if="canViewAnalytics" to="/backend/analytics" class="flex flex-col items-center p-2 transition hover:bg-gray-100" :class="{ 'bg-gray-100': isActive('/backend/analytics') }">
        <Icon icon="mdi:chart-bar" class="h-6 w-6 text-gray-800 nav-icon" />
      </router-link>
      <router-link v-if="canManageCommunications && !isSuperAdmin" to="/backend/communications" class="flex flex-col items-center p-2 transition hover:bg-gray-100" :class="{ 'bg-gray-100': isActive('/backend/communications') }">
        <Icon icon="maki:communications-tower" class="h-6 w-6 text-gray-800 nav-icon" />
      </router-link>
      <router-link v-if="customerServiceRepresentative && !isSuperAdmin" to="/backend/accord-form-submission" class="flex flex-col items-center p-2 transition hover:bg-gray-100" :class="{ 'bg-gray-100': isActive('/backend/accord-form-submission') }">
        <Icon icon="ant-design:form-outlined" class="h-6 w-6 text-gray-800 nav-icon" />
      </router-link>
      <router-link v-if="customerServiceRepresentative && !isSuperAdmin" to="/backend/accord-form-log" class="flex flex-col items-center p-2 transition hover:bg-gray-100" :class="{ 'bg-gray-100': isActive('/backend/accord-form-log') }">
        <Icon icon="ant-design:form-outlined" class="h-6 w-6 text-gray-800 nav-icon" />
      </router-link>
      <router-link v-if="underwriter && !isSuperAdmin" to="/backend/underwriting" class="flex flex-col items-center p-2 transition hover:bg-gray-100" :class="{ 'bg-gray-100': isActive('/backend/underwriting') }">
        <Icon icon="ant-design:form-outlined" class="h-6 w-6 text-gray-800 nav-icon" />
      </router-link>
      <button @click="logout" class="flex flex-col items-center p-2 hover:bg-gray-100 transition">
        <Icon icon="mdi:logout" class="h-6 w-6 text-gray-800 nav-icon" />
      </button>
      <button @click="toggleUserProfile" class="flex flex-col items-center p-2 hover:bg-gray-100 transition">
        <img :src="userAvatar" alt="User avatar" class="h-6 w-6 rounded-full" />
      </button>
    </div>
    <profile-edit-modal :show-modal="showProfileModal" :close-modal="toggleUserProfile" />
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import axios from 'axios';
import { mapState, mapGetters } from 'vuex';
import profileEditModal from '../profile/ProfileEditModal.vue';

export default {
  name: 'Navigation',
  components: {
    Icon,
    profileEditModal,
  },
  data() {
    return {
      showProfileModal: false,
    };
  },
  computed: {
    ...mapState({
      userName: state => `${state.customerInfo.firstName} ${state.customerInfo.lastName}`,
      userAvatar: state => state.customerInfo.profileImage || '@/assets/images/backend-graphics/default_avatar.png',
    }),
    ...mapGetters(['formattedRole']),
    isSuperAdmin() {
      return this.$store.getters.userRole === 'superadmin';
    },
    canViewAnalytics() {
      return ['admin', 'superadmin'].includes(this.$store.getters.userRole);
    },
    canManageCommunications() {
      return ['superadmin', 'admin', 'csr'].includes(this.$store.getters.userRole);
    },
    customerServiceRepresentative() {
      return ['superadmin', 'admin', 'csr'].includes(this.$store.getters.userRole);
    },
    underwriter() {
      return ['superadmin', 'admin', 'csr', 'underwriter'].includes(this.$store.getters.userRole);
    },
  },
  methods: {
    isActive(path) {
      return this.$route.path === path;
    },
    async logout() {
      try {
        const response = await axios.post('/api/logout');
        console.log('Logout successful:', response.data);
        this.$store.commit('CLEAR_USER_DATA');
        await this.$router.push('/login');
      } catch (error) {
        console.error('Logout failed or redirect failed:', error);
        if (error.response && error.response.status === 419) {
          console.error('CSRF token mismatch:', error.response.data);
        }
      }
    },
    toggleUserProfile() {
      this.showProfileModal = !this.showProfileModal;
    },
  },
};
</script>

<style scoped>
.sidebar,
.fixed {
  transition: transform 0.5s, background 0.5s;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
}

.pb-safe {
  padding-bottom: env(safe-area-inset-bottom);
}

.nav-icon {
  transition: transform 0.1s ease;
}

.nav-icon:active {
  transform: scale(1.1);
}

.shiny-effect {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rounded-full {
  border-radius: 9999px;
}
</style>
