<template>
  <div>
    <div class="form-group mb-4">
      <label
        for="underwriter_name"
        class="block text-sm font-medium text-gray-700"
        >Underwriter Name:</label
      >
      <input
        v-bind:value="underwriterName"
        @input="$emit('update:underwriterName', $event.target.value)"
        type="text"
        id="underwriter_name"
        class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>

    <div class="form-group mb-4">
      <label
        for="underwriter_confirmation_date"
        class="block text-sm font-medium text-gray-700"
        >Underwriter Confirmation Date:</label
      >
      <input
        v-bind:value="confirmationDate"
        @input="$emit('update:confirmationDate', $event.target.value)"
        type="date"
        id="underwriter_confirmation_date"
        class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>

    <div class="form-group mb-4">
      <label
        for="fields_confirmed_by_underwriter"
        class="block text-sm font-medium text-gray-700"
        >Fields Confirmed by Underwriter:</label
      >
      <input
        v-bind:value="confirmedFields"
        @input="$emit('update:confirmedFields', $event.target.value)"
        type="text"
        id="fields_confirmed_by_underwriter"
        class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnderwriterConfirmationInput',
  props: {
    underwriterName: String,
    confirmationDate: String,
    confirmedFields: String,
  },
};
</script>

<style scoped>
/* Add any specific styles for this component here */
</style>
