<template>
  <div class="max-w-2xl">
    <h2
      v-if="selectedOption.length === 0"
      class="text-2xl font-bold my-4 md:my-6 text-center text-ietextlight"
    >
      What would you like to do,
      <span class="capitalize text-blue-500">{{
        formData.contactInformation.firstName
      }}</span>
      ?
    </h2>
    <div
      v-if="selectedOption.length === 0"
      class="grid grid-cols-1 gap-6 md:grid-cols-2"
    >
      <!-- Vehicle Changes Card -->
      <div
        class="transition transform hover:-translate-y-1 cursor-pointer p-4 border rounded-lg shadow-sm hover:shadow-md"
        @click="toggleSelection('vehicleChanges')"
      >
        <div class="flex items-center space-x-3">
          <Icon icon="mdi:car" class="h-6 w-6 text-blue-500" />
          <h3 class="font-semibold text-ietextlight">Vehicle Changes</h3>
        </div>
        <p class="text-sm text-gray-600 mt-2">
          Add or remove a vehicle, or modify vehicle details.
        </p>
      </div>
      <!-- Driver Changes -->
      <div
        class="transition transform hover:-translate-y-1 cursor-pointer p-4 border rounded-lg shadow-sm hover:shadow-md"
        @click="toggleSelection('driverChanges')"
      >
        <div class="flex items-center space-x-3">
          <Icon icon="material-symbols:person" class="h-6 w-6 text-blue-500" />
          <h3 class="font-semibold text-ietextlight">Driver Changes</h3>
        </div>
        <p class="text-sm text-gray-600 mt-2">
          Add or remove a driver, or modify driver details.
        </p>
      </div>
      <!-- Coverage Adjustments Card -->
      <div
        class="transition transform hover:-translate-y-1 cursor-pointer p-4 border rounded-lg shadow-sm hover:shadow-md"
        @click="toggleSelection('coverageAdjustments')"
      >
        <div class="flex items-center space-x-3">
          <Icon icon="mdi:shield-car" class="h-6 w-6 text-blue-500" />
          <h3 class="font-semibold text-ietextlight">Coverage Adjustments</h3>
        </div>
        <p class="text-sm text-gray-600 mt-2">Modify your coverage options.</p>
      </div>
      <!-- Other Changes Card -->
      <div
        class="transition transform hover:-translate-y-1 cursor-pointer p-4 border rounded-lg shadow-sm hover:shadow-md"
        @click="toggleSelection('otherChanges')"
      >
        <div class="flex items-center space-x-3">
          <Icon icon="mdi:file-question" class="h-6 w-6 text-blue-500" />
          <h3 class="font-semibold text-ietextlight">Other Changes</h3>
        </div>
        <p class="text-sm text-gray-600 mt-2">
          Specify any other changes or updates you need.
        </p>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="selectedOption.length > 0" class="mt-5">
        <!-- Vehicle Changes Content -->
        <div
          v-if="selectedOption.includes('vehicleChanges')"
          class="text-center mx-0 md:mx-auto mt-4 mb-6 max-w-md"
        >
          <!-- Select Vehicle Change Option -->
          <div v-if="!okayToProceedAndCreateVehicle">
            <h2
              class="text-2xl font-bold my-4 md:my-6 text-center text-ietextlight"
            >
              Change Type
            </h2>
            <h3 class="font-semibold text-ietextlight">
              Please select what changes you would like to make:
            </h3>

            <div
              class="flex flex-col md:flex-row md:mt-4 space-y-2 md:space-y-0 justify-center items-center mx-auto"
            >
              <!-- Add Vehicle Button -->
              <button
                @click="vehicleChangeOption = 'Add'"
                :class="[
                  'mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex md:flex-col justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                  vehicleChangeOption === 'Add'
                    ? 'scale-110 bg-blue-500 text-white focus:ring-blue-400'
                    : 'scale-90 bg-blue-500 hover:bg-blue-600 text-white',
                  vehicleChangeOption != null && vehicleChangeOption !== 'Add'
                    ? 'cursor-not-allowed bg-blue-300 text-blue-800'
                    : '',
                ]"
              >
                <icon
                  :icon="'mi:add'"
                  class="inline-block w-5 h-5 my-2 md:mx-auto align-middle"
                ></icon>
                <span
                  class="inline-block text-center justify-left md:justify-center m-auto"
                  >Add Vehicle</span
                >
              </button>
              <!-- Remove Vehicle Button -->
              <button
                @click="vehicleChangeOption = 'Remove'"
                :class="[
                  'mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex md:flex-col justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                  vehicleChangeOption === 'Remove'
                    ? 'scale-110 bg-red-500 text-white focus:ring-red-400'
                    : 'scale-90 bg-red-500 hover:bg-red-600 text-white',
                  vehicleChangeOption != null &&
                  vehicleChangeOption !== 'Remove'
                    ? 'cursor-not-allowed bg-red-300 text-red-800'
                    : '',
                ]"
              >
                <icon
                  :icon="'line-md:remove'"
                  class="inline-block w-5 h-5 my-2 md:mx-auto align-middle"
                ></icon>
                <span
                  class="inline-block text-center justify-left md:justify-center m-auto"
                  >Remove Vehicle</span
                >
              </button>
              <!-- Replace Vehicle Button -->
              <button
                @click="vehicleChangeOption = 'Replace'"
                :class="[
                  'mt-3 sm:mt-0 w-full sm:w-auto flex md:flex-col justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                  vehicleChangeOption === 'Replace'
                    ? 'scale-110 bg-gray-200 text-gray-700 focus:ring-gray-400'
                    : 'scale-90 bg-gray-200 hover:bg-gray-400 text-gray-700',
                  vehicleChangeOption != null &&
                  vehicleChangeOption !== 'Replace'
                    ? 'cursor-not-allowed bg-gray-300 text-gray-800'
                    : '',
                ]"
              >
                <icon
                  :icon="'material-symbols:swap-horiz-rounded'"
                  class="inline-block w-5 h-5 my-2 md:mx-auto align-middle"
                ></icon>
                <span
                  class="inline-block text-center justify-left md:justify-center m-auto"
                  >Replace Vehicle</span
                >
              </button>
            </div>
          </div>

          <!--Create Vehicle-->
          <div
            v-if="
              okayToProceedAndCreateVehicle &&
              !askQuestionForVehicleAndDriverChanges &&
              !okayToProceedAndCreateDriver
            "
            class="grid grid-cols-1 gap-4 relative"
          >
            <!--Action Badge-->
            <div class="text-right">
              <div
                v-if="addMoreVehicles"
                :class="[
                  {
                    'bg-green-500 text-white': vehicleChangeOption === 'Add',
                    'bg-red-500 text-white': vehicleChangeOption === 'Remove',
                    'bg-gray-200 text-ietextlight':
                      vehicleChangeOption === 'Replace',
                    hidden: vehicleChangeOption === null,
                  },
                  'inline-block text-xs font-bold py-1 px-3 rounded-full shadow-md pulse-animate',
                ]"
              >
                {{
                  vehicleChangeOption === 'Add'
                    ? 'Add Vehicle'
                    : vehicleChangeOption === 'Remove'
                      ? 'Remove Vehicle'
                      : vehicleChangeOption === 'Replace'
                        ? 'New Vehicle'
                        : ''
                }}
              </div>
            </div>

            <!--NEEDS VIF STATEMENT I THINK-->
            <div>
              <h2
                class="text-2xl font-bold my-4 md:my-6 text-center text-ietextlight"
                v-html="
                  vehicleChangeOption === 'Replace'
                    ? 'Enter your <span class=&quot;text-blue-500&quot;>New</span> Vehicle'
                    : 'Enter your Vehicle Information'
                "
              ></h2>
              <!--List Vehicles-->
              <div v-if="vehicles.length > 0" class="w-full">
                <h3
                  class="text-md md:text-lg font-bold my-4 text-center text-ietextlight"
                >
                  Selected Vehicle Changes
                </h3>
                <div v-if="vehicles.length > 0">
                  <div
                    v-for="vehicle in vehicles"
                    :key="vehicle.id"
                    class="border-t border-gray-200 rounded-lg"
                  >
                    <!--Vehicle Header-->
                    <div
                      class="bg-gray-100 flex items-center justify-between p-3 cursor-pointer"
                      @click="vehicle.isExpanded = !vehicle.isExpanded"
                    >
                      <div class="flex items-center space-x-2">
                        <icon
                          :icon="'fluent:vehicle-car-profile-ltr-24-regular'"
                          class="w-6 h-6 text-gray-600"
                        ></icon>
                        <span v-if="vehicle.isExpanded"
                          >Vehicle Information</span
                        >
                        <span v-else class="capitalize">
                          {{ vehicle.make }} {{ vehicle.model }}</span
                        >
                      </div>
                      <icon
                        v-if="vehicle.associatedDriverID"
                        :icon="'iconamoon:link-fill'"
                        class="w-6 h-6 text-gray-600"
                      ></icon>
                      <div
                        :class="[
                          {
                            'bg-green-500 text-white': vehicle.action === 'Add',
                            'bg-red-500 text-white':
                              vehicle.action === 'Remove',
                            'bg-gray-200 text-ietextlight':
                              vehicle.action === 'Replace',
                            hidden: !vehicle.action,
                          },
                          'text-xs font-bold py-1 px-3 rounded-full shadow-md',
                        ]"
                      >
                        {{
                          vehicle.action === 'Replace'
                            ? 'Replace (New)'
                            : vehicle.action
                        }}
                      </div>
                    </div>
                    <div
                      v-show="vehicle.isExpanded"
                      class="p-4 grid grid-cols-1 md:grid-cols-2 gap-4"
                    >
                      <div class="text-left text-ietextlight">
                        <p class="text-sm font-bold capitalize">
                          {{ vehicle.make }} {{ vehicle.model }}
                        </p>
                        <p class="text-xs">
                          Year:
                          <span class="text-blue-800">{{ vehicle.year }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Add More Vehicles Button-->
              <div v-if="vehicles.length > 0 && !addMoreVehicles">
                <button
                  @click="handleAddMoreVehicles()"
                  class="inline-flex justify-center mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {{
                    vehicleChangeOption === 'Replace'
                      ? 'Add Previous Vehicle'
                      : 'Add More Vehicles'
                  }}
                </button>
              </div>

              <!--Create Vehicle Inputs-->
              <div v-if="addMoreVehicles">
                <h3 class="text-lg text-ietextlight font-bold mb-4">
                  {{
                    vehicleChangeOption === 'Replace'
                      ? 'New Vehicle Details'
                      : 'Vehicle Details:'
                  }}
                </h3>

                <!-- Select Year -->
                <div class="py-2">
                  <label
                    for="addVehicleYear"
                    class="block text-sm font-medium text-left text-gray-700"
                    >Select Year:</label
                  >
                  <select
                    v-model="selectedYear"
                    id="addVehicleYear"
                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  >
                    <option value="">Select Year</option>
                    <option v-for="year in vehicleYears" :value="year">
                      {{ year }}
                    </option>
                  </select>
                </div>
                <!-- Select Make -->
                <div class="py-2">
                  <label
                    for="addVehicleMake"
                    class="block text-sm font-medium text-left text-gray-700"
                    >Select Make:</label
                  >
                  <select
                    v-model="selectedMake"
                    id="addVehicleMake"
                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    :disabled="!selectedYear"
                  >
                    <option value="">Select Make</option>
                    <option v-for="make in sortedMakes" :value="make.make">
                      {{ make.make }}
                    </option>
                  </select>
                </div>
                <!-- Select Model -->
                <div class="py-2">
                  <label
                    for="addVehicleModel"
                    class="block text-sm font-medium text-left text-gray-700"
                    >Select Model:</label
                  >
                  <select
                    v-model="selectedModel"
                    id="addVehicleModel"
                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    :disabled="!selectedMake"
                  >
                    <option value="">Select Model</option>
                    <option
                      v-for="model in sortedModels"
                      :value="model.ModelName"
                    >
                      {{ model.ModelName }}
                    </option>
                  </select>
                </div>
                <!-- Add/ Remove Vehicle Buttons -->
                <div
                  class="flex flex-col md:flex-row md:mt-4 space-y-2 md:space-y-0 justify-center items-center mx-auto"
                >
                  <!-- Add Vehicle Button -->
                  <button
                    v-if="vehicleChangeOption === 'Add'"
                    @click="handleVehicleAction"
                    class="mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
                  >
                    <icon
                      :icon="'mi:add'"
                      class="inline-block w-5 h-5 align-middle"
                    ></icon>
                    <span class="ml-2">Add</span>
                    <!-- Only shows text on small screens -->
                  </button>

                  <!-- Remove Vehicle Button -->
                  <button
                    v-if="vehicleChangeOption === 'Remove'"
                    @click="handleVehicleAction"
                    class="mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
                  >
                    <icon
                      :icon="'line-md:remove'"
                      class="inline-block w-5 h-5 align-middle"
                    ></icon>
                    <span class="ml-2">Remove</span>
                    <!-- Only shows text on small screens -->
                  </button>

                  <!-- Replace Vehicle Button -->
                  <button
                    v-if="vehicleChangeOption === 'Replace'"
                    @click="handleVehicleAction"
                    class="mt-3 sm:mt-0 w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                  >
                    <icon
                      :icon="'material-symbols:swap-horiz-rounded'"
                      class="inline-block w-5 h-5 align-middle"
                    ></icon>
                    <span class="ml-2">Replace</span>
                    <!-- Only shows text on small screens -->
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!--Ask Question for Vehicle and Driver Changes-->
          <div
            v-if="
              askQuestionForVehicleAndDriverChanges &&
              !okayToProceedAndCreateDriver
            "
          >
            <div class="text-center">
              <h2
                class="text-2xl font-bold my-4 md:my-6 text-center text-ietextlight"
              >
                Driver Change?
              </h2>
              <div class="mt-2 px-7 py-3">
                <p class="font-semibold text-ietextlight">
                  Would you like to add, remove, or replace a driver along with
                  the vehicle change?
                </p>
              </div>
              <div
                class="flex flex-col md:flex-row items-center justify-center px-4 py-3"
              >
                <!--Yes Button-->
                <button
                  @click="answerToQuestionForVehicleAndDriverChanges = 'Yes'"
                  :class="[
                    'mt-3 md:mt-0 md:mr-2 w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                    answerToQuestionForVehicleAndDriverChanges === 'Yes'
                      ? 'scale-110 bg-blue-500 text-white focus:ring-blue-400'
                      : 'scale-90 bg-blue-500 hover:bg-blue-600 text-white',
                    answerToQuestionForVehicleAndDriverChanges != null &&
                    answerToQuestionForVehicleAndDriverChanges !== 'Yes'
                      ? 'cursor-not-allowed bg-blue-300 text-blue-800'
                      : '',
                  ]"
                >
                  Yes
                </button>
                <!--No Button-->
                <button
                  @click="answerToQuestionForVehicleAndDriverChanges = 'No'"
                  :class="[
                    'mt-3 md:mt-0 md:mr-2 w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                    answerToQuestionForVehicleAndDriverChanges === 'No'
                      ? 'scale-110 bg-red-500 text-white focus:ring-red-400'
                      : 'scale-90 bg-red-500 hover:bg-red-600 text-white',
                    answerToQuestionForVehicleAndDriverChanges != null &&
                    answerToQuestionForVehicleAndDriverChanges !== 'No'
                      ? 'cursor-not-allowed bg-red-300 text-red-800'
                      : '',
                  ]"
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="okayToProceedAndCreateDriver && !okayToProceedToSelectDrivers"
          >
            <h2
              class="text-2xl font-bold my-4 md:text-3xl md:my-8 text-center text-ietextlight"
            >
              Change Type
            </h2>
            <h3 class="font-semibold text-ietextlight md:text-lg">
              Please select what changes you would like to make:
            </h3>
            <!--Select Driver Change Option-->
            <div
              class="flex flex-col md:flex-row md:mt-4 space-y-2 md:space-y-0 justify-center items-center mx-auto"
            >
              <!-- Add Driver Button -->
              <button
                @click="driverChangeOption = 'Add'"
                :class="[
                  'mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex md:flex-col justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                  driverChangeOption === 'Add'
                    ? 'scale-110 bg-blue-500 text-white focus:ring-blue-400'
                    : 'scale-90 bg-blue-500 hover:bg-blue-600 text-white',
                  driverChangeOption != null && driverChangeOption !== 'Add'
                    ? 'cursor-not-allowed bg-blue-300 text-blue-800'
                    : '',
                ]"
              >
                <icon
                  :icon="'mi:add'"
                  class="inline-block w-5 h-5 my-2 md:mx-auto align-middle"
                ></icon>
                <span
                  class="inline-block text-center justify-left md:justify-center m-auto"
                  >Add Driver</span
                >
              </button>

              <!-- Remove Driver Button -->
              <button
                @click="driverChangeOption = 'Remove'"
                :class="[
                  'mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex md:flex-col justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                  driverChangeOption === 'Remove'
                    ? 'scale-110 bg-red-500 text-white focus:ring-red-400'
                    : 'scale-90 bg-red-500 hover:bg-red-600 text-white',
                  driverChangeOption != null && driverChangeOption !== 'Remove'
                    ? 'cursor-not-allowed bg-red-300 text-red-800'
                    : '',
                ]"
              >
                <icon
                  :icon="'line-md:remove'"
                  class="inline-block w-5 h-5 my-2 md:mx-auto align-middle"
                ></icon>
                <span
                  class="inline-block text-center justify-left md:justify-center m-auto"
                  >Remove Driver</span
                >
              </button>

              <!-- Replace Driver Button -->
              <button
                @click="driverChangeOption = 'Replace'"
                :class="[
                  'mt-3 sm:mt-0 w-full sm:w-auto flex md:flex-col justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                  driverChangeOption === 'Replace'
                    ? 'scale-110 bg-gray-200 text-gray-700 focus:ring-gray-400'
                    : 'scale-90 bg-gray-200 hover:bg-gray-400 text-gray-700',
                  driverChangeOption != null && driverChangeOption !== 'Replace'
                    ? 'cursor-not-allowed bg-gray-300 text-gray-800'
                    : '',
                ]"
              >
                <icon
                  :icon="'material-symbols:swap-horiz-rounded'"
                  class="inline-block w-5 h-5 my-2 md:mx-auto align-middle"
                ></icon>
                <span
                  class="inline-block text-center justify-left md:justify-center m-auto"
                  >Replace Driver</span
                >
              </button>
            </div>
          </div>
          <div v-if="okayToProceedToSelectDrivers">
            <div>
              <div v-if="vehicles.length > 0" class="w-full">
                <h3
                  class="text-md md:text-lg font-bold my-4 text-center text-ietextlight"
                >
                  Selected Vehicle Changes
                </h3>
                <div>
                  <div
                    v-for="vehicle in availableVehicles"
                    :key="vehicle.id"
                    class="border-t border-gray-200 rounded-lg"
                  >
                    <!--Vehicle Header-->
                    <div
                      class="bg-gray-100 flex items-center justify-between p-3 cursor-pointer"
                      @click="vehicle.isExpanded = !vehicle.isExpanded"
                    >
                      <div class="flex items-center space-x-2">
                        <icon
                          :icon="'fluent:vehicle-car-profile-ltr-24-regular'"
                          class="w-6 h-6 text-ietextlight"
                        ></icon>
                        <span v-if="vehicle.isExpanded"
                          >Vehicle Information</span
                        >
                        <span v-else class="capitalize text-ietextlight"
                          >{{ vehicle.make }} {{ vehicle.model }}</span
                        >
                      </div>
                      <div class="flex items-center space-x-2">
                        <icon
                          v-if="vehicle.associatedDriverID"
                          :icon="'iconamoon:link-fill'"
                          class="w-6 h-6 text-gray-600"
                        ></icon>
                        <div
                          :class="[
                            {
                              'bg-green-500 text-white':
                                vehicle.action === 'Add',
                              'bg-red-500 text-white':
                                vehicle.action === 'Remove',
                              'bg-gray-200 text-ietextlight':
                                vehicle.action === 'Replace',
                              hidden: !vehicle.action,
                            },
                            'text-xs font-bold py-1 px-3 rounded-full shadow-md',
                          ]"
                        >
                          {{ vehicle.action }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-show="vehicle.isExpanded"
                      class="p-4 grid grid-cols-1 md:grid-cols-2 gap-4"
                    >
                      <div class="text-left text-ietextlight">
                        <p class="text-sm font-bold capitalize">
                          {{ vehicle.make }} {{ vehicle.model }}
                        </p>
                        <p class="text-xs">
                          Year:
                          <span class="text-blue-800">{{ vehicle.year }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Show Added Drivers-->
              <div v-if="drivers.length > 0" class="mx-auto">
                <h3 class="text-lg font-bold my-4 text-center text-ietextlight">
                  Driver(s) & Associated Vehicles
                </h3>
                <div class="space-y-4">
                  <div
                    v-for="{ driver, vehicles } in driverVehiclePairs"
                    :key="driver.id"
                    class="bg-white shadow-lg rounded-lg overflow-hidden"
                  >
                    <!-- Driver Header -->
                    <div
                      class="flex items-center justify-between bg-gray-100 p-3 cursor-pointer"
                      @click="driver.isExpanded = !driver.isExpanded"
                    >
                      <div class="flex items-center space-x-2">
                        <icon
                          :icon="'f7:person'"
                          class="w-6 h-6 text-ietextlight"
                        ></icon>
                        <p class="text-ietextlight">
                          <span v-if="driver.isExpanded"
                            >Driver Information</span
                          >
                          <span v-else class="capitalize text-ietextlight">
                            {{ driver.firstName }} {{ driver.lastName }}</span
                          >
                        </p>
                      </div>
                      <div class="flex items-center space-x-2">
                        <icon
                          v-if="driver.associatedVehicleID"
                          :icon="'iconamoon:link-fill'"
                          class="w-6 h-6 text-ietextlight"
                        ></icon>
                        <div
                          :class="[
                            {
                              'bg-green-500 text-white':
                                driver.action === 'Add',
                              'bg-red-500 text-white':
                                driver.action === 'Remove',
                              'bg-gray-200 text-ietextlight':
                                driver.action === 'Replace',
                              hidden: !driver.action,
                            },
                            'text-xs font-bold py-1 px-3 rounded-full shadow-md',
                          ]"
                        >
                          {{ driver.action }}
                        </div>
                      </div>
                    </div>
                    <!-- Driver Information -->
                    <div
                      v-show="driver.isExpanded"
                      class="p-4 text-ietextlight"
                    >
                      <div class="grid grid-cols-2 gap-4">
                        <div class="text-left">
                          <p class="text-sm font-bold capitalize">
                            {{ driver.firstName }} {{ driver.lastName }}
                          </p>
                          <p class="text-xs">
                            Date of Birth:
                            <span class="text-blue-800">{{
                              formatDOB(driver.dateOfBirth)
                            }}</span>
                          </p>
                          <p class="text-xs text-nowrap">
                            Driver License Number:
                            <span class="text-blue-800 capitalize">{{
                              driver.driversLicenseNumber
                            }}</span>
                          </p>
                          <p class="text-xs">
                            Age:
                            <span class="text-blue-800">{{
                              calculateAge(driver.dateOfBirth)
                            }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- Vehicle Information (within the Driver section) -->
                    <div
                      v-if="
                        driver.associatedVehicleIDs &&
                        driver.associatedVehicleIDs.length > 0
                      "
                      v-show="!driver.isExpanded"
                    >
                      <div
                        v-for="vehicleId in driver.associatedVehicleIDs"
                        :key="vehicleId"
                        class="border-t border-gray-200"
                      >
                        <!-- Find and display vehicle information -->
                        <div
                          v-if="
                            (vehicle = vehicles.find((v) => v.id === vehicleId))
                          "
                          class="p-3 bg-gray-100"
                        >
                          <div
                            class="flex items-center justify-between cursor-pointer"
                            @click="vehicle.isExpanded = !vehicle.isExpanded"
                          >
                            <div
                              class="flex items-center space-x-2 text-ietextlight"
                            >
                              <icon
                                :icon="'fluent:vehicle-car-profile-ltr-24-regular'"
                                class="w-6 h-6"
                              ></icon>
                              <span v-if="vehicle.isExpanded"
                                >Vehicle Information</span
                              >
                              <span v-else class="capitalize"
                                >{{ vehicle.make }} {{ vehicle.model }}</span
                              >
                            </div>
                            <div class="flex items-center space-x-2">
                              <icon
                                :icon="'iconamoon:link-fill'"
                                class="w-6 h-6 text-gray-600"
                              ></icon>
                              <div
                                :class="[
                                  {
                                    'bg-green-500 text-white':
                                      vehicle.action === 'Add',
                                    'bg-red-500 text-white':
                                      vehicle.action === 'Remove',
                                    'bg-gray-200 text-white':
                                      vehicle.action === 'Replace',
                                    hidden: !vehicle.action,
                                  },
                                  'text-xs font-bold py-1 px-3 rounded-full',
                                ]"
                              >
                                {{ vehicle.action }}
                              </div>
                            </div>
                          </div>
                          <div
                            v-show="vehicle.isExpanded"
                            class="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 text-ietextlight"
                          >
                            <div class="text-left">
                              <p class="text-sm font-bold capitalize">
                                {{ vehicle.make }} {{ vehicle.model }}
                              </p>
                              <p class="text-xs">
                                Year:
                                <span class="text-blue-800">{{
                                  vehicle.year
                                }}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="drivers.length > 0 && !addMoreDrivers">
                <button
                  @click="handleAddMoreDrivers()"
                  class="inline-flex justify-center md:mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Add More Drivers
                </button>
              </div>
              <!--Create Driver-->
              <div v-if="vehicles.length > 0 && addMoreDrivers">
                <h3
                  class="text-md md:text-lg font-bold my-4 text-center text-ietextlight"
                >
                  Driver Details:
                </h3>
                <!--Driver First Name-->
                <div class="py-2">
                  <label
                    for="driver-first-name"
                    class="block text-sm font-medium text-left text-gray-700"
                    >Driver First Name:</label
                  >
                  <input
                    v-model="driverFirstName"
                    id="driver-first-name"
                    type="text"
                    placeholder="First Name"
                    class="capitalize mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  />
                </div>
                <!--Driver Last Name-->
                <div class="py-2">
                  <label
                    for="driver-last-name"
                    class="block text-sm font-medium text-left text-gray-700"
                    >Driver Last Name:</label
                  >
                  <input
                    v-model="driverLastName"
                    id="driver-last-name"
                    type="text"
                    placeholder="Last Name"
                    class="capitalize mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  />
                </div>
                <!--Driver Date of Birth-->
                <div class="py-2">
                  <label
                    for="driver-date-of-birth"
                    class="block text-sm font-medium text-left text-gray-700"
                    >Driver Date of Birth:</label
                  >
                  <input
                    v-model="driverDateOfBirth"
                    id="driver-date-of-birth"
                    type="date"
                    placeholder="MM-DD-YYYY"
                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  />
                </div>
                <!--Driver License Number-->
                <div class="py-2">
                  <label
                    for="driver-dl-number"
                    class="block text-sm font-medium text-left text-gray-700"
                    >Driver License Number:</label
                  >
                  <input
                    v-model="driverLicenseNumber"
                    v-mask="'A ###-###-###-#'"
                    maxlength="14"
                    id="driver-dl-number"
                    type="text"
                    placeholder="Driver License Number"
                    class="capitalize mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  />
                </div>
                <!--Associate Vehicle(s)-->
                <div
                  v-if="
                    driverChangeOption === 'Add' ||
                    driverChangeOption === 'Replace'
                  "
                  class="py-2"
                >
                  <label
                    class="block text-sm font-medium text-left text-gray-700 mb-4"
                    >Select Vehicle(s):</label
                  >
                  <div class="space-y-4">
                    <div
                      v-for="vehicle in vehicles.filter(
                        (vehicle) => vehicle.action === 'Add',
                      )"
                      :key="vehicle.id"
                      class="flex items-start"
                    >
                      <div class="flex items-center h-5">
                        <input
                          v-model="selectedVehicles"
                          :value="vehicle.id"
                          type="checkbox"
                          id="'vehicle-' + vehicle.id"
                          class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label
                          :for="'vehicle-' + vehicle.id"
                          class="font-medium text-gray-700"
                          >{{ vehicle.make }} {{ vehicle.model }} ({{
                            vehicle.year
                          }})</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Add/ Remove Driver Buttons -->
                <button
                  v-if="driverChangeOption === 'Add'"
                  @click="handleDriverAction"
                  class="inline-flex justify-center md:mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Add Driver
                </button>
                <button
                  v-if="driverChangeOption === 'Remove'"
                  @click="handleDriverAction"
                  class="inline-flex justify-center md:mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Remove Driver
                </button>
                <!-- Replace Vehicle Button -->
                <button
                  @click="handleVehicleAction"
                  :class="[
                    'mt-3 sm:mt-0 w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                    vehicleChangeOption === 'Replace'
                      ? 'bg-gray-500 text-white'
                      : 'bg-gray-200 hover:bg-gray-400 text-gray-700',
                  ]"
                >
                  <icon
                    :icon="'material-symbols:swap-horiz-rounded'"
                    class="inline-block w-5 h-5 my-2 md:mx-auto align-middle"
                  ></icon>
                  <span
                    class="inline-block text-center justify-left md:justify-center m-auto"
                    >{{
                      vehicleChangeOption === 'Replace'
                        ? 'Add Vehicle Replacement (New Vehicle)'
                        : 'Replace Vehicle'
                    }}</span
                  >
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="errorMessage"
            class="fixed inset-0 flex items-center justify-center px-4"
            role="alert"
          >
            <div
              class="w-full max-w-md px-4 py-2 bg-red-100 border border-red-400 text-red-700 rounded-lg shadow-lg"
            >
              <div class="flex justify-between items-start">
                <div class="flex">
                  <strong class="font-bold mr-2">Error! </strong>
                  <span class="block">{{ errorMessage }}</span>
                </div>
                <button
                  @click="errorMessage = ''"
                  class="ml-auto -mx-1.5 -my-1.5 bg-red-100 p-1.5 rounded-lg text-red-500 hover:bg-red-200 focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--Driver Changes-->
        <div
          v-if="selectedOption.includes('driverChanges')"
          class="text-center mx-0 md:mx-auto mt-4 mb-6 max-w-md"
        >
          <!--Select Driver Change Option-->
          <div v-if="!okayToProceedAndCreateDriver">
            <h2
              class="text-2xl font-bold my-4 md:text-3xl md:my-8 text-center text-ietextlight"
            >
              Change Type
            </h2>
            <h3 class="font-semibold text-ietextlight md:text-lg">
              Please select what changes you would like to make:
            </h3>
            <div
              class="flex flex-col md:flex-row md:mt-4 space-y-2 md:space-y-0 justify-center items-center mx-auto"
            >
              <!-- Add Driver Button -->
              <button
                @click="driverChangeOption = 'Add'"
                :class="[
                  'mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex md:flex-col justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                  driverChangeOption === 'Add'
                    ? 'scale-110 bg-blue-500 text-white focus:ring-blue-400'
                    : 'scale-90 bg-blue-500 hover:bg-blue-600 text-white',
                  driverChangeOption != null && driverChangeOption !== 'Add'
                    ? 'cursor-not-allowed bg-blue-300 text-blue-800'
                    : '',
                ]"
              >
                <icon
                  :icon="'mi:add'"
                  class="inline-block w-5 h-5 my-2 md:mx-auto align-middle"
                ></icon>
                <span
                  class="inline-block text-center justify-left md:justify-center m-auto"
                  >Add Driver</span
                >
              </button>
              <!-- Remove Driver Button -->
              <button
                @click="driverChangeOption = 'Remove'"
                :class="[
                  'mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex md:flex-col justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                  driverChangeOption === 'Remove'
                    ? 'scale-110 bg-red-500 text-white focus:ring-red-400'
                    : 'scale-90 bg-red-500 hover:bg-red-600 text-white',
                  driverChangeOption != null && driverChangeOption !== 'Remove'
                    ? 'cursor-not-allowed bg-red-300 text-red-800'
                    : '',
                ]"
              >
                <icon
                  :icon="'line-md:remove'"
                  class="inline-block w-5 h-5 my-2 md:mx-auto align-middle"
                ></icon>
                <span
                  class="inline-block text-center justify-left md:justify-center m-auto"
                  >Remove Driver</span
                >
              </button>
              <!-- Replace Driver Button -->
              <button
                @click="driverChangeOption = 'Replace'"
                :class="[
                  'mt-3 sm:mt-0 w-full sm:w-auto flex md:flex-col justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                  driverChangeOption === 'Replace'
                    ? 'scale-110 bg-gray-200 text-gray-700 focus:ring-gray-400'
                    : 'scale-90 bg-gray-200 hover:bg-gray-400 text-gray-700',
                  driverChangeOption != null && driverChangeOption !== 'Replace'
                    ? 'cursor-not-allowed bg-gray-300 text-gray-800'
                    : '',
                ]"
              >
                <icon
                  :icon="'material-symbols:swap-horiz-rounded'"
                  class="inline-block w-5 h-5 my-2 md:mx-auto align-middle"
                ></icon>
                <span
                  class="inline-block text-center justify-left md:justify-center m-auto"
                  >Replace Driver</span
                >
              </button>
            </div>
          </div>
          <!--Create Drivers-->
          <div v-if="okayToProceedAndCreateDriver">
            <div class="relative mt-6">
              <!--Action Badge -->
              <div class="text-right">
                <div
                  v-if="addMoreDrivers"
                  :class="[
                    {
                      'bg-green-500 text-white': driverChangeOption === 'Add',
                      'bg-red-500 text-white': driverChangeOption === 'Remove',
                      'bg-gray-200 text-ietextlight':
                        driverChangeOption === 'Replace',
                      hidden: driverChangeOption === null,
                    },
                    'inline-block text-xs font-bold py-1 px-3 rounded-full shadow-md pulse-animate',
                  ]"
                >
                  {{
                    driverChangeOption === 'Add'
                      ? 'Add Driver'
                      : driverChangeOption === 'Remove'
                        ? 'Remove Driver'
                        : 'Replace Driver'
                  }}
                </div>
              </div>
              <!--Create Driver Section-->
              <div v-if="!attachVehicleToDriver">
                <h2
                  class="text-2xl font-bold my-4 md:my-6 text-center text-ietextlight"
                >
                  Enter your Driver Information
                </h2>
                <!--List Drivers-->
                <div v-if="drivers.length > 0">
                  <h3
                    class="text-lg font-bold my-4 text-center text-ietextlight"
                  >
                    Driver(s)
                  </h3>
                  <div class="flex flex-col">
                    <ul>
                      <li
                        v-for="driver in drivers"
                        :key="driver.id"
                        class="shadow-xl rounded-lg overflow-hidden my-2"
                      >
                        <div
                          class="flex items-center justify-between bg-gray-200 p-2"
                        >
                          <div class="flex items-center gap-2">
                            <icon
                              :icon="'line-md:person'"
                              class="w-6 h-6 mx-auto text-ietextlight"
                            ></icon>
                            <p class="text-ietextlight font-bold text-sm">
                              Driver
                            </p>
                          </div>
                          <div
                            :class="[
                              {
                                'bg-green-500 text-white':
                                  driver.action === 'Add',
                                'bg-red-500 text-white':
                                  driver.action === 'Remove',
                                'bg-gray-200 text-ietextlight':
                                  driver.action === 'Replace',
                                hidden: driver.action === null,
                              },
                              'text-xs text-right font-bold py-1 px-3 rounded-full shadow-md',
                            ]"
                          >
                            {{
                              driver.action === 'Add'
                                ? 'Add Driver'
                                : driver.action === 'Remove'
                                  ? 'Remove Driver'
                                  : 'Replace Driver'
                            }}
                          </div>
                        </div>
                        <div class="p-4 text-ietextlight">
                          <div class="grid grid-cols-2 gap-4">
                            <div class="text-left">
                              <p class="text-sm font-bold capitalize">
                                {{ driver.firstName }} {{ driver.lastName }}
                              </p>
                              <p class="text-xs">
                                Date of Birth:
                                <span class="text-blue-800">{{
                                  formatDOB(driver.dateOfBirth)
                                }}</span>
                              </p>
                              <p class="text-xs text-nowrap">
                                Driver License Number:
                                <span class="text-blue-800 capitalize">{{
                                  driver.driversLicenseNumber
                                }}</span>
                              </p>
                              <p class="text-xs">
                                Age:
                                <span class="text-blue-800">{{
                                  calculateAge(driver.dateOfBirth)
                                }}</span>
                              </p>
                            </div>
                            <div class="flex justify-end md:mt-0">
                              <div
                                class="flex flex-col items-center my-auto bg-gray-200 hover:bg-gray-300 text-ietextlight rounded-lg cursor-pointer drop-shadow-sm px-1 py-1 self-start"
                              >
                                <icon
                                  :icon="'solar:undo-left-square-outline'"
                                  class="w-3 h-3"
                                ></icon>
                                <p class="text-xs font-thin mt-1 pb-0">Undo</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!--Add More Drivers Button-->
                <div v-if="drivers.length > 0 && !addMoreDrivers">
                  <button
                    @click="handleAddMoreDrivers()"
                    class="inline-flex justify-center md:mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Add More Drivers
                  </button>
                </div>
                <!--Create Driver- Inputs-->
                <div v-if="addMoreDrivers">
                  <h3
                    class="text-md md:text-lg font-bold my-4 text-center text-ietextlight"
                  >
                    Driver Details:
                  </h3>
                  <!--Driver First Name-->
                  <div class="py-2">
                    <label
                      for="driver-first-name"
                      class="block text-sm font-medium text-left text-gray-700"
                      >Driver First Name:</label
                    >
                    <input
                      v-model="driverFirstName"
                      id="driver-first-name"
                      type="text"
                      placeholder="First Name"
                      class="capitalize mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    />
                  </div>
                  <!--Driver Last Name-->
                  <div class="py-2">
                    <label
                      for="driver-last-name"
                      class="block text-sm font-medium text-left text-gray-700"
                      >Driver Last Name:</label
                    >
                    <input
                      v-model="driverLastName"
                      id="driver-last-name"
                      type="text"
                      placeholder="Last Name"
                      class="capitalize mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    />
                  </div>
                  <!--Driver Date of Birth-->
                  <div class="py-2">
                    <label
                      for="driver-date-of-birth"
                      class="block text-sm font-medium text-left text-gray-700"
                      >Driver Date of Birth:</label
                    >
                    <input
                      v-model="driverDateOfBirth"
                      id="driver-date-of-birth"
                      type="date"
                      placeholder="MM-DD-YYYY"
                      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    />
                  </div>
                  <!--Driver License Number-->
                  <div class="py-2">
                    <label
                      for="driver-dl-number"
                      class="block text-sm font-medium text-left text-gray-700"
                      >Driver License Number:</label
                    >
                    <input
                      v-model="driverLicenseNumber"
                      v-mask="'A ###-###-###-#'"
                      maxlength="14"
                      id="driver-dl-number"
                      type="text"
                      placeholder="Driver License Number"
                      class="capitalize mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    />
                  </div>
                </div>
                <div v-if="addMoreDrivers">
                  <button
                    v-if="driverChangeOption === 'Add'"
                    @click="handleDriverAction"
                    class="inline-flex justify-center md:mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-300"
                  >
                    Add Driver
                  </button>
                  <button
                    v-if="driverChangeOption === 'Remove'"
                    @click="handleDriverAction"
                    class="inline-flex justify-center md:mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-red-300"
                  >
                    Remove Driver
                  </button>
                  <button
                    v-if="driverChangeOption === 'Replace'"
                    @click="handleDriverAction"
                    class="inline-flex justify-center md:mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-200 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 disabled:bg-gray-300"
                  >
                    Replace Driver
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="okayToProceedAndCreateVehicle">
            <div>
              <!--Create Vehicle for Association with Driver-->
              <div v-if="drivers.length > 0 && attachVehicleToDriver">
                <h2
                  class="text-2xl font-bold my-4 md:my-6 text-center text-ietextlight"
                >
                  Assign Vehicle(s) to Driver
                </h2>

                <!-- Drivers List Section -->
                <div v-if="drivers.length > 0" class="mx-auto p-4">
                  <h3 class="text-lg font-bold my-4 text-center text-gray-700">
                    Driver(s)
                  </h3>
                  <div class="space-y-4">
                    <div
                      v-for="{ driver, vehicles } in driverVehiclePairs"
                      :key="driver.id"
                      class="bg-white shadow-lg rounded-lg overflow-hidden"
                    >
                      <!-- Driver Header -->
                      <div
                        class="flex items-center justify-between bg-gray-100 p-3 cursor-pointer"
                        @click="driver.isExpanded = !driver.isExpanded"
                      >
                        <div class="flex items-center space-x-2">
                          <icon
                            :icon="'line-md:person'"
                            class="w-6 h-6 text-gray-600"
                          ></icon>
                          <p class="text-gray-800 font-semibold">
                            <span v-if="driver.isExpanded"
                              >Driver Information</span
                            >
                            <span v-else class="capitalize">
                              {{ driver.firstName }} {{ driver.lastName }}</span
                            >
                          </p>
                        </div>
                        <icon
                          v-if="driver.associatedVehicleID"
                          :icon="'iconamoon:link-fill'"
                          class="w-6 h-6 text-gray-600"
                        ></icon>
                        <div
                          :class="[
                            {
                              'bg-green-500 text-white':
                                driver.action === 'Add',
                              'bg-red-500 text-white':
                                driver.action === 'Remove',
                              'bg-gray-200 text-ietextlight':
                                driver.action === 'Replace',
                              hidden: !driver.action,
                            },
                            'text-xs font-bold py-1 px-3 rounded-full shadow-md',
                          ]"
                        >
                          {{ driver.action }}
                        </div>
                      </div>
                      <!-- Driver Information -->
                      <div
                        v-show="driver.isExpanded"
                        class="p-4 text-ietextlight"
                      >
                        <div class="grid grid-cols-2 gap-4">
                          <div class="text-left">
                            <p class="text-sm font-bold capitalize">
                              {{ driver.firstName }} {{ driver.lastName }}
                            </p>
                            <p class="text-xs">
                              Date of Birth:
                              <span class="text-blue-800">{{
                                formatDOB(driver.dateOfBirth)
                              }}</span>
                            </p>
                            <p class="text-xs text-nowrap">
                              Driver License Number:
                              <span class="text-blue-800 capitalize">{{
                                driver.driversLicenseNumber
                              }}</span>
                            </p>
                            <p class="text-xs">
                              Age:
                              <span class="text-blue-800">{{
                                calculateAge(driver.dateOfBirth)
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- Vehicle Information -->
                      <div
                        v-if="vehicles.length > 0"
                        v-show="!driver.isExpanded"
                      >
                        <div
                          v-for="vehicle in vehicles"
                          :key="vehicle.id"
                          class="border-t border-gray-200"
                        >
                          <!--Vehicle Header-->
                          <div
                            class="bg-gray-100 flex items-center justify-between p-3 cursor-pointer"
                            @click="vehicle.isExpanded = !vehicle.isExpanded"
                          >
                            <div class="flex items-center space-x-2">
                              <icon
                                :icon="'fluent:vehicle-car-profile-ltr-24-regular'"
                                class="w-6 h-6 text-gray-600"
                              ></icon>
                              <span v-if="vehicle.isExpanded"
                                >Vehicle Information</span
                              >
                              <span v-else class="capitalize">
                                {{ vehicle.make }} {{ vehicle.model }}</span
                              >
                            </div>
                            <icon
                              v-if="vehicle.associatedDriverID"
                              :icon="'iconamoon:link-fill'"
                              class="w-6 h-6 text-gray-600"
                            ></icon>
                            <div
                              :class="[
                                {
                                  'bg-green-500 text-white':
                                    vehicle.action === 'Add',
                                  'bg-red-500 text-white':
                                    vehicle.action === 'Remove',
                                  'bg-gray-200 text-ietextlight':
                                    vehicle.action === 'Replace',
                                  hidden: !vehicle.action,
                                },
                                'text-xs font-bold py-1 px-3 rounded-full shadow-md',
                              ]"
                            >
                              {{ vehicle.action }}
                            </div>
                          </div>
                          <div
                            v-show="vehicle.isExpanded"
                            class="p-4 grid grid-cols-1 md:grid-cols-2 gap-4"
                          >
                            <div class="text-left text-ietextlight">
                              <p class="text-sm font-bold capitalize">
                                {{ vehicle.make }} {{ vehicle.model }}
                              </p>
                              <p class="text-xs">
                                Year:
                                <span class="text-blue-800">{{
                                  vehicle.year
                                }}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--Add More Vehicles Button-->
                <div v-if="vehicles.length > 0 && !addMoreVehicles">
                  <button
                    @click="addMoreVehicles = true"
                    class="inline-flex justify-center md:mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Add More Vehicles
                  </button>
                </div>
                <!--Driver Inputs-->
                <div v-if="addMoreVehicles">
                  <h3 class="text-lg text-ietextlight font-bold mb-4">
                    Vehicle Details:
                  </h3>
                  <!-- Select Year -->
                  <div class="py-2">
                    <label
                      for="addVehicleYear"
                      class="block text-sm font-medium text-left text-gray-700"
                      >Select Year:</label
                    >
                    <select
                      v-model="selectedYear"
                      id="addVehicleYear"
                      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    >
                      <option value="">Select Year</option>
                      <option v-for="year in vehicleYears" :value="year">
                        {{ year }}
                      </option>
                    </select>
                  </div>
                  <!-- Select Make -->
                  <div class="py-2">
                    <label
                      for="addVehicleMake"
                      class="block text-sm font-medium text-left text-gray-700"
                      >Select Make:</label
                    >
                    <select
                      v-model="selectedMake"
                      id="addVehicleMake"
                      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      :disabled="!selectedYear"
                    >
                      <option value="">Select Make</option>
                      <option v-for="make in sortedMakes" :value="make.make">
                        {{ make.make }}
                      </option>
                    </select>
                  </div>
                  <!-- Select Model -->
                  <div class="py-2">
                    <label
                      for="addVehicleModel"
                      class="block text-sm font-medium text-left text-gray-700"
                      >Select Model:</label
                    >
                    <select
                      v-model="selectedModel"
                      id="addVehicleModel"
                      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      :disabled="!selectedMake"
                    >
                      <option value="">Select Model</option>
                      <option
                        v-for="model in sortedModels"
                        :value="model.ModelName"
                      >
                        {{ model.ModelName }}
                      </option>
                    </select>
                  </div>
                  <!-- Select Driver-->
                  <div class="py-2">
                    <label
                      for="driver-select"
                      class="block text-sm font-medium text-left text-gray-700"
                      >Assigned Driver:</label
                    >
                    <select
                      v-model="selectedDrivers"
                      id="driver-select"
                      class="capitalize mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    >
                      <option value="" disabled>Select a driver</option>
                      <option
                        v-for="driver in drivers.filter(
                          (driver) => driver.action === 'Add',
                        )"
                        :value="driver.id"
                        :key="driver.id"
                      >
                        {{ driver.firstName }} {{ driver.lastName }}
                      </option>
                    </select>
                  </div>
                  <!-- Assign Vehicle Button -->
                  <div
                    class="flex flex-col md:flex-row md:mt-4 space-y-2 md:space-y-0 justify-center items-center mx-auto"
                  >
                    <button
                      @click="handleVehicleAction"
                      class="mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
                    >
                      <icon
                        :icon="'mi:add'"
                        class="inline-block w-5 h-5 align-middle"
                      ></icon>
                      <span class="ml-2">Assign Vehicle</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Ask Question for Vehicle and Driver Changes-->
          <div
            v-if="
              askQuestionForVehicleAndDriverChanges &&
              !okayToProceedToSelectDrivers
            "
          >
            <div class="text-center">
              <h2
                class="text-2xl font-bold my-4 md:my-6 text-center text-ietextlight"
              >
                Vehicle Change?
              </h2>
              <div class="mt-2 px-7 py-3">
                <p class="font-semibold text-ietextlight">
                  Would you like to add, remove, or replace a vehicle along with
                  the driver change?
                </p>
              </div>
              <div class="items-center px-4 py-3">
                <button
                  @click="answerToQuestionForVehicleAndDriverChanges = 'Yes'"
                  :class="[
                    'mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex md:flex-col justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                    answerToQuestionForVehicleAndDriverChanges === 'Yes'
                      ? 'scale-110 bg-blue-500 text-white focus:ring-blue-400'
                      : 'scale-90 bg-blue-500 hover:bg-blue-600 text-white',
                    answerToQuestionForVehicleAndDriverChanges != null &&
                    answerToQuestionForVehicleAndDriverChanges !== 'Yes'
                      ? 'cursor-not-allowed bg-blue-300 text-blue-800'
                      : '',
                  ]"
                >
                  Yes
                </button>
                <button
                  @click="answerToQuestionForVehicleAndDriverChanges = 'No'"
                  :class="[
                    'mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex md:flex-col justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform duration-300 ease-in-out',
                    answerToQuestionForVehicleAndDriverChanges === 'No'
                      ? 'scale-110 bg-red-500 text-white focus:ring-red-400'
                      : 'scale-90 bg-red-500 hover:bg-red-600 text-white',
                    answerToQuestionForVehicleAndDriverChanges != null &&
                    answerToQuestionForVehicleAndDriverChanges !== 'No'
                      ? 'cursor-not-allowed bg-red-300 text-red-800'
                      : '',
                  ]"
                >
                  No
                </button>
              </div>
            </div>
          </div>

          <!--Error Message Pop Up-->
          <div
            v-if="errorMessage"
            class="fixed inset-0 flex items-center justify-center px-4"
            role="alert"
          >
            <div
              class="w-full max-w-md px-4 py-2 bg-red-100 border border-red-400 text-red-700 rounded-lg shadow-lg"
            >
              <div class="flex justify-between items-start">
                <div class="flex">
                  <strong class="font-bold mr-2">Error! </strong>
                  <span class="block">{{ errorMessage }}</span>
                </div>
                <button
                  @click="errorMessage = ''"
                  class="ml-auto -mx-1.5 -my-1.5 bg-red-100 p-1.5 rounded-lg text-red-500 hover:bg-red-200 focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Coverage Adjustments Content -->
        <div
          v-if="selectedOption.includes('coverageAdjustments')"
          class="text-center mx-0 md:mx-auto mt-4 mb-6 max-w-md"
        >
          <div v-if="!okayToProceed">
            <!--List Vehicles-->
            <div v-if="vehicles.length > 0" class="w-full">
              <h3
                class="text-md md:text-lg font-bold my-4 text-center text-ietextlight"
              >
                Selected Vehicle(s)
              </h3>
              <div v-if="vehicles.length > 0">
                <div
                  v-for="vehicle in vehicles"
                  :key="vehicle.id"
                  class="rounded-lg border-t border-gray-200"
                >
                  <!--Vehicle Header-->
                  <div
                    class="bg-gray-100 flex items-center justify-between p-3 cursor-pointer"
                    @click="vehicle.isExpanded = !vehicle.isExpanded"
                  >
                    <div class="flex items-center space-x-2">
                      <icon
                        :icon="'fluent:vehicle-car-profile-ltr-24-regular'"
                        class="w-6 h-6 text-gray-600"
                      ></icon>
                      <span v-if="vehicle.isExpanded">Vehicle Information</span>
                      <span v-else class="capitalize">
                        {{ vehicle.make }} {{ vehicle.model }}</span
                      >
                    </div>
                    <icon
                      :icon="'lucide:mouse-pointer-click'"
                      class="w-6 h-6 text-blue-600 show-click-accordion"
                    ></icon>
                    <icon
                      :icon="'mingcute:down-fill'"
                      class="w-6 h-6 text-gray-600"
                    ></icon>
                    <div
                      :class="[
                        {
                          'bg-green-500 text-white': vehicle.action === 'Add',
                          'bg-red-500 text-white': vehicle.action === 'Remove',
                          'bg-gray-200 text-ietextlight':
                            vehicle.action === 'Replace',
                          hidden: !vehicle.action,
                        },
                        'text-xs font-bold py-1 px-3 rounded-full shadow-md',
                      ]"
                    >
                      {{ vehicle.action }}
                    </div>
                  </div>
                  <div
                    v-show="vehicle.isExpanded"
                    class="p-4 grid grid-cols-1 md:grid-cols-2 gap-4"
                  >
                    <div class="text-left text-ietextlight">
                      <p class="text-sm font-bold capitalize">
                        {{ vehicle.make }} {{ vehicle.model }}
                      </p>
                      <p class="text-xs">
                        Year:
                        <span class="text-blue-800">{{ vehicle.year }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p v-if="!showVehicleInput" class="my-2 text-sm text-ietextlight">
              Add all vehicles you want to change coverage to:
            </p>
            <button
              v-if="!showVehicleInput"
              @click="handleAddMoreVehicles"
              class="inline-flex justify-center md:mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Add More Vehicles
            </button>
          </div>
          <!-- Vehicle Selected for Coverage Change -->
          <div v-if="showVehicleInput && !okayToProceed">
            <h2
              class="text-2xl font-bold my-4 md:my-6 text-center text-ietextlight"
            >
              Which Vehicle?
            </h2>
            <h3 class="block text-md font-medium text-ietextlight">
              Vehicle this coverage change applies to:
            </h3>
            <div class="text-left my-2">
              <!-- Select Year -->
              <div class="py-2">
                <label
                  for="addVehicleYear"
                  class="block text-sm font-medium text-gray-700"
                  >Select Year:</label
                >
                <select
                  v-model="selectedYear"
                  id="addVehicleYear"
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                  <option value="">Select Year</option>
                  <option v-for="year in vehicleYears" :value="year">
                    {{ year }}
                  </option>
                </select>
              </div>
              <!-- Select Make -->
              <div class="py-2">
                <label
                  for="addVehicleMake"
                  class="block text-sm font-medium text-gray-700"
                  >Select Make:</label
                >
                <select
                  v-model="selectedMake"
                  id="addVehicleMake"
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  :disabled="!selectedYear"
                >
                  <option value="">Select Make</option>
                  <option v-for="make in sortedMakes" :value="make.make">
                    {{ make.make }}
                  </option>
                </select>
              </div>
              <!-- Select Model -->
              <div class="py-2">
                <label
                  for="addVehicleModel"
                  class="block text-sm font-medium text-gray-700"
                  >Select Model:</label
                >
                <select
                  v-model="selectedModel"
                  id="addVehicleModel"
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  :disabled="!selectedMake"
                >
                  <option value="">Select Model</option>
                  <option
                    v-for="model in sortedModels"
                    :value="model.ModelName"
                  >
                    {{ model.ModelName }}
                  </option>
                </select>
              </div>
            </div>
            <!-- Add Vehicle Button -->
            <div
              class="flex flex-col md:flex-row md:mt-4 space-y-2 md:space-y-0 justify-center items-center mx-auto"
            >
              <button
                @click="handleVehicleAction"
                class="mt-3 sm:mt-0 sm:mr-2 w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              >
                Add
              </button>
            </div>
          </div>

          <!--Error Message Pop Up-->
          <div
            v-if="errorMessage"
            class="fixed inset-0 flex items-center justify-center px-4"
            role="alert"
          >
            <div
              class="w-full max-w-md px-4 py-2 bg-red-100 border border-red-400 text-red-700 rounded-lg shadow-lg"
            >
              <div class="flex justify-between items-start">
                <div class="flex">
                  <strong class="font-bold mr-2">Error! </strong>
                  <span class="block">{{ errorMessage }}</span>
                </div>
                <button
                  @click="errorMessage = ''"
                  class="ml-auto -mx-1.5 -my-1.5 bg-red-100 p-1.5 rounded-lg text-red-500 hover:bg-red-200 focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <!-- Selected Coverages Section: Car/Cars Selected to apply coverage to-->
          <div v-if="okayToProceed">
            <div>
              <h3 class="py-2 text-xl font-semibold text-ietextlight mb-4">
                Your changes will apply to:
              </h3>
              <!--List Vehicles-->
              <div v-if="vehicles.length > 0" class="w-full">
                <div v-if="vehicles.length > 0">
                  <div
                    v-for="vehicle in vehicles"
                    :key="vehicle.id"
                    class="rounded-lg border-t border-gray-200 shadow-lg"
                  >
                    <!--Vehicle Header-->
                    <div
                      class="bg-gray-100 flex items-center justify-between p-3"
                    >
                      <div class="flex items-center space-x-2">
                        <icon
                          :icon="'fluent:vehicle-car-profile-ltr-24-regular'"
                          class="w-6 h-6 text-gray-600"
                        ></icon>
                        <span v-if="vehicle.isExpanded"
                          >Vehicle Information</span
                        >
                        <span v-else class="capitalize"
                          >{{ vehicle.make }} {{ vehicle.model }}</span
                        >
                      </div>
                      <input
                        type="checkbox"
                        v-model="selectedVehicleIds"
                        :value="vehicle.id"
                        class="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <icon
                        v-if="vehicle.associatedDriverID"
                        :icon="'iconamoon:link-fill'"
                        class="w-6 h-6 text-gray-600"
                      ></icon>
                      <div
                        :class="[
                          {
                            'bg-green-500 text-white': vehicle.action === 'Add',
                            'bg-red-500 text-white':
                              vehicle.action === 'Remove',
                            'bg-gray-200 text-ietextlight':
                              vehicle.action === 'Replace',
                            hidden: !vehicle.action,
                          },
                          'text-xs font-bold py-1 px-3 rounded-full shadow-md',
                        ]"
                      >
                        {{ vehicle.action }}
                      </div>
                    </div>
                    <div
                      v-show="vehicle.isExpanded"
                      class="p-4 grid grid-cols-1 gap-4"
                    >
                      <div class="text-left text-ietextlight">
                        <div class="flex flex-col">
                          <div class="flex">
                            <p class="text-sm font-bold capitalize mr-2">
                              {{ vehicle.make }} {{ vehicle.model }}
                            </p>
                            <icon
                              v-for="coverage in vehicle.coverages"
                              :key="coverage.type"
                              :icon="coverageMapping[coverage.type].icon"
                              class="w-5 h-5 text-blue-500 ml-1"
                            />
                          </div>
                          <div>
                            <p class="text-xs">
                              Year:
                              <span class="text-blue-800">{{
                                vehicle.year
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="vehicle.coverages && vehicle.coverages.length > 0"
                      >
                        <h4
                          class="text-sm text-ietextlight text-left font-bold capitalize mr-2"
                        >
                          Coverage Changes:
                        </h4>
                        <ul class="list-disc space-y-2">
                          <li
                            v-for="(coverage, index) in vehicle.coverages"
                            :key="coverage.type"
                            class="flex justify-between items-center bg-blue-100 rounded-lg p-3 shadow-sm"
                          >
                            <span class="text-xs text-blue-800"
                              >{{ coverageMapping[coverage.type].label }}:</span
                            >
                            <span class="text-blue-600 text-xs">{{
                              coverage.amount
                            }}</span>
                            <button
                              @click="removeCoverage(vehicle.id, index)"
                              class="ml-4 p-1 bg-transparent hover:bg-gray-200 rounded-full transition duration-200"
                              aria-label="Undo coverage change"
                            >
                              <icon
                                :icon="'akar-icons:circle-chevron-left-fill'"
                                class="w-4 h-4 text-gray-600 hover:text-red-600"
                              ></icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  vehicles.some(
                    (vehicle) =>
                      vehicle.coverages && vehicle.coverages.length > 0,
                  )
                "
                class="mt-4"
              >
                <p
                  class="pulse text-center text-lg font-medium text-blue-800 bg-blue-100 p-3 rounded-lg"
                >
                  Click "Next" when done
                </p>
              </div>
            </div>

            <h3 class="text-xl font-medium text-ietextlight my-4">
              Coverage Adjustments
            </h3>
            <div class="grid grid-cols-1 gap-x-6 gap-y-4 text-left">
              <!-- Select Coverage Type -->

              <!-- Bodily Injury Button -->
              <div>
                <button
                  @click="selectCoverageType('bodilyInjury')"
                  :class="{
                    'bg-blue-500 hover:bg-blue-600 text-white':
                      coverageChangeType === 'bodilyInjury',
                    'bg-blue-100 hover:bg-blue-200 text-blue-800':
                      coverageChangeType !== 'bodilyInjury',
                  }"
                  class="transition-style rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-1 flex items-center space-x-2 px-4 py-2"
                >
                  <Icon icon="solar:health-bold" class="icon" />
                  <span>Bodily Injury</span>
                </button>
                <!--Bodily Injury-->
                <transition name="accordion">
                  <div
                    v-if="
                      coverageOptions.find(
                        (option) => option.type === 'bodilyInjury',
                      ).selected
                    "
                    class="bg-gray-100 p-2 rounded-b-lg"
                  >
                    <label
                      for="bodilyInjury"
                      class="block text-sm font-medium text-gray-700"
                      >Bodily Injury</label
                    >
                    <select
                      id="bodilyInjury"
                      v-model="coverageOptions[0].selectedAmount"
                      @change="
                        updateVehicleCoverages(
                          'bodilyInjury',
                          $event.target.value,
                        )
                      "
                      class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                    >
                      <option value="" disabled>Select Coverage Amount</option>
                      <option
                        v-for="option in coverageOptions[0].options"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </transition>
              </div>
              <!-- Property Damage Button -->
              <div>
                <button
                  @click="selectCoverageType('propertyDamage')"
                  :class="{
                    'bg-blue-500 hover:bg-blue-600 text-white':
                      coverageChangeType === 'propertyDamage',
                    'bg-blue-100 hover:bg-blue-200 text-blue-800':
                      coverageChangeType !== 'propertyDamage',
                  }"
                  class="transition-style rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-1 flex items-center space-x-2 px-4 py-2"
                >
                  <Icon icon="mdi:home" class="icon" />
                  <span>Property Damage</span>
                </button>
                <!--Property Damage-->
                <transition name="accordion">
                  <div
                    v-if="
                      coverageOptions.find(
                        (option) => option.type === 'propertyDamage',
                      ).selected
                    "
                    class="bg-gray-100 p-2 rounded-b-lg"
                  >
                    <label
                      for="propertyDamage"
                      class="block text-sm font-medium text-gray-700"
                      >Property Damage</label
                    >
                    <select
                      id="propertyDamage"
                      v-model="coverageOptions[1].selectedAmount"
                      @change="
                        updateVehicleCoverages(
                          'propertyDamage',
                          $event.target.value,
                        )
                      "
                      class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                    >
                      <option value="" disabled>Select Coverage Amount</option>
                      <option
                        v-for="option in coverageOptions[1].options"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </transition>
              </div>
              <!-- Uninsured Motorist Button -->
              <div>
                <button
                  @click="selectCoverageType('uninsuredMotorist')"
                  :class="{
                    'bg-blue-500 hover:bg-blue-600 text-white':
                      coverageChangeType === 'uninsuredMotorist',
                    'bg-blue-100 hover:bg-blue-200 text-blue-800':
                      coverageChangeType !== 'uninsuredMotorist',
                  }"
                  class="transition-style rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-1 flex items-center space-x-2 px-4 py-2"
                >
                  <Icon icon="mdi:car" class="icon" />
                  <span>Uninsured Motorist</span>
                </button>
                <!--Uninsured Motorist-->
                <transition name="accordion">
                  <div
                    v-if="
                      coverageOptions.find(
                        (option) => option.type === 'uninsuredMotorist',
                      ).selected
                    "
                    class="bg-gray-100 p-2 rounded-b-lg"
                  >
                    <label
                      for="uninsuredMotorist"
                      class="block text-sm font-medium text-gray-700"
                      >Uninsured Motorist</label
                    >
                    <select
                      id="uninsuredMotorist"
                      v-model="coverageOptions[2].selectedAmount"
                      @change="
                        updateVehicleCoverages(
                          'uninsuredMotorist',
                          $event.target.value,
                        )
                      "
                      class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                    >
                      <option value="" disabled>Select Coverage Amount</option>
                      <option
                        v-for="option in coverageOptions[2].options"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </transition>
              </div>
              <!-- Comp Deductible Button -->
              <div>
                <button
                  @click="selectCoverageType('compDeductible')"
                  :class="{
                    'bg-blue-500 hover:bg-blue-600 text-white':
                      coverageChangeType === 'compDeductible',
                    'bg-blue-100 hover:bg-blue-200 text-blue-800':
                      coverageChangeType !== 'compDeductible',
                  }"
                  class="transition-style rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-1 flex items-center space-x-2 px-4 py-2"
                >
                  <Icon icon="mdi:shield-car" class="icon" />
                  <span>Comp Deductible</span>
                </button>
                <!--Comp Deductible-->
                <transition>
                  <div
                    v-if="
                      coverageOptions.find(
                        (option) => option.type === 'compDeductible',
                      ).selected
                    "
                    class="bg-gray-100 p-2 rounded-b-lg"
                  >
                    <label
                      for="compDeductible"
                      class="block text-sm font-medium text-gray-700"
                      >Comprehensive Deductible</label
                    >
                    <select
                      id="compDeductible"
                      v-model="coverageOptions[3].selectedAmount"
                      @change="
                        updateVehicleCoverages(
                          'compDeductible',
                          $event.target.value,
                        )
                      "
                      class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                    >
                      <option value="" disabled>Select Option</option>
                      <option
                        v-for="option in coverageOptions[3].options"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </transition>
              </div>
              <!-- Collision Deductible Button -->
              <div>
                <button
                  @click="selectCoverageType('colDeductible')"
                  :class="{
                    'bg-blue-500 hover:bg-blue-600 text-white':
                      coverageChangeType === 'colDeductible',
                    'bg-blue-100 hover:bg-blue-200 text-blue-800':
                      coverageChangeType !== 'colDeductible',
                  }"
                  class="transition-style rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-1 flex items-center space-x-2 px-4 py-2"
                >
                  <Icon icon="mdi:car-multiple" class="icon" />
                  <span>Collision Deductible</span>
                </button>
                <!--Col Deductible-->
                <transition name="accordion">
                  <div
                    v-if="
                      coverageOptions.find(
                        (option) => option.type === 'colDeductible',
                      ).selected
                    "
                    class="bg-gray-100 p-2 rounded-b-lg"
                  >
                    <label
                      for="colDeductible"
                      class="block text-sm font-medium text-gray-700"
                      >Collision Deductible</label
                    >
                    <select
                      id="colDeductible"
                      v-model="coverageOptions[4].selectedAmount"
                      @change="
                        updateVehicleCoverages(
                          'colDeductible',
                          $event.target.value,
                        )
                      "
                      class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                    >
                      <option value="" disabled>Select Coverage Amount</option>
                      <option
                        v-for="option in coverageOptions[4].options"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </transition>
              </div>
              <!-- Rental Car Coverage Button -->
              <div>
                <button
                  @click="selectCoverageType('rentalCarCoverage')"
                  :class="{
                    'bg-blue-500 hover:bg-blue-600 text-white':
                      coverageChangeType === 'extras',
                    'bg-blue-100 hover:bg-blue-200 text-blue-800':
                      coverageChangeType !== 'extras',
                  }"
                  class="transition-style rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-1 flex items-center space-x-2 px-4 py-2"
                >
                  <Icon icon="material-symbols:car-rental" class="icon" />
                  <span>Rental Car Coverage</span>
                </button>
                <!-- Rental Car Coverage-->
                <transition>
                  <div
                    v-if="
                      coverageOptions.find(
                        (option) => option.type === 'rentalCarCoverage',
                      ).selected
                    "
                    class="bg-gray-100 p-2 rounded-b-lg"
                  >
                    <label
                      for="rentalCarCoverage"
                      class="block text-sm font-medium text-gray-700"
                      >Rental Car Coverage</label
                    >
                    <select
                      id="rentalCarCoverage"
                      v-model="coverageOptions[5].selectedAmount"
                      @change="
                        updateVehicleCoverages(
                          'rentalCarCoverage',
                          $event.target.value,
                        )
                      "
                      class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                    >
                      <option value="" disabled>Select Option</option>
                      <option
                        v-for="option in coverageOptions[5].options"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </transition>
              </div>
              <!--Roadside Assistance Button-->
              <div class="py-2">
                <button
                  @click="selectCoverageType('roadsideAssistance')"
                  :class="{
                    'bg-blue-500 hover:bg-blue-600 text-white':
                      coverageChangeType === 'extras',
                    'bg-blue-100 hover:bg-blue-200 text-blue-800':
                      coverageChangeType !== 'extras',
                  }"
                  class="transition-style rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-1 flex items-center space-x-2 px-4"
                >
                  <Icon icon="game-icons:tow-truck" class="icon" />
                  <span>Roadside Assistance</span>
                </button>
                <!-- Roadside Assistance-->
                <transition>
                  <div
                    v-if="
                      coverageOptions.find(
                        (option) => option.type === 'roadsideAssistance',
                      ).selected
                    "
                    class="bg-gray-100 p-2 rounded-b-lg"
                  >
                    <label
                      for="roadsideAssistance"
                      class="block text-sm font-medium text-gray-700"
                      >Roadside Assistance</label
                    >
                    <select
                      id="roadsideAssistance"
                      v-model="coverageOptions[6].selectedAmount"
                      @change="
                        updateVehicleCoverages(
                          'roadsideAssistance',
                          $event.target.value,
                        )
                      "
                      class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                    >
                      <option value="" disabled>Select Option</option>
                      <option
                        v-for="option in coverageOptions[6].options"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div
            v-if="errorMessage"
            class="fixed inset-0 flex items-center justify-center px-4"
            role="alert"
          >
            <div
              class="w-full max-w-md px-4 py-2 bg-red-100 border border-red-400 text-red-700 rounded-lg shadow-lg"
            >
              <div class="flex justify-between items-start">
                <div class="flex">
                  <strong class="font-bold mr-2">Error! </strong>
                  <span class="block">{{ errorMessage }}</span>
                </div>
                <button
                  @click="errorMessage = ''"
                  class="ml-auto -mx-1.5 -my-1.5 bg-red-100 p-1.5 rounded-lg text-red-500 hover:bg-red-200 focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Other Changes Content -->
        <div
          v-if="selectedOption.includes('otherChanges')"
          class="text-center mx-0 md:mx-auto mt-4 mb-6 max-w-md"
        >
          <div v-if="otherChangesSelection === null">
            <h2
              class="text-2xl font-bold my-4 md:my-6 text-center text-ietextlight"
            >
              Other Changes
            </h2>
            <h3 class="font-semibold text-ietextlight">
              Please select what changes you would like to make:
            </h3>
            <div class="flex flex-col my-2 space-y-2">
              <!-- Add/Delete/Modify Lessor (Leased) Button -->
              <button
                class="block w-full px-4 py-2 bg-red-500 hover:bg-red-600 text-white font-medium text-sm rounded-md shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 flex items-center justify-center"
                @click="otherChangesSelection = 'lessor'"
              >
                <Icon
                  icon="mi:add"
                  class="inline-block w-5 h-5 mr-2 align-middle"
                />
                Add/Delete/Modify Lessor (Leased)
              </button>
              <!-- Add/Delete/Modify Lienholder Button -->
              <button
                class="block w-full px-4 py-2 bg-gray-200 text-gray-700 font-medium text-sm rounded-md shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 flex items-center justify-center"
                @click="otherChangesSelection = 'lienholder'"
              >
                <Icon
                  icon="mi:add"
                  class="inline-block w-5 h-5 mr-2 align-middle"
                />
                Add/Delete/Modify Lienholder
              </button>
            </div>
          </div>

          <div
            v-if="otherChangesSelection === 'lessor'"
            class="p-4 bg-white shadow rounded-lg"
          >
            <h3
              class="text-sm md:text-md text-center font-semibold text-ietextlight mb-4"
            >
              Add/Delete/Modify Lessor (Leased)
            </h3>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <!-- Lessor Name -->
              <div class="mb-2 md:col-span-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >Lessor Name</label
                >
                <input
                  v-model="lienholderName"
                  type="text"
                  class="form-input capitalize"
                  placeholder="Lienholder Name"
                />
              </div>
              <!-- Policy Number -->
              <div class="mb-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >Policy Number</label
                >
                <input
                  v-model="policyNumber"
                  type="text"
                  class="form-input"
                  placeholder="Policy Number"
                />
              </div>
              <!-- Lessor Position -->
              <div class="mb-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >Position</label
                >
                <select class="form-select" v-model="position">
                  <option
                    value="Select Position"
                    disabled
                    selected
                    class="placeholder-option"
                  >
                    Select Position
                  </option>
                  <option value="1st Lessor">1st Lessor</option>
                  <option value="2nd Lessor">2nd Lessor</option>
                  <option value="3rd Lessor">3rd Lessor</option>
                </select>
              </div>
              <!-- Address -->
              <div class="mb-2 md:col-span-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >Address</label
                >
                <input
                  v-model="address"
                  type="text"
                  class="form-input"
                  placeholder="Address"
                />
              </div>
              <!-- City -->
              <div class="mb-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >City</label
                >
                <input
                  v-model="city"
                  type="text"
                  class="capitalize form-input"
                  placeholder="City"
                />
              </div>
              <!-- State -->
              <div class="mb-2">
                <label
                  class="block text-gray-700 text-sm font-semibold mb-2"
                  for="state-select"
                  >State:</label
                >
                <select
                  class="form-select"
                  id="state-select"
                  placeholder="Alabama"
                  v-model="state"
                >
                  <option value="" selected>Select a state</option>
                  <option
                    v-for="usState in usStates"
                    :key="usState.abbreviation"
                    :value="usState.abbreviation"
                  >
                    {{ usState.abbreviation }}
                  </option>
                </select>
              </div>
              <!-- Zip Code -->
              <div class="mb-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >Zip Code</label
                >
                <input
                  v-model="zip"
                  type="text"
                  class="form-input"
                  placeholder="Zip"
                />
              </div>
            </div>

            <div class="flex flex-col space-y-2 mt-6">
              <button
                class="bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
              >
                Add
              </button>
              <button
                class="bg-red-500 text-white py-2 rounded-md hover:bg-red-600 transition duration-300"
              >
                Delete
              </button>
              <button
                class="bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400 transition duration-300"
              >
                Replace
              </button>
            </div>
          </div>

          <div
            v-if="otherChangesSelection === 'lienholder'"
            class="p-4 bg-white shadow rounded-lg"
          >
            <h3
              class="text-sm md:text-md text-center font-semibold text-ietextlight mb-4"
            >
              Add/Delete/Modify Lienholder
            </h3>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <!-- Lienholder Name -->
              <div class="mb-2 md:col-span-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >Lienholder Name</label
                >
                <input
                  v-model="lienholderName"
                  type="text"
                  class="form-input capitalize"
                  placeholder="Lienholder Name"
                />
              </div>
              <!-- Policy Number -->
              <div class="mb-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >Policy Number</label
                >
                <input
                  v-model="policyNumber"
                  type="text"
                  class="form-input"
                  placeholder="Policy Number"
                />
              </div>
              <!--  Position -->
              <div class="mb-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >Position</label
                >
                <select class="form-select" v-model="position">
                  <option
                    value="Select Position"
                    disabled
                    selected
                    class="placeholder-option"
                  >
                    Select Position
                  </option>
                  <option value="1st Lienholder">1st Lienholder</option>
                  <option value="2nd Lienholder">2nd Lienholder</option>
                  <option value="3rd Lienholder">3rd Lienholder</option>
                </select>
              </div>
              <!-- Address -->
              <div class="mb-2 md:col-span-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >Address</label
                >
                <input
                  v-model="address"
                  type="text"
                  class="form-input"
                  placeholder="Address"
                />
              </div>
              <!-- City -->
              <div class="mb-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >City</label
                >
                <input
                  v-model="city"
                  type="text"
                  class="capitalize form-input"
                  placeholder="City"
                />
              </div>
              <!-- State -->
              <div class="mb-2">
                <label
                  class="block text-gray-700 text-sm font-semibold mb-2"
                  for="state-select"
                  >State:</label
                >
                <select
                  class="form-select"
                  id="state-select"
                  placeholder="Alabama"
                  v-model="state"
                >
                  <option value="" selected>Select a state</option>
                  <option
                    v-for="usState in usStates"
                    :key="usState.abbreviation"
                    :value="usState.abbreviation"
                  >
                    {{ usState.abbreviation }}
                  </option>
                </select>
              </div>
              <!-- Zip Code -->
              <div class="mb-2">
                <label class="block text-gray-700 text-sm font-semibold mb-2"
                  >Zip Code</label
                >
                <input
                  v-model="zip"
                  type="text"
                  class="form-input"
                  placeholder="Zip"
                />
              </div>
            </div>
            <div class="flex flex-col space-y-2 mt-6">
              <button
                @click="handleAddLienholderLessor()"
                class="bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
              >
                Add
              </button>
              <button
                @click="handleDeleteLienholderLessor()"
                class="bg-red-500 text-white py-2 rounded-md hover:bg-red-600 transition duration-300"
              >
                Delete
              </button>
              <button
                @click="handleModifyLienholderLessor()"
                class="bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400 transition duration-300"
              >
                Modify
              </button>
            </div>
            <div v-if="lienholderLessor.length > 0">
              <h3 class="py-2 text-xl font-semibold text-ietextlight mb-4">
                Your selections:
              </h3>
              <ul>
                <li
                  v-for="newLienholderLessor in lienholderLessor"
                  :key="newLienholderLessor.id"
                  class="flex items-center mb-4 bg-blue-100 shadow-xl overflow-hidden rounded-md text-sm md:text-md pulse-animate"
                >
                  <div
                    class="flex-col bg-blue-100 text-blue-800 min-w-[80px] items-center justify-center text-sm font-medium rounded-l-md px-4 py-2"
                  >
                    <div>
                      <icon
                        :icon="'line-md:speed-loop'"
                        class="mx-auto w-4 h-4"
                      ></icon>
                    </div>
                    <div>
                      <p class="text-blue-800">
                        {{ newLienholderLessor.addRemoveModify }}
                      </p>
                    </div>
                  </div>
                  <div class="flex mx-2 justify-start">
                    <span class="font-semibold text-blue-800">{{
                      newLienholderLessor.position
                    }}</span>
                    <span class="capitalize font-semibold text-blue-800 px-2">{{
                      newLienholderLessor.name
                    }}</span>
                    <span class="font-semibold text-blue-800">{{
                      newLienholderLessor.address
                    }}</span>
                    <span class="font-semibold text-blue-800">{{
                      newLienholderLessor.city
                    }}</span>
                    <span class="font-semibold text-blue-800">{{
                      newLienholderLessor.state
                    }}</span>
                    <span class="font-semibold text-blue-800">{{
                      newLienholderLessor.zip
                    }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div
            v-if="errorMessage"
            class="fixed inset-0 flex items-center justify-center px-4"
            role="alert"
          >
            <div
              class="w-full max-w-md px-4 py-2 bg-red-100 border border-red-400 text-red-700 rounded-lg shadow-lg"
            >
              <div class="flex justify-between items-start">
                <div class="flex">
                  <strong class="font-bold mr-2">Error! </strong>
                  <span class="block">{{ errorMessage }}</span>
                </div>
                <button
                  @click="errorMessage = ''"
                  class="ml-auto -mx-1.5 -my-1.5 bg-red-100 p-1.5 rounded-lg text-red-500 hover:bg-red-200 focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Add a back button or similar to allow users to re-select options -->
        <div class="flex justify-between mt-4">
          <button
            @click="handleBackButton()"
            class="m-4 bg-gray-200 text-gray-800 p-2 rounded"
          >
            Back
          </button>
          <button
            @click="handleNextButton()"
            :disabled="isNextButtonDisabled"
            class="m-4 bg-blue-500 text-white p-2 rounded"
          >
            Next
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, computed, reactive, watch, nextTick } from 'vue';
import axios from 'axios';
import { Icon } from '@iconify/vue';
import Slider from 'primevue/slider';
import stateAbbreviations from '@/data/stateAbbreviations.js';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    Icon,
    Slider,
  },
  props: {
    formData: Object,
  },
  emits: ['updateMasterObject'],
  setup(props, { emit }) {
    const askQuestionForVehicleAndDriverChanges = ref(false);
    const addMoreDrivers = ref(false);
    const addMoreVehicles = ref(false);
    const showVehicleInput = ref(true);
    const isNextButtonDisabled = ref(false);
    const showReplacementProcess = ref(false);
    const answerToQuestionForVehicleAndDriverChanges = ref(null);
    const okayToProceedAndCreateVehicle = ref(false);
    const okayToProceedAndCreateDriver = ref(false);
    const attachVehicleToDriver = ref(false);
    const attachDriverToVehicle = ref(false);
    const readyToCheckDrivers = ref(false);
    const selectedVehicleForDriver = ref(false);
    const vehicleChangeOption = ref(null);
    const driverChangeOption = ref(null);
    const lienholderLessor = ref([]);
    const lienholderName = ref('');
    const policyNumber = ref('');
    const position = ref('Select Position');
    const address = ref('');
    const city = ref('');
    const state = ref('');
    const zip = ref('');
    const vehicles = ref([]);
    function removeCoverage(vehicleId, coverageIndex) {
      // Log the input parameters to ensure they're passed correctly
      console.log(
        `Attempting to remove coverage: Vehicle ID = ${vehicleId}, Coverage Index = ${coverageIndex}`,
      );

      // Find the vehicle and log whether it was found
      const vehicle = vehicles.value.find((v) => v.id === vehicleId);
      console.log(`Vehicle found: `, vehicle);

      // Check if the vehicle and its coverages array are correctly identified
      if (vehicle && vehicle.coverages && coverageIndex >= 0) {
        console.log(`Original coverages before removal:`, vehicle.coverages);

        // Perform the removal
        vehicle.coverages.splice(coverageIndex, 1);

        // Log the updated coverages to confirm the removal
        console.log(`Updated coverages after removal:`, vehicle.coverages);
      } else {
        // Log different scenarios for why removal might not proceed
        if (!vehicle) {
          console.log(`No vehicle found with ID ${vehicleId}`);
        } else if (!vehicle.coverages) {
          console.log(`Vehicle with ID ${vehicleId} has no coverages`);
        } else if (coverageIndex < 0) {
          console.log(`Invalid coverage index: ${coverageIndex}`);
        }
      }
    }
    const coverageMapping = ref({
      bodilyInjury: {
        label: 'Bodily Injury',
        icon: 'solar:health-bold',
      },
      propertyDamage: {
        label: 'Property Damage',
        icon: 'mdi:home',
      },
      uninsuredMotorist: {
        label: 'Uninsured Motorist',
        icon: 'mdi:car',
      },
      compDeductible: {
        label: 'Comp Deductible',
        icon: 'mdi:shield-car',
      },
      colDeductible: {
        label: 'Collision Deductible',
        icon: 'mdi:car-multiple',
      },
      rentalCarCoverage: {
        label: 'Rental Car Coverage',
        icon: 'material-symbols:car-rental',
      },
      roadsideAssistance: {
        label: 'Roadside Assistance',
        icon: 'game-icons:tow-truck',
      },
    });
    function setupVehicleWatches() {
      vehicles.value.forEach((_, index) => {
        watch(
          () => vehicles.value[index].coverages,
          (newCoverages) => {
            if (newCoverages && newCoverages.length > 0) {
              vehicles.value[index].isExpanded = true;
            }
          },
          { deep: true },
        );
      });
    }

    const drivers = ref([]);
    const okayToProceed = ref(false);
    const updateVehicleCoverages = (coverageType, selectedAmount) => {
      console.log('Coverage Type:', coverageType);
      console.log('Selected Amount:', selectedAmount);

      const coverageDetails = coverageMapping.value[coverageType];

      if (coverageDetails) {
        console.log('Coverage found:', coverageDetails);

        const newCoverage = { type: coverageType, amount: selectedAmount };

        selectedVehicleIds.value.forEach((vehicleId) => {
          const vehicle = vehicles.value.find((v) => v.id === vehicleId);
          if (vehicle) {
            const existingCoverageIndex = vehicle.coverages.findIndex(
              (c) => c.type === coverageType,
            );

            if (existingCoverageIndex >= 0) {
              vehicle.coverages[existingCoverageIndex] = newCoverage;
            } else {
              vehicle.coverages.push(newCoverage);
            }
          }
        });

        console.log('Updated Vehicles:', vehicles.value);

        // Explicitly close the accordion for the selected coverage type
        const coverageOption = coverageOptions.find(
          (c) => c.type === coverageType,
        );
        if (coverageOption) {
          coverageOption.selected = false; // Close the accordion
          coverageOption.selectedAmount = ''; // Reset the selection
        }

        // You might still want to use nextTick to ensure UI updates after these changes
        nextTick(() => {
          console.log('Post-update state:', coverageOptions);
        });
      } else {
        console.log('Coverage not found for type:', coverageType);
      }
    };
    watch(selectedVehicleIds, (newValue, oldValue) => {
      console.log('selectedVehicleIds changed to: ', newValue);
    });

    const selectedVehicleIds = ref([]);
    const toggleSelection = (option) => {
      const index = selectedOption.value.indexOf(option);
      if (index === -1) {
        selectedOption.value.push(option);
      } else {
        selectedOption.value.splice(index, 1); // Remove the option if already selected
      }
    };
    const availableVehicles = computed(() => {
      return vehicles.value.filter(
        (vehicle) =>
          !driverVehiclePairs.value.some((pair) =>
            pair.vehicles.some((v) => v.id === vehicle.id),
          ),
      );
    });
    const constructAndEmitMasterObject = () => {
      const masterObject = {
        // Assuming selectedOption contains the type of change the user wants to make
        selectedChangeOption: selectedOption.value,

        // Information about vehicles for coverage change
        vehicles: vehicles.value.map((v) => ({
          id: v.id,
          year: v.year,
          make: v.make,
          model: v.model,
          action: v.action, // "add", "remove", or "replace"
          driverIds: drivers.value
            .filter((d) => d.associatedVehicleID === v.id)
            .map((d) => d.id),
        })),

        // Information about drivers, kept separately from vehicles
        drivers: drivers.value.map((d) => ({
          id: d.id,
          firstName: d.firstName,
          lastName: d.lastName,
          dateOfBirth: d.dateOfBirth,
          driversLicenseNumber: d.driversLicenseNumber,
          action: d.action, // "add", "remove", or "replace"
          vehicleId: d.associatedVehicleID, // Keep this to know if a driver is associated with a vehicle
        })),

        // Selected coverages for the vehicles
        selectedCoverages: selectedCoverages.value.map((c) => ({
          type: c.type,
          label: c.label,
          selectedAmount: c.selectedAmount,
        })),

        // Information for lienholders or lessors
        lienholdersLessors: lienholderLessor.value.map((ll) => ({
          name: ll.name,
          policyNumber: ll.policyNumber,
          position: ll.position, // e.g., "1st lienholder"
          address: ll.address,
          city: ll.city,
          state: ll.state,
          zip: ll.zip,
          action: ll.addDeleteModify, // "add", "modify", or "delete"
          type: ll.lienholderLessor, // "lienholder" or "lessor"
        })),
      };
      // Emit the master object to the parent component
      emit('updateMasterObject', masterObject);
    };

    const handleAddMoreDrivers = () => {
      addMoreDrivers.value = false;

      // Check if 'vehicleChanges' is among the selected options
      if (selectedOption.value.includes('vehicleChanges')) {
        okayToProceedToSelectDrivers.value = false;
        okayToProceedAndCreateDriver.value = true; // Since 'vehicleChanges' is included, proceed to create driver
      } else {
        okayToProceedAndCreateDriver.value = false; // If 'vehicleChanges' is not selected, do not proceed to create driver
      }
      driverChangeOption.value = null;
    };

    // const handleAddMoreVehicles = () => {
    //     addMoreVehicles.value = false;
    //     okayToProceedAndCreateVehicle.value = false;
    //     vehicleChangeOption.value = null;
    //     showVehicleInput.value = true;
    // };

    const assignVehicleToDriver = (driverId, vehicleId) => {
      const driverIndex = drivers.value.findIndex(
        (driver) => driver.id === driverId,
      );
      if (driverIndex !== -1) {
        drivers.value[driverIndex].associatedVehicleID = vehicleId;
      }
    };

    const assignDriverToVehicle = (vehicleId, driverId) => {
      const vehicleIndex = vehicles.value.findIndex(
        (vehicle) => vehicle.id === vehicleId,
      );
      if (vehicleIndex !== -1) {
        vehicles.value[vehicleIndex].associatedDriverID = driverId;
      }
    };

    const validDrivers = computed(() => {
      return drivers.value.filter(
        (driver) =>
          driver.associatedVehicleID !== '' &&
          driver.associatedVehicleID !== 'defaultID',
      );
    });

    const handleNextButton = () => {
      console.log('handleNextButton called');
      errorMessage.value = '';

      if (!validateSelectedOption(selectedOption.value)) return;

      if (selectedOption.value.length > 0) {
        const currentOption = selectedOption.value[0];

        switch (currentOption) {
          case 'vehicleChanges':
            handleVehicleChanges();
            break;
          case 'driverChanges':
            handleDriverChanges();
            break;
          case 'coverageAdjustments':
            handleCoverageAdjustments();
            break;
          case 'otherChanges':
            handleOtherChanges();
            break;
          default:
            console.log('Unknown option:', currentOption);
        }
      }
    };

    const validateSelectedOption = () => {
      if (!selectedOption.value) {
        console.log('Error: No option selected.');
        errorMessage.value = 'Please select an option.';
        return false;
      }
      return true;
    };

    const handleVehicleChanges = () => {
      if (!vehicleChangeOption.value) {
        setErrorMessage('Please select an option for vehicle changes.');
        return;
      }

      if (vehicleChangeOption.value === 'Replace') {
        showReplacementProcess.value = true;
      } else {
      }
      console.log('Vehicle Changes Option Selected, Proceeding...');
      if (!checkProceedFlag(okayToProceedAndCreateVehicle)) return;
      console.log('Vehicle Creation Stage');
      if (!checkVehiclesAdded()) return;
      console.log('Vehicles Added Checked');

      console.log('Ask Question');
      if (!askQuestionForVehicleAndDriverChanges.value) {
        askQuestionForVehicleAndDriverChanges.value = true;
        return;
      }
      console.log('Question Asked');
      if (
        askQuestionForVehicleAndDriverChanges.value &&
        answerToQuestionForVehicleAndDriverChanges.value === null
      ) {
        setErrorMessage('Please answer the question before proceeding.');
        return;
      }
      console.log('Question Answered');
      if (
        answerToQuestionForVehicleAndDriverChanges.value === 'Yes' &&
        !okayToProceedAndCreateDriver.value
      ) {
        okayToProceedAndCreateDriver.value = true; // Allow showing driver change options
        console.log('What Kind of Driver Change');
        return; // Exit to show driver change options
      }

      // Assuming a driver change option is selected...
      if (
        okayToProceedAndCreateDriver.value &&
        driverChangeOption !== null &&
        !okayToProceedToSelectDrivers.value
      ) {
        okayToProceedToSelectDrivers.value = true; // Allow to show create driver inputs
        console.log('Proceed To Driver Change');
        return; // Exit to let the user select/add drivers
      }

      // When attempting to proceed past driver selection...
      if (okayToProceedToSelectDrivers.value) {
        if (!checkDriversAdded()) {
          console.log(
            'No drivers added, please add drivers before proceeding.',
          );
          setErrorMessage('Please add drivers before proceeding.');
          return; // Exit because no drivers have been added
        } else {
          console.log('Drivers Added, Checking for vehicle pairings...');
          // Logging out the current state for debugging
          console.log('Current vehicles:', JSON.stringify(vehicles.value));
          console.log(
            'Current driverVehiclePairs:',
            JSON.stringify(driverVehiclePairs.value),
          );

          // Check that each vehicle with action 'Add' has an associated driver
          const unpairedVehicles = vehicles.value.filter(
            (vehicle) =>
              vehicle.action === 'Add' &&
              !driverVehiclePairs.value.some((pair) =>
                pair.vehicles.some((v) => v.id === vehicle.id),
              ),
          );

          // Log out details of unpaired vehicles for further investigation
          console.log('Unpaired Vehicles:', JSON.stringify(unpairedVehicles));

          if (unpairedVehicles.length > 0) {
            console.log(
              'One or more vehicles intended for addition do not have an associated driver.',
            );
            setErrorMessage(
              'Please ensure all vehicles to be added have an associated driver before proceeding.',
            );
            return; // Exit because there are vehicles intended to be added without a driver pair
          } else {
            console.log(
              'All vehicles to be added have an associated driver. Proceeding...',
            );
            // Continue with the process
          }
        }
      }

      console.log('Driver Change Verification Passed');

      constructAndEmitMasterObject();
      emit('updateStep');
    };

    const handleDriverChanges = () => {
      console.log('handleDriverChanges called');
      if (!driverChangeOption.value) {
        console.log('No driver change option selected.');
        setErrorMessage('Please select an option for driver changes.');
        return;
      }
      console.log('Driver Changes Option Selected, Proceeding...');

      if (!checkProceedFlag(okayToProceedAndCreateDriver)) return;
      console.log(
        'Proceed Flag Checked OkayToProceedAndCreateDriver',
        okayToProceedAndCreateDriver.value,
      );

      if (!checkDriversAdded()) return;
      console.log('Drivers Added Checked', drivers.value.length);
      attachVehicleToDriver.value = true;
      console.log(
        'Attach Vehicle To Driver value turned to:',
        attachVehicleToDriver.value,
      );
      if (!checkProceedFlag(okayToProceedAndCreateVehicle)) return;
      console.log(
        'Proceed Flag Checked OkayToProceedAndCreateVehicle',
        okayToProceedAndCreateVehicle.value,
      );
      if (!checkVehicleForDrivers()) return;
      console.log('Vehicle For Drivers Checked', vehicles.value.length);

      constructAndEmitMasterObject();
      emit('updateStep');
      // if (!checkQuestionAnswered()) return;
    };

    const handleCoverageAdjustments = () => {
      if (!checkVehiclesAdded()) return;
      if (!checkProceedFlag(okayToProceed)) return;
      if (!checkCoveragesSelected()) return;

      console.log('Validation passed, proceeding with the next steps.');
      constructAndEmitMasterObject();
      emit('updateStep');
    };

    const handleOtherChanges = () => {
      if (!validateOtherChangesSelection()) return;
      handleSpecificChange(otherChangesSelection.value);
    };

    const setErrorMessage = (message) => {
      console.log('Error:', message);
      errorMessage.value = message;
    };

    const checkProceedFlag = (proceedFlag) => {
      if (!proceedFlag.value) {
        proceedFlag.value = true;
        console.log(proceedFlag.name + ':', proceedFlag.value);
        return false;
      }
      return true;
    };

    const checkVehiclesAdded = () => {
      if (vehicles.value.length === 0) {
        setErrorMessage('Please add vehicles before proceeding.');
        return false;
      }
      return true;
    };

    const checkDriversAdded = () => {
      console.log('Checking drivers added');
      if (drivers.value.length === 0) {
        setErrorMessage('Please add drivers before proceeding.');
        return false;
      }
      return true;
    };

    const checkVehicleForDrivers = () => {
      if (vehicles.value.length === 0) {
        setErrorMessage('Please select a vehicle for the driver.');
        return false;
      }
      return true;
    };

    const checkQuestionAnswered = () => {
      // Check if the question is asked and the answer is null
      if (
        askQuestionForVehicleAndDriverChanges.value &&
        answerToQuestionForVehicleAndDriverChanges.value === null
      ) {
        setErrorMessage('Please answer the question before proceeding.');
        return false;
      }

      // Check if the answer is yes and set the flag accordingly
      if (answerToQuestionForVehicleAndDriverChanges.value === 'Yes') {
        okayToProceedAndCreateDriver.value = true;
      } else if (answerToQuestionForVehicleAndDriverChanges.value === 'No') {
        // Proceed without setting the flag
      } else {
        // Handle the case when the answer is null
        setErrorMessage('Please answer the question before proceeding.');
        return false;
      }

      return true;
    };

    const checkVehicleDriverCoverage = () => {
      // Add logic if needed
      return true;
    };

    const checkCoveragesSelected = () => {
      // Add logic if needed
      return true;
    };

    const validateOtherChangesSelection = () => {
      // Add logic if needed
      return true;
    };

    const handleSpecificChange = (changeType) => {
      // Handle specific changes based on 'changeType'
    };

    const handleBackButton = () => {
      console.log('Back Button called');

      // Reset all values
      isNextButtonDisabled.value = false;
      addMoreDrivers.value = false;
      addMoreVehicles.value = false;
      selectedOption.value = [];
      showReplacementProcess.value = false;
      okayToProceed.value = false;
      showVehicleInput.value = true;
      okayToProceedAndCreateVehicle.value = false;
      okayToProceedAndCreateDriver.value = false;
      selectedVehicleForDriver.value = false;
      selectedDrivers.value = [];
      selectedVehicles.value = [];
      attachVehicleToDriver.value = false;
      attachDriverToVehicle.value = false;
      readyToCheckDrivers.value = false;
      vehicleChangeOption.value = null;
      driverChangeOption.value = null;
      okayToProceedToSelectDrivers.value = false;
      showDriverScreen.value = false;
      askQuestionForVehicleAndDriverChanges.value = false;
      answerToQuestionForVehicleAndDriverChanges.value = null;
      vehicles.value = [];
      replacementVehicles.value = [];
      lienholderLessor.value = [];
      selectedCoverages.value = [];

      drivers.value = [];

      // Reset all options in coverageOptions
      coverageOptions.forEach((option) => {
        option.selected = false;
        option.selectedAmount = '';
      });

      selectedYear.value = '';
      selectedMake.value = '';
      selectedModel.value = '';
      coverageChangeType.value = 'select_option';
      coverageAdjustmentsStep.value = 1;
      otherChangesSelection.value = null;
    };
    const validateLienholderLessorFields = () => {
      let isValid = true;
      let fields = [
        { value: lienholderName.value, name: 'Name' },
        { value: policyNumber.value, name: 'Policy Number' },
        { value: position.value, name: 'Position' },
        { value: address.value, name: 'Address' },
        { value: city.value, name: 'City' },
        { value: state.value, name: 'State' },
        { value: zip.value, name: 'Zip Code' },
      ];

      fields.forEach((field) => {
        if (!field.value.trim()) {
          console.log(`Error: ${field.name} is required.`);
          errorMessage.value = `${field.name} is required for Lienholder/Lessor.`;
          isValid = false;
        }
      });

      return isValid;
    };

    const handleAddLienholderLessor = () => {
      if (!validateLienholderLessorFields()) return;
      const newLienholderLessor = {
        name: lienholderName.value,
        policyNumber: policyNumber.value,
        position: position.value,
        address: address.value,
        city: city.value,
        state: state.value,
        zip: zip.value,
        addRemoveModify: 'Add',
        lienholderLessor: otherChangesSelection.value,
      };
      lienholderLessor.value.push(newLienholderLessor);
      console.log('Lienholder/Lessor:', lienholderLessor.value);

      lienholderName.value = '';
      policyNumber.value = '';
      position.value = '';
      address.value = '';
      city.value = '';
      state.value = '';
      zip.value = '';
      addRemoveModify.value = '';
    };
    const errorMessage = ref('');

    const otherChangesSelection = ref(null);
    const okayToProceedToSelectDrivers = ref(false);
    const showDriverScreen = ref(false);
    const vehicleForCoverageChangeAdded = ref(false);
    const coverageAdjustmentsStep = ref(1);
    const selectedOption = ref([]);

    const coverageChangeType = ref('select_option');
    const coverageOptions = reactive([
      {
        type: 'bodilyInjury',
        label: 'Bodily Injury',
        icon: 'mdi:car-side',
        options: [
          { value: 'na', text: 'N/A' },
          { value: 'Reject', text: 'Reject' },
          { value: '$10,000/$20,000', text: '$10,000/$20,000' },
          { value: '$25,000/$50,000', text: '$25,000/$50,000' },
          { value: '$50,000/$100,000', text: '$50,000/$100,000' },
          { value: '$100,000/$100,000', text: '$100,000/$100,000' },
          { value: '$100,000/$300,000', text: '$100,000/$300,000' },
          { value: '$250,000/$250,000', text: '$250,000/$250,000' },
          { value: '$250,000/$500,000', text: '$250,000/$500,000' },
          { value: '$500,000/$500,000', text: '$500,000/$500,000' },
          { value: '$500,000/1M', text: '$500,000/$1,000,000' },
          { value: '$750,000/$750,000', text: '$750,000/$750,000' },
          { value: '$1,000,000/$1,000,000', text: '$1,000,000/$1,000,000' },
        ],
        selected: false,
        selectedAmount: '',
      },
      {
        type: 'propertyDamage',
        label: 'Property Damage',
        icon: 'mdi:home',
        options: [
          { value: 'N/A', text: 'N/A' },
          { value: '$10,000', text: '$10,000' },
          { value: '$20,000', text: '$20,000' },
          { value: '$50,000', text: '$50,000' },
          { value: '$100,000', text: '$100,000' },
          { value: '$250,000', text: '$250,000' },
          { value: '$300,000', text: '$300,000' },
          { value: '$500,000', text: '$500,000' },
          { value: '$750,000', text: '$750,000' },
          { value: '$1,000,000', text: '$1,000,000' },
        ],
        selected: false,
        selectedAmount: '',
      },
      {
        type: 'uninsuredMotorist',
        label: 'Uninsured Motorist',
        icon: 'mdi:car',
        options: [
          { value: 'N/A', text: 'N/A' },
          { value: 'Reject', text: 'Reject' },
          { value: '$10,000/$20,000', text: '$10,000/$20,000' },
          { value: '$15,000/$30,000', text: '$15,000/$30,000' },
          { value: '$25,000/$50,000', text: '$25,000/$50,000' },
          { value: '$50,000/$50,000', text: '$50,000/$50,000' },
          { value: '$50,000/$100,000', text: '$50,000/$100,000' },
          { value: '$100,000/$100,000', text: '$100,000/$100,000' },
          { value: '$100,000/$300,000', text: '$100,000/$300,000' },
          { value: '$250,000/$250,000', text: '$250,000/$250,000' },
          { value: '$300,000/$300,000', text: '$300,000/$300,000' },
          { value: '$500,000/$500,000', text: '$500,000/$500,000' },
          { value: '$500,000/$1,000,000', text: '$500,000/$1,000,000' },
          { value: '$750,000/$750,000', text: '$750,000/$750,000' },
          { value: '$1,000,000/$1,000,000', text: '$1,000,000/$1,000,000' },
        ],
        selected: false,
        selectedAmount: '',
      },
      {
        type: 'compDeductible',
        label: 'Comp Deductible',
        icon: 'mdi:shield-car',
        options: [
          { value: 'N/A', text: 'N/A' },
          { value: 'Reject', text: 'Reject' },
          { value: '$250', text: '$250' },
          { value: '$500', text: '$500' },
          { value: '$1,000', text: '$1,000' },
          { value: '$2,500', text: '$2,500' },
        ],
        selected: false,
        selectedAmount: '',
      },
      {
        type: 'colDeductible',
        label: 'Collision Deductible',
        icon: 'mdi:car-multiple',
        options: [
          { value: 'N/A', text: 'N/A' },
          { value: '$250', text: '$250' },
          { value: '$500', text: '$500' },
          { value: '$1,000', text: '$1,000' },
          { value: '$2,500', text: '$2,500' },
          { value: 'Reject', text: 'Reject' },
        ],
        selected: false,
        selectedAmount: '',
      },
      {
        type: 'rentalCarCoverage',
        label: 'Rental Car Coverage',
        icon: 'mdi:car-estate',
        options: [
          { value: 'Add', text: 'Add' },
          { value: 'Remove', text: 'Remove' },
          { value: 'N/A', text: 'N/A' },
        ],
        selected: false,
        selectedAmount: '',
      },
      {
        type: 'roadsideAssistance',
        label: 'Roadside Assistance',
        icon: 'mdi:car-emergency',
        options: [
          { value: 'Add', text: 'Add' },
          { value: 'Remove', text: 'Remove' },
          { value: 'N/A', text: 'N/A' },
        ],
        selected: false,
        selectedAmount: '',
      },
      {
        type: 'stackedUnstackedUm',
        label: 'Stacked/Unstacked UM',
        icon: 'mdi:car-multiple',
        options: [
          { value: 'Stacked', text: 'Stacked' },
          { value: 'Unstacked', text: 'Unstacked' },
        ],
        selected: false,
        selectedAmount: '',
      },
      {
        type: 'extras',
        label: 'Extras',
        icon: 'mdi:plus',
        options: [
          { value: 'Anti-Lock Brakes', text: 'Anti-Lock Brakes' },
          { value: 'Anti-Theft System', text: 'Anti-Theft Systems' },
        ],
        selected: false,
        selectedAmount: '',
      },
    ]);
    const selectedCoverages = computed(() =>
      coverageOptions.filter(
        (coverage) => coverage.selected && coverage.selectedAmount,
      ),
    );
    function selectCoverageType(coverageType) {
      coverageOptions.forEach((c) => {
        if (c.type === coverageType) {
          c.selected = !c.selected; // Toggle the selected coverage
        } else {
          c.selected = false; // Ensure all other coverages are closed
        }
      });
    }

    function selectCoverageAmount(coverageType, amount) {
      const coverage = coverageOptions.find((c) => c.type === coverageType);
      if (coverage) {
        coverage.selectedAmount = amount;
      }
    }

    const selectedYear = ref('');
    watch(selectedYear, (newYear) => {
      if (newYear) {
        fetchVehicleMakes(newYear);
      } else {
        vehicleMakes.value = []; // Reset if no year is selected
      }
    });
    const selectedMake = ref('');
    watch(selectedMake, (newMake) => {
      if (newMake && selectedYear.value) {
        // Ensure both year and make are selected
        fetchVehicleModels(selectedYear.value, newMake); // Pass both year and make
      } else {
        vehicleModels.value = []; // Reset if no make is selected
      }
    });
    const selectedModel = ref('');
    const vehicleYears = computed(() => {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = currentYear; year >= 1920; year--) {
        years.push(year);
      }
      return years;
    });
    const vehicleMakes = ref([]);
    const vehicleModels = ref([]);
    const vehicleAdded = ref(false);
    const emitSelection = (change) => {
      selectedOption.value = change;
    };
    const fetchVehicleMakes = async (year) => {
      console.log(`Fetching makes for year: ${year}`);
      try {
        const response = await axios.get(`/api/vehicle-makes/${year}`);
        vehicleMakes.value = response.data;
        console.log(`Received makes:`, response.data);
      } catch (error) {
        console.error('There was an error fetching vehicle makes:', error);
      }
    };
    const fetchVehicleModels = async (year, make) => {
      console.log(`Fetching models for year: ${year} and make: ${make}`);
      try {
        const response = await axios.get(`/api/vehicle-models/${year}/${make}`);
        vehicleModels.value = response.data;
        console.log(`Received models:`, response.data);
      } catch (error) {
        console.error('Error fetching vehicle models:', error);
      }
    };
    watch(
      vehicles,
      (newVehicles) => {
        // This will run whenever there's a change in vehicles
        newVehicles.forEach((vehicle) => {
          if (!vehicle.associatedDriverID) {
            // Try to find an unassigned driver
            const unassignedDriver = drivers.value.find(
              (driver) => !driver.associatedVehicleID,
            );
            if (unassignedDriver) {
              assignDriverToVehicle(vehicle.id, unassignedDriver.id);
              assignVehicleToDriver(unassignedDriver.id, vehicle.id);
            }
          }
        });
      },
      { deep: true },
    );
    watch(
      drivers,
      (newDrivers) => {
        newDrivers.forEach((driver) => {
          if (!driver.associatedVehicleID) {
            // Try to find an unassigned vehicle
            const unassignedVehicle = vehicles.value.find(
              (vehicle) => !vehicle.associatedDriverID,
            );
            if (unassignedVehicle) {
              assignVehicleToDriver(driver.id, unassignedVehicle.id);
              assignDriverToVehicle(unassignedVehicle.id, driver.id);
            }
          }
        });
      },
      { deep: true },
    );
    const sortedMakes = computed(() => {
      return vehicleMakes.value
        .slice()
        .sort((a, b) => a.make.localeCompare(b.make));
    });
    const sortedModels = computed(() => {
      return vehicleModels.value
        .slice()
        .sort((a, b) => a.ModelName.localeCompare(b.ModelName));
    });
    const undoVehicleStack = ref([]);
    const currentVehicleAction = ref('add');
    const currentVehicleId = ref(null);
    const driverVehiclePairs = computed(() => {
      return drivers.value.map((driver) => {
        const vehiclesForDriver = driver.associatedVehicleIDs
          .map((id) => vehicles.value.find((vehicle) => vehicle.id === id))
          .filter((v) => v); // This removes any undefined entries
        return { driver, vehicles: vehiclesForDriver };
      });
    });
    const handleVehicleAction = () => {
      errorMessage.value = ''; // Reset error message

      // Validate input for 'add' and 'modify' actions
      if (
        vehicleChangeOption.value === 'Add' ||
        vehicleChangeOption.value === 'Modify'
      ) {
        if (
          !selectedYear.value ||
          !selectedMake.value ||
          !selectedModel.value
        ) {
          errorMessage.value = 'Please select a year, make, and model.';
          console.error(errorMessage.value);
          return; // Exit early if validation fails
        }
      }

      const vehicleData = {
        id: uuidv4(),
        year: selectedYear.value,
        make: selectedMake.value,
        model: selectedModel.value,
        associatedDriverIDs: selectedDrivers.value || [], // Now an array
        action:
          selectedOption.value === 'driverChanges'
            ? 'Add'
            : vehicleChangeOption.value,
        coverages: [],
      };

      // If there are drivers selected, update their associatedVehicleIDs to include this vehicle
      if (selectedDrivers.value && selectedDrivers.value.length > 0) {
        selectedDrivers.value.forEach((driverId) => {
          const driverToUpdate = drivers.value.find(
            (driver) => driver.id === driverId,
          );
          if (driverToUpdate) {
            if (!driverToUpdate.associatedVehicleIDs) {
              driverToUpdate.associatedVehicleIDs = []; // Initialize if undefined
            }
            if (!driverToUpdate.associatedVehicleIDs.includes(vehicleData.id)) {
              driverToUpdate.associatedVehicleIDs.push(vehicleData.id);
            }
          }
        });
      }

      // Update the vehicles list: replace if existing, add if new
      const index = vehicles.value.findIndex((v) => v.id === vehicleData.id);
      if (index !== -1) {
        vehicles.value[index] = vehicleData; // Update existing vehicle
      } else {
        vehicles.value.push(vehicleData); // Add new vehicle
        // Automatically select the new vehicle if it is a new addition
        selectedVehicleIds.value = [vehicleData.id];
      }

      console.log('Vehicle added/updated:', vehicleData);

      // Reset form fields and state after action
      setupVehicleWatches();
      resetVehicleForm();
      vehicleChangeOption.value = null;
      addMoreVehicles.value = false;
      currentVehicleId.value = null;
      showVehicleInput.value = false;
    };

    const handleAddMoreVehicles = () => {
      addMoreVehicles.value = true;
      okayToProceedAndCreateVehicle.value = false;
      showVehicleInput.value = true;
    };

    const resetVehicleForm = () => {
      selectedYear.value = '';
      selectedMake.value = '';
      selectedModel.value = '';
      errorMessage.value = '';
      // Add more resets if needed
    };
    const prepareVehicleForm = (action, vehicleId = null) => {
      currentVehicleAction.value = action;
      currentVehicleId.value = vehicleId;

      if (action === 'modify' && vehicleId) {
        const vehicle = vehicles.value.find((v) => v.id === vehicleId);
        if (vehicle) {
          selectedYear.value = vehicle.year;
          selectedMake.value = vehicle.make;
          selectedModel.value = vehicle.model;
          // Set other fields as necessary
        }
      } else if (action === 'add' || action === 'remove') {
        resetVehicleForm(); // Clears the form if adding a new vehicle or preparing to remove
      }
    };
    const undoLastVehicleAction = () => {
      if (undoVehicleStack.value.length === 0) {
        console.error('No vehicle actions to undo');
        return;
      }

      const lastVehicle = undoVehicleStack.value.pop(); // Pop the last action
      if (lastVehicle.action === 'add') {
        vehicles.value = vehicles.value.filter((v) => v.id !== lastVehicle.id);
      } else if (lastVehicle.action === 'remove') {
        vehicles.value.push(lastVehicle);
      } else if (lastVehicle.action === 'modify') {
        const index = vehicles.value.findIndex((v) => v.id === lastVehicle.id);
        if (index !== -1) {
          vehicles.value[index] = lastVehicle.previousState;
        }
      }

      console.log(`Undid last vehicle action: ${lastVehicle.action}`);
    };

    const replacementVehicles = ref([]);
    const replacementVehicleAdded = ref(false);
    const undoDriverStack = ref([]);

    const driverFirstName = ref('');
    const driverLastName = ref('');
    const driverDateOfBirth = ref('');
    const driverLicenseNumber = ref('');
    const associatedVehicleID = ref('');
    const usagePercentage = ref(100);
    const formatDOB = (dateStr) => {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero
      const day = ('0' + date.getDate()).slice(-2); // Add leading zero
      return `${month}-${day}-${year}`;
    };
    const calculateAge = (dateStr) => {
      const today = new Date();
      const birthDate = new Date(dateStr);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };
    const selectedDrivers = ref([]);
    const selectedVehicles = ref([]);
    const currentAction = ref('add');
    const currentDriverId = ref(null);

    const getVehicleDetails = (vehicleId) => {
      const vehicle = vehicles.value.find((v) => v.id === vehicleId);
      return vehicle
        ? `${vehicle.year} ${vehicle.make} ${vehicle.model}`
        : 'Vehicle not found';
    };
    const newDriver = {
      id: uuidv4(), // Generates a unique UUID
      // other driver properties...
    };
    const handleDriverAction = () => {
      errorMessage.value = ''; // Reset error message

      if (
        driverChangeOption.value === 'Add' ||
        driverChangeOption.value === 'Replace'
      ) {
        if (
          !driverFirstName.value ||
          !driverLastName.value ||
          !driverDateOfBirth.value ||
          !driverLicenseNumber.value
        ) {
          errorMessage.value = 'Please fill out all driver details.';
          console.error(errorMessage.value);
          return; // Exit early if validation fails
        }
      }

      // Construct the new or modified driver object, noting associatedVehicleIDs now expects an array
      const newOrModifiedDriver = {
        id: uuidv4(), // New ID for 'Add', existing for 'Replace'
        firstName: driverFirstName.value,
        lastName: driverLastName.value,
        dateOfBirth: driverDateOfBirth.value,
        driversLicenseNumber: driverLicenseNumber.value,
        associatedVehicleIDs: selectedVehicles.value || [], // Now expects an array
        needsDriverVehiclePairing: selectedOption.value === 'driverChanges',
        usagePercentage: usagePercentage.value,
        action: driverChangeOption.value,
      };

      // If there are vehicles selected, update their associatedDriverIDs to include this driver
      if (selectedVehicles.value && selectedVehicles.value.length > 0) {
        selectedVehicles.value.forEach((vehicleId) => {
          const vehicleToUpdate = vehicles.value.find(
            (vehicle) => vehicle.id === vehicleId,
          );
          if (vehicleToUpdate) {
            if (!vehicleToUpdate.associatedDriverIDs) {
              vehicleToUpdate.associatedDriverIDs = []; // Initialize if undefined
            }
            if (
              !vehicleToUpdate.associatedDriverIDs.includes(
                newOrModifiedDriver.id,
              )
            ) {
              vehicleToUpdate.associatedDriverIDs.push(newOrModifiedDriver.id);
            }
          }
        });
      }

      // Add or update the driver
      const index = drivers.value.findIndex(
        (d) => d.id === newOrModifiedDriver.id,
      );
      if (index !== -1) {
        drivers.value[index] = newOrModifiedDriver; // Update existing driver
      } else {
        drivers.value.push(newOrModifiedDriver); // Add new driver
      }

      console.log('Driver added/updated:', newOrModifiedDriver);

      // Reset form fields and state after action
      resetDriverForm();
      addMoreDrivers.value = false;
      currentDriverId.value = null;
    };

    watch([driverChangeOption, okayToProceedAndCreateDriver], () => {
      // Automatically open driver inputs if proceeding with a change
      if (
        okayToProceedAndCreateDriver.value &&
        ['Add', 'Remove', 'Replace'].includes(driverChangeOption.value)
      ) {
        addMoreDrivers.value = true;
      }
    });
    watch([okayToProceedAndCreateVehicle], () => {
      // Automatically open vehicle inputs if proceeding with a change
      if (okayToProceedAndCreateVehicle.value) {
        addMoreVehicles.value = true;
      }
    });

    const showDriverInputs = () => {
      addMoreDrivers.value = true;
      // Reset other states as necessary, e.g., clearing selected driver fields
      resetDriverForm(); // If you have a method to reset form fields
    };
    const resetDriverForm = () => {
      driverFirstName.value = '';
      driverLastName.value = '';
      driverDateOfBirth.value = '';
      driverLicenseNumber.value = '';
      associatedVehicleID.value = '';
      errorMessage.value = '';
      // Add more resets if needed
    };
    const prepareDriverForm = (action, driverId = null) => {
      currentAction.value = action;
      currentDriverId.value = driverId;

      if (action === 'modify' && driverId) {
        const driver = drivers.value.find((d) => d.id === driverId);
        if (driver) {
          driverFirstName.value = driver.firstName;
          driverLastName.value = driver.lastName;
          driverDateOfBirth.value = driver.dateOfBirth;
          driverLicenseNumber.value = driver.driversLicenseNumber;
          associatedVehicleID.value = driver.associatedVehicleID;
          usagePercentage.value = driver.usagePercentage;
        }
      } else if (action === 'add' || action === 'remove') {
        resetDriverForm(); // Clears the form if adding a new driver or preparing to remove
      }
    };
    const undoLastDriverAction = () => {
      if (undoDriverStack.value.length === 0) {
        console.error('No driver actions to undo');
        return;
      }

      const lastAction = undoDriverStack.value.pop(); // Pop the last action

      switch (lastAction.action) {
        case 'add':
          // Remove the newly added driver
          drivers.value = drivers.value.filter(
            (driver) => driver.id !== lastAction.id,
          );
          break;
        case 'modify':
          // Revert to the previous state of the driver
          const index = drivers.value.findIndex(
            (driver) => driver.id === lastAction.id,
          );
          if (index !== -1) {
            drivers.value[index] = lastAction.previousState;
          }
          break;
        case 'remove':
          // Re-add the removed driver
          drivers.value.push(lastAction.previousState);
          break;
        default:
          console.error('Unknown action type in undo stack');
      }

      console.log(`Undid last driver action: ${lastAction.action}`);
    };
    const usStates = ref(
      Object.entries(stateAbbreviations.states).map(([name, abbreviation]) => ({
        name,
        abbreviation,
      })),
    );

    onMounted(() => {
      fetchVehicleMakes();
    });

    return {
      // UI Handlers
      handleNextButton,
      handleBackButton,
      handleVehicleAction,
      handleAddLienholderLessor,
      handleDriverAction,
      handleAddMoreDrivers,
      handleAddMoreVehicles,
      isNextButtonDisabled,
      showVehicleInput,

      // UI State and Form Inputs
      selectedOption,
      selectedYear,
      availableVehicles,
      selectedMake,
      selectedModel,
      selectedDrivers,
      showReplacementProcess,
      selectedVehicles,
      otherChangesSelection,

      // Form Field States
      driverFirstName,
      driverLastName,
      driverDateOfBirth,
      driverLicenseNumber,
      associatedVehicleID,
      showDriverInputs,
      lienholderName,
      policyNumber,
      position,
      address,
      city,
      state,
      zip,
      errorMessage,

      // Reactive Lists and Options
      vehicles,
      drivers,
      vehicleMakes,
      vehicleModels,
      usStates,
      coverageOptions,
      lienholderLessor,
      selectedVehicleIds,

      // Flags and Checkers
      okayToProceed,
      okayToProceedToSelectDrivers,
      okayToProceedAndCreateVehicle,
      okayToProceedAndCreateDriver,
      selectedVehicleForDriver,
      attachVehicleToDriver,
      attachDriverToVehicle,
      vehicleAdded,
      vehicleForCoverageChangeAdded,
      showDriverScreen,
      askQuestionForVehicleAndDriverChanges,
      answerToQuestionForVehicleAndDriverChanges,
      vehicleChangeOption,
      driverChangeOption,
      readyToCheckDrivers,
      addMoreDrivers,
      addMoreVehicles,

      // Coverage Related
      selectedCoverages,
      selectCoverageType,
      selectCoverageAmount,
      coverageChangeType,
      coverageAdjustmentsStep,

      // Entity Management
      undoVehicleStack,
      undoLastVehicleAction,
      removeCoverage,

      // Data Fetching and Sorting
      fetchVehicleMakes,
      fetchVehicleModels,
      sortedMakes,
      sortedModels,

      // Utility and Miscellaneous
      emit,
      emitSelection,
      coverageMapping,
      toggleSelection,
      constructAndEmitMasterObject,
      formatDOB,
      assignDriverToVehicle,
      driverVehiclePairs,
      newDriver,
      validDrivers,
      calculateAge,
      updateVehicleCoverages,
      getVehicleDetails,
      vehicleYears, // If this is used in your template for dropdowns etc.
    };
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.transition-style {
  transition:
    transform 150ms ease,
    box-shadow 150ms ease;
  width: 100%;
  height: auto;
  min-height: 3rem;
}
.accordion-enter-active,
.accordion-leave-active {
  transition: all 0.3s ease;
}
.accordion-enter,
.accordion-leave-to {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}
.pulse-animate {
  animation: pulse 2s infinite;
}

.show-click-accordion {
  animation: show-click-accordion 1s infinite;
}
@keyframes show-click-accordion {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulse {
  animation: pulse 2s infinite;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
