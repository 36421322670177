<template>
  <div class="max-w-fit rounded-lg mb-4 md:p-2 overflow-y-auto">
    <h3 class="text-lg font-semibold mb-3 text-center md:text-left">Age of Roof</h3>
    <div class="flex flex-wrap justify-center md:justify-start gap-4">
      <div v-for="(item, index) in parsedItems" :key="index" class="relative bg-white rounded-lg shadow-xl w-56 flex flex-col">
            <!-- Newly Updated Badge -->
            <div v-if="isNewlyUpdated(item.updated_at)" class="absolute top-0 right-0 m-2 px-2 py-1 bg-green-500 text-white text-xs font-bold rounded-full">
              Newly Updated
            </div>
            <div class="flex-grow">
              <img v-if="getImagePath(item.material)" class="w-56 h-auto rounded-t-lg" :src="getImagePath(item.material)" :alt="item.material"/>
            <div>
              <p class="font-bold text-base py-2 text-center">{{ formatText(item.material) }}</p>
            </div>
        
            <hr class="mb-2 border-gray-300"/>
            <div class="p-2">
              <p class="font-bold text-md">
                {{ formatText(item.age_comparison)}}:
                <span class="bg-blue-500 text-sm text-white font-normal px-2 rounded-full">{{ item.age_single_value }} years</span>
              </p>
              <p v-if="item.age_range_min || item.age_range_max">
                <strong>Age Range:</strong> {{ item.age_range_min }} -
                {{ item.age_range_max }}
              </p>
              <p>
                <strong>Must be:</strong><br>
                <span v-for="condition in item.conditions" class="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full mr-2"
                  :key="condition">
                  {{ formatText(condition) }}
                </span>
              </p>
              <p v-if="item.custom_condition">
                <strong>Custom Condition:</strong> {{ item.custom_condition }}
              </p>
          </div>
        </div>
        <div class="mx-auto">
          <ToolTip>
            <template #trigger>
              <button class="flex items-center ">
                <p class="text-xs font-medium">Update History</p>
                <Icon icon="iconamoon:history-duotone" class="w-6 ml-1"/>
              </button>
            </template>
            <div>
              <p><strong>Last Updated By:</strong> {{ item.username_of_last_user_update }}</p>
              <p><strong>Last Updated:</strong> {{ formatDate(item.updated_at) }}</p>
            </div>
          </ToolTip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import ToolTip from '../../../components/ToolTip.vue';
import asphaltShingle from '@/assets/images/underwriting_images/asphalt_shingle.jpg';
import clayTiles from '@/assets/images/underwriting_images/clay_tiles.jpg';
import concreteTiles from '@/assets/images/underwriting_images/concrete_tiles.jpg';
import flat from '@/assets/images/underwriting_images/flat.jpg';
import metalRoof from '@/assets/images/underwriting_images/metal_roof.jpg';
import slate from '@/assets/images/underwriting_images/slate.jpg';
import wood from '@/assets/images/underwriting_images/wood.jpg';

export default {
  name: 'AgeOfRoofSection',
  components: {
    Icon,
    ToolTip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    parsedItems() {
      const parsed = this.items.map((item) => {
        try {
          return typeof item === 'string' ? JSON.parse(item) : item;
        } catch (e) {
          console.error('Failed to parse item:', item, e);
          return {};
        }
      });
      console.log(parsed); // Debug output
      return parsed;
    },
    isNewlyUpdated() {
      return (updatedAt) => {
        if (!updatedAt) return false;
        const updatedDate = new Date(updatedAt);
        const currentDate = new Date();
        const differenceInTime = currentDate - updatedDate;
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return differenceInDays <= 2;
      };
    },
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    formatText(text) {
      return text
        ? text.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
        : 'N/A';
    },
    getImagePath(material) {
      const images = {
        shingle: asphaltShingle,
        tile: clayTiles,
        'concrete tile': concreteTiles,
        flat: flat,
        metal: metalRoof,
        slate: slate,
        wood: wood,
      };
      return images[material.toLowerCase()] || '';
    },
  },
};
</script>
