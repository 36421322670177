<template>
  <div class="min-h-fit relative shadow">
    <div class="flex flex-col mb-20 z-10 relative">
      <div v-if="showChangeOptions">
        <div class="text-center mx-auto mt-4">
          <Icon
            icon="fluent:building-bank-20-filled"
            class="text-red-600 mx-auto text-4xl md:text-6xl"
          />
        </div>
        <h1 class="text-center text-4xl font-bold text-gray-800 mt-4 mb-2">
          Lender Requests
        </h1>
        <p class="text-center text-xl text-gray-600 mb-8">
          Explore our selection of convenient options below.
        </p>
      </div>

      <div
        v-if="showChangeOptions"
        class="flex flex-col items-center space-y-4"
      >
        <!-- Mortgagee Change Button -->
        <button
          class="rounded-lg p-4 flex items-center bg-blue-500 hover:bg-blue-600 text-white focus:outline-none focus:ring focus:ring-blue-300 transition duration-300 min-w-[300px] justify-center"
          @click="handleMortgageeChange"
        >
          <Icon
            :icon="mortgageeChangeIcon"
            class="icon mr-2"
            style="font-size: 1.5rem"
          />
          <span>Mortgagee Change</span>
        </button>

        <!-- Request Coverage Change Button -->
        <button
          class="rounded-lg p-4 flex items-center bg-green-600 hover:bg-green-700 text-white focus:outline-none focus:ring focus:ring-green-300 transition duration-300 min-w-[300px] justify-center"
          @click="handleRequestCoverageChange"
        >
          <Icon
            :icon="coverageChangeIcon"
            class="icon mr-2"
            style="font-size: 1.5rem"
          />
          <span>Request Coverage Change</span>
        </button>

        <!-- Request Proof of Insurance Button -->
        <button
          class="rounded-lg p-4 flex items-center bg-orange-500 hover:bg-orange-600 text-white focus:outline-none focus:ring focus:ring-orange-300 transition duration-300 min-w-[300px] justify-center"
          @click="handleRequestProofOfInsurance"
        >
          <Icon
            :icon="proofOfInsuranceIcon"
            class="icon mr-2"
            style="font-size: 1.5rem"
          />
          <span>Request Proof of Insurance</span>
        </button>
      </div>

      <MortgageeChange v-if="showMortgageeChange" customerType="customer" />
      <!-- Request Coverage Change Section -->
      <homeInsuranceChange
        v-if="showRequestCoverageChange"
        :customerType="'lender'"
      />
      <RequestProofOfInsurance v-if="showRequestProofOfInsurance" />
    </div>
    <!-- Background Images -->
    <div v-if="showChangeOptions" class="mx-auto max-w-6xl">
      <div class="hidden md:flex justify-between mt-[-200px]">
        <img
          src="@/assets/images/productHeroBanners/businessinsurance/businessinsuranceHeroBannerLeft.svg"
          class="ml-4 w-1/3"
        />
        <div class="flex-grow"></div>
        <img
          src="@/assets/images/productHeroBanners/businessinsurance/businessinsuranceHeroBannerRight.svg"
          class="mr-4 w-1/3"
        />
      </div>
    </div>
  </div>
  <!-- <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Submit Request</button> -->
</template>

<script>
import { Icon } from '@iconify/vue';
import MortgageeChange from './mortgageeChangesForm.vue';
import homeInsuranceChange from './homeInsurancePolicyChange.vue';
import RequestProofOfInsurance from '../../requestProofOfInsurance.vue';

export default {
  name: 'LenderRequest',
  components: {
    Icon,
    MortgageeChange,
    homeInsuranceChange,
    RequestProofOfInsurance,
  },
  data() {
    return {
      usStates: [
        { name: 'Alabama', abbreviation: 'AL' },
        { name: 'Alaska', abbreviation: 'AK' },
        { name: 'Arizona', abbreviation: 'AZ' },
        { name: 'Arkansas', abbreviation: 'AR' },
        { name: 'California', abbreviation: 'CA' },
        { name: 'Colorado', abbreviation: 'CO' },
        { name: 'Connecticut', abbreviation: 'CT' },
        { name: 'Delaware', abbreviation: 'DE' },
        { name: 'Florida', abbreviation: 'FL' },
        { name: 'Georgia', abbreviation: 'GA' },
        { name: 'Hawaii', abbreviation: 'HI' },
        { name: 'Idaho', abbreviation: 'ID' },
        { name: 'Illinois', abbreviation: 'IL' },
        { name: 'Indiana', abbreviation: 'IN' },
        { name: 'Iowa', abbreviation: 'IA' },
        { name: 'Kansas', abbreviation: 'KS' },
        { name: 'Kentucky', abbreviation: 'KY' },
        { name: 'Louisiana', abbreviation: 'LA' },
        { name: 'Maine', abbreviation: 'ME' },
        { name: 'Maryland', abbreviation: 'MD' },
        { name: 'Massachusetts', abbreviation: 'MA' },
        { name: 'Michigan', abbreviation: 'MI' },
        { name: 'Minnesota', abbreviation: 'MN' },
        { name: 'Mississippi', abbreviation: 'MS' },
        { name: 'Missouri', abbreviation: 'MO' },
        { name: 'Montana', abbreviation: 'MT' },
        { name: 'Nebraska', abbreviation: 'NE' },
        { name: 'Nevada', abbreviation: 'NV' },
        { name: 'New Hampshire', abbreviation: 'NH' },
        { name: 'New Jersey', abbreviation: 'NJ' },
        { name: 'New Mexico', abbreviation: 'NM' },
        { name: 'New York', abbreviation: 'NY' },
        { name: 'North Carolina', abbreviation: 'NC' },
        { name: 'North Dakota', abbreviation: 'ND' },
        { name: 'Ohio', abbreviation: 'OH' },
        { name: 'Oklahoma', abbreviation: 'OK' },
        { name: 'Oregon', abbreviation: 'OR' },
        { name: 'Pennsylvania', abbreviation: 'PA' },
        { name: 'Rhode Island', abbreviation: 'RI' },
        { name: 'South Carolina', abbreviation: 'SC' },
        { name: 'South Dakota', abbreviation: 'SD' },
        { name: 'Tennessee', abbreviation: 'TN' },
        { name: 'Texas', abbreviation: 'TX' },
        { name: 'Utah', abbreviation: 'UT' },
        { name: 'Vermont', abbreviation: 'VT' },
        { name: 'Virginia', abbreviation: 'VA' },
        { name: 'Washington', abbreviation: 'WA' },
        { name: 'West Virginia', abbreviation: 'WV' },
        { name: 'Wisconsin', abbreviation: 'WI' },
        { name: 'Wyoming', abbreviation: 'WY' },
      ],
      selectedState: '',
      currentStep: 1,
      requestCoverageChangeStep: 1,
      // Icons
      mortgageeChangeIcon: 'mdi:bank',
      coverageChangeIcon: 'mdi:shield-check',
      proofOfInsuranceIcon: 'mdi:file-document-outline',

      // Visibility controls
      showChangeOptions: true, // or false, depending on default visibility
      showMortgageeChange: false,
      showRequestCoverageChange: false,
      showRequestProofOfInsurance: false,

      // Form fields
      mortgageeChangeType: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      policyNumber: '',
      lienholderName: '',
      mortgageePosition: '',
      mortgageeClause: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      loanNumber: '',

      // Form fields for coverage change
      policyNumber: '',
      coverageType: '',
    };
  },
  methods: {
    handleMortgageeChange() {
      this.showMortgageeChange = true;
      this.showChangeOptions = false;
    },
    handleRequestProofOfInsurance() {
      this.showRequestProofOfInsurance = true;
      this.showChangeOptions = false;
    },
    handleRequestCoverageChange() {
      this.showRequestCoverageChange = !this.showRequestCoverageChange;
      this.showChangeOptions = false;
    },
    nextCoverageChangeStep() {
      if (this.requestCoverageChangeStep < 3) {
        this.requestCoverageChangeStep++;
      }
    },
    previousCoverageChangeStep() {
      if (this.requestCoverageChangeStep > 1) {
        this.requestCoverageChangeStep--;
      }
    },
    submitCoverageChangeForm() {
      // Logic to handle form submission
      console.log('Submitting Coverage Change Request', this.$data);
      // Add appropriate logic as needed
    },
    toggleChangeOptions() {
      this.showChangeOptions = !this.showChangeOptions;
    },
    submitRequest() {
      // Handle the form submission logic here
      console.log('Submitting Mortgagee Change Request', this.$data);
      // Add appropriate logic as needed
    },
  },
};
</script>
