<template>
  <div class="h-10 bg-gray-100"></div>
  <div class="max-w-[1440px] mx-auto font-Roboto text-[#646778] mt-[50px]">
    <div class="md:flex justify-between">
      <div class="left-side md:w-[30%] relative">
        <div
          class="sticky top-[30px] xl:w-[300px] md:h-[1050px] lg:w-[288px] md:w-[230px] px-[20px] mt-[50px]"
        >
          <div class="progress-bar hidden md:block">
            <ul>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#home1"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Home Insurance Introduction</a
                >
              </li>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#htgbr"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >How to Get The Best Rate</a
                >
              </li>
              <li class="hover:text-[#D24948] duration-300">
                <a
                  href="#coverage"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Components and Coverage</a
                >
              </li>
              <li class="after:hidden hover:text-[#D24948] duration-300">
                <a
                  href="#home2"
                  class="pb-[60px] text-[15px] pl-[40px] flex leading-[27px]"
                  >Main Price Impacting Factors</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right-side px-[20px] md:w-[70%]">
        <div>
          <div class="flex text-[11px] items-center text-[#000105] uppercase">
            <span class="pr-[20px] hover:text-[#D24948] duration-300"
              >Insurance</span
            >
            <span class="pr-[20px]">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25L15.75 11.25C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75L8.25 12.75C7.83579 12.75 7.5 12.4142 7.5 12Z"
                  fill="#000105"
                />
              </svg>
            </span>
            <span class="hover:text-[#D24948] duration-300"
              >Home Insurance</span
            >
          </div>
          <div id="home1">
            <h2
              class="xl:text-[35px] lg:text-[25px] text-[25px] pt-[10px] font-Merriweather font-black tracking-[-0.2px] pb-[20px] text-[#1F2937]"
            >
              Home Insurance Guide
            </h2>
            <p class="text-[15px] my-[15px] leading-[27px]">
              Welcome to Insurance Express's home insurance guide! We're
              dedicated to helping homeowners protect their biggest investment
              by offering the best coverage at affordable rates. With years of
              experience in the industry, we have the knowledge and expertise to
              help you understand the ins and outs of home insurance.
            </p>
            <p class="text-[15px] my-[15px] leading-[27px]">
              As a leading provider of home insurance in our region, we have
              access to a variety of insurance markets, allowing us to offer a
              wide range of coverage options to our clients. In this guide,
              we'll explain what home insurance is, the different types of
              policies available, and provide tips on how to get the best rate
              on your policy.
            </p>
            <p class="text-[15px] my-[15px] leading-[27px]">
              Whether you're a first-time homeowner or looking to switch your
              existing policy, we hope that this guide will provide you with the
              information you need to make an informed decision. So, let's get
              started!
            </p>
          </div>

          <div class="" id="htgbr">
            <div class="bg-[#EDEDED] lg:p-6 p-4 rounded-md">
              <h2
                class="xl:text-[20px] lg:text-[15px] text-[15px] pt-[10px] font-roboto font-bold tracking-[-0.2px] pb-[20px] text-[#1F2937]"
              >
                How to get the best rate
              </h2>
              <ul
                class="list-disc lg:ml-12 ml-8 text-red-600 text-3xl leading-7"
              >
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >Shop around: It's important to get quotes from multiple
                    insurance providers to ensure you're getting the best rate.
                    Use online comparison tools or work with an independent
                    insurance agent who can help you navigate the market.</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >Bundle policies: Many insurance companies offer discounts
                    for customers who bundle their home insurance policy with
                    other types of insurance, such as auto or life
                    insurance.</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >Increase your deductible: The higher your deductible (the
                    amount you pay out of pocket before insurance kicks in), the
                    lower your monthly premium will be. Consider increasing your
                    deductible if you have savings to cover the higher
                    amount.</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >Improve your credit score: Many insurance companies use
                    credit scores as a factor in determining rates. By improving
                    your credit score, you may be able to get a better rate on
                    your home insurance policy.</span
                  >
                </li>
                <li>
                  <span
                    class="text-[#1F2937] font-roboto font-regular text-[15px]"
                    >Ask about discounts: Many insurance providers offer
                    discounts for various reasons, such as having a security
                    system installed, being a senior citizen, or having a good
                    claims history. Be sure to ask your insurance provider about
                    any available discounts.</span
                  >
                </li>
              </ul>
            </div>
            <hr class="w-full mt-6 h-[3px] bg-gray-300" />
          </div>

          <div class="hidden md:block">
            <div class="">
              <h2
                class="xl:text-[35px] lg:text-[25px] text-[25px] pt-[10px] font-Merriweather font-black tracking-[-0.2px] pb-[20px] text-[#1F2937]"
              >
                Understanding Home Insurance: A Brief Guide
              </h2>
              <p class="text-[15px] my-[15px] leading-[27px]">
                Owning a home is one of the biggest investments you can make in
                your lifetime. That's why it's important to protect your
                investment with a good home insurance policy. Home insurance not
                only covers damages to your property caused by unexpected
                events, but it also provides liability protection if someone is
                injured while on your property. Understanding home insurance
                policies and the types of coverage available can help you make
                an informed decision when choosing a policy that meets your
                needs.
              </p>
            </div>

            <div
              class="grid lg:grid-cols-2 sm:grid-cols-2 gap-2 mb-10"
              id="coverage"
            >
              <div class="grid col">
                <p class="text-[15px] my-[15px] leading-[27px]">
                  Components and Coverage of Home Insurance Policies
                </p>
                <ul class="list-disc ml-12 text-red-600 text-3xl leading-7">
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >What is home insurance and why is it important</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Types of home insurance policies: HO-1, HO-2, and
                      HO-3</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >Components of a home insurance policy: dwelling coverage,
                      personal property coverage, liability coverage, and
                      additional living expenses</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >What does home insurance typically cover?</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >What is not covered by home insurance?</span
                    >
                  </li>
                  <li>
                    <span
                      class="text-[#DC2626] font-roboto font-regular text-[15px]"
                      >How much home insurance coverage do you need?</span
                    >
                  </li>
                </ul>
              </div>
              <div class="grid col mx-auto my-auto">
                <img src="@/assets/images/productPageImages/book.png" alt="" />
              </div>
            </div>
            <div>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Home insurance is a crucial investment for protecting your home
                and your belongings. It can provide financial support if your
                home is damaged or destroyed, if your possessions are stolen, or
                if someone is injured on your property. But what exactly is home
                insurance and how does it work?
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px]">
                Types of home insurance policies:
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                HO-1: Provides basic coverage for a limited number of perils.
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                HO-2: Provides broader coverage for more types of perils than
                HO-1.
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                HO-3: Provides comprehensive coverage for most types of perils,
                except those specifically excluded in the policy.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                Components of a home insurance policy
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Dwelling coverage: Covers damage to the physical structure of
                your home.
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Personal property coverage: Covers damage to your belongings.
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Liability coverage: Protects you in the event that someone is
                injured on your property.
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Additional living expenses coverage: Provides financial
                assistance if you're unable to live in your home while it's
                being repaired or rebuilt.
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Home insurance is an essential part of owning a home, but it can
                be difficult to understand what it covers and how much coverage
                you need. In this brief guide, we'll cover the basics of home
                insurance to help you make informed decisions about your
                coverage.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                What is home insurance and why is it important?
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Home insurance provides financial protection in the event of
                damage to your home, your possessions, or injury to someone on
                your property.<br />Without home insurance, you could be left
                with a large financial burden if an unexpected event occurs.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                Types of home insurance policies:
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                HO-1: Provides basic coverage for a limited number of perils.
                <br />HO-2: Provides broader coverage for more types of perils
                than HO-1.<br />
                HO-3: Provides comprehensive coverage for most types of perils,
                except those specifically excluded in the policy.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                Components of a home insurance policy:
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Dwelling coverage: Covers damage to the physical structure of
                your home.<br />
                Personal property coverage: Covers damage to your belongings.<br />
                Liability coverage: Protects you in the event that someone is
                injured on your property. <br />
                Additional living expenses coverage: Provides financial
                assistance if you're unable to live in your home while it's
                being repaired or rebuilt.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                What does home insurance typically cover?
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                Damage caused by fire, lightning, wind, hail, and other natural
                disasters.<br />
                Theft of personal property. <br />
                Liability claims if someone is injured on your property.<br />
                What is not covered by home insurance?<br />
                Damage caused by floods or earthquakes (requires separate
                insurance policies). <br />Damage caused by normal wear and tear
                or lack of maintenance.<br />
                Damage caused by intentional acts.
              </p>
              <p class="text-[15px] my-[15px] font-bold leading-[27px] mb-2">
                How much home insurance coverage do you need?
              </p>
              <p class="text-[15px] my-[15px] font-regular leading-[27px] mb-2">
                The amount of coverage you need depends on factors such as the
                value of your home and possessions, the cost of rebuilding your
                home, and the level of risk in your area.<br />
                In conclusion, understanding the basics of home insurance is
                crucial for protecting your home and your possessions. At
                Insurance Express, we're here to help you find the right
                coverage at a price that fits your budget. Contact us to learn
                more.
              </p>
            </div>
          </div>
        </div>

        <section>
          <div id="home2">
            <h2
              class="xl:text-[35px] lg:text-[25px] text-[25px] font-Merriweather font-black tracking-[-0.2px] py-[20px] text-[#1F2937]"
            >
              Main Price Impacting Factors
            </h2>
            <p class="text-[15px] my-[15px] leading-[27px]">
              When comparing rates and coverages for home insurance, it is
              important to keep in mind data points that Insurance Companies
              find most important when evaluating your rate.
            </p>
          </div>
          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/multi.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Location
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                Where your home is located can have a significant impact on the
                cost of your insurance. Factors such as crime rates, weather
                patterns, and the likelihood of natural disasters can all affect
                your rate.
              </p>
            </div>
          </div>
          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/credit.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Age and Condition of the Home
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                Older homes or homes in poor condition may be more expensive to
                insure. Upgrades and renovations to your home can help reduce
                your insurance costs.
              </p>
            </div>
          </div>

          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/marital.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Size and Type of Coverage
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                The size of your home and the amount of coverage you need can
                impact your insurance costs. Choosing a higher deductible can
                also help reduce your monthly premiums.
              </p>
            </div>
          </div>

          <div class="grid grid-cols-4 p-4">
            <div class="md:w-[105px] col-span-1 my-auto md:my-o mx-auto">
              <img src="@/assets/images/productPageImages/age.png" alt="" />
            </div>
            <div class="col-span-3 p-4 md:p-0">
              <h3
                class="text-[17px] font-bold text-[#1F2937] pb-[9px] group-hover:text-[#D24948] duration-500"
              >
                Credit Score
              </h3>
              <p
                class="text-xs md:text-[14px] lg:max-w-[565px] md:max-w-[445px] max-w-[536px] w-full leading-[24px]"
              >
                Insurance companies may use your credit score as a factor in
                determining your rate. Maintaining good credit can help you get
                a better rate on your home insurance policy.
              </p>
            </div>
          </div>
        </section>

        <div
          class="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 pb-[30px] mt-[20px]"
          id="home4"
        >
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/autoInsurance.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-[#1F2937] md:py-[15px] py-2"
                >
                  Auto Insurance
                </h3>
                <div>
                  <a
                    href="/products/autoinsurance"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/floodInsurance.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-[#1F2937] md:py-[15px] py-2"
                >
                  Flood Insurance
                </h3>
                <div>
                  <a
                    href="/products/floodinsurance"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="xl:m-[20px] lg:m-[10px] md:m-[10px] my-[10px]">
            <div
              class="group bg-[#EDEDED] hover:bg-[#bdbdbd] duration-500 p-[30px] rounded-[5px]"
            >
              <div class="text-center md:pb-[20px]">
                <img
                  src="@/assets/images/product-type-icons/medicare.svg"
                  alt=""
                  class="w-[90px] mx-auto py-[5px]"
                />
                <h3
                  class="font-medium text-[15px] text-[#1F2937] md:py-[15px] py-2"
                >
                  Medicare
                </h3>
                <div>
                  <a
                    href="/products/medicare"
                    class="text-[11px] text-[#1F2937] group-hover:text-[#DC2626] duration-500 underline underline-offset-8 uppercase"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border-t pt-[50px] md:pb-[200px] pb-24 md:mx-[-20px]">
          <div class="md:mx-[20px] bg-[#EDEDED] rounded-[5px]">
            <div
              class="bg-[url('@/assets/images/productPageImages/watch-bg.png')] lg:bg-contain bg-[length:395px] bg-no-repeat bg-right-bottom"
            >
              <div class="xl:p-[30px] lg:p-[20px] p-[20px]">
                <h2
                  class="xl:text-[25px] lg:text-[21px] text-[21px] font-extrabold text-[#1F2937] mb-[20px]"
                >
                  WATCH YOUR SAVINGS ADD UP.
                </h2>
                <p
                  class="text-[15px] py-[15px] leading-[27px] 2xl:max-w-[495px] xl:max-w-[415px] lg:max-w-[301px] md:max-w-[405px] sm:max-w-[500px]"
                >
                  Always check to see if you can save money by adding an
                  additional product to your life insurance policy! (Auto, Home,
                  Flood, etc.)
                </p>
                <div
                  class="mt-[20px] lg:pb-0 md:pb-[150px] sm:pb-[230px] pb-[200px] bg-padding"
                >
                  <a
                    href="/chooseproducts"
                    class="bg-[#DB0909] hover:bg-[#DC2626] duration-500 lg:max-w-[220px] max-w-[200px] w-full flex items-center rounded-[5px] uppercase text-white text-center tracking-[2px] text-[14px] px-[20px] py-[10px] lg:px-[40px] lg:py-[15px]"
                    >Get a quote
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      class="ml-[5px]"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
                        fill="#ffffff"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'homeInsurancePage',
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped></style>
