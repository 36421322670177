<template>
  <div class="guideline-section p-4 bg-white rounded-lg shadow-lg mb-4">
    <h3 class="text-lg font-semibold mb-3">Age of Home Guidelines</h3>
    <div
      v-for="(item, index) in parsedItems"
      :key="index"
      class="mb-3 p-3 bg-gray-100 rounded-md"
    >
      <div class="flex justify-between"></div>
      <div class="mt-2 ml-4">
        <div v-if="item.additionalInfo">
          <p v-if="item.additionalInfo.noLimit"><strong>No Limit</strong></p>
          <p v-if="item.additionalInfo.yearFrom || item.additionalInfo.yearTo">
            <strong>Year Range:</strong> {{ item.additionalInfo.yearFrom }} -
            {{ item.additionalInfo.yearTo }}
          </p>
          <p v-if="item.additionalInfo.ageFrom || item.additionalInfo.ageTo">
            <strong>Age Range:</strong> {{ item.additionalInfo.ageFrom }} -
            {{ item.additionalInfo.ageTo }}
          </p>
          <p v-if="item.additionalInfo.requiresInspection">
            <strong>Requires Inspection:</strong>
            {{ item.additionalInfo.requiresInspection ? 'Yes' : 'No' }}
          </p>
          <p v-if="item.additionalInfo.requiresProofOfUpdates">
            <strong>Requires Proof of Updates:</strong>
            {{ item.additionalInfo.requiresProofOfUpdates ? 'Yes' : 'No' }}
          </p>
          <p v-if="item.additionalInfo.countyRestrictions">
            <strong>County Restrictions:</strong>
            {{ item.additionalInfo.countyRestrictions }}
          </p>
          <p v-if="item.additionalInfo.additionalDetails">
            <strong>Additional Details:</strong>
            {{ item.additionalInfo.additionalDetails }}
          </p>
        </div>
        <p>
          <strong>Last Updated By:</strong>
          {{ item.username_of_last_user_update }}
        </p>
        <p>
          <strong>Last Updated At:</strong>
          {{ formatDate(item.updated_at) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgeOfHomeSection',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localItems: this.items.map((item) => ({ ...item, showDetails: false })),
    };
  },
  computed: {
    parsedItems() {
      return this.localItems.map((item) => {
        try {
          return typeof item === 'string' ? JSON.parse(item) : item;
        } catch (e) {
          console.error('Failed to parse item:', item, e);
          return {};
        }
      });
    },
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    formatText(text) {
      return text
        ? text.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
        : 'N/A';
    },
    toggleDetails(index) {
      this.localItems[index].showDetails = !this.localItems[index].showDetails;
    },
  },
};
</script>

<style scoped>
.guideline-section {
  max-width: 800px;
}
</style>
