<template>
  <div class="flex flex-col min-h-screen">
    <AppHeaderDesktop />
    <main class="flex-grow">
      <ImportantAnnouncements v-if="showCards" />
      <!-- Back Button with Conditional Rendering -->
      <div class="ml-8 text-left" v-if="!showCards">
        <button
          @click="resetToDefaultView"
          class="bg-gray-400 text-white rounded px-4 py-2 hover:bg-gray-500 focus:outline-none focus:ring focus:border-blue-300 transition-colors mt-4"
        >
          Back
        </button>
      </div>
      <div v-if="showCards" class="container mx-auto px-4 mt-8 pb-32">
        <!-- Page Title -->
        <div v-if="showCards" class="mb-12">
          <div class="text-center mx-auto mt-4">
            <img
              src="@/assets/images/customerService.svg"
              alt="Customer Service"
              class="h-48 md:h-56 mx-auto"
              loading="lazy"
            />
          </div>
          <h1
            class="text-center align-middle text-2xl md:text-4xl font-bold text-ietextlight mb-4"
          >
            Customer Service
            <span class="inline-block items-center"
              ><Icon
                icon="line-md:heart-filled"
                class="text-red-600 text-2xl md:text-4xl"
            /></span>
          </h1>
          <p class="text-center text-md md:text-xl text-gray-600">
            We're here to help you with all your service needs
          </p>
        </div>

        <!-- Cards Container -->
        <div
          v-if="showCards"
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"
        >
          <!-- Card 1 for Citizens -->
          <div
            class="card relative rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow flex flex-col justify-between"
          >
            <div>
              <img
                src="@/assets/images/carrierLogos/citizens-prop-1.svg"
                alt="Citizens Insurance Logo"
                class="h-20 mb-4 mx-auto"
                loading="lazy"
              />
              <h2 class="text-lg font-semibold text-gray-700 mb-3">
                Citizens Insurance Policies
              </h2>
              <p class="text-gray-600 mb-5">
                Information and support for policies with Citizens Insurance.
              </p>
            </div>
            <button
              class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition-colors mt-4"
              @click="showCitizensInfo"
            >
              Learn More
            </button>
          </div>
          <!-- Card 2 for Chat Support -->
          <div
            class="card relative rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow flex flex-col justify-between"
          >
            <!-- Recommended Badge -->
            <div
              class="absolute top-0 right-0 bg-green-500 text-white text-sm py-1 px-3 rounded-bl-lg rounded-tr-lg"
            >
              Recommended
            </div>
            <div>
              <img
                src="@/assets/images/customerServiceIcons/liveChat.svg"
                alt="Live Chat Support"
                class="h-20 mb-4 mx-auto"
                loading="lazy"
              />
              <h2 class="text-lg font-semibold text-gray-700 mb-3">
                Live Chat Support
              </h2>
              <p class="text-gray-600 mb-5">
                Instant support at your fingertips. Chat with us now for quick
                answers and policy assistance.
              </p>
            </div>
            <button
              id="chatNowButton"
              class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition-colors mt-4"
            >
              Chat Now
            </button>
          </div>
          <!-- Card 3 for Service Requests -->
          <div
            class="card rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow flex flex-col justify-between"
          >
            <div>
              <img
                src="@/assets/images/customerServiceIcons/policyChanges.svg"
                alt="Policy Changes"
                class="h-20 mb-4 mx-auto"
                loading="lazy"
              />
              <h2 class="text-lg font-semibold text-gray-700 mb-3">
                Policy Changes
              </h2>
              <p class="text-gray-600 mb-5">
                Make service requests for your policy through our web site.
              </p>
            </div>
            <button
              id="servicerequests"
              class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition-colors mt-4"
              @click="handleServiceRequests"
            >
              Policy Changes
            </button>
          </div>
          <!-- Card 4 for Lender Requests -->
          <div
            class="card rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow flex flex-col justify-between"
          >
            <div>
              <img
                src="@/assets/images/customerServiceIcons/mortgageeChanges.svg"
                alt="Mortgagee Changes"
                class="h-20 mb-4 mx-auto"
                loading="lazy"
              />
              <h2 class="text-lg font-semibold text-gray-700 mb-3">
                Mortgagee Changes
              </h2>
              <p class="text-gray-600 mb-5">
                Quick and easy requests for lenders to submit policy changes or
                receive proof of insurance
              </p>
            </div>
            <button
              id="lenderRequest"
              class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 transition-colors mt-4"
              @click="handleLenderRequests"
            >
              Lender Requests
            </button>
          </div>
          <!-- Card 4 for Phone Support  -->
          <!-- <div class="card rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow flex flex-col justify-between">
          <div>
            <Icon icon="ic:outline-phone-in-talk" class="icon mb-4 text-blue-600 mx-auto" style="font-size: 4rem;" />
            <h2 class="text-lg font-semibold text-gray-700 mb-3">Phone Support</h2>
            <p class="text-gray-600 mb-5">Give us a call and speak to our licensed customer service team for assistance at (800) 268-9137</p>
          </div>
          <a href="tel:8002689137" class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 transition-colors mt-4">Call Us</a>
        </div> -->
        </div>

        <!-- Second Row of Cards -->
        <div
          v-if="showCards"
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6"
        >
          <div class="hidden lg:block"></div>
          <!-- Card for Making Payments -->
          <div
            class="card relative rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow flex flex-col justify-between"
          >
            <img
              src="@/assets/images/customerServiceIcons/makeAPayment.svg"
              alt="Make a Payment"
              class="h-20 mb-4 mx-auto"
              loading="lazy"
            />
            <h2 class="text-lg font-semibold text-gray-700 mb-3">
              Make a Payment
            </h2>
            <p class="text-gray-600 mb-5">
              Use our directory to find your carrier and make payments through
              carrier payment links.
            </p>
            <router-link
              :to="`/customerservice/carrier-directory/make-payment`"
              class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 transition-colors mt-4"
            >
              Make a Payment
            </router-link>
          </div>

          <!-- Card for Filing Claims -->
          <div
            class="card relative rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow flex flex-col justify-between"
          >
            <img
              src="@/assets/images/customerServiceIcons/claims.svg"
              alt="Make a Claim"
              class="h-20 mb-4 mx-auto"
              loading="lazy"
            />
            <h2 class="text-lg font-semibold text-gray-700 mb-3">Claims</h2>
            <p class="text-gray-600 mb-5">
              Use our directory to find your carrier and make claims through
              carrier claims links.
            </p>
            <router-link
              :to="`/customerservice/carrier-directory/make-claim`"
              class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 transition-colors mt-4"
            >
              Make a Claim
            </router-link>
          </div>
          <div class="hidden lg:block"></div>
          <!-- <div class="card rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow">
        <Icon icon="ic:baseline-business-center" class="icon mb-4 text-blue-600 mx-auto" style="font-size: 4rem;" />
        <h2 class="text-lg font-semibold text-gray-700 mb-3">Real Estate & Mortgage</h2>
        <p class="text-gray-600 mb-5">Specialized support for Real Estate and Mortgage professionals.</p>
        <button class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 transition-colors">Learn More</button>
      </div> 

      
      <div class="card rounded-lg shadow-md p-6 text-center border border-gray-200 hover:shadow-lg transition-shadow">
        <Icon icon="mdi:partnership-outline" class="icon mb-4 text-blue-600 mx-auto" style="font-size: 4rem;" />
        <h2 class="text-lg font-semibold text-gray-700 mb-3">Carriers & Partners</h2>
        <p class="text-gray-600 mb-5">Information for our carriers and partners.</p>
        <button class="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 transition-colors">Get Info</button>
      </div> -->
        </div>
      </div>
      <makeAClaimMakeAPayment
        v-if="showMakePaymentMakeClaim"
        :carrierDirectoryType="directoryChoice"
      />
      <LenderRequest v-if="showLenderRequests" />
      <serviceRequests v-if="showServiceRequests" />
      <CitizensQandA v-if="showCitizensQandA" />
    </main>
    <AppFooter class="mt-auto" />
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import AppFooter from '../components/AppFooter.vue';
import AppHeaderDesktop from '../components/AppHeaderDesktop.vue';
import ImportantAnnouncements from '../components/ImportantAnnouncements.vue';
import CitizensQandA from '../components/customerservice/CitizensQandA.vue';
import FAQ from '../components/customerservice/FAQ.vue';
import serviceRequests from '../components/customerservice/serviceRequests.vue';
import LenderRequest from '../components/customerservice/managepolicy/serviceRequestForms.vue/lenderRequest.vue';
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import makeAClaimMakeAPayment from '../views/makeAClaimMakeAPayment.vue';
export default {
  name: 'CustomerServicePage',
  components: {
    Icon,
    ImportantAnnouncements,
    AppHeaderDesktop,
    makeAClaimMakeAPayment,
    AppFooter,
    CitizensQandA,
    FAQ,
    serviceRequests,
    LenderRequest,
  },
  setup() {
    // Reactive property for window width
    const windowWidth = ref(window.innerWidth);

    // Computed property to determine if the screen is mobile size
    const isMobile = computed(() => windowWidth.value < 768);

    // Function to update the window width
    const updateWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    // Mounted lifecycle hook
    onMounted(() => {
      window.addEventListener('resize', updateWidth);
      setupChatwootButton();
    });

    // BeforeUnmount lifecycle hook
    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWidth);
    });

    const setupChatwootButton = () => {
      const chatButton = document.getElementById('chatNowButton');
      if (chatButton) {
        chatButton.addEventListener('click', () => {
          if (window.$chatwoot) {
            window.$chatwoot.toggle('open');
          }
        });
      }
    };

    return { isMobile, setupChatwootButton };
  },
  data() {
    return {
      showCards: true,
      showCitizensQandA: false,
      showServiceRequests: false,
      showLenderRequests: false,
      showMakePaymentMakeClaim: false,
    };
  },
  methods: {
    handleServiceRequests() {
      this.showCards = false;
      this.showServiceRequests = true;
    },
    handleLenderRequests() {
      this.showCards = false;
      this.showLenderRequests = true;
    },
    showCitizensInfo() {
      this.showCards = false;
      this.showCitizensQandA = true;
    },
    resetToDefaultView() {
      this.showCards = true;
      this.showServiceRequests = false;
      this.showCitizensQandA = false;
      this.showLenderRequests = false;
    },
    handleClaims() {
      this.showCards = false;
      this.showMakePaymentMakeClaim = true;
      this.directoryChoice = 'Make a Claim'; // Set the choice to "Make a Claim"
    },
    handlePayments() {
      this.showCards = false;
      this.showMakePaymentMakeClaim = true;
      this.directoryChoice = 'Make a Payment'; // Set the choice to "Make a Payment"
    },
  },
};
</script>
